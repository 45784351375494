import React, {useState} from 'react';
import { Row, Col } from 'reactstrap';
import { Select } from '../FormInput';
import Flatpickr from "react-flatpickr";
import {getDateISOFormat} from "../../services/utilities";

const FilterComponent = ({
     filters,
     handleClearFilter,
     activateFilter,
     handleActiveFilter,
     handleFilter,
     dateFilter = true,
     params,
     setParams
   }) => {
  const [clearFilter, setClearFilter] = useState(false);

  return (
      activateFilter && (
          <Row className={`w-100 align-content-center ${activateFilter ? 'visible' : 'hidden'}`}>
            <Col className="d-flex flex-wrap align-items-center gap-2">
              {filters.map((filter, index) => (
                  <div key={index} className="p-2">
                    <Select
                        defaultOption={filter.label}
                        selectedValue={filter.value}
                        options={filter.options}
                        handleFilter={(e) => {
                          handleFilter(e, filter.label);
                        }}
                        className="border-danger bglightred text-black"/>
                  </div>
              ))}
              {dateFilter && (
                    <Flatpickr
                        id="val7"
                        className='flatpickr-input button-filter x-2 d-flex align-items-center justify-content-between rounded-3 gap-2 py-2 px-3 m-2 w-auto'
                    placeholder="Select Date Range"
                        value={
                          params.fromDate && params.toDate
                              ? [params.fromDate, params.toDate]
                              : params.fromDate && !params.toDate
                                  ? [params.fromDate, ""]
                                  : ["", ""]
                        }
                        options={{
                          mode: "range",
                          maxDate: new Date(),
                          dateFormat: "Y-m-d",
                          defaultDate: [""],
                        }}
                        onChange={(date) => {
                          setClearFilter(true);
                          if (!date) return;
                          if (date.length === 1) {
                            const fromDate = getDateISOFormat(date[0]);
                            return setParams({
                              product: params.product,
                              status: params.status,
                              channel: params.channel,
                              type: params.type,
                              fromDate: fromDate,
                              toDate: "",
                            });
                          } else {
                            const startDate = getDateISOFormat(date[0]);
                            const toDate = true;
                            const endDate = getDateISOFormat(date[1], toDate);
                            return setParams({
                              product: params.product,
                              status: params.status,
                              channel: params.channel,
                              type: params.type,
                              fromDate: startDate,
                              toDate: endDate,
                            });
                          }
                        }}
                    />
              )}
              {clearFilter && (
                  <div className="p-2">
                    <button
                        name="Clear Filter"
                        onClick={() => {
                          setClearFilter(false)
                          handleClearFilter()
                        }}
                        className='clear-button'
                    >
                      Clear Filter
                    </button>
                  </div>
              )}
              <div className="p-2">
                <button
                    name="Close Filter"
                    onClick={handleActiveFilter}
                    className='clear-button'
                >
                Close Filter
                </button>
              </div>
            </Col>
          </Row>
      )
  );
};

export default FilterComponent;
