import VerifyProfileComponent from '../components/VerifyProfile';

const VerifyProfile = () => {
  return (
      <div className="d-flex flex-column" style={{ minHeight: '100vh' }}>
        <VerifyProfileComponent />
      </div>
  );
};

export default VerifyProfile;
