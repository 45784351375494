import { Card, CardBody, Col, Row, Spinner } from "reactstrap";
import { Container } from "react-bootstrap";
import FooterLogo from "../assets/images/footer.svg";
import ProfileRequestTable from "../util/ProfileRequestTable";
import { ArrowLeft } from "react-feather";
import { useCallback, useEffect, useState } from "react";
import EmptyImg from "../assets/images/empty.png";
import { Link } from "react-router-dom";
import { request, getDateISOFormat } from "../services/utilities";
import ReactPaginate from "react-paginate";
import { CustomButton } from "./shared/CustomButton";
import { profileUpdateRequestGroupOptions } from "../util/data";
import { Select } from "../components/FormInput";
import Flatpickr from "react-flatpickr";
import {DESIGNATION} from "../services/constants";
import {useAuthContext} from "../context/UserProvider";

const ManageUpdateProfileRequest = () => {
  const { userType } = useAuthContext();
  const [, setError] = useState([]);
  const [requests, setRequests] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentCount, setCurrentCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [firstItemOnPage, setFirstItemOnPage] = useState(0);
  const [lastItemOnPage, setLastItemOnPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [activateFilter, setActivateFilter] = useState(false);
  const [clearFilter, setClearFilter] = useState(false);

  const [requestCounts, setRequestCounts] = useState({
    totalRequests: 0,
    approvedRequests: 0,
    pendingRequests: 0,
    rejectedRequests: 0,
  });

  const [params, setParams] = useState({
    status: "",
    fromDate: "",
    toDate: "",
  });

  // Card style
  const columnLayout =
      userType === DESIGNATION.SUPPORTOFFICER ? "col-xl-6" : "col-xl-3";

  const fetchProfileRequest = useCallback(async () => {
    setLoading(true);
    const url = `Profile/GetAccountUpdateRequests?PageNumber=${currentPage}&pageSize=20&${
      params.toDate && `ToDate=${params.toDate}`
    }&${params.fromDate && `FromDate=${params.fromDate}`}&&${
      params.status && `status=${params.status}`
    }`;
    try {
      const rs = await request(url, "GET", true);
      if (rs.code === "OK") {
        setRequests(rs.payload.items);
        setTotalItemsCount(rs.payload.totalItemCount);
        setLastItemOnPage(rs.payload.lastItemOnPage);
        setFirstItemOnPage(rs.payload.firstItemOnPage);
        setTotalPages(rs.payload.pageCount);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError([
        "Unable to retrieve the profile pdate request at the moment. Please try again later",
      ]);
      const timer = setTimeout(() => setError([]), 3000);
      return () => clearTimeout(timer);
    }
  }, [currentPage, params.status, params.fromDate, params.toDate]);

  const handlePageChange = (selectedPage) => {
    setCurrentCount(currentCount + 1);
    setCurrentPage(selectedPage.selected + 1);
  };
  const handleActiveFilter = () => {
    if (activateFilter) {
      setParams({
        status: params.status,
        fromDate: "",
        toDate: "",
      });
    }
    setActivateFilter(!activateFilter);
  };
  const handleClearFilter = () => {
    setCurrentPage(1);
    setClearFilter(false);
    setParams({
      status: "",
      fromDate: "",
      toDate: "",
    });
  };
  const handleFilter = (e, group) => {
    setCurrentPage(1);
    if (group === "Status Type") {
      setParams({
        status: e,
        fromDate: params.fromDate,
        toDate: params.toDate,
      });
    } else {
      setParams({
        status: "",
        fromDate: "",
        toDate: "",
      });
    }
  };

  const fetchCounts = useCallback(async () => {
    try {
      setLoading(true); // Assuming you have a loading state to indicate loading status
      const url = `Profile/GetAccountUpdateRequestCounts`;
      const response = await request(url, "GET", true); // Adjust this call according to your request function's signature
      if (response.code === "OK") {
        setRequestCounts({
          totalRequests: response.payload.totalRequests,
          approvedRequests: response.payload.totalApprovedRequests,
          pendingRequests: response.payload.totalPendingRequests,
          rejectedRequests: response.payload.totalRejectedRequests,
        });
      } else {
        setError(["Failed to fetch request counts"]);
        console.error("Failed to fetch request counts", response);
      }
    } catch (error) {
      setLoading(false);
      setError(["Error fetching request counts"]);
      console.error("Error fetching request counts", error);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchProfileRequest();
    fetchCounts();
  }, [fetchProfileRequest, fetchCounts]);
  return (
    <Container>
      <Col xl={6} sm={12} md={6}>
        <Link
          to="/admin/practitioner"
          className="mb-3 btn text-decoration-none"
          style={{ background: "#EFEFEF" }}
        >
          <ArrowLeft size={16} /> Back
        </Link>
      </Col>
      <Row>
        <Card className="border-none border-white">
          <h1 className="h1-color mb-0">Manage Profile Update Requests </h1>

          <CardBody className="p-0 mt-3">
            <Row>
              <Col>
                <p className="p-color fs-5 mx-2">
                  {" "}
                  {requests?.length >= 1
                    ? `${firstItemOnPage}-${lastItemOnPage} / ${totalItemsCount}`
                    : "0"}{" "}
                  Practitioners
                </p>
              </Col>
              <Col>
                <div className="float-end">
                  {loading ? <Spinner color="danger" /> : ""}
                </div>
              </Col>
            </Row>
            <Row>
              <div className="mb-4">
                <CustomButton
                  name="Filter"
                  iconType
                  icon="filter"
                  color={activateFilter ? "red" : "white"}
                  action={handleActiveFilter}
                />
              </div>
            </Row>
            <Row className="mb-3">
              {activateFilter && (
                <Row className="d-flex gap-3">
                  {profileUpdateRequestGroupOptions.map(
                    (requestGroup, index) => {
                      return (
                        <Col xl={2} sm={12} md={4} key={index}>
                          <Select
                            defaultOption={requestGroup.group}
                            selectedValue={params[`${requestGroup.filterName}`]}
                            options={requestGroup.options}
                            handleFilter={(e) => {
                              setClearFilter(true);
                              handleFilter(e, requestGroup.group);
                            }}
                            overideStyle="rounded-pill border-danger bglightred text-grey"
                          />
                        </Col>
                      );
                    }
                  )}
                  <Col xl={3} sm={12} md={4}>
                    <Flatpickr
                      id="val7"
                      className="form-control border-danger bglightred rounded-pill"
                      placeholder="Select Date Range"
                      style={{ backgroundColor: "#e9ecef" }}
                      value={
                        params.fromDate && params.toDate
                          ? [params.fromDate, params.toDate]
                          : ""
                      }
                      options={{
                        mode: "range",
                        maxDate: new Date(),
                        dateFormat: "Y-m-d",
                        defaultDate: ["", ""],
                      }}
                      onChange={(date) => {
                        setClearFilter(true);
                        if (!date) return;
                        if (date.length === 1) {
                          const fromDate = getDateISOFormat(date[0]);
                          return setParams({
                            status: params.status,
                            fromDate: fromDate,
                            toDate: "",
                          });
                        } else {
                          const startDate = getDateISOFormat(date[0]);
                          const toDate = true;
                          const endDate = getDateISOFormat(date[1], toDate);
                          return setParams({
                            status: params.status,
                            fromDate: startDate,
                            toDate: endDate,
                          });
                        }
                      }}
                    />
                  </Col>
                  <Col
                    xl={3}
                    sm={12}
                    md={8}
                    className="d-flex gap-3 mt-2 mt-md-0"
                  >
                    {clearFilter && (
                      <CustomButton
                        name="Clear Filter"
                        color="lightRed"
                        action={handleClearFilter}
                        overideStyle="rounded-pill"
                      />
                    )}
                    <CustomButton
                      name="Close Filter"
                      color="lightRed"
                      action={handleActiveFilter}
                      overideStyle="rounded-pill"
                    />
                  </Col>
                </Row>
              )}
            </Row>

            <Row className="pt-4 pb-3">
              <div className={`${columnLayout} col-md-6 col-sm-12 mb-3`}>
                <div className="card crm-widget">
                  <div className="card-body p-0">
                    <div className="py-4 px-3">
                      <h5 className="text-muted fs-13">Total Requests</h5>
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                          <h2 className="mb-0">{requestCounts.totalRequests}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${columnLayout} col-md-6 col-sm-12 mb-3`}>
                <div className="card crm-widget">
                  <div className="card-body p-0">
                    <div className="py-4 px-3">
                      <h5 className="text-muted fs-13">Approved Requests</h5>
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                          <h2 className="mb-0">{requestCounts.approvedRequests}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${columnLayout} col-md-6 col-sm-12 mb-3`}>
                <div className="card crm-widget">
                  <div className="card-body p-0">
                    <div className="py-4 px-3">
                      <h5 className="text-muted fs-13">Pending Requests</h5>
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                          <h2 className="mb-0">{requestCounts.pendingRequests}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${columnLayout} col-md-6 col-sm-12 mb-3`}>
                <div className="card crm-widget">
                  <div className="card-body p-0">
                    <div className="py-4 px-3">
                      <h5 className="text-muted fs-13">Rejected Requests</h5>
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                          <h2 className="mb-0">{requestCounts.rejectedRequests}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Row>

            <div className="">
              {requests?.length >= 1 ? (
                <ProfileRequestTable
                  data={requests}
                  fetchProfileRequest={fetchProfileRequest}
                />
              ) : (
                <div>
                  <div>
                    <ProfileRequestTable
                      data={requests?.length >= 1 ? requests : []}
                    />
                    <div className="text-center pt-5">
                      <img src={EmptyImg} className="img-fluid" alt="empty" />
                      <p className="p-color pt-2">No Record to show</p>
                      <p className="p-color pt-2">
                        Click the Add Practitioner button on the top <br />
                        right to add new practitioner profile
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {requests?.length >= 1 ? (
                <div className="d-flex mt-5  justify-content-center">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel="next >"
                    onPageChange={handlePageChange}
                    pageRangeDisplayed={5}
                    pageCount={totalPages}
                    previousLabel="< previous"
                    // forcePage={currentPage}
                    containerClassName={"pagination"}
                    previousLinkClassName={"pagination__link"}
                    nextLinkClassName={"pagination__link"}
                    disabledClassName={"pagination__link--disabled"}
                    activeClassName={"pagination__link--active"}
                  />
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="text-end " style={{ paddingTop: "5rem" }}>
              <p className="mb-5">
                <img
                  src={FooterLogo}
                  width="20"
                  className="img-fluid"
                  alt="footer"
                />{" "}
                Powered by Milsat Technologies
              </p>
            </div>
          </CardBody>
        </Card>
      </Row>
    </Container>
  );
};

export default ManageUpdateProfileRequest;
