import ForgotPasswordComponent from "../components/ForgotPassword";

const ForgotPassword = () => {
  return (
    <div style={{ backgroundColor: "#eee", height: "100vh" }}>
      <ForgotPasswordComponent />
    </div>
  );
};

export default ForgotPassword;
