import Navbar from "../components/Navbar";
import RegisterPremisesComponent from "../components/RegisterPremises";
import { ToastContainer } from "react-toastify";

const RegisterPremises = () => {
  return (
    <>
      <Navbar />
      <div style={{ paddingTop: "6rem"}}>
        <RegisterPremisesComponent/>
      </div>
      <ToastContainer />
    </>
  );
};

export default RegisterPremises;
