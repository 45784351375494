import React from 'react';
import SortImg from "../../assets/images/chevron-selector.svg";

function SortButton({onClick}) {
  return (
      <span id="sort" style={{cursor: "pointer"}} onClick={onClick}>
      <img src={SortImg} className="img-fluid float-end" alt="sort"/>
    </span>
  );
}

const ReusableTable = ({headers, data, onSortChange, onRowClick, cellRenderer }) => {
  return (
      <table
          id="table-border-collapse"
          className="table table-borderless table-hover align-middle table-nowrap table-no-gap"
      >
        <thead className="table-header-red">
        <tr>
          {headers.map((header) => (
              <td key={header.key} className="table-header">
                {header.label}
                <SortButton onClick={() => onSortChange(header.key)}/>
              </td>
          ))}
        </tr>
        </thead>
        <tbody className="table-red">
        {data.map((row) => (
            <tr
                key={row.id}
                className="table-body"
                onClick={() => onRowClick && onRowClick(row)}
            >
              {headers.map((header) => (
                  <td key={header.key} style={{padding: '1em'}}>
                    {header.key === 'paymentDate' ? new Date(row[header.key]).toDateString() :
                        header.key === 'totalAmountOwed' ? `₦${new Intl.NumberFormat("en-US").format(row[header.key])}` :
                            cellRenderer ? cellRenderer(row, header.key) : header.renderer ? header.renderer(row) : row[header.key] || "N/A"
                    }
                  </td>
              ))}
            </tr>
        ))}
        </tbody>
      </table>
  );
};

export default ReusableTable;
