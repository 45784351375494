export const payments = [
  {
    vcnId: "VCN000010",
    description: "Marrilee",
    year: "2022",
    amount: "20000",
    amountPaid: "20000",
    gender: "Female",
    paymentType: "Card Payment",
    paymentDate: "6 May 2020",
    action: "Pay Now",
  },
  {
    vcnId: "VCN001010",
    description: "lorem dd jskd sdsss jjjsdjsd",
    year: "2022",
    amount: "20000",
    amountPaid: "20000",
    gender: "Female",
    paymentType: "Card Payment",
    paymentDate: "6 May 2020",
    action: "Pay Now",
  },
  {
    vcnId: "VCN020010",
    description: "Marrilee",
    year: "2022",
    amount: "30000",
    amountPaid: "30000",
    gender: "Female",
    paymentType: "Card Payment",
    paymentDate: "6 May 2020",
    action: "Pay Now",
  },
];

export const practitioners = [
  {
    id: 1,
    fullname: "Chukwudi Chukwudi Ayobemi ",
    phone: "090887766",
    email: "ayobemi@gmail.com",
    amount: "20000",
    paymentStatus: "Pending Payments",
    date: "4 May, 2020",
  },
  {
    id: 2,
    fullname: "Mercy Ayobemi ",
    phone: "070887766",
    email: "mercy@gmail.com",
    amount: "40000",
    paymentStatus: "Paid",
    date: "6 May, 2020",
  },
];

export const termsAndCondtion = [
  {
    header: "Terms and Condition",
    subHeader: "Acceptance of Terms",
    text: "By accessing or using the Veterinary Care Network (VCN') website and mobile application (collectively, the 'Platform'), you agree to comply with and be bound by these Terms and Conditions ('Terms'). If you do not agree to these Terms, please do not use the Platform. These Terms govern your use of the Platform, including any content, features, or services offered through the Platform.",
  },
  {
    header: "Terms and Condition",
    subHeader: "User Registration",
    text: "To access certain features of the Platform, you may be required to register an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. VCN reserves the right to suspend or terminate your account at its sole discretion.",
  },
  {
    header: "Terms and Condition",
    subHeader: "Eligibility",
    text: "You must be at least 18 years old and a licensed veterinary doctor to use the Platform. By using the Platform, you represent and warrant that you meet these eligibility requirements.",
  },
  {
    header: "Terms and Condition",
    subHeader: "Services Provided",
    text: "VCN provides a platform for veterinary doctors to register their services and connect with pet owners seeking veterinary care. VCN does not provide veterinary care services itself and is not responsible for the actions, omissions, or quality of services provided by registered veterinary doctors.",
  },
  {
    header: "Terms and Condition",
    subHeader: "Veterinary Doctor Responsibilities",
    text: "Registered veterinary doctors are responsible for:",
    a: "a. Providing accurate and up-to-date information about their qualifications and services",
    b: "b. Delivering veterinary care services in accordance with applicable laws, regulations, and industry standards.",
    c: "c. Maintaining the confidentiality of patient information and complying with privacy laws.",
  },
  {
    header: "Terms and Condition",
    subHeader: "User Conduct",
    text: "You agree not to:",
    a: "a. Use the Platform for any unlawful or fraudulent purpose.",
    b: "b. Upload, post, or transmit any content that is harmful, offensive, defamatory, or infringing on intellectual property rights.",
    c: "c. Use the Platform in a manner that disrupts, interferes with, or damages the operation of the Platform.",
    d: "d. Attempt to gain unauthorized access to any portion of the Platform or any related systems or networks.",
    e: "e. Share your account credentials or access the account of another user without permission.",
  },
  {
    header: "Terms and Condition",
    subHeader: "Privacy Policy",
    text: "Your use of the Platform is also governed by our Privacy Policy. By using the Platform, you consent to the collection and use of your information as described in the Privacy Policy.",
  },
  {
    header: "Terms and Condition",
    subHeader: "Intellectual Property",
    text: "Your use of the Platform is also governed by our Privacy Policy, which can be found  By using the Platform, you consent to the collection and use of your information as described in the Privacy Policy.",
  },
  {
    header: "Terms and Condition",
    subHeader: "Limitation of Liability",
    text: "To the fullest extent permitted by law, VCN disclaims all warranties, whether express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement. VCN shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or for any loss of profits or revenues.",
  },
  {
    header: "Terms and Condition",
    subHeader: "Termination",
    text: "VCN reserves the right to terminate or suspend your access to the Platform at any time and for any reason, without notice.",
  },
  {
    header: "Terms and Condition",
    subHeader: "Changes to Terms and Platform",
    text: "VCN may update these Terms at any time without notice. You are responsible for reviewing these Terms regularly. Continued use of the Platform after changes to these Terms are posted constitutes your acceptance of the changes.",
  },
  {
    header: "Terms and Condition",
    subHeader: "Governing Law",
    text: "These Terms are governed by and construed in accordance with the laws of Nigeria. Any disputes arising under or in connection with these Terms shall be subject to the exclusive jurisdiction of the Federal Court.",
  },
  {
    header: "Terms and Condition",
    subHeader: "Contact Information",
    text: "If you have any questions or concerns about these Terms, please contact us at Email: vetcouncilnig_org@yahoo.com. Tel: 09036262132, 09038978212",
  },
];

export const paymentStatus = {
  pending: "pending",
  successful: "successful",
  failed: "failed",
};

//Enum for DesignationType
export const DesignationType = {
  Veterinarian: "Veterinarian",
  VeterinarianParaProfessional : "VeterinarianParaProfessional ",
  NonVeterinarian: "Non-Veterinarian",
}

//Enum for veterinarianTabType
export const VeterinarianTabType = {
  Update: "update-profile",
  Verify: "verify-profile",
}

export const paymentStatusOptions = [
  { value: "paymentSuccessful", label: "Payment Successful" },
  { value: "paymentInitiated", label: "Payment Initiated" },
  { value: "others", label: "Others" },
];

// Enum for payment Channels
export const paymentChannel = {
  remita: "Remita",
  paystack: "Paystack",
  offline: "Offline"
};

// Enum for payment type
export const productType = {
  practitionerLicense: "Practitioners_License",
  premiseLicense: "Premise_License",
  identityCard: "Identity_Card",
  vetSeal: "Vet_Seal",
};


// Enum for AprovalStatus type
export const approvalStatusType = {
  Approved: "Approved",
  Onboarding: "Onboarding",
  AwaitingInspection: "Awaiting_Inspection",
};

export const paymentsGroupOptions = [
  {
    group: "Product Type",
    filterName: "product",
    options: [
      { value: "", label: "Product Type" },
      { value: "PractiseLicense", label: "Practise License" },
      { value: "PremiseLicense", label: "Premise License" },
      { value: "Accessories", label: "Accessories" },
      // { value: "PremiseForm", label: "Premise Form" },
      // { value: "DeliveryFee", label: "Delivery Fee" },
    ],
  },
  {
    group: "Provider Type",
    filterName: "channel",
    options: [
      { value: "", label: "Provider Type" },
      { value: "remita", label: "Remita" },
      { value: "paystack", label: "Paystack" },
      { value: "offline", label: "Offline" },
    ],
  },
  {
    group: "Status Type",
    filterName: "status",
    options: [
      { value: "", label: "Status Type" },
      { value: "pending", label: "Pending" },
      { value: "successful", label: "Successful" },
      { value: "failed", label: "Failed" },
    ],
  },
];

export const profileUpdateRequestGroupOptions = [
  {
    group: "Status Type",
    filterName: "status",
    options: [
      { value: "", label: "Status Type" },
      { value: "pending", label: "Pending" },
      { value: "approved", label: "Approved" },
      { value: "rejected", label: "Rejected" },
    ],
  },
];


export const PremiseGroupOptions = [
  {
    group: "Approval Status",
    filterName: "approvalStatus",
    options: [
      { value: "", label: "Approval Status" },
      { value: "Approved", label: "Approved" },
      { value: "Onboarding", label: "Onboarding" },
      { value: "AwaitingInspection", label: "Awaiting Inspection" },
    ],
  },
]

export const PractitionerGroupOptions = [
  {
    group: "Access Status",
    filterName: "accessStatus",
    options: [
      { value: "", label: "Access Status" },
      { value: "Approve", label: "Approve" },
      { value: "Disapprove", label: "Disapprove" },
    ],
  },
  {
    group: "Profile Updated",
    filterName: "firstTimeUpdate",
    options: [
      { value: "", label: "Profile Updated" },
      { value: true, label: "Updated"},
      { value: false, label: "Not Updated" }
    ]
  },
  {
    group: "Designations",
    filterName: "designations",
    options: [
      { value: "", label: "Designations" },
      { value: "Guest", label: "Guest"},
      { value: "Veterinarian", label: "Veterinarian" },
      { value: "SupportOfficer", label: "Support Officer" },
      { value: "CouncilManagement", label: "Council Management" },
      { value: "Administrator", label: "Administrator" }
    ]
  }
];

export const designationForNonVeterinarian = [
  {value: "Guest", label: "Guest"},
  {value: "SupportOfficer", label: "Support Officer"},
  {value: "Administrator", label: "Administrator"},
  {value: "StateOfficer", label: "State Officer"},
  {value: "CouncilManagement", label: "Council Management"},
]
