import {
  ButtonDropdown,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Spinner,
} from "reactstrap";
import { Container } from "react-bootstrap";
import FooterLogo from "../assets/images/footer.svg";
import PractitionerTable from "../util/PractitonerTable";
import { Plus, Search, ArrowLeft } from "react-feather";
import { useCallback, useEffect, useState } from "react";
import EmptyImg from "../assets/images/empty.png";
import { Link, useLocation } from "react-router-dom";
import { currentDate, request } from "../services/utilities";
import ReactPaginate from "react-paginate";
import { CustomButton } from "../components/shared/CustomButton";
import Download from "./shared/Download";
import { useAuthContext } from "../context/UserProvider";
import { DESIGNATION } from "../services/constants";
import ConfirmQRCodeModal from "./modals/ConfirmGenerateQRCode";
import { DesignationType } from "../util/data";
import OnboardingModal from "./modals/OnboardingModal";
import { API_URI, USER_COOKIE } from "../services/constants";
import SSRStorage from "../services/storage";
import LoadingModal from "./modals/LoadingModal";
import SuccessModal from "./modals/SuccessModal";
import ErrorModal from "./modals/ErrorModal";
import { Select } from "../components/FormInput";
import { PractitionerGroupOptions } from "../util/data";
import UserDetailsModal from "./modals/UserDetailsModal";
const storage = new SSRStorage();

const Practitioner = () => {
  const { userType } = useAuthContext();
  const [practitioners, setPractitioners] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentCount, setCurrentCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false); // New state for modal toggle
  const [selectedId, setSelectedId] = useState(null); // State to keep track of selected ID
  const [selectedVcnID, setSelectedVcnID] = useState(null); // State to keep track of selected ID
  const [selectedUserName, setSelectedUserName] = useState(null); // State to keep track of selected user
  const [selectedUser, setSelectedUser] = useState(null);
  const [showSelectedUser, setShowSelectedUser] = useState(false);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [firstItemOnPage, setFirstItemOnPage] = useState(0);
  const [lastItemOnPage, setLastItemOnPage] = useState(0);
  const [activateFilter, setActivateFilter] = useState(false);
  const [clearFilter, setClearFilter] = useState(false);
  // const [isvcn, setIsvcn] = useState(false);
  // const [isfirstname, setIsfirstname] = useState(false);
  // const [islastname, setIslastname] = useState(false);
  // const [isphone, setIsphone] = useState(false);
  // const [isapprove, setIsapprove] = useState(false);
  // const [isdisapprove, setIsdisapprove] = useState(false);
  const [count, setCount] = useState({
    totalUsers: 0,
    totalUpdatedUsers: 0,
    totalDebtors: 0,
    totalAmountOwed: 0.0,
  });
  const [params, setParams] = useState({
    accessStatus: "",
    firstTimeUpdate: "",
    designations: "",
  });
  const [vcn, setVcn] = useState("");
  const [loading, setLoading] = useState(false);
  const [practitionerDropdown, setPractitionerDropdown] = useState(false);
  const [showBoard, setShowBoard] = useState(false);
  const [showL, setShowL] = useState(false);
  const [showS, setShowS] = useState(false);
  const [showE, setShowE] = useState(false);
  const [file, setFile] = useState(null);
  const [error, setError] = useState([]);
  const [requestCount, setRequestsCount] = useState(0);
  const bgTxt = "Success!!";
  const smTxt = "All Practitioners have been onboarded successfully";
  const location = useLocation().pathname;

  // Card style
  const columnLayout =
    userType === DESIGNATION.SUPPORTOFFICER ? "col-xl-6" : "col-xl-3";

  const fetchPractitioners = useCallback(
    async (vcn) => {
      setLoading(true);
      try {
        const url = `profile/getusers?PageSize=50&PageNumber=${currentPage}&${
          vcn ? `vcnId=${vcn}` : ""
        }&${params.accessStatus ? `accessStatus=${params.accessStatus}` : ""}&${
          params.firstTimeUpdate !== "" ?
          `IsFirstTimeUpdateDone=${params.firstTimeUpdate}`: ""
        }&${params.designations ? `Designations=${params.designations}` : ""}`;
        const rs = await request(url, "GET", true);
        const filteredPractitioners = rs.payload?.items.filter(practitioner => practitioner.designation !== 'VeterinarianParaProfessional');
        setTotalItemsCount(rs.payload?.totalItemCount);
        setLastItemOnPage(rs.payload?.lastItemOnPage);
        setFirstItemOnPage(rs.payload?.firstItemOnPage);
        setPractitioners(filteredPractitioners);
        setTotalPages(rs.payload?.pageCount);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    },
    [currentPage, params.accessStatus, params.designations, params.firstTimeUpdate]
  );

  const fetchPractitionerCount = useCallback(async () => {
    setLoading(true);
    try {
      const url = `profile/getusercounts`;
      const rs = await request(url, "GET", true);
      const { totalUsers, totalUpdatedUsers, totalDebtors, totalAmountOwed } =
        rs.payload;
      setCount({
        totalUsers,
        totalUpdatedUsers,
        totalDebtors,
        totalAmountOwed,
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }, []);
  const fetchProfileRequestCount = useCallback(async () => {
    const url = `Profile/GetAccountUpdateRequests?status=Pending`;
    try {
      const rs = await request(url, "GET", true);
      if (rs.code === "OK") {
        setRequestsCount(rs.payload.items.length);
      }
    } catch (err) {
      setError(["Failed to fetch profile requests, kindly try again"]);
    }
  }, []);

  const handleOnboarding = async () => {
    setShowBoard(false);
    setShowL(true);
    const user = await storage.getItem(USER_COOKIE);
    const url = `${API_URI}/Profile/UploadUsersExcel`;
    const data = new FormData();
    data.append("file", file);
    try {
      const rs = await fetch(url, {
        method: "POST",
        body: data,
        //  Set headers manually for single file upload
        headers: {
          Authorization: `Bearer ${user.token}`,
          ContentType: `multipart/form-data"`,
          length: `${file.size}`,
          name: `${file.name}`,
          fileName: `${file.name}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setShowL(false);
          if (data.code === "OK") {
            setShowS(true);
            setFile(null);
            fetchPractitioners();
          } else {
            setFile(null);
            setError([data.errors]);
            setShowE(true);
          }
        });
    } catch (err) {
      setFile(null);
      setShowL(false);
      setError(["Failed to onboard practitioner, kindly try again later"]);
      setShowE(true);
    }
  };

  const downloadExcelSheet = useCallback(async (event) => {
    // If called in response to an event and you want to prevent the default browser action:
    event.preventDefault();

    try {
      setShowBoard(false);
      setLoading(true);
      const url = `Profile/GetUserExcelSheet`;
      const response = await request(url, "GET", true, null, "file");
      let outputFilename = `UserExcelSheet.xlsx`;
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up
      setLoading(false);
    } catch (err) {
      setError(["Failed to download user excel sheet, kindly try again later"]);
      setLoading(false);
    }
  }, []);

  // Export all initiated payments as excel sheet
  const handleDownloadRecords = useCallback(
    async (type, pageSize = 50) => {
      setLoading(true);
      try {
        const url = `Profile/ExportUsersExcel?pageSize=${pageSize}&pageNumber=${currentPage}&${
          vcn ? `vcnId=${vcn}` : ""
        }&${params.accessStatus && `accessStatus=${params.accessStatus}`}&${
          params.firstTimeUpdate &&
          `IsFirstTimeUpdateDone=${params.firstTimeUpdate}`
        }`;
        const response = await request(url, "GET", true, null, "file");
        let outputFilename = "";

        if (type === "all") {
          outputFilename =
            `All_${currentPage}` +
            `${params.accessStatus !== "" ? `_${params.accessStatus}d` : ""}` +
            `${
              params.firstTimeUpdate !== ""
                ? `_${
                    params.firstTimeUpdate === true ? "Updated" : "Not_Updated"
                  }`
                : ""
            }` +
            `_Practitioners_Records(${currentDate}).xlsx`;
        } else {
          outputFilename =
            `Page_${currentPage}` +
            `${params.accessStatus !== "" ? `_${params.accessStatus}d` : ""}` +
            `${
              params.firstTimeUpdate !== ""
                ? `_${
                    params.firstTimeUpdate === true ? "Updated" : "Not_Updated"
                  }`
                : ""
            }` +
            `_Practitioners_Records(${currentDate}).xlsx`;
        }
        const blobUrl = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = blobUrl;
        link.setAttribute("download", outputFilename);
        document.body.appendChild(link);
        link.click();
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setError(
          "Failed to get all initiated Payments history, kindly try again later"
        );
      }
    },
    [currentPage, params.firstTimeUpdate, params.accessStatus]
  );
  const handlePageDownload = (type) => {
    let recordSize = 0;
    if (type === "All records") {
      recordSize = totalItemsCount;
      handleDownloadRecords("all", recordSize);
    }
    if (type === "Current page records") {
      recordSize = 50;
      handleDownloadRecords("one", recordSize);
    }
  };
  const handlePageChange = (selectedPage) => {
    setCurrentCount(currentCount + 1);
    setCurrentPage(selectedPage.selected + 1);
  };

  const generateQRCode = (event) => {
    event.stopPropagation();
    const ids = practitioners.map((practitioner) => practitioner.id);
    const vcnID = practitioners.map((practitioner) => practitioner.vcnId);
    const names = practitioners.map((practitioner) => practitioner.fullName);

    setSelectedId(ids); // Set the selected ID
    setSelectedVcnID(vcnID); // Set the selected vcnId
    setSelectedUserName(names); // Set the selected user's name
    setIsModalOpen(true); // Open the modal
  };

  const handleFilter = (e, group) => {
    if(group === "Profile Updated"){
      setParams(prevParams => ({...prevParams, firstTimeUpdate: e}));
    }else if(group === "Access Status"){
      setParams(prevParams => ({...prevParams, accessStatus: e}));
    }else if(group === "Designations"){
      setParams(prevParams => ({...prevParams, designations: e}));
    }else{
      setParams({accessStatus: "", firstTimeUpdate: "", designations: ""})
      handleClearFilter();
    } 
  }

  const handleActiveFilter = () => {
    if (activateFilter) {
      handleClearFilter();
    }
    setActivateFilter(!activateFilter);
  };

  const handleClearFilter = () => {
    setCurrentPage(1);
    setClearFilter(false);
    setParams({
      accessStatus: "",
      firstTimeUpdate: "",
    });
  };

  useEffect(() => {
    fetchPractitionerCount();
    fetchPractitioners();
    fetchProfileRequestCount();
  }, [fetchPractitioners, fetchPractitionerCount, fetchProfileRequestCount]);

  return (
    <Container>
      <Row>
        <Card className="border-none border-white">
          <Row className="d-flex align-items-center mb-3  ">
            <Col>
              <h1 className="h1-color mb-0">VCN Practitioners</h1>
            </Col>
            <Col className="d-flex justify-content-end align-items-center">
              <Link
                to={
                  location === "/admin/practitioner"
                    ? "/admin/dashboard"
                    : "/practitioner"
                }
                className="btn text-decoration-none"
                id="bggrey"
              >
                <ArrowLeft size={16} /> Back
              </Link>
            </Col>
          </Row>
          <Row>
            <Col xl={6} sm={12}>
              <p className="text-muted pt-3 fs-6 text-capitalize">
                {" "}
                This is a Directory of all registered vet doctors
              </p>
            </Col>
            <Col xl={6} sm={12}>
              <div className="float-end">
                <Link
                  className="btn text-decoration-none border-danger"
                  to="/admin/profile-update-request"
                >
                  {" "}
                  Profile Update Request
                  <span
                    style={{ fontSize: "12px", padding: "2px" }}
                    className="mx-2 px-1 text-center text-white bg-danger rounded"
                  >
                    {" "}
                    {requestCount}
                  </span>
                </Link>
              </div>
            </Col>
          </Row>
          <CardBody className="p-0">
            <Row className="pt-4 pb-3">
              <div className={` ${columnLayout} col-md-6 col-sm-12  mb-3`}>
                <div className="card crm-widget">
                  <div className="card-body p-0">
                    <div className="">
                      <div className="col">
                        <div className="py-4 px-3">
                          <h5 className="text-muted fs-13">
                            Total Registered Practitioners
                          </h5>
                          <div className="d-flex align-items-center">
                            <div className="flex-grow-1">
                              <h2 className="mb-0">{totalItemsCount}</h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={` ${columnLayout} col-md-6 col-sm-12  mb-3`}>
                <div className="card crm-widget">
                  <div className="card-body p-0">
                    <div className="">
                      <div className="col">
                        <div className="py-4 px-3">
                          <h5 className="text-muted fs-13">
                            Updated Practitioners
                          </h5>
                          <div className="d-flex align-items-center">
                            <div className="flex-grow-1">
                              <h2 className="mb-0">
                                {count?.totalUpdatedUsers}
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {userType === DESIGNATION.ADMIN && (
                <div className="col-xl-2 col-md-6 col-sm-12">
                  <div className="card crm-widget">
                    <div className="card-body p-0">
                      <div className="">
                        <div className="col">
                          <div className="py-4 px-3">
                            <h5 className="text-muted fs-13">Debtors</h5>
                            <div className="d-flex align-items-center">
                              <div className="flex-grow-1">
                                <h2 className="mb-0">{count?.totalDebtors}</h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {userType === DESIGNATION.ADMIN && (
                <div className="col-xl-4 col-md-6 col-sm-12">
                  <div className="card crm-widget">
                    <div className="card-body p-0">
                      <div className="">
                        <div className="col">
                          <div className="py-4 px-3">
                            <h5 className="text-muted fs-13">
                              Outstanding Payments
                            </h5>
                            <div className="d-flex align-items-center">
                              <div className="flex-grow-1">
                                <h2 className="mb-0">
                                  ₦
                                  {new Intl.NumberFormat("en-US").format(
                                    count?.totalAmountOwed
                                  )}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Row>
            <Row className="float-end">
              <Col className="float-end">
                {loading ? (
                  <div>
                    {" "}
                    <Spinner color="danger" />{" "}
                  </div>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="p-color fs-5 mx-2">
                  {" "}
                  {practitioners.length >= 1
                    ? `${firstItemOnPage}-${lastItemOnPage} / ${totalItemsCount}`
                    : "0"}{" "}
                  Practitioners
                </p>
              </Col>
            </Row>
            <Row className="mt-5 mb-3 d-flex align-items-center">
              <Col className="mb-3" xl={2} sm={12} md={6}>
                <CustomButton
                  name="Filter"
                  icon="filter"
                  iconType
                  color={activateFilter ? "red" : "white"}
                  action={handleActiveFilter}
                />
              </Col>
              <Col className="d-flex mb-3" xl={5} sm={12} md={6}>
                <div className="d-flex gap-2 align-items-center form-control">
                 <Search size={15} className="search-icon" />
                    <input
                      type="number"
                      className="w-100 border-0 custom-search"
                      placeholder="Search Practitioners by VCN Number"
                      onChange={(e) => {
                        setVcn(e.target.value);
                        if (e.target.value === "") {
                          fetchPractitioners();
                        }
                      }}
                      value={vcn}
                    />
                </div>
                <div className="fs-6 ms-1">
                  <button
                    onClick={() => {
                      setParams({
                        accessStatus: "",
                        firstTimeUpdate: "",
                      });
                      fetchPractitioners(vcn);
                      fetchPractitionerCount();
                    }}
                    className="p-2 border-0 rounded text-light d-flex align-items-center gap-1"
                    id="bgred"
                    placeholder="Search ID, Licences"
                  >
                     <Search size={15} color="white" className="search-icon" />
                    Search
                  </button>
                  
                </div>
              </Col>
              <Col xl={5} sm={12} md={12} className="d-flex align-items-center gap-2 justify-content-end mb-3">
                <ButtonGroup className="">
                  <ButtonDropdown
                    id="bgred"
                    isOpen={practitionerDropdown}
                    className="d-flex align-items-center"
                    toggle={() =>
                      setPractitionerDropdown(!practitionerDropdown)
                    }
                  >
                    <DropdownToggle caret id="bgred" style={{ border: "none" }}>
                      <Plus size={20} /> Add Veterinarian
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem className="mb-2">
                        <Link
                          to={`/admin/add-user?designation=${DesignationType.Veterinarian}`}
                          className="text-dark text-decoration-none "
                        >
                          Onboard Single Veterinarian
                        </Link>
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => setShowBoard(true)}
                        className="mb-1"
                      >
                        Bulk Onboarding
                      </DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                </ButtonGroup>
                <Link
                to={`/admin/add-user?designation=${DesignationType.NonVeterinarian}`}
                className="text-decoration-none"
              >
              <CustomButton
                    id="bgred"
                    iconType
                    icon="add"
                    color="red"
                    overideStyle="px-2"
                    name="Add Non-Veterinarian"
                />
                </Link>
              </Col>
            </Row> 
            {activateFilter && (
              <Row className="mb-3 gap-2">
                  {PractitionerGroupOptions.map((item, index) => {
                    return(
                      <Col xl={index === 0 || index === 1 ? 2: 3} sm={12} md={6} key={index}>
                        <Select
                          defaultOption={item.group}
                          selectedValue={params[`${item.filterName}`]}
                          options={item.options}
                          handleFilter={(e) => {
                            setClearFilter(true);
                            handleFilter(e, item.group);
                          }}
                          overideStyle="border-danger bglightred text-black"
                        />
                      </Col>
                    )
                  })
                }
                <Col xl={3} sm={12} md={8} className="d-flex gap-3 mt-2 mt-md-0">
                    {clearFilter && (
                      <CustomButton
                        name="Clear Filter"
                        color="lightRed"
                        action={handleClearFilter}
                        overideStyle="rounded-pill"
                      />
                    )}
                    <CustomButton
                      name="Close Filter"
                      color="lightRed"
                      action={handleActiveFilter}
                      overideStyle="rounded-pill"
                    />
                  </Col>
              </Row>
            )}
            <Row>
              <Col>
                <div>
                  <Download
                    currentPage={currentPage}
                    handleDownloadRecords={handlePageDownload}
                  />
                </div>
              </Col>
              <Col>
                <div className="mb-3 float-end">
                  <div className="">
                    <CustomButton
                      name="Barcode"
                      iconType
                      icon="barcode"
                      color="red"
                      action={(event) => generateQRCode(event)}
                    />
                  </div>
                </div>
              </Col>
            </Row>

            <div className="">
              {practitioners.length >= 1 ? (
                <PractitionerTable
                    data={practitioners}
                    showViewUser={(data) => {
                      setSelectedUser(data);
                      setShowSelectedUser(!showSelectedUser);
                    }}
                    showViewUserButton={true}
                />
              ) : (
                <div>
                  <div>
                    <PractitionerTable
                      data={practitioners.length >= 1 ? practitioners : []}
                      showViewUser={(data) => {
                        setSelectedUser(data);
                        setShowSelectedUser(!showSelectedUser);
                      }}
                      showViewUserButton={true}
                    />
                    <div className="text-center pt-5">
                      <img src={EmptyImg} className="img-fluid" alt="empty" />
                      <p className="p-color pt-2">No Record to show</p>
                      <p className="p-color pt-2">
                        Click the Add Practitioner button on the top <br />
                        right to add new practitioner profile
                      </p>
                      _
                    </div>
                  </div>
                </div>
              )}
              {practitioners.length >= 1 ? (
                <div className="d-flex mt-5  justify-content-center">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel="next >"
                    onPageChange={handlePageChange}
                    pageRangeDisplayed={5}
                    pageCount={totalPages}
                    previousLabel="< previous"
                    // forcePage={currentPage}
                    containerClassName={"pagination"}
                    previousLinkClassName={"pagination__link"}
                    nextLinkClassName={"pagination__link"}
                    disabledClassName={"pagination__link--disabled"}
                    activeClassName={"pagination__link--active"}
                  />
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="text-end " style={{ paddingTop: "5rem" }}>
              <p className="mb-5">
                <img
                  src={FooterLogo}
                  width="20"
                  className="img-fluid"
                  alt="footer"
                />{" "}
                Powered by Milsat Technologies
              </p>
            </div>
          </CardBody>
        </Card>
      </Row>
      {/*<ConfirmQRCodeModal*/}
      {/*  show={isModalOpen}*/}
      {/*  fileName={"Practitioners QR Codes"}*/}
      {/*  modalTitle={"All Practitoners"}*/}
      {/*  setShow={setIsModalOpen}*/}
      {/*  userData={{*/}
      {/*    id: selectedId,*/}
      {/*    vcnID: selectedVcnID,*/}
      {/*    name: selectedUserName,*/}
      {/*  }}*/}
      {/*/>*/}
      <UserDetailsModal
          show={showSelectedUser}
          setShow={setShowSelectedUser}
          user={selectedUser}
          fetchPractitioners={fetchPractitioners}
      />
      <OnboardingModal
        show={showBoard}
        setShow={setShowBoard}
        setFile={setFile}
        btnName="Onboard"
        file={file}
        title="Bulk Practitioner Onboarding"
        description="Onboarding multiple practitioner from xlsx file"
        subDescription="Select Xlsx file containing practitioners"
        handleOnboarding={handleOnboarding}
        handleDownload={downloadExcelSheet}
      >
        <CustomButton
            name="Download Sample "
            iconType
            icon="download"
            color="red"
            action={downloadExcelSheet}
        />
      </OnboardingModal>

      <LoadingModal show={showL} setShow={setShowL} />
      <SuccessModal
        show={showS}
        setShow={setShowS}
        bgTxt={bgTxt}
        smTxt={smTxt}
      />
      <ErrorModal
        show={showE}
        setShow={setShowE}
        bgTxt={"Failed"}
        smTxt2={"Check csv file, and make sure data is properly arranged"}
        smTxt={"Could not onboard practitioners."}
        serverError={error}
      />
    </Container>
  );
};

export default Practitioner;
