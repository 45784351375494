import React, { useState, useContext, useCallback, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Col,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import {
  request
} from "../../services/utilities"
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_red.css";
import { uploadPassport } from "../../services/utilities";
import { Store } from "../../context/store"

const RequestProfileModel = ({
  show,
  setShow,
  user,
  setUser,
  requestProfileUpdate,
  loading,
  setLoading,
}) => {
  const store = useContext(Store);
  const [allState, setAllState] = store.state;
  const [allLga, setAllLga] = useState([]);
  const [whatToUpdate, setWhatToUpdate] = useState({
    profileImg: false,
    dob: false,
    fname: false,
    othername: false,
    lname: false,
    number: false,
    address: false,
    stateAndLga: false,
  });

  const resetCheckBoxState = () => {
    setWhatToUpdate({
      profileImg: false,
      dob: false,
      fname: false,
      othername: false,
      lname: false,
      number: false,
      address: false,
      stateAndLga: false,
    });
  };

  const fetchState = useCallback(async () => {
    try {
      const url = "Locations/GetAllState";
      const rs = await request(url, "GET", false);
      setAllState(rs.payload);
    } catch (err) {}
  }, [setAllState]);
  
  const fetchLga = useCallback(async (e) => {
    try {
      const url = `Locations/GetLGA?state=${e}`;
      const rs = await request(url, "GET", false);
      setAllLga(rs.payload);
    } catch (err) {}
  }, []);

  useEffect(() => {
    fetchState();
  }, [fetchState]);
  return (
    <>
      <Modal
        isOpen={show}
        // centered
        className="modalw"
      >
        <ModalHeader
          style={{ borderBottom: "none" }}
          className="modal-title pb-0 fw-bold"
          toggle={() => {
            resetCheckBoxState();
            setShow(!show);
          }}
        >
          Request Profile Update
        </ModalHeader>
        <ModalBody className="">
          <div className="row g-3">
            <Col lg={12}>
              <label className="form-label pb-2">
                What do you want to update
              </label>
              <div className="checkBox">
                <div>
                  <div className="form-check">
                    <Input
                      className="form-check-input checkbox"
                      type="checkbox"
                      name="inlineRadioOptions"
                      id="profileImg"
                      onChange={() =>
                        setWhatToUpdate({
                          profileImg: !whatToUpdate.profileImg,
                          dob: whatToUpdate.dob,
                          fname: whatToUpdate.fname,
                          othername: whatToUpdate.othername,
                          lname: whatToUpdate.lname,
                          number: whatToUpdate.number,
                          address: whatToUpdate.address,

                        })
                      }
                    />
                    <label className="form-check-label" htmlFor="profileImg">
                      Profile Image
                    </label>
                  </div>
                  <div>
                    <div
                      className={`mb-2 d-flex mt-2 ${
                        whatToUpdate.profileImg !== true && "d-none"
                      }`}
                    >
                      <Button
                        id="bgred"
                        className="w-100"
                        style={{
                          borderTopRightRadius: "0px",
                          border: "none",
                          borderBottomRightRadius: "0px",
                        }}
                        onClick={() => {
                          document.getElementById("file").click();
                        }}
                        type="button"
                      >
                        Choose File
                      </Button>
                      <Input
                        style={{
                          borderTopLeftRadius: "0px",
                          borderBottomLeftRadius: "0px",
                        }}
                        readOnly
                        className="form-control"
                        value={
                          user?.imageUrl?.length > 64
                            ? user.imageUrl.substring(64)
                            : user.imageUrl
                        }
                      />
                    </div>
                    <Input
                      type="file"
                      id="file"
                      onChange={async (e) => {
                        setLoading(true);
                        const rs = await uploadPassport(e.target.files[0]);
                        setUser({
                          dateOfBirth: user?.dateOfBirth,
                          firstName: user?.firstName,
                          lastName: user?.lastName,
                          middleName: user?.middleName,
                          phoneNumber: user?.phoneNumber,
                          address: user?.address,
                          imageUrl: rs ? rs : e.target.files[0].name,
                          state: user?.state,
                          lga: user?.lga,
                          state_code: user?.state_code,
                          lga_code: user?.lga_code,
                        });
                        setLoading(false);
                      }}
                      className="d-none"
                    />
                  </div>
                </div>
                <div>
                  <div className="form-check">
                    <Input
                      className="form-check-input checkbox"
                      type="checkbox"
                      name="inlineRadioOptions"
                      id="dob"
                      onChange={() =>
                        setWhatToUpdate({
                          dob: !whatToUpdate.dob,
                          fname: whatToUpdate.fname,
                          othername: whatToUpdate.othername,
                          lname: whatToUpdate.lname,
                          number: whatToUpdate.number,
                          address: whatToUpdate.address,
                          profileImg: whatToUpdate.profileImg,
                        })
                      }
                    />
                    <label className="form-check-label" htmlFor="dob">
                      Date of Birth
                    </label>
                  </div>
                  <div
                    className={`mb-2 mt-2 ${
                      whatToUpdate.dob !== true && "d-none"
                    }`}
                  >
                    <Flatpickr
                      name="dob"
                      placeholder="dd/mm/yyyy"
                      value={user?.dateOfBirth?.split("T")[0]}
                      onChange={(selectedDates, dateStr) => {
                        if (!selectedDates.length) return;
                        setUser({
                          dateOfBirth: dateStr,
                          firstName: user?.firstName,
                          lastName: user?.lastName,
                          middleName: user?.middleName,
                          phoneNumber: user?.phoneNumber,
                          address: user?.address,
                          imageUrl: user?.imageUrl,
                          state: user?.state,
                          lga: user?.lga,
                          state_code: user?.state_code,
                          lga_code: user?.lga_code,
                        });
                      }}
                      options={{
                        altInput: true,
                        altFormat: "F j, Y",
                        dateFormat: "Y-m-d",
                        maxDate: new Date(),
                      }}
                    />
                  </div>
                </div>
                <div>
                  <div className="form-check">
                    <Input
                      className="form-check-input checkbox"
                      type="checkbox"
                      name="inlineRadioOptions"
                      id="fname"
                      onChange={() =>
                        setWhatToUpdate({
                          profileImg: whatToUpdate.profileImg,
                          dob: whatToUpdate.dob,
                          fname: !whatToUpdate.fname,
                          othername: whatToUpdate.othername,
                          lname: whatToUpdate.lname,
                          number: whatToUpdate.number,
                          address: whatToUpdate.address,
                        })
                      }
                    />
                    <label className="form-check-label" htmlFor="fname">
                      First Name
                    </label>
                  </div>
                  <div>
                    <Input
                      type="text"
                      placeholder="Enter first name"
                      value={user?.firstName}
                      onChange={(e) => {
                        setUser({
                          dateOfBirth: user.dateOfBirth,
                          firstName: e.target.value,
                          lastName: user?.lastName,
                          middleName: user?.middleName,
                          phoneNumber: user?.phoneNumber,
                          address: user?.address,
                          imageUrl: user?.imageUrl,
                          state: user?.state,
                          lga: user?.lga,
                          state_code: user?.state_code,
                          lga_code: user?.lga_code,
                          
                        });
                      }}
                      className={`mb-2 mt-2 ${
                        whatToUpdate.fname !== true && "d-none"
                      }`}
                    />
                  </div>
                </div>
                <div>
                  <div className="form-check">
                    <Input
                      className="form-check-input checkbox"
                      type="checkbox"
                      name="inlineRadioOptions"
                      id="othername"
                      onChange={() =>
                        setWhatToUpdate({
                          profileImg: whatToUpdate.profileImg,
                          dob: whatToUpdate.dob,
                          fname: whatToUpdate.fname,
                          othername: !whatToUpdate.othername,
                          lname: whatToUpdate.lname,
                          number: whatToUpdate.number,
                          address: whatToUpdate.address,
                        })
                      }
                    />
                    <label className="form-check-label" htmlFor="othername">
                      Other Name
                    </label>
                  </div>
                  <div>
                    <Input
                      type="text"
                      placeholder="Enter other name"
                      value={user?.middleName}
                      onChange={(e) => {
                        setUser({
                          dateOfBirth: user.dateOfBirth,
                          firstName: user.firstName,
                          lastName: user?.lastName,
                          middleName: e.target.value,
                          phoneNumber: user?.phoneNumber,
                          address: user?.address,
                          imageUrl: user?.imageUrl,
                        });
                      }}
                      className={`mb-2 mt-2 ${
                        whatToUpdate.othername !== true && "d-none"
                      }`}
                    />
                  </div>
                </div>
                <div>
                  <div className="form-check">
                    <Input
                      className="form-check-input checkbox"
                      type="checkbox"
                      name="inlineRadioOptions"
                      id="lname"
                      onChange={() =>
                        setWhatToUpdate({
                          profileImg: whatToUpdate.profileImg,
                          dob: whatToUpdate.dob,
                          fname: whatToUpdate.fname,
                          othername: whatToUpdate.othername,
                          lname: !whatToUpdate.lname,
                          number: whatToUpdate.number,
                          address: whatToUpdate.address,
                        })
                      }
                    />
                    <label className="form-check-label" htmlFor="lname">
                      Last Name
                    </label>
                  </div>
                  <div>
                    <Input
                      type="text"
                      value={user?.lastName}
                      onChange={(e) => {
                        setUser({
                          dateOfBirth: user.dateOfBirth,
                          firstName: user?.firstName,
                          lastName: e.target.value,
                          middleName: user?.middleName,
                          phoneNumber: user?.phoneNumber,
                          address: user?.address,
                          imageUrl: user?.imageUrl,
                          state: user?.state,
                          lga: user?.lga,
                          state_code: user?.state_code,
                          lga_code: user?.lga_code,
                        });
                      }}
                      placeholder="Enter last name"
                      className={`mb-2 mt-2 ${
                        whatToUpdate.lname !== true && "d-none"
                      }`}
                    />
                  </div>
                </div>
                <div>
                  <div className="form-check">
                    <Input
                      className="form-check-input checkbox"
                      type="checkbox"
                      name="inlineRadioOptions"
                      id="number"
                      onChange={() =>
                        setWhatToUpdate({
                          profileImg: whatToUpdate.profileImg,
                          dob: whatToUpdate.dob,
                          fname: whatToUpdate.fname,
                          othername: whatToUpdate.othername,
                          lname: whatToUpdate.lname,
                          number: !whatToUpdate.number,
                          address: whatToUpdate.address,
                        })
                      }
                    />
                    <label className="form-check-label" htmlFor="number">
                      Phone Number
                    </label>
                  </div>
                  <div>
                    <Input
                      type="number"
                      max={11}
                      value={user?.phoneNumber}
                      onChange={(e) => {
                        setUser({
                          dateOfBirth: user.dateOfBirth,
                          firstName: user?.firstName,
                          lastName: user?.lastName,
                          middleName: user?.middleName,
                          phoneNumber: e.target.value,
                          address: user?.address,
                          imageUrl: user?.imageUrl,
                          state: user?.state,
                          lga: user?.lga,
                          state_code: user?.state_code,
                          lga_code: user?.lga_code,
                        });
                      }}
                      placeholder="Enter phone number"
                      className={`mb-2 mt-2 ${
                        whatToUpdate.number !== true && "d-none"
                      }`}
                    />
                  </div>
                </div>
                <div>
                  <div className="form-check">
                    <Input
                      className="form-check-input checkbox"
                      type="checkbox"
                      name="inlineRadioOptions"
                      id="address"
                      onChange={() =>
                        setWhatToUpdate({
                          profileImg: whatToUpdate.profileImg,
                          dob: whatToUpdate.dob,
                          fname: whatToUpdate.fname,
                          othername: whatToUpdate.othername,
                          lname: whatToUpdate.lname,
                          number: whatToUpdate.number,
                          address: !whatToUpdate.address,
                        })
                      }
                    />
                    <label className="form-check-label" htmlFor="address">
                      Address
                    </label>
                  </div>
                  <div>
                    <Input
                      type="text"
                      value={user?.address}
                      onChange={(e) => {
                        setUser({
                          dateOfBirth: user.dateOfBirth,
                          firstName: user?.firstName,
                          lastName: user?.lastName,
                          middleName: user?.middleName,
                          phoneNumber: user?.phoneNumber,
                          address: e.target.value,
                          imageUrl: user?.imageUrl,
                          state: user?.state,
                          lga: user?.lga,
                          state_code: user?.state_code,
                          lga_code: user?.lga_code,
                        });
                      }}
                      placeholder="Enter address"
                      className={`mb-2 mt-2 ${
                        whatToUpdate.address !== true && "d-none"
                      }`}
                    />
                  </div>
                </div>
                <div>
                  <div className="form-check">
                  <Input
                      className="form-check-input checkbox"
                      type="checkbox"
                      value={whatToUpdate.stateAndLga}
                      name="inlineRadioOptions"
                      id="address"
                      onChange={() =>
                        setWhatToUpdate({
                          profileImg: whatToUpdate.profileImg,
                          dob: whatToUpdate.dob,
                          fname: whatToUpdate.fname,
                          othername: whatToUpdate.othername,
                          lname: whatToUpdate.lname,
                          number: whatToUpdate.number,
                          address: whatToUpdate.address,
                          stateAndLga: !whatToUpdate.stateAndLga,
                        })
                      }
                    />
                    <label className="form-check-label" htmlFor="address">
                      State and Local Government Area
                    </label>
                  </div>
                  <div className="d-flex align-items-center gap-3">
                  <Input
                      name="state"
                      type="select"
                      id="state"  
                      placeholder="Select State"
                      value={user?.lastName}
                      onChange={(e) => {
                        console.log(e.target.value)
                        const stateId = document.querySelector("#state");
                        const state_code =
                          stateId.options[stateId.selectedIndex].getAttribute(
                            "statecode"
                          );
                        console.log(state_code)
                        setUser({
                          dateOfBirth: user.dateOfBirth,
                          firstName: user?.firstName,
                          lastName: user?.lastName,
                          middleName: user?.middleName,
                          phoneNumber: user?.phoneNumber,
                          address: user?.address,
                          imageUrl: user?.imageUrl,
                          state: e.target.value,
                          state_code: state_code,
                          lga: user?.lga,
                          lga_code: user?.lga_code,
                        })
                        fetchLga(e.target.value);
                      }}
                      className={`mb-2 mt-2 ${
                        whatToUpdate.stateAndLga !== true && "d-none"
                      }`}
                    >
                      <option>Select State</option>
                      {allState?.map((e) => {
                        return (
                          <option
                            id={e.state__name}
                            statecode={e.state__id}
                            key={e.state__id}
                          >
                            {e.state__name}
                          </option>
                        );
                      })}
                    </Input>
                    <Input
                      name="lga"
                      type="select"
                      id="lga"
                      placeholder="Select LGA"
                      onChange={(e) => {
                        const lgaId = document.querySelector("#lga");
                        const lga_code =
                          lgaId.options[lgaId.selectedIndex].getAttribute(
                            "lgacode"
                          );
                        setUser({
                          dateOfBirth: user.dateOfBirth,
                          firstName: user?.firstName,
                          lastName: user?.lastName,
                          middleName: user?.middleName,
                          phoneNumber: user?.phoneNumber,
                          address: user?.address,
                          imageUrl: user?.imageUrl,
                          state: user?.state,
                          stateCode: user?.state_code,
                          lga: e.target.value,
                          lgaCode: lga_code,
                        })
                      }}
                      className={`mb-2 mt-2 ${
                        whatToUpdate.stateAndLga !== true && "d-none"
                      }`}
                    >
                      <option>{"Select LGA"}</option>
                      {allLga?.map((e) => {
                        return (
                          <option lgacode={e["LGA CODE"]} key={e["LGA CODE"]}>
                            {e["LGA NAME"]}
                          </option>
                        );
                      })}
                    </Input>
                  </div>
                </div>
              </div>
            </Col>

            <Col lg={12}>
              <div className="hstack gap-2 justify-content-between">
                <div>{loading ? <Spinner color="danger" /> : ""}</div>

                <button
                  id="bgred"
                  className="btn text-light"
                  onClick={() => {
                    requestProfileUpdate();
                    resetCheckBoxState();
                  }}
                >
                  Send Request
                </button>
              </div>
            </Col>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default RequestProfileModel;
