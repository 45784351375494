import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner,
} from "reactstrap";
import { request } from "../services/utilities";
import SuccessModal from "./modals/SuccessModal";
import { uploadReceipt } from "../services/utilities";
import ConfrimActionsModal from "./modals/ConfirmActionsModal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ArrowLeft } from "react-feather";
import PaymentStatusModal from "./modals/PaymentStatusModal";

const RecordOfflineTransaction = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState([]);
  const [entry, setEntry] = useState({});
  const [bgTxt, setBgTxt] = useState("Record Uploaded Successfully");
  const [smTxt, setSmTxt] = useState(
    "You have successfully uploaded your record"
  );
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();

  // Get the bill id for query parameter
  const billId = searchParams.get("billId");

  // Get the Veterinarian Info for query parameter
  const userId = searchParams.get("userId");
  const vcnId = searchParams.get("vcnId");

  const [text, setText] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [productCategory, setProductCategory] = useState("");
  const [billDescription, setbillDescription] = useState("");
  const [file, setFile] = useState(null);
  const [verificationStatus, setVerificationStatus] = useState(null); // New state for verification status
  const [isEditable, setIsEditable] = useState(true);
  const [paymentStatusModal, setPaymentStatusModal] = useState({ show: false, message: "", success: false });


  const validationSchema = Yup.object({
    paymentDate: Yup.date()
        .required("Date of payment is required")
        .max(new Date(), "Future dates are not allowed"),
    rrr: Yup.string()
        .required("RRR is required"),
    amount: Yup.number()
      .typeError("Amount must be a number")
      .required("Amount paid is required"),
    receiptNumber: Yup.string().required("Receipt number is required"),
    description: Yup.string(),
    receiptImage: Yup.string(),
  });

  // Formik for form handling
  const formik = useFormik({
    initialValues: {
      paymentDate: "",
      rrr: "",
      amount: "",
      receiptNumber: "",
      description: "",
      receiptImage: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (e) => {
      setEntry(e);
      setShowConfirmModal(true);
    },
  });

  const submitForm = async (e, receiptImg) => {
    const data = {
      billId: Number(billId), // Ensuring billId is treated as a number
      userId: userId,
      vcnId: vcnId,
      paymentDate: e.paymentDate,
      description: e.description,
      amount: Number(e.amount),
      rrr: e.rrr,
      receiptNumber: e.receiptNumber,
      receiptImage: receiptImg ? receiptImg : "",
    };

    try {
      const url = `Payment/AddOfflineTransaction`;
      const rs = await request(url, "POST", true, data);
      setLoading(false);
      if (rs.hasErrors === true) {
        if (rs.errors) {
          setError(rs.errors);
          console.log(rs.errors);
        }
        if (rs.description) {
          setError([rs.description]);
        }
        if (rs.payload) {
          setError(rs.payload);
        }
      }
      if (rs.code === "OK") {
        setBgTxt("Record Completed Successfully");
        setSmTxt(`You have successfully made an offline transaction for VCNID: ${vcnId}`);
        setShowSuccessModal(true);
        formik.resetForm(); // Reset the form to initial values
      }
      const timer = setTimeout(() => setError([]), 6000);
      return () => clearTimeout(timer);
    } catch (err) {
      setFile(null);
      setLoading(false);
      setError(err);
      console.log(err);
    }
    setLoading(false);
  };

  const handlePayment = async () => {
    setShowConfirmModal(false);
    setLoading(true);
    const receiptDocument = await uploadReceipt(file);
    await submitForm(entry, receiptDocument);
  };

  const getUserInfo = async (billId) => {
    try {
      const url = `BillingStructure/GetBillings?Id=${billId}`;
      const rs = await request(url, "GET", true);
      setLoading(false);
      if (rs.code === "OK") {
        setUserInfo(rs.payload.items[0].user);
        setbillDescription(rs.payload.items[0].description);
        setProductCategory(rs.payload.items[0].product);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const handleVerifyRRR = async () => {
    if (!formik.values.rrr) {
      setError(["Please enter an RRR number to verify."]);
      return;
    }

    setLoading(true);
    try {
      const rrr = formik.values.rrr;
      const url = `Payment/VerifyRRRPayment?RRR=${rrr}`;

      const response = await request(url, "GET", true);

      setLoading(false);

      if (response.code === "OK" && response.payload) {
        const { status, message, amount, transactiontime, responseMsg } = response.payload;

        if (status === "00") { // Successful
          setVerificationStatus("Successful");
          formik.setFieldValue("amount", amount.toString());
          formik.setFieldValue("paymentDate", transactiontime.split(" ")[0]); // Assuming date is in 'YYYY-MM-DD' format
          formik.setFieldValue("description", responseMsg);
          setIsEditable(false); // Make inputs non-editable
          setPaymentStatusModal({ show: true, message: "The payment attached to this RRR has been successfully verified.", success: true });
        } else if (status === "021") { // Pending
          setVerificationStatus("Pending");
          formik.setFieldValue("amount", amount.toString());
          formik.setFieldValue("paymentDate", transactiontime.split(" ")[0]);
          formik.setFieldValue("description", responseMsg);
          setIsEditable(false);
          setPaymentStatusModal({ show: true, message: "The payment attached to this RRR is still pending approval by Remita. Try again after remita has successfully approved the payment.", success: false });
        } else {
          setVerificationStatus("Error");
          setError([message || "An error occurred during RRR verification."]);
          setPaymentStatusModal({ show: true, message: "An error occurred during RRR verification. Please check the RRR number and try again.", success: false });
        }
      } else {
        throw new Error("Failed to verify RRR.");
      }
    } catch (error) {
      setLoading(false);
      setError([error.message]);
    }
  };

  const handleReset = () => {
    formik.resetForm(); // Reset Formik form
    setFile(null); // Clear file input
    setVerificationStatus(null); // Reset verification status
    setError([]); // Clear any errors
    setIsEditable(true); // Re-enable inputs if they were disabled
  };

  useEffect(() => {
    if (billId) {
      getUserInfo(billId);
    }
  }, [billId]);

  return (
    <Container>
      <Row className="d-flex align-items-center mb-3">
        <Col xl={6} sm={8} md={8}>
          <h2 className="h1-color mb-3">Record Offline Transaction</h2>
        </Col>
        <Col xl={6} sm={4} md={4}>
          <Link
            to={"/admin/billings"}
            className="mb-3 btn text-decoration-none float-end"
            style={{ background: "#EFEFEF" }}
          >
            <ArrowLeft size={16} /> Back
          </Link>
        </Col>
      </Row>
      <Row className="align-items-center mb-3 h-50">
        <Col xl={6} sm={12} md={4}>
          {error?.map((e, index) => {
            return (
              <div
                key={index}
                className="alert text-center w-100 text-capitalize alert-danger alert-dismissible border-2 p-1 fade show"
                role="alert"
                style={{ background: "#fff", fontSize: "14px" }}
              >
                {e}
              </div>
            );
          })}
        </Col>
        <Col xl={6} sm={12} md={6}>
          <div className="float-end">
            {loading ? <Spinner color="danger" /> : ""}
          </div>
        </Col>
      </Row>
      <Row>
        <p>
          You're about to initiate a payment on behalf of{" "}
          <span className="font-bold"> {userInfo?.fullName} </span> with
          VCNID: <span className="font-bold">{vcnId}</span> for{" "}
          {billDescription} of{" "}
          <span className="font-bold">
            {productCategory?.productCategoryType}
          </span>
          .
        </p>
        <p>Please provide the necessary following information:</p>
      </Row>
      <Card>
        <CardBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit();
              setText(`Confirm to record N${new Intl.NumberFormat("en-US").format(formik.values.amount)} for VCNID:${userInfo?.vcnId} for ${billDescription}`)
              return false;
            }}
          >
            <Row>
              {/* Date of Payment */}
              <Col md={6}>
                <FormGroup>
                  <Label for="paymentDate">Date of Payment</Label>
                  <Input
                      type="date"
                      name="paymentDate"
                      id="paymentDate"
                      disabled={!isEditable} // Controlled by isEditable state
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.paymentDate}
                      invalid={formik.touched.paymentDate && !!formik.errors.paymentDate}
                  />
                  {formik.touched.paymentDate && formik.errors.paymentDate && (
                      <FormFeedback>{formik.errors.paymentDate}</FormFeedback>
                  )}
                </FormGroup>
              </Col>

              {/* RRR */}
              <Col md={6}>
                <FormGroup>
                  <Label for="rrr">RRR</Label>
                  <Row>
                    <Col xs="10">
                      <Input
                          type="number"
                          name="rrr"
                          id="rrr"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.rrr}
                          invalid={formik.touched.rrr && !!formik.errors.rrr}
                      />
                      <FormFeedback>{formik.errors.rrr}</FormFeedback>
                    </Col>
                    <Col xs="2">
                      <button id="bgred" className="btn text-light" onClick={(e) => {
                        e.preventDefault()
                        handleVerifyRRR();
                      }}>Verify</button>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>

              {/* Amount Paid */}
              <Col md={6}>
                <FormGroup>
                  <Label for="amountPaid">Amount Paid</Label>
                  <Input
                      type="number"
                      name="amount"
                      id="amount"
                      disabled={!isEditable} // Controlled by isEditable state
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.amount}
                      invalid={formik.touched.amount && !!formik.errors.amount}
                  />
                  {formik.touched.amount && formik.errors.amount && (
                      <FormFeedback>{formik.errors.amount}</FormFeedback>
                  )}
                </FormGroup>
              </Col>

              {/* Receipt Number */}
              <Col md={6}>
                <FormGroup>
                  <Label for="receiptNumber">Receipt Number</Label>
                  <Input
                    type="text"
                    name="receiptNumber"
                    id="receiptNumber"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.receiptNumber}
                    invalid={
                      formik.touched.receiptNumber &&
                      !!formik.errors.receiptNumber
                    }
                  />
                  <FormFeedback>{formik.errors.receiptNumber}</FormFeedback>
                </FormGroup>
              </Col>

              {/* Description */}
              <Col md={6}>
                <FormGroup>
                  <Label for="description">Description</Label>
                  <Input
                      type="text"
                      name="description"
                      id="description"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.description}
                  />
                </FormGroup>
              </Col>

              {/* Receipt Image */}
              <Col md={6}>
                <FormGroup>
                  <Label for="receiptImage">Receipt Image (optional)</Label>
                  <Input
                    type="file"
                    name="receiptImage"
                    id=""
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      setFile(e.target.files[0]);
                      formik.setFieldValue("receiptImage", e.target.files[0]);
                    }}
                  />
                </FormGroup>
              </Col>

              <Col md={12}>
                <button
                    type="button" // Ensure this is of type 'button' to prevent form submission
                    className="btn btn-light me-2"
                    onClick={handleReset}
                    data-toggle="tooltip"
                    data-placement="top"
                    title={"Click to reset your form"}
                >
                  Reset
                </button>

                <span
                    data-toggle="tooltip"
                    data-placement="top"
                    title={verificationStatus === "Pending" ? "RRR is still pending verification." : "Click to submit your form"}
                >
                   <button
                       id="bgred"
                       className=" btn text-light"
                       type="submit"
                       disabled={verificationStatus === "Pending"} // Disable button if verificationStatus is 'Pending'
                   >
                  Submit
                </button>
                </span>

              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
      <SuccessModal
          show={showSuccessModal}
          setShow={setShowSuccessModal}
          bgTxt={bgTxt}
          smTxt={smTxt}
          action={() => navigate("/admin/billings")}
      />
      <PaymentStatusModal
          isOpen={paymentStatusModal.show}
          toggle={() => setPaymentStatusModal({...paymentStatusModal, show: false})}
          message={paymentStatusModal.message}
          success={paymentStatusModal.success}
      />
      <ConfrimActionsModal
        show={showConfirmModal}
        setShow={setShowConfirmModal}
        text={text}
        smText={""}
        action={handlePayment}
      />
    </Container>
  );
};

export default RecordOfflineTransaction;
