import { useCallback, useState } from "react";
import SortImg from "../assets/images/arrowsort.png";
import UserImage from "../assets/images/user.png";
import { Table } from "reactstrap";
import ProcessRequestProfileModal from "../components/modals/ProcessRequestProfileModal";
import SuccessModal from "../components/modals/SuccessModal";
import ConfirmActionsModal from "../components/modals/ConfirmActionsModal";
import { request } from "../services/utilities";

function ProfileRequestTable({ data, fetchProfileRequest }) {
  const [sortKey, setSortKey] = useState("last_name");
  const [sortOrder, setSortOrder] = useState("ascn");
  const [show, setShow] = useState(false);
  const [showC, setShowC] = useState(false);
  const [user, setUser] = useState(null);
  const [shows, setShows] = useState(false);
  const [bgTxt, setBgTxt] = useState("Profile Request Approved Successfully'");
  const [smTxt, setSmTxt] = useState("profile approved");
  const [cText, setCText] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState([]);
  const [payload, setPayload] = useState({
    id: "",
    status: "",
  });
  const clearError = () => {
    const timer = setTimeout(() => setError([]), 3000);
    return () => clearTimeout(timer);
  };

  const handleComfirmActionText = (vcn, status, id) => {
    setPayload({
      id,
      status,
    });
    setCText(
      `You Are About To ${status} Requested Profile Changes For VCNID ${vcn}.`
    );
    setShowC(true);
  };

  const handleProfileRequest = async (reason) => {
    setLoading(true);
    setShowC(false);
    const url = `profile/ProcessAccountUpdateRequest`;
    let data;
    data = {
      id: payload.id,
      status: `${payload.status === "Approve" ? "Approved" : "Rejected"}`,
    };
    if (payload.status === "Reject") {
      data.rejectReason = reason;
    }
    try {
      const rs = await request(url, "POST", true, data);
      setLoading(false);
      if (rs.code === "OK") {
        setBgTxt(
          `VCN ${user?.vcnId} Profile Request ${
            payload.status === "Approve" ? "Approved" : "Rejected"
          } Successfully `
        );
        setSmTxt(
          `Profile ${payload.status === "Approve" ? "Approved" : "Rejected"}`
        );
        setShow(false);
        setShows(true);
        fetchProfileRequest();
      } else {
        setError([rs.errors]);
        clearError();
      }
    } catch (err) {
      setLoading(false);
      setError([
        "Unable to process request profile update kindly try again later",
      ]);
      clearError();
    }
  };
  function sortData({ tableData, sortKey, reverse }) {
    if (!sortKey) return tableData;

    const sortedData = data.sort((a, b) => {
      return a[sortKey] > b[sortKey] ? 1 : -1;
    });

    if (reverse) {
      return sortedData.reverse();
    }

    return sortedData;
  }

  function SortButton({ onClick }) {
    return (
      <span
        id="sort"
        style={{ cursor: "pointer" }}
        className="mx-2"
        onClick={onClick}
      >
        <img src={SortImg} className="img-fluid float-end" alt="sort" />
      </span>
    );
  }
  const headers = [
    { key: "fullName", label: "Full Name" },
    { key: "vcnId", label: "VCN Number" },
    { key: "phoneNumber", label: "Phone Number" },
    { key: "address", label: "Address" },
    { key: "status", label: "Status" },
    { key: "dateCreated", label: "Date Created" },
    { key: "actions", label: "Actions" },
  ];

  const sortedData = useCallback(
    () => sortData({ tableData: data, sortKey, reverse: sortOrder === "desc" }),
    [data, sortKey, sortOrder]
  );

  function changeSort(key) {
    setSortOrder(sortOrder === "ascn" ? "desc" : "ascn");

    setSortKey(key);
  }

  return (
    <div className="fixTableHead">
      <Table
        responsive
        id="table-border-collapse"
        className="table table-borderless table-hover align-middle table-nowrap headerFixed"
      >
        <thead className="admin-table-red headerFixed">
          <tr>
            {headers.map((row) => {
              return (
                <th
                  scope="col"
                  className="text-nowrap headerFixed"
                  key={row.key}
                  style={{ padding: "12px" }}
                >
                  {row.label}{" "}
                  <SortButton
                    columnKey={row.key}
                    onClick={() => changeSort(row.key)}
                    {...{
                      sortOrder,
                      sortKey,
                    }}
                  />
                </th>
              );
            })}
          </tr>
        </thead>

        <tbody>
          {data.map((e) => {
            return (
              <tr key={e.id}>
                <td className="text-nowrap" style={{ padding: "12px" }}>
                  <img
                    src={
                      e.imageUrl?.request
                        ? e.imageUrl?.request
                        : e.imageUrl?.current
                        ? e.imageUrl?.current
                        : UserImage
                    }
                    className="mx-2"
                    width="35"
                    height="35"
                    style={{ borderRadius: "50%", border: "1px solid #fff" }}
                    alt="user"
                  />
                  {`${e.firstName?.request || ""} ${
                    e.middleName?.request || ""
                  } ${e.lastName?.request || ""}`}
                </td>
                <td className="text-nowrap">{e.vcnId || "--"}</td>
                <td className="text-nowrap">
                  {e.phoneNumber?.request || "--"}
                </td>
                <td className="text-nowrap">{e.address?.request || "--"}</td>
                <td className="text-nowrap">{e.status || "--"}</td>

                <td className="text-nowrap">
                  {new Date(e.dateCreated).toDateString()}
                </td>
                <td className="text-nowrap">
                  <button
                    style={{
                      padding: "5px",
                      fontSize: "12px",
                      borderRadius: "10px",
                    }}
                    onClick={() => {
                      setUser(e);
                      setShow(true);
                    }}
                    className="btn border-danger bg-white text-danger"
                  >
                    Manage Request
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <ProcessRequestProfileModal
        show={show}
        setShow={setShow}
        user={user}
        error={error}
        loading={loading}
        handleComfirmActionText={handleComfirmActionText}
      />
      <ConfirmActionsModal
        show={showC}
        setShow={setShowC}
        text={cText}
        smText={"These changes are not reversible"}
        action={handleProfileRequest}
        showReason={payload.status === "Reject" ? true : false}
      />
      <SuccessModal
        show={shows}
        setShow={setShows}
        bgTxt={bgTxt}
        smTxt={smTxt}
      />
    </div>
  );
}

export default ProfileRequestTable;
