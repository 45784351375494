import {useEffect, useState } from "react";
import SortImg from "../assets/images/arrowsort.png";
import ReusableTable from "../components/misc/TableComponent";

function PaymentTable({ data, pending, passSelectedTransaction }) {
  const [sortKey, setSortKey] = useState("last_name");
  const [sortOrder, setSortOrder] = useState("ascn");
  const [paymentData, setPaymentData] = useState([]);

  function sortData({ tableData, sortKey, reverse }) {
    if (!sortKey) return tableData;

    const sortedData = data.sort((a, b) => {
      return a[sortKey] > b[sortKey] ? 1 : -1;
    });

    if (reverse) {
      return sortedData.reverse();
    }

    return sortedData;
  }

  const headers = [
    { key: "vcnID", label: "VCN Number" },
    { key: "productName", label: "Product Name" },
    { key: "amount", label: "Amount Paid" },
    { key: "paymentChannel", label: "Payment Channel" },
    { key: "paymentStatus", label: "Status" },
    { key: "paymentDate", label: "Date" },
    { key: "rrr", label: "RRR" },
  ];
  const pendingHeader = [
    { key: "vcnId", label: "VCN Number" },
    { key: "description", label: "Description" },
    { key: "year", label: "Year" },
    { key: "amount", label: "Amount" },
    { key: "action", label: "" },
  ];

  let statusStyle

  const statusClass = ( status )=> {
    switch (status) {
      case 'Successful':
        statusStyle = 'status-success';
        return;
      case 'Failed':
        statusStyle = 'status-failed';
        return;
      case 'Pending':
        statusStyle =  'status-pending';
        return;
      default:
        return; // Handle unexpected status values gracefully
    }
  };

  const cellRenderer = (row, key) => {
    if (key === 'paymentStatus') {
      const status = row[key];
      statusClass(status);
      return <span className={statusStyle}>{status}</span>;
    }
    return row[key];
  };

  // updates the table based on updated data
  useEffect(() => {
    if (data !== paymentData) {
      setPaymentData(
        sortData({ tableData: data, sortKey, reverse: sortOrder === "ascn" })
      );
    }
  }, [data, paymentData, sortKey, sortOrder]);

  function changeSort(key) {
    setSortOrder(sortOrder === "ascn" ? "desc" : "ascn");
    setSortKey(key);
    setPaymentData(
      sortData({ tableData: data, sortKey, reverse: sortOrder === "ascn" })
    );
  }

  return (
    <div>
      <ReusableTable
          data={data}
          headers={headers}
          onSortChange={changeSort}
          cellRenderer={cellRenderer}
      />
    </div>
  );
}

export default PaymentTable;
