import ManageUpdateProfileRequest from "../components/ManageUpdateProfileRequest";
import Navbar from "../components/Navbar";

const ProfileRequestUpdate = () => {
  return (
    <>
      <Navbar />
      <div style={{paddingTop: "6rem"}}>
        <ManageUpdateProfileRequest/>
      </div>
    </>
  );
};

export default ProfileRequestUpdate;
