import React from "react";
import { Spinner } from "reactstrap";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const LoadingModal = ({
  show,
  setShow,
  bglTxt = "Bulk Practitioner Onboarding",
  smlTxt = "Importing Practitioners",
}) => {
  return (
    <>
      <Modal isOpen={show} centered className="w-100">
        <ModalHeader
          style={{ borderBottom: "none" }}
          className="modal-title pb-0 fw-bold"
          toggle={() => {
            setShow(!show);
          }}
        >
          {bglTxt}
        </ModalHeader>
        <ModalBody className="text-center">
          <Spinner style={{ height: "40px", width: "40px" }} color="primary" />
          <p className="fs-6 text-light-emphasis">{smlTxt}...</p>
        </ModalBody>
      </Modal>
    </>
  );
};

export default LoadingModal;
