
const CheckIcon = (props) => (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={8}
        cy={8}
        r={6.25}
        fill="#F9FAFB"
        stroke="currentColor"
        strokeWidth={3.5}
      />
    </svg>
  );
  
  export default CheckIcon;