import { Card, CardBody, Row, Spinner, Col } from "reactstrap";
import { Container } from "react-bootstrap";
import FooterLogo from "../assets/images/footer.svg";
import {Link, useNavigate, useParams} from "react-router-dom";
import { ArrowLeft } from "react-feather";
import { useLocation } from "react-router-dom";
import { request } from "../services/utilities";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Store } from "../context/store";
import EmptyImg from "../assets/images/EMPTY-Illustrations.svg";
import PremiseCard from "./misc/PremiseCard";

const Premises = () => {
  const store = useContext(Store);
  const [user] = store.user;
  const [premises, setPremises] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation().pathname;

  const fetchPremises = useCallback(async () => {
    setLoading(true);
    const url = `premises/getpremises?VCNId=${user.vcnId}`;
    try {
      const rs = await request(url, "GET", true);
      setLoading(false);
      if (rs.code === "OK") {
        setPremises(rs.payload.items);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }, [user?.id]);

  useEffect(() => {
    fetchPremises();
  }, [fetchPremises]);
  return (
    <Container>
      <Row>
        <Card className="border-none border-white">
          <Row className="justify-content-between align-items-center mb-3">
            <Col xs={12} md={4} className="d-flex justify-content-start">
              <Link
                  to={
                    location === "/admin/payments"
                        ? "/admin/dashboard"
                        : "/dashboard"
                  }
                  className="btn text-decoration-none"
                  id="bggrey"
              >
                <ArrowLeft size={16}/>
                Back
              </Link>
            </Col>

            <Col xs={12} md={4} className="d-flex justify-content-center">
              <p className="fs-3 fw-semibold mb-0 m-0">My Premise</p>
            </Col>

            <Col xs={12} md={4} className="d-flex justify-content-end">
              <div className="d-flex align-items-center gap-2">
                    <div className="text-start">
                      <Link
                          to="/register-premises"
                          id="bgred"
                          className="btn login-btn text-white d-flex align-items-center"
                      >
                        Add New Premise
                      </Link>
                    </div>
                {loading ?
                    <div className="d-flex justify-content-end ">
                      <div><Spinner color="danger"/> </div>
                    </div> : ""}
              </div>
            </Col>
          </Row>

          <CardBody className="p-0">
            {premises.length >= 1 ? (
              <div className='premise-card-container'>
                {
                  premises.map((premise, index) => (
                  <PremiseCard
                      key={index}
                      premise={premise}
                  />
                  ))
                }
              </div>
            ) : (
                <div className="text-center pt-5 w-100 d-flex justify-content-center">
                  <div style={{width: "16em"}}>
                    <img src={EmptyImg} className="img-fluid" alt="empty"/>
                    <p className="p-color fs-5 pt-2">You have not registered any Premise</p>
                  </div>
                </div>
            )}
          </CardBody>
        </Card>
      </Row>
    </Container>
  );
};

export default Premises;
