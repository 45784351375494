import React, {useEffect, useState} from "react";
import { Row, Col } from "reactstrap";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Logo from "../../assets/images/logo-vcn.png";
import FooterLogo from "../../assets/images/footer.svg";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const TransactionDetailsModal = ({ show, setShow, transaction }) => {
  const [filename, setFilename] = useState("");

  // Set selected transaction details
  const transactionDetails = [
    { id: 1, label: "To", value: "Veterinary Council of Nigeria" },
    {
      id: 2,
      label: "VCN ID",
      value: transaction?.vcnID ?? "",
    },
    { id: 3, label: "Description", value: transaction?.productName ?? "" },
    {
      id: 8,
      label: "Name",
      value: transaction?.fullName ?? "--",
    },
    {
      id: 4,
      label: "Date",
      value: new Date(transaction?.paymentDate).toDateString() ?? "",
    },
    {
      id: 5,
      label: "Payment Method",
      value: transaction?.paymentChannel ?? "",
    },
    {
      id: 6,
      label: "Transaction Status",
      value: transaction?.paymentStatus ?? "",
    },
    {
      id: 7,
      label: "Transaction Reference No",
      value: transaction?.transactionId ?? "",
    },
    {
      id: 8,
      label: "RRR",
      value: transaction?.rrr ?? "--",
    },
  ];


  const downloadPDF = async () => {
    const element = document.getElementById("transactionDetailsModal");
    if (!element) return;

    const canvas = await html2canvas(element, {
      scale: 2, // Adjust for higher resolution
      useCORS: true // Use this if you're loading images from external domains
    });
    const imgData = canvas.toDataURL("image/png");

    const pdfWidth = canvas.width / (72 / 25.4); // Convert pixels to mm based on 72 DPI
    const pdfHeight = canvas.height / (72 / 25.4); // Convert pixels to mm

    const pdf = new jsPDF({
      orientation: pdfWidth > pdfHeight ? "l" : "p",
      unit: "mm",
      format: [pdfWidth, pdfHeight],
    });

    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

    pdf.save(filename);
  };

  useEffect(() => {
    // Update the filename whenever the transaction changes
    if(transaction) {
      const name = transaction.fullName || ''; // Default to 'Transaction' if name isn't available
      const vcnID = transaction.vcnID || '';
      setFilename(`${name}_${vcnID}_TransactionDetails.pdf`);
    }
  }, [transaction]);

  return (
    <Modal isOpen={show} centered id="transactionDetailsModal" className="min-w-100 modal-lg ">
      <ModalHeader
        style={{ borderBottom: "none" }}
        className="modal-title pb-0 fw-bold px-5 pt-4 pb-3"
        toggle={() => {
          setShow(!show);
        }}
      ></ModalHeader>
      <ModalBody className="d-flex flex-column gap-3 px-5 pb-2">
        <div className="d-flex flex-column gap-3 align-items-md-center flex-md-row justify-content-md-between">
          <div className="mw-50">
            <img
              src={Logo}
              className="img-fluid"
              style={{ maxWidth: "70%", height: "auto" }}
              alt="logo"
            />
          </div>
          <h5 className="text-capitalize fw-semibold fs-4">
            Transaction Details
          </h5>
        </div>
        <div className="d-flex flex-column gap-md-4 gap-2 pb-4"
             style={{borderBottom: "1px solid #eee"}}
        >
          <div className="pb-3" style={{borderBottom: "1px solid #eee"}}>
            <h5 className="text-uppercase bglightred px-2 py-3 ">
              TRANSACTION AMOUNT
            </h5>
            <h4 className="fw-bold fs-2 px-2">
              ₦{new Intl.NumberFormat("en-US").format(transaction?.amount) ?? 0}
            </h4>
          </div>
          <div className="d-flex flex-column gap-3 px-3">
            {transactionDetails.map((detail) => (
                <Row className="gap-3 d-flex " key={detail.id} style={{color: "#6B7280"}}>
                  <Col
                      xl={4}
                      md={4}
                      sm={4}
                      className="text-capitalize  fw-bold bglightred p-2"
                  >
                    <label className="text-capitalize fw-bold ">
                      {detail.label}
                    </label>
                  </Col>
                  <Col
                      xl={7}
                      md={7}
                      sm={7}
                      className={`d-flex align-items-center ${
                          detail.id === 7 ? "" : "text-uppercase"
                      }  fs-6 p-2 text-black`}
                  >
                    <p>{detail.value}</p>
                  </Col>
                </Row>
            ))}
          </div>
          <button
              id="bgred"
              className="btn text-light"
              onClick={downloadPDF}>Download as PDF</button>
        </div>
        <div className="text-center pt-3"
        >
          <p className="mb-5">
                <img
                  src={FooterLogo}
                  width="20"
                  className="img-fluid"
                  alt="footer"
                />{" "}
                <span className="text-capitalize fw-bold">
                Powered by Milsat Technologies
                </span>
              </p>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default TransactionDetailsModal;
