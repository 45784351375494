import Navbar from "../components/Navbar";
import OptionSectionComponent from "../components/OptionSection";
import Footer from "../components/shared/Footer";


const Premises = () => {
  return (
      <>
          <div style={{backgroundColor: "#eee", height: "100vh"}}>
            <OptionSectionComponent/>
          </div>
      </>
  );
};

export default Premises;
