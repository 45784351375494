import React from "react";
import { Modal, ModalBody, ModalHeader, Col, Input, Button } from "reactstrap";

const TermsAndPolicy = ({ show, setShow, termsData }) => {
  return (
    <>
      <Modal isOpen={show} scrollable={true} centered size="xl">
        <ModalHeader
          style={{ borderBottom: "none" }}
          className="modal-title pb-0 fw-bold"
          toggle={() => {
            setShow(!show);
          }}
        ></ModalHeader>
        <ModalBody>
          <form>
            <div className="row g-3">
              <h4>{termsData[0]?.header}</h4>
              {termsData.map((e, i) => {
                return (
                  <div key={i}>
                    <b>
                      <p
                        style={{ fontWeight: "900" }}
                        className="text-capitalize"
                      >
                        {i + 1}. {e.subHeader}
                      </p>
                    </b>
                    <p>{e.text}</p>
                    <p className="mb-0">{e.a}</p>
                    <p className="mb-0">{e.b}</p>
                    <p className="mb-0">{e.c}</p>
                    <p className="mb-0">{e.d}</p>
                    <p className="mb-0">{e.e}</p>
                  </div>
                );
              })}
              <p>
                {
                  "By using the Platform, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions."
                }
              </p>

              <Col lg={12}>
                <div className=" gap-2 pb-3 float-end">
                  <button
                    id="bgred"
                    type="button"
                    style={{ width: "200px" }}
                    className="btn text-light"
                    onClick={() => setShow(!show)}
                  >
                    Okay
                  </button>
                </div>
              </Col>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
};
export default TermsAndPolicy;
