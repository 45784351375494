import {Button, Card, CardBody, Col, Container, Row, Spinner} from 'reactstrap';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {ArrowLeft} from 'react-feather';
import React, {useCallback, useEffect, useState} from 'react';
import {API_URI, DESIGNATION, USER_COOKIE} from '../services/constants';
import {CustomButton} from './shared/CustomButton';
import {useAuthContext} from '../context/UserProvider';
import PractitionerTable from '../util/PractitonerTable';
import EmptyImg from '../assets/images/empty.png';
import ReactPaginate from 'react-paginate';
import FooterLogo from '../assets/images/footer.svg';
import {notifyToast, request} from '../services/utilities';
import FileUploadActionModal from "./modals/FileUploadActionModal";
import SuccessModal from "./modals/SuccessModal";
import ErrorModal from "./modals/ErrorModal";
import SSRStorage from "../services/storage";
import { DesignationType } from "../util/data";

const VetParaProfessional = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation().pathname;
  const {userType} = useAuthContext();
  const [practitioners, setPractitioners] = useState([]); // Holds the fetched data
  const [currentPage, setCurrentPage] = useState(1);
  const [currentCount, setCurrentCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [firstItemOnPage, setFirstItemOnPage] = useState(0);
  const [lastItemOnPage, setLastItemOnPage] = useState(0);
  const [showUserSampleModal, setShowUserSampleModal] = useState(false);
  const [userSampleFile, setUserSampleFile] = useState(null);
  const [showL, setShowL] = useState(false);
  const [showS, setShowS] = useState(false);
  const [showE, setShowE] = useState(false);
  const [error, setError] = useState([]);
  const [file, setFile] = useState(null);
  const bgTxt = "Success!!";
  const smTxt = "All Uploads have been done successfully";
  const navigate = useNavigate();


  const fetchVpp = useCallback(
      async () => {
        setLoading(true);
        try {
          const url = `profile/getusers?Designation=VeterinarianParaProfessional&PageSize=50&PageNumber=${currentPage}`;

          const rs = await request(url, "GET", true);
          setTotalItemsCount(rs.payload?.totalItemCount);
          setLastItemOnPage(rs.payload?.lastItemOnPage);
          setFirstItemOnPage(rs.payload?.firstItemOnPage);
          setPractitioners(rs.payload?.items);
          setTotalPages(rs.payload?.pageCount);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          console.log(err);
        }
      },
      [currentPage]
  );

  const handleUserSampleUpload = async () => {
    setShowUserSampleModal(false);
    setShowL(true);
    const storage = new SSRStorage();
    const user = await storage.getItem(USER_COOKIE);
    const url = `${API_URI}/Profile/UploadUsersExcel`;
    const data = new FormData();
    data.append("file", userSampleFile);
    try {
      const rs = await fetch(url, {
        method: "POST",
        body: data,
        headers: {
          Authorization: `Bearer ${user.token}`,
          ContentType: `multipart/form-data`,
          length: `${userSampleFile.size}`,
          name: `${userSampleFile.name}`,
          fileName: `${userSampleFile.name}`,
        },
      })
          .then((res) => res.json())
          .then((data) => {
            setLoading(false);
            if (data.code === "OK") {
              setShowS(true);
              setFile(null);
            } else {
              setUserSampleFile(null);
              setLoading(false);
              setError([data.errors]);
              setShowE(true);
            }
          })
    } catch (err) {
      setFile(null);
      setLoading(false);
      setError([
        "Failed to upload multiple Offline transaction, kindly try again ",
      ]);
      setShowE(true);
    }
  };

  const downloadExcelSheet = useCallback(async () => {
    try {
      setShowUserSampleModal(false)
      setLoading(true);
      const url = `Profile/GetUserExcelSheet`;
      const response = await request(url, "GET", true, null, "file");
      let outputFilename = `VetParaProfessionalData.xlsx`;
      const blobUrl = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      link.click();
      setLoading(false);
      notifyToast("success", "Downloaded successfully");
    } catch (err) {
      setLoading(false);
      notifyToast("error", "Failed to download User Excel Sheet");
    }
  }, []);

  const handlePageChange = (selectedPage) => {
    setCurrentCount(currentCount + 1);
    setCurrentPage(selectedPage.selected + 1);
  };

  useEffect(() => {
    fetchVpp();
  }, [fetchVpp]);

  return (
      <Container>
        <Row>
          <Card className="border-none border-white">
            <Row className="d-flex align-items-center mb-3">
              <Col>
                <h1 className="h1-color mb-0">Vet Para Professionals</h1>
              </Col>
              <Col className="d-flex justify-content-end align-items-center">
                <Link
                    to={"/admin/dashboard"}
                    className="btn text-decoration-none"
                    id="bggrey"
                >
                  <ArrowLeft size={16}/>
                  Back
                </Link>
              </Col>
            </Row>
            <div className="d-flex justify-content-end ">
              <div>{loading ? <Spinner color="danger"/> : ""}</div>
            </div>

            <div className="my-3">
              <div
                  className={`d-flex align-items-center w-100 justify-content-end gap-2 relative`}
              >
                <CustomButton
                    name="Upload"
                    iconType
                    icon="upload"
                    color="red"
                    action={() => setShowUserSampleModal(true)}
                />

                <button
                    id="bgred"
                    className="btn text-light"
                    color="red"
                    onClick={() => navigate(`/admin/add-vpp?designation=${DesignationType.VeterinarianParaProfessional }`)}
                >
                  Add Vet Para Professional
                </button>
              </div>
            </div>
            <CardBody>
              <div className="">
                {practitioners.length >= 1 ? (
                    <PractitionerTable data={practitioners}/>
                ) : (
                    <div>
                      <div>
                        <PractitionerTable
                            data={practitioners.length >= 1 ? practitioners : []}
                        />
                        <div className="text-center pt-5">
                          <img src={EmptyImg} className="img-fluid" alt="empty"/>
                          <p className="p-color pt-2">No Record to show</p>
                          <p className="p-color pt-2">
                            Click the Add Practitioner button on the top <br/>
                            right to add new practitioner profile
                          </p>
                          _
                        </div>
                      </div>
                    </div>
                )}
                {practitioners.length >= 1 ? (
                    <div className="d-flex mt-5  justify-content-center">
                      <ReactPaginate
                          breakLabel="..."
                          nextLabel="next >"
                          onPageChange={handlePageChange}
                          pageRangeDisplayed={5}
                          pageCount={totalPages}
                          previousLabel="< previous"
                          // forcePage={currentPage}
                          containerClassName={"pagination"}
                          previousLinkClassName={"pagination__link"}
                          nextLinkClassName={"pagination__link"}
                          disabledClassName={"pagination__link--disabled"}
                          activeClassName={"pagination__link--active"}
                      />
                    </div>
                ) : (
                    ""
                )}
              </div>
              <div className="text-end " style={{paddingTop: "5rem"}}>
                <p className="mb-5">
                  <img
                      src={FooterLogo}
                      width="20"
                      className="img-fluid"
                      alt="footer"
                  />{" "}
                  Powered by Milsat Technologies
                </p>
              </div>
            </CardBody>
          </Card>
        </Row>

        <FileUploadActionModal
            show={showUserSampleModal}
            setShow={setShowUserSampleModal}
            setFile={setUserSampleFile}
            file={userSampleFile}
            handleAction={handleUserSampleUpload}
            title="User Sample Upload"
            message="Upload a sample user data file"
            subMessage="Ensure the file is in xslx format and follows the required structure"
            buttonText="Upload"
        >
          <CustomButton
              name="Download Sample "
              iconType
              icon="download"
              color="red"
              action={downloadExcelSheet}
          />
        </FileUploadActionModal>

        <SuccessModal
            show={showS}
            setShow={setShowS}
            bgTxt={bgTxt}
            smTxt={smTxt}
        />

        <ErrorModal
            show={showE}
            setShow={setShowE}
            bgTxt={"Failed"}
            smTxt2={"Check xlsx file, and make sure data is properly arranged"}
            smTxt={"Could not onboard practitioners."}
            serverError={error}
        />
      </Container>
  );
};

export default VetParaProfessional;
