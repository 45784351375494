import React, {useState} from 'react';
import { Container } from 'react-bootstrap';
import {Link, useNavigate, useParams} from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import PremiseProfileCard from './misc/PremiseProfileCard';
import ProgressIndicator from "./misc/ProgressIndicator";
import InformationCard from "./misc/InformationCard";
import {Col, Row, Spinner} from "reactstrap";
import {ArrowLeft} from "react-feather";
import WhiteCheckMark from "../assets/images/whiteCheckMark.svg"
import LicenseErrorIcon from "../assets/images/licenseErrorIcon.svg"
import AddBillModal from "./modals/AddBillModal";

const PremiseProfile = () => {
  const location = useLocation();
  const params = useParams();
  const premise = location.state?.premise;
  const navigate = useNavigate();
  const [showP, setShowP] = useState(false);

  const getStatusPercentage = (status) => {
    switch (status) {
      case 'Onboarding':
        return 0;
      case 'AwaitingInspection':
        return 33.34;
      case 'InspectionPassed':
        return 66.68;
      case 'InspectionFailed':
        return 66.68;
      case 'Approved':
        return 100;
      case 'DisApproved':
        return 100;
      default:
        return 0;
    }
  };


  const progressPercentage = getStatusPercentage(premise.approvalStatus);

  const extractFields = (obj, keys) => {
    return keys.map(key => {
      return { [key]: obj[key] };
    });
  };

  const professionalInfo = ['cacRegNumber', 'premiseType', 'clinicalServicesType'];
  const personalInfo = ['phoneNumber', 'emailAddress', 'address', 'lgaName', 'stateName'];
  const extractedPersonalInfo = extractFields(premise, personalInfo);
  const extractedProfessionalInfo= extractFields(premise, professionalInfo);

  return (
      <Container>
        <Row className="justify-content-between align-items-center mb-3">
          <Col xs={12} md={4} className="d-flex justify-content-start">
            <Link
                to={"/premises"}
                className="btn text-decoration-none"
                id="bggrey"
            >
              <ArrowLeft size={16}/>
              Back
            </Link>
          </Col>

          <Col xs={12} md={4} className="d-flex justify-content-center">
            <p className="fs-3 fw-semibold mb-0 m-0">My Premise Profile</p>
          </Col>

          <Col xs={12} md={4} className="d-flex justify-content-end">
            <button
                onClick={(e) => {
                  e.preventDefault()
                  setShowP(true)
                }}
                className="renew-btn py-2 px-3 text-white"
            >
              Renew License
            </button>
          </Col>
        </Row>

        <div className="top-bar">
          <div>
            {/*<button*/}
            {/*    onClick="#"*/}
            {/*    disabled={true}*/}
            {/*    className="login-btn p-3 text-white disabled"*/}
            {/*>*/}
            {/*  All Licenses*/}
            {/*</button>*/}
          </div>
          <div className="profile-card-container">
            <PremiseProfileCard
                premiseName={premise.facilityName}
            />
            <div className='progress-indicator m-4'>
              <ProgressIndicator progressPercentage={progressPercentage} status={premise.approvalStatus}/>
            </div>

            <div className='d-flex gap-2 flex-column'>
              <InformationCard
                  info={extractedProfessionalInfo}
              />

              <InformationCard
                  info={extractedPersonalInfo}
              />

              <div className="information-row">
                <span className="label-premise">Premise Owner</span>
                <span className={`value-premise ${!premise.id ? 'not-available' : ''}`}>
                  {premise.id ? (
                      <>
                        {premise.id}
                        <img src={WhiteCheckMark} alt="check-mark"/>
                      </>
                  ) : (
                      <>
                        {'Not Available'}
                        <img src={LicenseErrorIcon} alt="check-mark"/>
                      </>
                  )}
                  </span>
              </div>

              <div className="information-row">
                <span className="label-premise">Manager</span>
                <span className={`value-premise ${!premise.managingDirectorFullName ? 'not-available' : ''}`}>
                  {premise.managingDirectorFullName ? (
                      <>
                        {premise.managingDirectorFullName}
                        <img src={WhiteCheckMark} alt="check-mark"/>
                      </>
                  ) : (
                      <>
                        {'Not Available'}
                        <img src={LicenseErrorIcon} alt="check-mark"/>
                      </>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="top-buttons-right">
            {/*<button className="top-button">Inbox</button>*/}
            <button className="top-button" onClick={() => navigate(`/premises/${premise.ownerId}/${premise.id}`)}>
              Edit Premise
            </button>
          </div>
        </div>

        <AddBillModal
            show={showP}
            setShow={setShowP}
            premiseId={premise.id}
        />
      </Container>
  );
};

export default PremiseProfile;
