import React, {useEffect, useState, useCallback} from 'react';
import SortImg from '../assets/images/arrowsort.png';
import UserImage from '../assets/images/user.png';
import {Table} from 'reactstrap';
import { request } from "../services/utilities";
import SuccessModal from "../components/modals/SuccessModal";
import ErrorModal from "../components/modals/ErrorModal";
import ConfirmActionsModal from "../components/modals/ConfirmActionsModal";

function CertificatesTable({data}) {
  const [sortKey, setSortKey] = useState('last_name');
  const [sortOrder, setSortOrder] = useState('ascn');
  const [text, setText] = useState('ascn');
  const [practicionerLicencesedData, setPracticionerLicencesedData] = useState([]); // added state to hold
  const [selectedRows, setSelectedRows] = useState([]); // added state to hold
  const [userIds, setUserIds] = useState([]); // added state to hold
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showS, setShowS] = useState(false);
  const [showE, setShowE] = useState(false);
  const [error, setError] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false); // New state for modal toggle
  const [selectedId, setSelectedId] = useState(null); // State to keep track of selected ID
  const [selectedUserName, setSelectedUserName] = useState(null); // State to keep track of selected user
  const bgTxt = "Success!!";
  const smTxt = "Certificates have been sent successfully";

  const generateQRCode = (event, user) => {
    event.stopPropagation();
    // Implement QR Code generation logic here
    setSelectedId(user.id); // Set the selected ID
    setSelectedUserName(user.fullName); // Set the selected user's name
    setIsModalOpen(true); // Open the modal
  };

  const handleSendCertificateClick = (event, e) => {
    if (event) event.stopPropagation(); // Prevent triggering row click if event is present

    let userIds = [];
    let userNames = '';

    if (e) {
      // If a specific user is provided, use that user's ID and name
      userIds = [e.userId];
      userNames = e.user.fullName;
    } else if (selectedRows.length > 0) {
      // If no specific user is provided but there are selected rows, use those
      userIds = selectedRows;
      userNames = practicionerLicencesedData
          .filter(p => selectedRows.includes(p.userId))
          .map(p => p.user.fullName)
          .join(", ");

      console.log(selectedRows, 'rlf,lrf')
    }

    // Construct the confirmation text based on the operation's context
    const confirmationText = userNames
        ? `Are you sure you want to send certificates to:<br /> <b>${userNames}</b>?`
        : "Please select at least one practitioner to send certificates to.";
    // Proceed only if there's meaningful action to take
    if (userIds.length > 0) {
      // Set the confirmation text and show the modal
      setText(confirmationText);
      setShowConfirmModal(true);
      setUserIds(userIds)
    } else {
      // Handle the case where no users are selected and no specific user is provided
      alert("No users selected for sending certificates.");
      setUserIds([])
    }
  };

  const sendAnnualPracticingLicenses = useCallback(async (userIds) => {
    try {
      const url = `Payment/SendAnnualPractisingLicense`;
      const data = { userIds };
      const response = await request(url, "POST", true, data);

      if (response.code === "OK" && !response.hasErrors) {
        setShowS(true);
        setSelectedRows([]);
      } else {
        setError([response.errors]);
        setShowE(true);
      }
    } catch (err) {
      setError([`Request failed:${err}`]);
      setShowE(true);
    } finally {
      setShowConfirmModal(false); // Close modal regardless of outcome
    }
  }, []);


  const handleSubmit = () => {
    sendAnnualPracticingLicenses(userIds)
  }

  function sortData({tableData, sortKey, reverse}) {
    if (!sortKey) return tableData;

    const sortedData = data.sort((a, b) => {
      return a[sortKey] > b[sortKey] ? 1 : -1;
    });

    if (reverse) {
      return sortedData.reverse();
    }

    return sortedData;
  }

  function SortButton({onClick}) {
    return (
        <span
            id="sort"
            style={{cursor: 'pointer'}}
            className="mx-2"
            onClick={onClick}
        >
        <img
            src={SortImg}
            className="img-fluid float-end"
            alt="sort"
        />
      </span>
    );
  }

  const headers = [
    {key: 'select', label: 'Select' },
    {key: 'fullName', label: 'Full Name'},
    {key: 'vcnId', label: 'VCN Number'},
    {key: 'productName', label: 'Product Name'},
    { key: 'send', label: 'Send Certificate' },
  ].filter(Boolean);

  // updates the table based on updated data
  useEffect(() => {
    if (data !== practicionerLicencesedData) {
      setPracticionerLicencesedData(
          sortData({tableData: data, sortKey, reverse: sortOrder === 'desc'}));
    }
  }, [data, practicionerLicencesedData, sortKey, sortOrder]);


  function changeSort(key) {
    setSortOrder(sortOrder === 'ascn' ? 'desc' : 'ascn');
    setSortKey(key);
    setPracticionerLicencesedData(sortData({tableData: data, sortKey, reverse: sortOrder === 'desc'}));
  }

  return (
      <div className="fixTableHead">
        <div className="mb-2 d-flex justify-content-end">
          <button
              id="bgred"
              className="btn text-light"
              onClick={(event) => {
                if (selectedRows.length > 0) {
                  handleSendCertificateClick(event); // Added a third parameter to distinguish the global action
                } else {
                  alert("Please select at least one user.");
                }
              }}
          >
            Send Certification to Selected User
          </button>
        </div>

        <Table
            responsive
            id="table-border-collapse"
            className="table table-borderless table-hover align-middle table-nowrap headerFixed"
        >
          <thead className="admin-table-red headerFixed">
          <tr>
            {headers.map((row) => {
              return (
                  <th
                      scope="col"
                      className="text-nowrap headerFixed"
                      key={row.key}
                      style={{padding: '12px'}}
                  >
                    {row.label}{' '}
                    <SortButton
                        columnKey={row.key}
                        onClick={() => changeSort(row.key)}
                        {...{
                          sortOrder,
                          sortKey,
                        }}
                    />
                  </th>
              );
            })}
          </tr>
          </thead>

          <tbody>
          {practicionerLicencesedData.map((e) => {
            return (
                <tr
                    key={e.userId}
                    style={{cursor: 'pointer'}}
                >
                  <td>
                    <input
                        type="checkbox"
                        className="d-flex justify-content-end w-100 checkBox"
                        checked={selectedRows.includes(e.userId)}
                        onChange={(event) => {
                          const isChecked = event.target.checked;
                          setSelectedRows((prev) => {
                            if (isChecked) {
                              // Add row id to selectedRows
                              return [...prev, e.userId];
                            } else {
                              // Remove row id from selectedRows
                              return prev.filter((rowId) => rowId !== e.userId);
                            }
                          });
                        }}
                    />
                  </td>

                  <td className="text-nowrap">{e.user.fullName || '--'}</td>
                  <td className="text-nowrap">{e.user.vcnId || '--'}</td>
                  <td className="text-nowrap">{e.product.name || '--'}</td>


                  <td className="text-nowrap">
                    <button
                        style={{
                          padding: "5px",
                          fontSize: "12px",
                          borderRadius: "10px",
                        }}
                        className="btn border-danger bg-white text-danger d-flex align-items-center w-100 justify-content-center"
                        onClick={(event) => handleSendCertificateClick(event, e)}
                    >
                      Send Certification
                    </button>
                  </td>
                  {/*<td className="text-nowrap">*/}
                  {/*  <CustomButton*/}
                  {/*    name="Barcode"*/}
                  {/*    iconType*/}
                  {/*    icon="barcode"*/}
                  {/*    color="red"*/}
                  {/*    action={(event) => generateQRCode(event, e)}*/}
                  {/*  />*/}
                  {/*  </td>*/}
                </tr>
            );
          })}
          </tbody>
        </Table>

        <ConfirmActionsModal
            show={showConfirmModal}
            setShow={setShowConfirmModal}
            text={text}
            smText={""}
            action={handleSubmit}
        />

        <SuccessModal
            show={showS}
            setShow={setShowS}
            bgTxt={bgTxt}
            smTxt={smTxt}
        />
        <ErrorModal
            show={showE}
            setShow={setShowE}
            bgTxt={"Failed"}
            smTxt={"Could not send certificate."}
            serverError={error}
        />
      </div>
  );
}

export default CertificatesTable;
