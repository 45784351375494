export const API_URI = process.env.REACT_APP_API_URL;
export const TOKEN_URI = process.env.REACT_APP_TOKEN_URL;
export const ADMIN_EMAIL_GENERATE_TOKEN = process.env.REACT_APP_ADMIN_EMAIL; // remove later
export const PAYMENT_CALLBACK_URL = process.env.REACT_APP_CALLBACK_URL;
export const BILLING_PAYMENT_CALLBACK_URL = process.env.REACT_APP_BILLING_CALLBACK_URL;
export const TOKEN_COOKIE = "EMR:TOKEN_COOKIE";
export const USER_COOKIE = "EMR:USER_COOKIE";
export const DESIGNATION  = {
    GUEST: 'Guest',
    VETERINARIAN: 'Veterinarian',
    SUPPORTOFFICER: 'SupportOfficer',
    STATEOFFICER: 'StateOfficer',
    COUNCILMANAGEMENT: 'CouncilManagement',
    ADMIN: 'Administrator',
};

export const USERVALUE = {
    ADMIN: 0,
    SUPPORTOFFICER: 1,
    PRACTITIONER: 2,
}
