import React from 'react';
import SignInIcon from "../assets/images/signIn.svg";
import ProfileUpdateIcon from "../assets/images/profileUpdate.svg";
import VerifyIcon from "../assets/images/verifyIcon.svg";
import AuthLayout from "./layout/AuthLayout";
import {useNavigate} from "react-router-dom";


const OptionSection = () => {
  const navigate = useNavigate();

  const options = [
    {
      label: "Profile Update",
      icon: ProfileUpdateIcon,
      subText:'Click here to update your veterinarian profile with your correct details.',
      path: '/veterinarian?tab=update-profile',
    },
    {
      label: "Verify A Practitioner",
      icon: VerifyIcon,
      subText:'Verify the identity and practice license of any veterinarian.',
      path: '/verify-profile',
    },
    {
      label: "Sign In",
      icon: SignInIcon,
      subText:'Your profile must have been updated by you and approved by the council for you to sign in.',
      path: '/login',
    },
  ];

  const handleSelect = (path) => {
    navigate(path);
  };

  return (
      <AuthLayout>
        <div className="option-wrapper">
          <div className="d-flex justify-content-between">
            <h4 className="fs-2 fw-bold">CLICK AN OPTION</h4>
          </div>

          <div className="option-grid">
            {options.map((option, index) => (
                <div className='option-box' onClick={() => handleSelect(option.path)}>
                  <div className="menu-icon">
                    <img
                        loading="lazy"
                        src={option.icon}
                        className="img-fluid"
                        alt={option.label}
                    />
                  </div>
                  <div className="menu-content">
                    <div className="menu-label">{option.label}</div>
                    <div className="menu-subtext">{option.subText}</div>
                  </div>
                </div>
            ))}
          </div>
        </div>
      </AuthLayout>
  );
};

export default OptionSection;
