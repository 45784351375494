import React, {useEffect, useState} from 'react';
import {Modal, ModalBody, ModalHeader, Row, Col, Spinner} from 'reactstrap';
import UserImage from "../../assets/images/user.png";
import ImageViewModal from "./ImageViewModal";
import {request} from "../../services/utilities";
import SuccessModal from "./SuccessModal";
import ConfirmActionsModal from "./ConfirmActionsModal"; // Path to default user image

const UserDetailsModal = ({ show, setShow, user, fetchPractitioners }) => {
  const [selectedImage, setSelectedImage] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [text, setText] = useState("");
  const [actionStatus, setActionStatus] = useState("");
  const [isImageViewOpen, setIsImageViewOpen] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [bgTxt, setBgTxt] = useState("Update Access status Completed Successfully");
  const [smTxt, setSmTxt] = useState("Access status updated successfully");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState([]);

  // Function to handle image click (enlarge image)
  const handleImageClick = () => {
    setSelectedImage(user.imageUrl || UserImage);
    setIsImageViewOpen(true); // Open the image view modal
  };
  const handleValidClick = () => {
    setSelectedImage(user.uploadValidId || UserImage);
    setIsImageViewOpen(true); // Open the image view modal
  };
  const handlePassportClick = () => {
    setSelectedImage(user.passportPhotograph || UserImage);
    setIsImageViewOpen(true); // Open the image view modal
  };
  const handleToggleConfirmModal = (action) => {
    setText(
        `You Are About To ${action} Practitioner Profile  For VCNID ${user?.vcnId}.`
    );
    setActionStatus(action);
    setShowConfirmModal(true);
  };

  const approvePractitioner = async () => {
    setLoading(true);
    setShowConfirmModal(false);
    const data = { accessStatus: actionStatus, userId: user?.id };
    const url = "profile/UpdateUserStatus";
    try {
      const rs = await request(url, "POST", true, data);
      setLoading(false);
      if (rs.code === "OK") {
        fetchPractitioners();
        setBgTxt(
            `VCN ${user?.vcnId} Profile Successfully ${actionStatus}d `
        );
        setSmTxt("");
        setShowSuccessModal(true);
      } else {
        setLoading(false);
        setError(rs.errors);
        clearError();
      }
    } catch (err) {
      setLoading(false);
      setError([`Failed to ${actionStatus}e kindly try again later`]);
      clearError();
    }
  };

  const clearError = () => {
    const timer = setTimeout(() => setError([]), 3000);
    return () => clearTimeout(timer);
  };

  useEffect(() => {
    if (showSuccessModal === false || error === false) {
      setShow(false);
    }
  }, [showSuccessModal]);

  return (
      <Modal isOpen={show} centered className="min-w-100 modal-lg">
        <ModalHeader
            className="modal-title pb-0 fw-bold px-5 pt-4 pb-3"
            toggle={() => setShow(!show)}
        ></ModalHeader>
        <ModalBody className="d-flex flex-column gap-3 px-5 pb-2">
          <div>
            <div className="text-center d-flex justify-content-center my-3">
              <div className="d-flex flex-column">
                <img
                    src={user?.imageUrl || UserImage}
                    alt="User"
                    className="m-2"
                    style={{width: '100px', height: '100px', borderRadius: '50%', cursor: 'pointer'}}
                    onClick={handleImageClick}
                />
                <span>Profile Image</span>
              </div>
              <div className="d-flex flex-column">
                <img
                    src={user?.uploadValidId || UserImage}
                    alt="User"
                    className="m-2"
                    style={{width: '100px', height: '100px', borderRadius: '50%', cursor: 'pointer'}}
                    onClick={handleValidClick}
                />
                <span>Valid Id</span>
              </div>
              <div className="d-flex flex-column">
                <img
                    src={user?.passportPhotograph || UserImage}
                    alt="User"
                    className="m-2"
                    style={{width: '100px', height: '100px', borderRadius: '50%', cursor: 'pointer'}}
                    onClick={handlePassportClick}
                />
                <span>Passport Photograph</span>
              </div>
            </div>

            <h5 className="text-capitalize fw-semibold fs-4">User Details</h5>
          </div>

          <div className="d-flex flex-column gap-3 px-3">
            <Row className="align-items-center">
              <Col className="fw-bold bglightred p-2">Full Name</Col>
              <Col>{user?.fullName || '--'}</Col>
            </Row>
            <Row className="align-items-center">
              <Col className="fw-bold bglightred p-2">VCN ID</Col>
              <Col>{user?.vcnId || '--'}</Col>
            </Row>
            <Row className="align-items-center">
              <Col className="fw-bold bglightred p-2">Phone Number</Col>
              <Col>{user?.phoneNumber || '--'}</Col>
            </Row>
            <Row className="align-items-center">
              <Col className="fw-bold bglightred p-2">Email Address</Col>
              <Col>{user?.email || '--'}</Col>
            </Row>
            <Row className="align-items-center">
              <Col className="fw-bold bglightred p-2">Access Status</Col>
              <Col>{user?.accessStatus || '--'}</Col>
            </Row>

          </div>

          {/* Approval Button */}
          <div className="text-center m-4">
            <button
                id="bgred"
                className="btn text-light"
                onClick={() =>
                    handleToggleConfirmModal(
                        user?.accessStatus === "Approve"
                            ? "DisApprove"
                            : "Approve"
                    )
                }
            >
              {user?.accessStatus === "Approve" ? "Disapprove" : "Approve"}
            </button>
          </div>

          <div className="float-end modalw mt-4">
            {error.map((e) => {
              return (
                  <div
                      key={e}
                      className="alert text-center w-100 text-capitalize alert-danger alert-dismissible border-2 p-1 fade show"
                      role="alert"
                      style={{background: "#fff", fontSize: "14px"}}
                  >
                    {e}
                  </div>
              );
            })}
          </div>
        </ModalBody>

        <div className="float-end">
          {loading ? <Spinner color="danger"/> : ""}
        </div>

        <ImageViewModal
            isOpen={isImageViewOpen}
            toggle={() => setIsImageViewOpen(false)}
            imageUrl={selectedImage}
        />

        <SuccessModal
            show={showSuccessModal}
            setShow={setShowSuccessModal}
            bgTxt={bgTxt}
            smTxt={smTxt}
            action={fetchPractitioners}
        />

        <ConfirmActionsModal
            show={showConfirmModal}
            setShow={setShowConfirmModal}
            text={text}
            smText={""}
            action={approvePractitioner}
        />
      </Modal>
  );
};

export default UserDetailsModal;
