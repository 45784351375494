import React from 'react'
import { Container, Navbar } from 'react-bootstrap'
import Logo from '../../assets/images/vcn_logo.svg'
import Footer from '../shared/Footer'
import { Link } from 'react-router-dom'

const AuthLayout = ({children}) => {
  return (
    <Container
      fluid
      className="d-flex p-0 flex-column justify-content-between container-bg"
      style={{ height: "100vh" }}
    >
         <Container fluid className="border-bottom">
          <Link to="/">
        <Navbar className=" py-3">
          <Container fluid className="align-items-center">
            <img
              src={Logo}
              className="img-fluid"
              style={{ maxWidth: "70%", height: "auto", cursor: "pointer"}}
              alt="logo"
            />
          </Container>
        </Navbar>
        </Link>
      </Container>
        {children}
     <Footer />
    </Container>
  )
}

export default AuthLayout