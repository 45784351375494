import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label
} from "reactstrap";
import { useState } from "react";
import CaretIcon from "../assets/icons/CaretIcon";
import CheckIcon from "../assets/icons/CheckIcon";
import {ChevronDown} from "react-feather";

// Select Component
export const Select = ({
   handleFilter,
   type,
   defaultOption = "",
   selectedValue,
   options = [],
   }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const getSelectedLabel = (selectedValue, options) => {
    const selectedOption = options.find(option => option.value === selectedValue);
    return selectedOption ? selectedOption.label : defaultOption;
  }

  return (
      <div>
        {type && <h6>{type}:</h6>}
        <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
          <DropdownToggle className={`button-filter x-2 d-flex align-items-center justify-content-between rounded-3 gap-2 py-2 px-3 w-auto`}>
            <span className="select-input">
              {getSelectedLabel(selectedValue, options)}
          </span>
            <ChevronDown style={{width: '1em', height: '1em'}} />
          </DropdownToggle>
          <DropdownMenu  className="mt-1 position-absolute">
            {options.map((option, index) => (
                <DropdownItem
                    key={index}
                    className="border-0 d-flex gap-4 hover-effect align-items-center py-2 justify-content-between px-4"
                    onClick={()=>handleFilter(option.value)}
                >
                  <span className='select-input'> {option.label} </span>
                  {
                    option.value === "" ? null : (
                        <CheckIcon className={`${option.value === selectedValue ? "textred": "checkIcon border rounded-circle" }`} />
                    )
                  }

                </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>
  );
};
// For Labels

export const LabelTag = ({ htmlFor, required, label}) => {
  return(
      <Label htmlFor={htmlFor}>
        {label} {" "}
        {
            required && <span style={{color: "red"}}>*</span>
        }
      </Label>
  )
}
