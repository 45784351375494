import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const PaymentStatusModal = ({ isOpen, toggle, message }) => {
  return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Payment Verification Status</ModalHeader>
        <ModalBody>{message}</ModalBody>
        <ModalFooter>
          <button id="bgred" className="btn text-light"  onClick={toggle}>
            Close
          </button>
        </ModalFooter>
      </Modal>
  );
};

export default PaymentStatusModal;
