import Navbar from "../components/Navbar";
import { ToastContainer } from "react-toastify";
import CreateBillingComponent from "../components/CreateBilling";

const CreateBilling = () => {
  return (
      <>
        <Navbar />
        <div style={{paddingTop: "6rem"}}>
          <CreateBillingComponent/>
        </div>
        <ToastContainer />
      </>
  );
};

export default CreateBilling;
