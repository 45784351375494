import { Input, FormFeedback, FormGroup, Label } from "reactstrap"; // Step 1: Import Label
import { Eye, EyeOff } from "react-feather";
import { useState } from "react";

const CustomInput = ({
   Icon,
   name,
   placeholder,
   file,
   type,
   className = "py-3 align-items-center border-1 rounded-lg",
   id,
   onChange,
   onBlur,
   value,
   invalid,
   errors,
   touched,
   changeBackgroundOnData = false,
   children,
   label // Assume label prop is provided to customize the label text
}) => {
  const [eye, setEye] = useState(false);

  const inputStyle = {
    paddingLeft: Icon ? "2.6rem" : "1rem"
  }

  return (
      <FormGroup className="input-container">
        {label && <Label for={id}>{label}</Label>}
        {Icon && (
            <span
                className={`input-icon ${value ? "text-active" : "text-neutral"}`}
            >
          <Icon/>
        </span>
        )}
        <div className="d-flex">
          {file && children}
          <Input
              name={name}
              placeholder={placeholder}
              type={name === "password" || name === "confirmPassword" ? (eye === true ? "text" : "password") : type}
              className={`form-control ${className} ${invalid ? "is-invalid" : ""} ${type === 'radio' ? "radio-label" : ""}`}
              style={inputStyle}
              id={id} // Ensure this matches the Label's for attribute
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              invalid={invalid}
          >
            {type === "select" && children} {/* Render children for select type */}
          </Input>
        </div>
        <FormFeedback type="invalid" className="feedback">
          {touched && errors ? errors : null}
        </FormFeedback>

        {/* Shows up for password input fields */}
        {(name === "password" || name === "confirmPassword") && (
            <button
                className="btn btn-link position-absolute end-0 top-0 left-3 p-3 text-decoration-none text-muted"
                type="button"
                id="password-addon"
            >
              {eye === true ? (
                  <Eye size={20} onClick={() => setEye(false)}/>
              ) : (
                  <EyeOff onClick={() => setEye(true)} size={20}/>
              )}
            </button>
        )}
      </FormGroup>
  );
};

export default CustomInput;
