import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Container, Row, Col, FormGroup, Input, Label, Spinner, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'react-feather';
import { Store } from '../context/store';
import {request, scrollToTop} from '../services/utilities';
import VerificationModal from "./modals/VerificationModal";
import Logo from '../assets/images/vcn_logo.svg'
import Footer from "./shared/Footer";
import {Navbar} from "react-bootstrap";
import ErrorModal from "./modals/ErrorModal";

const VerifyProfile = () => {
  const store = useContext(Store);
  const [loading, setLoading] = useState(false);
  const [showE, setShowE] = useState(false);
  const [error, setError] = useState([]);
  const [tempVcn, setTempVcn] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [verifiedUser, setVerifiedUser] = useState({});
  const [licenseStatusCount, setLicensingStatusCount] = useState(0);
  const [licenseStatus, setLicensingStatus] = useState(0);


  const verifyVcn = async (e) => {
    if (tempVcn === "" && e === undefined) {
      setError(["VCN Number can not be empty"]);
      setShowE(true)
      return;
    }
    setLoading(true);
    try {
      const url = `Profile/GetUserProfileByVcnId?vcnId=${e ? e : tempVcn}`;
      const rs = await request(url, "GET", false);
      if (rs.hasErrors === true) {
        if (rs.errors) {
          setError(rs.errors);
        }
        if (rs.description) {
          setError([rs.description]);
        }
        if (rs.payload) {
          setError(rs.payload);
        }
      }
      if (rs.code === "OK") {
        if (rs.payload && rs.errors.length === 0) {
          setVerifiedUser(rs.payload);
          fetchLicenseStatus()
          setIsModalOpen(true)
        }
      } else {
        setIsModalOpen(false)
        setShowE(true)
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(["Failed to verify vcn number kindly try again later"]);
      setShowE(true)
    }
  };

  const fetchLicenseStatus = useCallback(async () => {
    setLoading(true);
    const url = `BillingStructure/GetBillings?VcnId=${tempVcn}&PaymentCompleted=${false}&ProductCategoryType=PractiseLicense`;
    try {
      const rs = await request(url, "GET", true);
      setLoading(false);
      if (rs.code === "OK") {
        // Update the license status count in both state and localStorage
        const count = rs.payload.items.length;
        setLicensingStatusCount(count);
        const currentYear = new Date().getFullYear();
        // Determine licensing status based on the updated count
        const licensingStatus = count === 0 ?
            `Licensed to practice for ${currentYear}` :
            `Not licensed to practice for year ${currentYear}`;
        setLicensingStatus(licensingStatus);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }, [tempVcn]);

  const msgTimer = () => {
    const timer = setTimeout(() => {
      setError([]);
    }, 3000);
    return () => clearTimeout(timer);
  };

  // useEffect(() => {
  //   msgTimer();
  // }, [error]);

  return (
      <Container
          fluid
          className="d-flex flex-column p-0 container-bg"
          style={{ minHeight: '100vh' }}
      >
        <Container fluid className="border-bottom">
          <Link to="/">
            <Navbar className="py-3">
              <Container fluid className="align-items-center">
                <img
                    src={Logo}
                    className="img-fluid"
                    style={{ maxWidth: "70%", height: "auto", cursor: "pointer" }}
                    alt="logo"
                />
              </Container>
            </Navbar>
          </Link>
        </Container>

        {/* Back Button */}
        <Row className="mx-5">
          <Col xs={12} md={4} className="d-flex justify-content-start">
            <Link to={'/'} className="mt-3 btn text-decoration-none" style={{ background: '#EFEFEF' }}>
              <ArrowLeft size={16} /> Back
            </Link>
          </Col>
        </Row>

        {/* Centered Content */}
        <div className="flex-grow-1 d-flex flex-column justify-content-center">
          <Row className="mx-5">
            <Col md={{ size: 6, offset: 3 }}>
              <div className="d-flex gap-2 flex-column">
                <div className="text-center">
                  <Label htmlFor="val0">Verify VCN Number</Label>
                  <Input
                      name="vcnId"
                      placeholder="VCN Number"
                      type="text"
                      className="form-control"
                      value={tempVcn}
                      id="val0"
                      onChange={(e) => setTempVcn(e.target.value)}
                  />
                </div>

                <button
                    onClick={() => verifyVcn()}
                    className="login-btn text-light p-2"
                    type="button"
                >
                  Verify VCN
                </button>
              </div>
            </Col>
          </Row>
        </div>

        <Footer />

        <ErrorModal
            show={showE}
            setShow={setShowE}
            bgTxt={"Failed"}
            smTxt={"Could not verify practitioner."}
            serverError={error}
        />

        <VerificationModal
            isOpen={isModalOpen}
            toggle={setIsModalOpen}
            user={verifiedUser}
            licenseStatusCount={licenseStatusCount}
            licenseStatus={licenseStatus}
        />
      </Container>
  );
};

export default VerifyProfile;
