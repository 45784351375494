import LockIcon from "../assets/icons/LockIcon";
import FingerPrintIcon from "../assets/icons/FingerPrintIcon";
import AlertIcon from "../assets/icons/AlertIcon";
import CancelIcon from "../assets/icons/CancelIcon";
import React, { useContext, useState } from "react";
import {
  Form,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import CustomInput from "./shared/CustomInput";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import SSRStorage from "../services/storage";
import { TOKEN_URI, USER_COOKIE, DESIGNATION } from "../services/constants";
import jwt from "jwt-decode";
import { Store } from "../context/store";
import AuthLayout from "./layout/AuthLayout";

const storage = new SSRStorage();

const LoginComponent = () => {
  const store = useContext(Store);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState([]);
  const navigate = useNavigate();
  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      vcnId: "",
      password: "",
    },
    validationSchema: Yup.object({
      vcnId: Yup.string().required("Please Enter Your VCN Number"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: async (e) => {
      setLoading(true);
      const data = {
        username: e.vcnId.toString(),
        password: e.password,
        grant_type: process.env.REACT_APP_ADMIN_GRANT_TYPE,
        client_secret: process.env.REACT_APP_ADMIN_CLIENT_SECRET,
        client_id: process.env.REACT_APP_ADMIN_CLIENT_ID,
      };
      try {
        const rs = await fetch(TOKEN_URI, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams(data),
        })
            .then((rs) => rs.json())
            .then((res) => {
              setLoading(false);
              if (res.access_token) {
                const token = res.access_token;
                const user = jwt(token);
                const userObj = { token, user };
                storage.setItem(USER_COOKIE, userObj);
                if (
                    user?.userType === DESIGNATION.ADMIN ||
                    user?.userType === DESIGNATION.SUPPORTOFFICER
                ) {
                  navigate("/admin/dashboard");
                } else {
                  navigate("/dashboard");
                }
              } else {
                setError([res.error_description]);
              }
            });
        const timer = setTimeout(() => setError([]), 3000);
        return () => clearTimeout(timer);
      } catch (err) {
        setLoading(false);
        setError(["Failed to login kindly try again later"]);
        console.log(err);
      }
    },
  });
  return (
      <AuthLayout>
        <div className="login-error-container">
          {error.length > 0 && (
              <div
                  className="login-error-alert d h-100 w-100 text-capitalize border-1"
                  role="alert"
              >
                <div className="gap-3 d-flex w-100 ">
                  <AlertIcon />
                  <div className="d-flex flex-column gap-2 justify-content-between">
                    {error.map((e) => {
                      return (
                          <h3 key={e} className="fs-4 fw-semibold">
                            {e}
                          </h3>
                      );
                    })}
                    <p className="">
                      Contact the admin for help or use the forgot password to reset
                      your login details
                    </p>
                  </div>
                </div>
                <CancelIcon />
              </div>
          )}
        </div>
        <div className="login-wrapper">
          <div className="d-flex justify-content-between">
            <h4 className="fs-1 fw-bold">Login</h4>
          </div>
          <Form
              className="pt-4 w-100"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
          >
            <div>
              <Label htmlFor="validationCustom01" className="fs-6">
                VCN Number
              </Label>
              <CustomInput
                  name="vcnId"
                  placeholder="Enter your VCN Number"
                  type="text"
                  id="validationCustom01"
                  Icon={FingerPrintIcon}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.vcnId || ""}
                  errors={validation.errors.vcnId}
                  touched={validation.touched.vcnId}
                  invalid={
                    validation.touched.vcnId && validation.errors.vcnId
                        ? true
                        : false
                  }
              />
            </div>
            <div>
              <Label htmlFor="validationCustom02" className="fs-6">
                Password
              </Label>
              <div className="position-relative auth-pass-inputgroup">
                <CustomInput
                    name="password"
                    placeholder="Enter your Password"
                    Icon={LockIcon}
                    id="validationCustom02"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.password || ""}
                    errors={validation.errors.password}
                    touched={validation.touched.password}
                    invalid={
                      validation.touched.password && validation.errors.password
                          ? true
                          : false
                    }
                />
              </div>
            </div>
            <div className="d-flex gap-2 justify-content-between">
              <div className="form-check checkbox">
                <Input
                    className="form-check-input"
                    type="checkbox"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value="option1"
                />
                <label
                    className="form-check-label font-medium"
                    htmlFor="inlineRadio1"
                >
                  Remember me
                </label>
              </div>
              <div className="float-end">
                <Link
                    to="/forgot-password"
                    className="textred font-medium text-decoration-none"
                >
                  Forgot password?
                </Link>
              </div>
            </div>

            <div className="w-100" style={{ paddingTop: "2em" }}>
              <button
                  className="w-100 login-btn py-2 text-light fw-normal"
                  type="submit"
              >
                {loading ? <Spinner size={"xs"} color="white" /> : " Login"}
              </button>
            </div>

            {/* <div className="mt-5 text-center">
                <p className="mb-0">Don't have an account ? <Link to="/signup" className=" text-primary text-decoration-none"> Register Here</Link> </p>
            </div> */}
            <div className="text-center pt-3">
              <p className="mb-0">
                Are you a registered practitioner?{" "}
                <Link
                    to="/veterinarian?tab=update-profile"
                    className="textRed text-decoration-none"
                >
                  {" "}
                  Update Profile
                </Link>{" "}
              </p>
            </div>

            <div className="text-center pt-4">
              <Link
                  to="/verify-profile"
                  className="textRed text-decoration-none"
              >
                Verify Practitioner's Professional Profile
              </Link>
            </div>
          </Form>
        </div>
      </AuthLayout>
  );
};

export default LoginComponent;
