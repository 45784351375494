import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  Label,
  Spinner,
  FormGroup,
  FormFeedback,
  Button,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link, useParams } from "react-router-dom";
import FooterLogo from "../assets/images/footer.svg";
import UserImage from "../assets/images/user.png";
import { ArrowLeft } from "react-feather";
import { Store } from "../context/store";
import {
  request,
  scrollToTop,
  uploadID,
  uploadPassport,
} from "../services/utilities";
import SuccessModal from "./modals/SuccessModal";
import { Toast } from "./shared/Toast";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import fileDownload from "js-file-download";
import axios from "axios";
import { DESIGNATION } from "../services/constants";
import PlaceholderImage from "../assets/images/placeholder.png";
import Flatpickr from "react-flatpickr";
import ConfirmActionsModal from "./modals/ConfirmActionsModal";

const Profile = () => {
  const store = useContext(Store);
  const [userType] = store.userType;
  const [allState] = store.state;
  const [userDetials, setUserDetails] = useState(null);
  const [show, setShow] = useState(false);
  const [error, setError] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bgTxt, setBgTxt] = useState("Update Profile Completed Successfully'");
  const [smTxt, setSmTxt] = useState("profile updated successfully");
  const [disabled, setDisabled] = useState(true);
  const [firstname, setFristname] = useState("");
  const [lastname, setLastname] = useState("");
  const [othername, setOthername] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [dob, setDob] = useState("");
  const [picker, setPicker] = useState("");
  const [sex, setSex] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [lga, setLga] = useState("");
  const [idCard, setIdCard] = useState("");
  const [highestQualification, setHighestQualification] = useState("");
  const [title, setTitle] = useState("");
  const [uploadId, setUploadId] = useState(null);
  const [userId, setUserId] = useState("");
  const [passport, setPassport] = useState(null);
  const [accessStatus, setAccessStatus] = useState("");
  const [allLga, setAllLga] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [text, setText] = useState("");
  const [actionStatus, setActionStatus] = useState("");

  const userid = useParams();
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstname,
      lastname,
      othername,
      email,
      phone: phone !== null ? phone.toString() : "",
      sex,
      dob,
      picker,
      passport,
      previewPassport: "",
      uploadValidId: "",
      address,
      state,
      lga,
      stateCode: "",
      lgaCode: "",
      idCard,
      highestQualification,
      title,
      uploadId,
      terms: true,
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email").email(),
      phone: Yup.string()
        .required("Please Enter Your Phone Number")
        .max(11, "Maximum of 11 number!"),
      sex: Yup.string().required("Please Enter Your Sex"),
      dob: Yup.string().required("Please Enter Your Date of Birth"),
      passport: Yup.string().required("Please Upload Your Passport"),
      address: Yup.string().required("Please Enter Your Address"),
      state: Yup.string().required("Please Enter Your State"),
      lga: Yup.string().required("Please Enter Your LGA"),
      idCard: Yup.string().required("Please Select Your ID "),
      title: Yup.string().required("Please Select Your Practitioner Title"),
      highestQualification: Yup.string().required(
        "Please Select Your Highest Qualification"
      ),
      uploadId: Yup.string().required("Please Upload Your ID"),
    }),
    onSubmit: async (e) => {
      scrollToTop();
      setLoading(true);
      const pasP = await uploadPassport(e.passport);
      const upIdc = await uploadID(e.uploadId);
      const upcert = "";
      await updatePractitioner(e, pasP, upIdc, upcert);
    },
  });

  const clearError = () => {
    const timer = setTimeout(() => setError([]), 3000);
    return () => clearTimeout(timer);
  };
  const updatePractitioner = async (e, pasP, upIdc, upcert) => {
    try {
      const data = {
        userId: userid.id,
        firstname: e.firstname,
        lastname: e.lastname,
        middleName:
          e.othername === "" ? null : e.othername === null ? null : e.othername,
        email: e.email,
        phoneNumber: e.phone.toString(),
        sex: e.sex,
        dateOfBirth: e.picker,
        photo: pasP ? pasP : "",
        permanentResidenceAddress: e.address,
        lgaCode: e.lgaCode,
        state: e.state,
        stateCode: e.stateCode,
        residenceLocalGovernmentArea: e.lga,
        recentUtilityBill: "",
        validIdType: e.idCard,
        validId: upIdc ? upIdc : "",
        qualification: e.highestQualification,
        practitionerTitle: e.title,
        highestInstitutionalCertificate: upcert ? upcert : "",
        acceptTermsAndCondition: e.terms,
        // inrelevant data
        accessStatus,
        countryCode: "+234",
        fingerPrint: "",
        highestForeignInstitutionalCertificate: "",
        licensedYear: "",
        nameOfForeignInstitution: "",
        nameOfInstitution: "",
        nationality: "",
        postalAddress: "",
        qrGeneration: "",
        registrationNumber: "",
        designations: "Veterinarian",
      };
      const url = `Profile/updateAccount`;
      const rs = await request(url, "POST", true, data);
      setLoading(false);
      if (rs.hasErrors === true) {
        if (rs.errors) {
          setError(rs.errors);
        }
        if (rs.description) {
          setError([rs.description]);
        }
        if (rs.payload) {
          setError(rs.payload);
        }
        scrollToTop();
      }
      if (rs.code === "OK") {
        fetchLoggedUser();
        setBgTxt(`VCN ${userDetials?.vcnId} Profile Updated Successfully`);
        setSmTxt("");
        setShowSuccessModal(true);
        setDisabled(true);
      }
      const timer = setTimeout(() => {
        setError([]);
      }, 3000);
      return () => clearTimeout(timer);
    } catch (err) {
      setLoading(false);
      setError(["Failed to update practitioner kindly try again later"]);
      clearError();
    }
  };
  const updateUserInput = (e) => {
    setAccessStatus(e.accessStatus);
    fetchLga(e.state);
    setUserId(e.id);
    setFristname(e.firstName);
    setLastname(e.lastName);
    setOthername(
      e.middleName === "unavailable" || e.middleName === null
        ? ""
        : e.middleName
    );
    setEmail(e.email);
    setPhone(e.phoneNumber);
    setState(e.state);
    setSex(e.sex);
    setDob(e.dateOfBirth.split("T")[0]);
    setPicker(e.dateOfBirth.split("T")[0]);
    validation.setFieldValue("previewPassport", null);
    validation.setFieldValue("passport", e.imageUrl);
    setPassport(e.imageUrl);
    setAddress(e.permanentResidenceAddress);
    setLga(e.residenceLocalGovernmentArea);
    setIdCard(e.validIdType);
    setHighestQualification(e.qualification);
    setTitle(e.practitionerTitle);
    setUploadId(e.uploadValidId);
    // validation.setFieldValue('uploadValidId', e.uploadValidId);
  };
  // preview passport
  const previewPassport = async (file) => {
    if (!file) {
      return validation.setFieldValue("previewPassport", null);
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      validation.setFieldValue("previewPassport", reader.result);
    };
  };

  const handleToggleConfirmModal = (action) => {
    setText(
      `You Are About To ${action} Practitioner Profile  For VCNID ${userDetials?.vcnId}.`
    );
    setActionStatus(action);
    setShowConfirmModal(true);
  };
  const approvePractitioner = async () => {
    setLoading(true);
    setShowConfirmModal(false);
    const data = { accessStatus: actionStatus, userId: userid?.id };
    const url = "profile/UpdateUserStatus";
    try {
      const rs = await request(url, "POST", true, data);
      setLoading(false);
      if (rs.code === "OK") {
        fetchLoggedUser();
        setBgTxt(
          `VCN ${userDetials?.vcnId} Profile Successfully ${actionStatus}d `
        );
        setSmTxt("");
        setShowSuccessModal(true);
      }
    } catch (err) {
      setLoading(false);
      setError([`Failed to ${actionStatus}e kindly try again later`]);
      clearError();
    }
  };
  const downLoadFile = ({ file, name }) => {
    setLoading(true);
    scrollToTop();
    const fileArr = file.split(".");
    const lastindex = fileArr.length - 1;
    const ext = fileArr[lastindex];

    axios
      .get(file, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, `${name}.${ext}`);
        setLoading(false);
        notifyToast();
      });
  };
  const notifyToast = () =>
    toast.success(<Toast tbgt="Downloaded Successfully" />, {
      icon: false,
      hideProgressBar: false,
    });

  const fetchLoggedUser = useCallback(async () => {
    try {
      setLoading(true);
      const url = `Profile/GetUserProfileById?id=${userid.id}`;
      const rs = await request(url, "GET", true);
      setUserDetails(rs.payload);
      updateUserInput(rs.payload);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(["Failed to get user details kindly try again later"]);
      clearError();
    }
  }, [userid.id]);
  const fetchLga = useCallback(async (e) => {
    try {
      const url = `Locations/GetLGA?state=${e}`;
      const rs = await request(url, "GET", false);
      setAllLga(rs.payload);
    } catch (err) {}
  }, []);

  useEffect(() => {
    fetchLoggedUser();
  }, [fetchLoggedUser]);

  return (
    <>
      <Container>
        <Row>
          <Col xs={12}>
            <Card id="bgred" className="text-white">
              <CardBody className="p-4">
                <Row className="align-items-end">
                  <Col sm={8}>
                    <div className="d-flex align-items-center">
                      <div>
                        <img
                          src={
                            userDetials?.imageUrl !== "passport" &&
                            userDetials?.imageUrl !== null
                              ? userDetials?.imageUrl
                              : UserImage
                          }
                          width="90"
                          height="90"
                          style={{
                            borderRadius: "50%",
                            border: "1px solid #fff",
                          }}
                          alt="user"
                        />
                      </div>
                      <div className="p-3">
                        <p className="fs-3 mb-0 fw-600 lh-base text-capitalize">
                          {userDetials?.firstName}{" "}
                          {userDetials?.middleName === "unavailable" ||
                          userDetials?.middleName === null
                            ? ""
                            : userDetials?.middleName}{" "}
                          {userDetials?.lastName}
                        </p>
                        <div className="fs-4 fw-600 text-light  ">
                          {userDetials?.vcnId}
                        </div>
                        <div className="fs-4 fw-600 text-light  ">
                          <strong className="">Current Status:</strong>{" "}
                          {userDetials?.accessStatus}
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col sm={4}>
                    <div className="float-end">
                      <div>
                        <p className="fs-3  mb-0 fw-600 lh-base text-capitalize">
                          {userDetials?.amountOwed > 0 ?
                              `This user has an extra ₦${new Intl.NumberFormat("en-US").format(Math.abs(userDetials?.amountOwed))} in their account` : ''
                          }
                        </p>
                      </div>
                      {userType !== DESIGNATION.ADMIN ? (
                        ""
                      ) : (
                        <div className="p-3 float-end">
                          <Link
                            to="#"
                            onClick={() =>
                              handleToggleConfirmModal(
                                userDetials?.accessStatus === "Approve"
                                  ? "DisApprove"
                                  : "Approve"
                              )
                            }
                            className="btn bg-white text-danger"
                          >
                            {userDetials?.accessStatus === "Approve"
                              ? "Disapprove"
                              : "Approve"}
                          </Link>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Card className="border-none border-white">
            <CardBody className="p-0">
              <div className="bg-white">
                <div className="float-end modalw mt-4">
                  {error.map((e) => {
                    return (
                      <div
                        key={e}
                        className="alert text-center w-100 text-capitalize alert-danger alert-dismissible border-2 p-1 fade show"
                        role="alert"
                        style={{ background: "#fff", fontSize: "14px" }}
                      >
                        {e}
                      </div>
                    );
                  })}
                </div>
                <div className="w-100 d-flex justify-content-between">
                  <div>
                    <Link
                      to={
                        userType === DESIGNATION.ADMIN
                          ? "/admin/practitioner"
                          : "/dashboard"
                      }
                      className="mb-3 btn text-decoration-none"
                      style={{ background: "#EFEFEF" }}
                    >
                      <ArrowLeft size={16} /> Back
                    </Link>
                  </div>
                  <div className="float-end">
                    {loading ? <Spinner color="danger" /> : ""}
                  </div>
                </div>

                <p
                  className="fw-800 fs-6 pb-2 personal-information"
                  style={{ borderBottom: "1px solid #eee" }}
                >
                  Personal Information
                </p>
                {disabled === false ? (
                  <div className="bg-white mb-3 user-passport mb-4 d-flex align-items-end">
                    <div
                      style={{
                        width: "",
                        boxShadow: "0 1px 2px rgba(16,24,60,.05)",
                        padding: "10px",
                      }}
                    >
                      <img
                        src={
                          validation.values.previewPassport
                            ? validation.values.previewPassport
                            : validation.values.passport
                            ? validation.values.passport
                            : PlaceholderImage
                        }
                        width="150"
                        height="150"
                        className="rounded-circle"
                        alt="user passport"
                      />
                    </div>
                    <div className="mx-3">
                      <FormGroup className="">
                        <Label htmlFor="val8">
                          Upload Passport Photograph{" "}
                        </Label>
                        <Input
                          name="passport"
                          type="file"
                          className="form-control"
                          disabled={disabled}
                          id="val8"
                          onChange={(e) => {
                            validation.setFieldValue(
                              "passport",
                              e.currentTarget.files[0]
                            );
                            previewPassport(e.currentTarget.files[0]);
                          }}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.passport &&
                            validation.errors.passport
                              ? true
                              : false
                          }
                        />
                        {validation.touched.passport &&
                        validation.errors.passport ? (
                          <FormFeedback type="invalid">
                            {validation.errors.passport}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <Form
                  className="pt-4"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <Row>
                    <Col xl={4} sm={12} md={6}>
                      <FormGroup className="mb-3 ">
                        <Label htmlFor="val1">First Name</Label>
                        <Input
                          name="firstname"
                          placeholder=""
                          type="text"
                          className="form-control"
                          id="val1"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.firstname || ""}
                          disabled={disabled}
                          invalid={
                            validation.touched.firstname &&
                            validation.errors.firstname
                              ? true
                              : false
                          }
                        />
                        {validation.touched.firstname &&
                        validation.errors.firstname ? (
                          <FormFeedback type="invalid">
                            {validation.errors.firstname}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col xl={4} sm={12} md={6}>
                      <FormGroup className="mb-3">
                        <Label htmlFor="val3">Other Name</Label>
                        <Input
                          name="othername"
                          placeholder=""
                          type="text"
                          className="form-control"
                          id="val3"
                          disabled={disabled}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.othername || ""}
                          invalid={
                            validation.touched.othername &&
                            validation.errors.othername
                              ? true
                              : false
                          }
                        />
                        {validation.touched.othername &&
                        validation.errors.othername ? (
                          <FormFeedback type="invalid">
                            {validation.errors.othername}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col xl={4} sm={12} md={6}>
                      <FormGroup className="mb-3">
                        <Label htmlFor="val2">Last Name</Label>
                        <Input
                          name="lastname"
                          placeholder=""
                          disabled={disabled}
                          type="text"
                          className="form-control"
                          id="val2"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.lastname || ""}
                          invalid={
                            validation.touched.lastname &&
                            validation.errors.lastname
                              ? true
                              : false
                          }
                        />
                        {validation.touched.lastname &&
                        validation.errors.lastname ? (
                          <FormFeedback type="invalid">
                            {validation.errors.lastname}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={4} sm={12} md={6}>
                      <FormGroup className="mb-3">
                        <Label htmlFor="val4">Email</Label>
                        <Input
                          name="email"
                          placeholder=""
                          type="text"
                          className="form-control"
                          disabled={disabled}
                          id="val4"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col xl={4} sm={12} md={6}>
                      <FormGroup className="mb-3">
                        <Label htmlFor="val5">Phone Number</Label>
                        <Input
                          name="phone"
                          placeholder=""
                          type="number"
                          className="form-control"
                          disabled={disabled}
                          id="val5"
                          onChange={(e) => {
                            validation.setFieldValue("phone", e.target.value);
                          }}
                          onBlur={validation.handleBlur}
                          value={validation.values.phone || ""}
                          invalid={
                            validation.touched.phone && validation.errors.phone
                              ? true
                              : false
                          }
                        />
                        {validation.touched.phone && validation.errors.phone ? (
                          <FormFeedback type="invalid">
                            {validation.errors.phone}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col xl={4} sm={12} md={6}>
                      <FormGroup className="mb-3">
                        <Label htmlFor="val6">Sex</Label>
                        <Input
                          name="sex"
                          type={disabled === true ? "text" : "select"}
                          className="form-control"
                          id="val6"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.sex || ""}
                          disabled={disabled}
                          invalid={
                            validation.touched.sex && validation.errors.sex
                              ? true
                              : false
                          }
                        >
                          <option>Select Sex</option>
                          <option>Male</option>
                          <option>Female</option>
                          <option>Prefer Not To Say</option>
                        </Input>
                        {validation.touched.sex && validation.errors.sex ? (
                          <FormFeedback type="invalid">
                            {validation.errors.sex}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={4} sm={12} md={6}>
                      <FormGroup className="mb-3">
                        <Label htmlFor="val7">Date of Birth (dd/mm/yyy) </Label>
                        {disabled === true ? (
                          <Input
                            className="form-control"
                            disabled={disabled}
                            type="date"
                            value={dob}
                          />
                        ) : (
                          <Flatpickr
                            name="dob"
                            id="val7"
                            className="form-control"
                            placeholder="dd/mm/yyyy"
                            style={{
                              backgroundColor:
                                disabled === true ? "#e9ecef!important" : "",
                            }}
                            value={validation.values.dob || ""}
                            onChange={(selectedDates, dateStr) => {
                              if (!selectedDates.length) return;
                              const ISODate = selectedDates[0].toISOString();
                              const utct = ISODate.split("T")[1];
                              const newDate = `${dateStr}T${utct}`;
                              validation.setFieldValue("dob", ISODate);
                              validation.setFieldValue("picker", newDate);
                            }}
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              dateFormat: "Y-m-d",
                              maxDate: new Date(),
                              disabled,
                            }}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.dob && validation.errors.dob
                                ? true
                                : false
                            }
                          />
                        )}

                        {validation.touched.dob && validation.errors.dob ? (
                          <FormFeedback type="invalid">
                            {validation.errors.dob}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col xl={4} sm={12} md={6}>
                      <FormGroup className="mb-3">
                        <Label htmlFor="valT">Practitioner Title</Label>
                        <Input
                          name="title"
                          placeholder="Practitioner Title"
                          type={disabled === true ? "text" : "select"}
                          className="form-control"
                          disabled={disabled}
                          id="valT"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.title || ""}
                          invalid={
                            validation.touched.title && validation.errors.title
                              ? true
                              : false
                          }
                        >
                          <option>Select Practitioner Title</option>
                          <option>Prof.</option>
                          <option>Dr.</option>
                        </Input>
                        {validation.touched.title && validation.errors.title ? (
                          <FormFeedback type="invalid">
                            {validation.errors.title}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                    {disabled === true ? (
                      <Col xl={4} sm={12} md={6}>
                        <Label htmlFor="val7" style={{ visibility: "hidden" }}>
                          {" "}
                        </Label>
                        <div className="d-flex mt-2">
                          <button
                            type="button"
                            disabled={userDetials?.imageUrl ? false : true}
                            id="bgred"
                            onClick={() =>
                              downLoadFile({
                                file: userDetials?.imageUrl,
                                name: `${userDetials?.fullName}-${userDetials?.vcnId}-passport`,
                              })
                            }
                            style={{
                              borderTopRightRadius: "0px",
                              borderBottomRightRadius: "0px",
                            }}
                            className="btn  text-white w-75"
                          >
                            Download Passport
                          </button>
                          <Input
                            style={{
                              cursor: "pointer",
                              borderTopLeftRadius: "0px",
                              borderBottomLeftRadius: "0px",
                            }}
                            value={userDetials?.imageUrl?.substring(64)}
                            disabled
                            className="form-control"
                          />
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                  {/* </div> */}
                  <div
                    className="address pt-4 pb-3"
                    style={{ borderBottom: "1px solid #eee" }}
                  >
                    <p className="fw-800 fs-6">Address</p>
                    <Row>
                      <Col xl={4} sm={12} md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="val9a">Permanent Residence </Label>
                          <Input
                            name="address"
                            type="text"
                            className="form-control"
                            placeholder=""
                            id="val9a"
                            onChange={validation.handleChange}
                            disabled={disabled}
                            onBlur={validation.handleBlur}
                            value={validation.values.address || ""}
                            invalid={
                              validation.touched.address &&
                              validation.errors.address
                                ? true
                                : false
                            }
                          />
                          {validation.touched.address &&
                          validation.errors.address ? (
                            <FormFeedback type="invalid">
                              {validation.errors.address}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col xl={4} sm={12} md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="val9">State</Label>
                          <Input
                            name="state"
                            type={disabled === true ? "text" : "select"}
                            className="form-control"
                            id="val9"
                            onChange={(e) => {
                              const stateId = document.querySelector("#val9");
                              const state_code =
                                stateId.options[
                                  stateId.selectedIndex
                                ].getAttribute("statecode");
                              validation.setFieldValue("stateCode", state_code);
                              validation.handleChange(e);
                              fetchLga(e.target.value);
                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values.state || ""}
                            disabled={disabled}
                            invalid={
                              validation.touched.state &&
                              validation.errors.state
                                ? true
                                : false
                            }
                          >
                            <option>Select State</option>
                            {allState?.map((e) => {
                              return (
                                <option
                                  id={e.state__name}
                                  statecode={e.state__id}
                                  key={e.state__id}
                                >
                                  {e.state__name}
                                </option>
                              );
                            })}
                          </Input>
                          {validation.touched.state &&
                          validation.errors.state ? (
                            <FormFeedback type="invalid">
                              {validation.errors.state}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col xl={4} sm={12} md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="val10">LGA</Label>
                          <Input
                            name="lga"
                            type={disabled === true ? "text" : "select"}
                            className="form-control"
                            id="val10"
                            onChange={(e) => {
                              const lgaId = document.querySelector("#val10");
                              const lga_code =
                                lgaId.options[lgaId.selectedIndex].getAttribute(
                                  "lgacode"
                                );
                              validation.setFieldValue("lgaCode", lga_code);
                              validation.handleChange(e);
                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values.lga}
                            disabled={disabled}
                            invalid={
                              validation.touched.lga && validation.errors.lga
                                ? true
                                : false
                            }
                          >
                            <option>Select LGA</option>
                            {allLga?.map((e) => {
                              return (
                                <option
                                  lgacode={e["LGA CODE"]}
                                  key={e["LGA CODE"]}
                                >
                                  {e["LGA NAME"]}
                                </option>
                              );
                            })}
                          </Input>
                          {validation.touched.lga && validation.errors.lga ? (
                            <FormFeedback type="invalid">
                              {validation.errors.lga}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div
                    className="education pb-3"
                    style={{ borderBottom: "1px solid #eee" }}
                  >
                    <p className="fw-800 pt-4  fs-6">
                      Education & Qualification
                    </p>
                    <Row>
                      <Col xl={4} sm={12} md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="val15">Highest Qualification </Label>
                          <Input
                            name="highestQualification"
                            type={disabled === true ? "text" : "select"}
                            className="form-control"
                            id="val15"
                            placeholder="Select Highest Qualification"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.highestQualification || ""}
                            disabled={disabled}
                            invalid={
                              validation.touched.highestQualification &&
                              validation.errors.highestQualification
                                ? true
                                : false
                            }
                          >
                            <option>Select Highest Qualification</option>
                            <option>
                              Doctor of Veterinary Medicine (D.V.M)
                            </option>
                            <option>Doctor of Philosophy (PhD)</option>
                            <option>Masters</option>
                            <option>Others</option>
                          </Input>
                          {validation.touched.highestQualification &&
                          validation.errors.highestQualification ? (
                            <FormFeedback type="invalid">
                              {validation.errors.highestQualification}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col xl={4} sm={12} md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="val13">Select ID </Label>
                          <Input
                            name="idCard"
                            type="select"
                            className="form-control"
                            id="val13"
                            disabled={disabled}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.idCard || ""}
                            invalid={
                              validation.touched.idCard &&
                              validation.errors.idCard
                                ? true
                                : false
                            }
                          >
                            <option>Select ID</option>
                            <option>National ID</option>
                            <option>Drivers Licence</option>
                            <option>International Passport</option>
                            <option>Others</option>
                          </Input>
                          {validation.touched.idCard &&
                          validation.errors.idCard ? (
                            <FormFeedback type="invalid">
                              {validation.errors.idCard}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      {disabled === true ? (
                        <Col xl={4} sm={12} md={6}>
                          <Label
                            htmlFor="val7"
                            style={{ visibility: "hidden" }}
                          >
                            {" "}
                          </Label>
                          <div className="d-flex mt-2">
                            <button
                              type="button"
                              disabled={
                                userDetials?.uploadValidId ? false : true
                              }
                              id="bgred"
                              onClick={() =>
                                downLoadFile({
                                  file: userDetials?.uploadValidId,
                                  name: `${userDetials?.fullName}-${userDetials?.vcnId}-ID`,
                                })
                              }
                              style={{
                                borderTopRightRadius: "0px",
                                borderBottomRightRadius: "0px",
                              }}
                              className="btn text-white w-75"
                            >
                              Download ID
                            </button>
                            <Input
                              style={{
                                cursor: "pointer",
                                borderTopLeftRadius: "0px",
                                borderBottomLeftRadius: "0px",
                              }}
                              value={userDetials?.uploadValidId?.substring(64)}
                              disabled
                              className="form-control"
                            />
                          </div>
                        </Col>
                      ) : (
                        <Col xl={4} sm={12} md={6}>
                          <FormGroup className="mb-3">
                            <Label htmlFor="val14"> Upload ID</Label>
                            <div className="d-flex">
                              <Button
                                id="bgred"
                                className="w-100"
                                style={{
                                  borderTopRightRadius: "0px",
                                  border: "none",
                                  borderBottomRightRadius: "0px",
                                }}
                                onClick={() => {
                                  document.getElementById("val14").click();
                                }}
                                type="button"
                              >
                                Choose File
                              </Button>
                              <Input
                                style={{
                                  borderTopLeftRadius: "0px",
                                  borderBottomLeftRadius: "0px",
                                }}
                                readOnly
                                className="form-control"
                                value={
                                  validation.values.uploadValidId
                                    ? validation.values.uploadValidId
                                    : uploadId?.substring(64)
                                }
                              />
                            </div>
                            <Input
                              name="uploadId"
                              type="file"
                              className="form-control d-none"
                              id="val14"
                              disabled={disabled}
                              placeholder="Upload  ID"
                              onChange={(e) => {
                                validation.setFieldValue(
                                  "uploadId",
                                  e.currentTarget.files[0]
                                );
                                validation.setFieldValue(
                                  "uploadValidId",
                                  e.currentTarget.files[0].name
                                );
                              }}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.uploadId &&
                                validation.errors.uploadId
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.uploadId &&
                            validation.errors.uploadId ? (
                              <FormFeedback type="invalid">
                                {validation.errors.uploadId}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                      )}
                    </Row>
                  </div>
                  <div className="mt-4 text-end">
                    {disabled === true ? (
                      <button
                        className="btn text-light"
                        onClick={(e) => {
                          e.preventDefault();
                          setDisabled(false);
                        }}
                        id="bgred"
                        type="button"
                      >
                        Edit
                      </button>
                    ) : (
                      <button
                        className="btn text-light"
                        id="bgred"
                        type="submit"
                      >
                        Update
                      </button>
                    )}
                  </div>
                </Form>
              </div>

              <div className="text-center pt-5 pb-5">
                <p className="mb-0">
                  <img
                    src={FooterLogo}
                    width="20"
                    className="img-fluid"
                    alt="footer"
                  />{" "}
                  Powered by Milsat Technologies
                </p>
              </div>
            </CardBody>
          </Card>
        </Row>
      </Container>
      <SuccessModal
        show={showSuccessModal}
        setShow={setShowSuccessModal}
        bgTxt={bgTxt}
        smTxt={smTxt}
      />
      <ConfirmActionsModal
        show={showConfirmModal}
        setShow={setShowConfirmModal}
        text={text}
        smText={""}
        action={approvePractitioner}
      />
    </>
  );
};

export default Profile;
