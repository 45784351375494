import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { useNavigate } from "react-router-dom";
import AddressIcon from "../../assets/images/addressIcon.svg";
import ProgressIndicator from './ProgressIndicator'; // Import the ProgressIndicator component

const PremiseCard = ({ premise }) => {
  const navigate = useNavigate();

  const getStatusPercentage = (status) => {
    switch (status) {
      case 'Onboarding':
        return 0;
      case 'AwaitingInspection':
        return 33.34;
      case 'InspectionPassed':
        return 66.68;
      case 'InspectionFailed':
        return 66.68;
      case 'Approved':
        return 100;
      case 'DisApproved':
        return 100;
      default:
        return 0;
    }
  };

  const progressPercentage = getStatusPercentage(premise.approvalStatus);

  const cardStyles = {
    borderRadius: '17px',
    gap: '11px',
    background: '#FAFAFA',
    border: 'none',
  };

  const handleClick = () => {
    navigate(`/premise/${premise.id}`, { state: { premise } }); // Pass object
  };

  return (
      <Card style={cardStyles} onClick={handleClick}>
        <CardBody className='card-body-style'>
          <Row className="d-flex align-items-start flex-column h-100 w-100 gap-1">
            <Col>
              <span className='fw-semibold fs-6'>{premise.facilityName}</span>
            </Col>
            <Col>
              <div className="progress-address d-flex align-items-center gap-2">
                <img src={AddressIcon} loading='lazy' alt="address" className="progress-address-image"/>
                <span>{premise.address}</span>
              </div>
            </Col>
            <Col className='column-style'>
              <div className='my-3 mx-5'>
                <ProgressIndicator progressPercentage={progressPercentage} status={premise.approvalStatus}/>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
  );
};

export default PremiseCard;
