import React, {useEffect, useState, useCallback} from 'react';
import SortImg from '../assets/images/arrowsort.png';
import UserImage from '../assets/images/user.png';
import {useNavigate} from 'react-router-dom';
import {Table} from 'reactstrap';
import ConfirmQRCodeModal from "../components/modals/ConfirmGenerateQRCode";
import ConfirmActionsModal from "../components/modals/ConfirmActionsModal";
import { request } from "../services/utilities";
import SuccessModal from "../components/modals/SuccessModal";
import ErrorModal from "../components/modals/ErrorModal";

function PractitionerTable({data, showViewUser, showSendCertificate, showSelectCheckbox, showViewUserButton}) {
  const [sortKey, setSortKey] = useState('last_name');
  const [sortOrder, setSortOrder] = useState('ascn');
  const [text, setText] = useState('ascn');
  const [practitionerData, setPractitionerData] = useState([]); // added state to hold
  const [selectedRows, setSelectedRows] = useState([]); // added state to hold
  const [userIds, setUserIds] = useState([]); // added state to hold
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const navigate = useNavigate();
  const [showS, setShowS] = useState(false);
  const [showE, setShowE] = useState(false);
  const [error, setError] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false); // New state for modal toggle
  const [selectedId, setSelectedId] = useState(null); // State to keep track of selected ID
  const [selectedUserName, setSelectedUserName] = useState(null); // State to keep track of selected user
  const bgTxt = "Success!!";
  const smTxt = "Certificates have been sent successfully";

  const generateQRCode = (event, user) => {
    event.stopPropagation();
    // Implement QR Code generation logic here
    setSelectedId(user.id); // Set the selected ID
    setSelectedUserName(user.fullName); // Set the selected user's name
    setIsModalOpen(true); // Open the modal
  };

  const handleSendCertificateClick = (event, user) => {
    if (event) event.stopPropagation(); // Prevent triggering row click if event is present

    let userIds = [];
    let userNames = '';

    if (user) {
      // If a specific user is provided, use that user's ID and name
      userIds = [user.id];
      userNames = user.fullName;
    } else if (selectedRows.length > 0) {
      // If no specific user is provided but there are selected rows, use those
      userIds = selectedRows;
      userNames = practitionerData
          .filter(p => selectedRows.includes(p.id))
          .map(p => p.fullName)
          .join(", ");
    }

    // Construct the confirmation text based on the operation's context
    const confirmationText = userNames
        ? `Are you sure you want to send certificates to:<br /> <b>${userNames}</b>?`
        : "Please select at least one practitioner to send certificates to.";
    // Proceed only if there's meaningful action to take
    if (userIds.length > 0) {
      // Set the confirmation text and show the modal
      setText(confirmationText);
      setShowConfirmModal(true);
      setUserIds(userIds)
    } else {
      // Handle the case where no users are selected and no specific user is provided
      alert("No users selected for sending certificates.");
      setUserIds([])
    }
  };

  const sendAnnualPracticingLicenses = useCallback(async (userIds) => {
    try {
      const url = `Payment/SendAnnualPractisingLicense`;
      const data = { userIds };
      const response = await request(url, "POST", true, data);

      if (response.code === "OK" && !response.hasErrors) {
        setShowS(true);
        setSelectedRows([]);
      } else {
        setError([response.errors]);
        setShowE(true);
      }
    } catch (err) {
      setError([`Request failed:${err}`]);
      setShowE(true);
    } finally {
      setShowConfirmModal(false); // Close modal regardless of outcome
    }
  }, []);


  const handleSubmit = () => {
    sendAnnualPracticingLicenses(userIds)
  }

  function sortData({tableData, sortKey, reverse}) {
    if (!sortKey) return tableData;

    const sortedData = data.sort((a, b) => {
      return a[sortKey] > b[sortKey] ? 1 : -1;
    });

    if (reverse) {
      return sortedData.reverse();
    }

    return sortedData;
  }

  function SortButton({onClick}) {
    return (
      <span
        id="sort"
        style={{cursor: 'pointer'}}
        className="mx-2"
        onClick={onClick}
      >
        <img
          src={SortImg}
          className="img-fluid float-end"
          alt="sort"
        />
      </span>
    );
  }

  const headers = [
    showSelectCheckbox && { key: 'select', label: 'Select' },
    {key: 'fullName', label: 'Full Name'},
    {key: 'vcnId', label: 'VCN Number'},
    {key: 'phoneNumber', label: 'Phone Number'},
    {key: 'isFirstTimeUpdateDone', label: 'Access Status'},
    {key: 'emailAndPhoneNumUpdated', label: 'Profile Updated'},
    showViewUserButton && {key: 'view', label: 'View User'},
    showSendCertificate && { key: 'send', label: 'Send Certificate' },
  ].filter(Boolean);

  // updates the table based on updated data
  useEffect(() => {
    if (data !== practitionerData) {
      setPractitionerData(
        sortData({tableData: data, sortKey, reverse: sortOrder === 'desc'}));
    }
  }, [data, practitionerData, sortKey, sortOrder]);


  function changeSort(key) {
    setSortOrder(sortOrder === 'ascn' ? 'desc' : 'ascn');
    setSortKey(key);
    setPractitionerData(sortData({tableData: data, sortKey, reverse: sortOrder === 'desc'}));
  }

  return (
    <div className="fixTableHead">
      {showSendCertificate && (
          <div className="mb-2 d-flex justify-content-end">
            <button
                id="bgred"
                className="btn text-light"
                onClick={(event) => {
                  if (selectedRows.length > 0) {
                    handleSendCertificateClick(event); // Added a third parameter to distinguish the global action
                  } else {
                    alert("Please select at least one user.");
                  }
                }}
            >
              Send Certification to Selected User
            </button>
          </div>
      )}

      <Table
        responsive
        id="table-border-collapse"
        className="table table-borderless table-hover align-middle table-nowrap headerFixed"
      >
        <thead className="admin-table-red headerFixed">
        <tr>
          {headers.map((row) => {
            return (
              <th
                scope="col"
                className="text-nowrap headerFixed"
                key={row.key}
                style={{padding: '12px'}}
              >
                {row.label}{' '}
                <SortButton
                  columnKey={row.key}
                  onClick={() => changeSort(row.key)}
                  {...{
                    sortOrder,
                    sortKey,
                  }}
                />
              </th>
            );
          })}
        </tr>
        </thead>

        <tbody>
        {practitionerData.map((e) => {
          const handleRowClick = () => {
            if (!showSendCertificate) {
              navigate(`/admin/user-details/${e.id}`);
            }
          };

          return (
              <tr
                  key={e.id}
                  onClick={handleRowClick}
                  style={{cursor: 'pointer'}}
              >
                {showSelectCheckbox && (
                    <td>
                      <input
                          type="checkbox"
                          className="checkBox d-flex justify-content-end w-100"
                          checked={selectedRows.includes(e.id)}
                          onChange={(event) => {
                            const isChecked = event.target.checked;
                            setSelectedRows((prev) => {
                              if (isChecked) {
                                // Add row id to selectedRows
                                return [...prev, e.id];
                              } else {
                                // Remove row id from selectedRows
                                return prev.filter((rowId) => rowId !== e.id);
                              }
                            });
                          }}
                      />
                    </td>
                )}
                <td
                    className="text-nowrap"
                    style={{padding: '12px'}}
                >
                  <img
                      src={e.imageUrl ? e.imageUrl : UserImage}
                      className="mx-2"
                      width="35"
                      height="35"
                      style={{borderRadius: '50%', border: '1px solid #fff'}}
                      alt="user"
                  />
                  {e.fullName}
                </td>
                <td className="text-nowrap">{e.vcnId || '--'}</td>
                <td className="text-nowrap">{e.phoneNumber || '--'}</td>
                <td className="text-nowrap">
                  {e.accessStatus}
                </td>
                <td className="text-nowrap"> {e.isFirstTimeUpdateDone === true
                    ? 'True'
                    : 'False' || '--'}</td>
                {showViewUserButton && (
                    <td className="text-nowrap">
                      <button
                          id="bgred"
                          className="btn text-light d-flex align-items-center w-100 justify-content-center"
                          type="button"
                          onClick={(event) => {
                            event.stopPropagation();
                            showViewUser(e);
                          }}
                      >
                        View
                      </button>
                    </td>
                )}


                {showSendCertificate && (
                    <td className="text-nowrap">
                      <button
                          style={{
                            padding: "5px",
                            fontSize: "12px",
                            borderRadius: "10px",
                          }}
                          className="btn border-danger bg-white text-danger d-flex align-items-center w-100 justify-content-center"
                          onClick={(event) => handleSendCertificateClick(event, e)}
                      >
                        Send Certification
                      </button>
                    </td>
                )}
                {/*<td className="text-nowrap">*/}
                {/*  <CustomButton*/}
                {/*    name="Barcode"*/}
                {/*    iconType*/}
                {/*    icon="barcode"*/}
                {/*    color="red"*/}
                {/*    action={(event) => generateQRCode(event, e)}*/}
                {/*  />*/}
                {/*  </td>*/}
              </tr>
        );
        })}
        </tbody>
        </Table>
      <ConfirmQRCodeModal
        show={isModalOpen}
        setShow={setIsModalOpen}
        userData={{id: selectedId, name: selectedUserName}}
      />
      <ConfirmActionsModal
          show={showConfirmModal}
          setShow={setShowConfirmModal}
          text={text}
          smText={""}
          action={handleSubmit}
      />

      <SuccessModal
          show={showS}
          setShow={setShowS}
          bgTxt={bgTxt}
          smTxt={smTxt}
      />
      <ErrorModal
          show={showE}
          setShow={setShowE}
          bgTxt={"Failed"}
          smTxt={"Could not send certificate."}
          serverError={error}
      />
    </div>
  );
}

export default PractitionerTable;
