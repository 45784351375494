import Navbar from "../components/Navbar";
import ProfileComponent from "../components/Profile";
import { ToastContainer } from "react-toastify";
import Footer from "../components/shared/Footer";

const Profile = () => {
  return (
    <>
      <div className="d-flex p-0 flex-column justify-content-between container-fluid" style={{height: '100vh'}}>
        <Navbar />
        <div style={{paddingTop: "6rem"}}>
          <ProfileComponent/>
        </div>
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};

export default Profile;
