import React from "react";
import { Modal, ModalBody, ModalHeader, Col, Input, Button } from "reactstrap";

const ConfirmPaymentModal = ({ show, setShow }) => {
  return (
    <>
      <Modal
        isOpen={show}
        // centered
        className="modalw"
      >
        <ModalHeader
          style={{ borderBottom: "none" }}
          className="modal-title pb-0 fw-bold"
          toggle={() => {
            setShow(!show);
          }}
        >
          Confirm Payments
        </ModalHeader>
        <ModalBody className="">
          <form action="#">
            <div className="row g-3">
              <Col xxl={12}>
                <div>
                  <label htmlFor="firstName" className="form-label">
                    VCN Number
                  </label>
                  <Input
                    type="text"
                    className="form-control"
                    id="firstName"
                    placeholder="VCN000010"
                  />
                </div>
              </Col>
              <Col xxl={12}>
                <div className="mb-2">
                  <label htmlFor="teller" className="form-label">
                    Bank Teller No.
                  </label>
                  <Input
                    type="text"
                    className="form-control"
                    id="teller"
                    placeholder="BNK000010"
                  />
                </div>
              </Col>

              <Col lg={12}>
                <div className="hstack gap-2 pb-3 justify-content-end">
                  <button
                    id="bgred"
                    className="btn text-light"
                    onClick={() => setShow(!show)}
                  >
                    Confirm Payment
                  </button>
                </div>
              </Col>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ConfirmPaymentModal;
