import SignupComponent from "../components/Signup";

const ResetPasssword = () => {
  return (
    <div style={{ backgroundColor: "" }} className="h-100">
      <SignupComponent />
    </div>
  );
};

export default ResetPasssword;
