import { Card, CardBody, Col, Row, Spinner } from 'reactstrap';
import { Container } from 'react-bootstrap';
import FooterLogo from '../assets/images/footer.svg';
import EmptyImg from '../assets/images/empty.png';
import { useLocation } from 'react-router-dom';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import { request, scrollToTop, } from '../services/utilities';
import ConfirmPaymentModal from '../components/modals/ConfirmPaymentModal';
import ReactPaginate from 'react-paginate';
import { useAuthContext } from '../context/UserProvider';
import 'flatpickr/dist/themes/material_red.css';
import ConfirmQRCodeModal from './modals/ConfirmGenerateQRCode';
import TransactionDetailsModal from './modals/TransactionDetailsModal';
import BillingTable from '../util/BillingTable';
import PaymentAmountModal from './modals/PaymentAmountModal';
import {Store} from "../context/store";

const Billings = () => {
	const store = useContext(Store);
	const [user] = store.user;
	const { userInfo, userType, userId } = useAuthContext();
	const [ show, setShow ] = useState( false );
	const [ selectedTransaction, setSelectedTransaction ] = useState( null );
	const [ isShowtransactionModal, setShowTransactionModal ] = useState( false );
	const [ isModalOpen, setIsModalOpen ] = useState( false ); // New state for modal toggle
	const [ selectedId, setSelectedId ] = useState( null ); // State to keep track of selected ID
	const [ selectedVcnID, setSelectedVcnID ] = useState( null ); // State to keep track of selected ID
	const [ selectedUserName, setSelectedUserName ] = useState( null ); // State to keep track of selected user
	const [ kpis, setKpis ] = useState( null );
	const [ currentPage, setCurrentPage ] = useState( 1 );
	const [ totalPages, setTotalPages ] = useState( 0 );
	const [ totalItemsCount, setTotalItemsCount ] = useState( 0 );
	const [ firstItemOnPage, setFirstItemOnPage ] = useState( 0 );
	const [ lastItemOnPage, setLastItemOnPage ] = useState( 0 );
	const [ billings, setBillings] = useState([]);
	const [amount, setAmount] = useState("");
	const [showP, setShowP] = useState(false);
	const [errP, setErrP] = useState(false);
	const [amountOwed, setAmountOwed] = useState("");
	const [premiseLicenseCount, setPremiseLicenseCount] = useState(0);
	const [practiseLicenseCount, setPractiseLicenseCount] = useState(0);
	const [accessoriesCount, setAccessoriesCount] = useState(0);
	const [totalAmountOwedSum, setTotalAmountOwedSum] = useState(0);


	const [ billingItems, setBillingItems] = useState([]);
	const [ loading, setLoading ] = useState( false );
	const [ currentCount, setCurrentCount ] = useState( 0 );
	const [ params, setParams ] = useState( {
		product: '',
		status: '',
		channel: '',
		type: '',
		fromDate: '',
		toDate: '',
	} );
	const [ error, setError ] = useState( '' );

	const location = useLocation().pathname;

	const clearError = () => {
		const timer = setTimeout( () => setError( [] ), 3000 );
		return () => clearTimeout( timer );
	};

	const fetchBillings = useCallback(async () => {
		setLoading(true);
		const url = `BillingStructure/GetBillings?VcnId=${user?.vcnId}&PaymentCompleted=false`;
		try {
			const rs = await request(url, "GET", true);
			setLoading(false);
			if (rs.code === "OK") {
				setBillings(rs.payload.items);
				setTotalItemsCount( rs.payload.totalItemCount ); //rs.payload.totalItemCount;
				setTotalPages( rs.payload.pageCount ); // rs.payload.pageCount
				setLastItemOnPage( rs.payload.lastItemOnPage );
				setFirstItemOnPage( rs.payload.firstItemOnPage );
			}
		} catch (err) {
			setLoading(false);
			setError( 'Failed to get billings kindly try again later' );
			clearError();
			console.log(err);
		}
	}, [user]);

	const fetchProductsAndAddBilling = useCallback(async (billingsArr) => {
		setLoading(true);
		const productDetailsCache = new Map();
		let updatedItemsPromises = billingsArr.map(async (item) => {
			try {
				if (!productDetailsCache.has(item.productId)) {
					const url = `Product/GetProducts?Id=${item.productId}`;
					const response = await request(url, "GET", true);
					if (response.code === "OK") {
						const productData = response.payload.items[0];
						productDetailsCache.set(item.productId, productData);
					} else {
						throw new Error('Product fetch failed');
					}
				}

				const productData = productDetailsCache.get(item.productId);

				return {
					...item,
					name: productData?.name,
					paymentServiceProvider: productData?.paymentServiceProvider,
					productCategoryType: productData?.productCategoryType,
				};
			} catch (error) {
				console.error("Error fetching product data:", error);
				return item;  // Keeps original item if there's an error
			}
		});

		try {
			const updatedItems = await Promise.all(updatedItemsPromises);
			setBillingItems(updatedItems);
		} catch (err) {
			setError('Failed to process billing information');
			console.error(err);
		} finally {
			setLoading(false);
		}
	}, [billings]);

	const updateCountsAndSum = (items) => {
		let premiseCount = 0;
		let practiseCount = 0;
		let accessoriesCount = 0;
		let totalSum = 0;

		items.forEach(item => {
			if (item.productCategoryType === 'PremiseLicense') {
				premiseCount++;
			} else if (item.productCategoryType === 'PractiseLicense') {
				practiseCount++;
			} else if (item.productCategoryType === 'Accessories') {
				accessoriesCount++;
			}
			totalSum += item.totalAmountOwed;
		});

		setPremiseLicenseCount(premiseCount);
		setPractiseLicenseCount(practiseCount);
		setAccessoriesCount(accessoriesCount);
		setTotalAmountOwedSum(totalSum);
	};


	const handlePageChange = ( selectedPage ) => {
		scrollToTop();
		setCurrentCount( currentCount + 1 );
		setCurrentPage( selectedPage.selected + 1 );
	};

	useEffect(() => {
		if (userInfo) {
			fetchBillings();
		}
	}, [fetchBillings, userInfo]);

	useEffect(() => {
		if (billings.length > 0) {
			fetchProductsAndAddBilling(billings);
		}
	}, [billings]);

	useEffect(() => {
		if (billingItems.length > 0) {
			updateCountsAndSum(billingItems);
		}
	}, [billingItems]);

	return (
			<Container>
				<Row>
					<Card className='border-none border-white table-container'>
						<CardBody className='p-0'>
							{error.length >= 1 && (
									<div
											className='float-end alert text-capitalize alert-danger alert-dismissible border-2 p-1 fade show'
											role='alert'
											style={{background: '#fff', fontSize: '14px'}}
									>
										{error}
									</div>
							)}
							<div className='float-end'>
								{loading ? <Spinner color='danger'/> : ''}
							</div>
							{/*<Row>*/}
							{/*	<Col>*/}
							{/*		<p className='p-color fs-5 mx-2'>*/}
							{/*			{ ' ' }*/}
							{/*			{ billingItems.length >= 1*/}
							{/*				? `${ firstItemOnPage }-${ lastItemOnPage } / ${ totalItemsCount }`*/}
							{/*				: '0' }{ ' ' }*/}
							{/*			Billing Section*/}
							{/*		</p>*/}
							{/*	</Col>*/}
							{/*</Row>*/}

							{/*<Row className='mb-2 align-items-center' style={{ backgroundColor: '#F5F5F5' }}>*/}
							{/*	<Col className='d-flex gap-3 m-2 align-items-center justify-content-between'>*/}
							{/*		<button*/}
							{/*				name="Filter"*/}
							{/*				id="bgred"*/}
							{/*				className="login-btn x-2 d-flex align-items-center justify-content-between gap-2 py-2 px-3 h-auto w-auto text-white"*/}
							{/*				onClick={handleActiveFilter}*/}
							{/*				style={{borderRadius: '10px'}}*/}
							{/*		>*/}
							{/*			<FilterIcon />*/}
							{/*			<span className=''>Filter</span>*/}
							{/*		</button>*/}

							{/*		<FilterComponent*/}
							{/*				filters={filters}*/}
							{/*				clearFilter={clearFilter}*/}
							{/*				handleClearFilter={handleClearFilter}*/}
							{/*				activateFilter={activateFilter}*/}
							{/*				handleActiveFilter={handleActiveFilter}*/}
							{/*		/>*/}
							{/*	</Col>*/}
							{/*</Row>*/}

							<Row className='table-responsive'>
								<div className=''>
									{ billingItems.length >= 1 ? (
											<BillingTable
													data={ billingItems }
													pending={ false }
											/>
									) : (
											<div>
												<div>
													<BillingTable
															data={ billingItems.length >= 1 ? billingItems : [] }
															pending={ false }
													/>
													<div className='text-center pt-5'>
														<img src={ EmptyImg } className='img-fluid' alt='empty' />
														<p className='p-color pt-2'>No Record to show</p>
													</div>
												</div>
											</div>
									) }
									{ billings.length > 1 ? (
											<div className='d-flex mt-5  justify-content-center'>
												<ReactPaginate
														breakLabel='...'
														nextLabel='next >'
														onPageChange={ handlePageChange }
														pageRangeDisplayed={ 5 }
														pageCount={ totalPages }
														previousLabel='< previous'
														// forcePage={currentPage}
														containerClassName={ 'pagination' }
														previousLinkClassName={ 'pagination__link' }
														nextLinkClassName={ 'pagination__link' }
														disabledClassName={ 'pagination__link--disabled' }
														activeClassName={ 'pagination__link--active' }
												/>
											</div>
									) : (
											''
									) }
								</div>
							</Row>

						</CardBody>
					</Card>
				</Row>
				<PaymentAmountModal
						show={showP}
						setShow={setShowP}
						amount={amount}
						setAmount={setAmount}
						amountOwed={amountOwed}
						errP={errP}
						setErrP={setErrP}
				/>
				<ConfirmPaymentModal show={ show } setShow={ setShow } />
				<TransactionDetailsModal
						show={ isShowtransactionModal }
						setShow={ setShowTransactionModal }
						transaction={ selectedTransaction }
				/>
				<ConfirmQRCodeModal
						show={ isModalOpen }
						fileName={ 'All Payments' }
						modalTitle={ 'All Payments' }
						setShow={ setIsModalOpen }
						userData={ {
							id: selectedId,
							vcnID: selectedVcnID,
							name: selectedUserName,
						} }
				/>
			</Container>
	);
};

export default Billings;
