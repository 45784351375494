import Navbar from "../components/Navbar";
import AdminProfileComponent from "../components/AdminProfile";
import { ToastContainer } from "react-toastify";

const AdminProfile = () => {
  return (
    <>
      <Navbar />
      <div style={{paddingTop: "6rem"}}>
        <AdminProfileComponent/>
      </div>
      <ToastContainer />
    </>
  );
};

export default AdminProfile;
