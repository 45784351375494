import FooterLogo from "../../assets/images/footer.svg";

const Footer = () => {
  return (
      <div className="text-center mt-3 h-full p-3" style={{ background: '#FAFAFA' }}>
        <p className="mb-0">
          <img src={FooterLogo} width="31" className="img-fluid" alt="footer" />{" "}
          Powered by Milsat Technologies
        </p>
      </div>
  );
};

export default Footer;