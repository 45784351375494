import Navbar from "../components/Navbar";
import AdminDashboardComponent from "../components/AdminDashboard";
import { useAuthContext } from "../context/UserProvider";
import { useEffect } from "react";


const  AdminDashboard = () => {
  const {getUser} = useAuthContext();
  useEffect(() => {
    getUser();
  }, []);
  return (
    <>
      <Navbar />
      <div style={{paddingTop: "6rem"}}>
        <AdminDashboardComponent />
      </div>
    </>
  );
};

export default AdminDashboard;
