import React from 'react';
import { ProgressBar, Step } from 'react-step-progress-bar';
import 'react-step-progress-bar/styles.css';

const ProgressIndicator = ({ progressPercentage, status }) => {

  return (
      <ProgressBar
          percent={progressPercentage}
          filledBackground="linear-gradient(to right, #12B76A, #12B76A)"
          style={{ width: '100%' }} // Set full width for the progress bar
      >
        <Step transition="scale">
          {({ accomplished }) => (
              <>
                <div className={`step-indicator ${accomplished ? 'completed' : ''}`} />
                <div className={`step-label ${accomplished ? 'completed' : ''}`}>Premise<br/>Created</div>
              </>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }) => (
              <>
                <div className={`step-indicator ${accomplished ? 'completed' : ''}`} />
                <div className={`step-label ${accomplished ? 'completed' : ''}`}>Paid<br/>for Inspection</div>
              </>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }) => (
              <>
                <div className={`step-indicator ${accomplished ? 'completed' : ''} ${status === 'InspectionFailed' ? 'failed' : ''}`} />
                <div className={`step-label ${accomplished ? 'completed' : ''} ${status === 'InspectionFailed' ? 'failed' : ''}`}>
                  {status === 'InspectionFailed' ? (
                      <>
                        Inspection<br />Failed
                      </>
                  ) : (
                      <>
                        Inspection<br />Passed
                      </>
                  )}
                </div>
              </>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }) => (
              <>
                <div className={`step-indicator ${accomplished ? 'completed' : ''} ${status === 'DisApproved' ? 'failed' : ''}`} />
                <div className={`step-label ${accomplished ? 'completed' : ''} ${status === 'DisApproved' ? 'failed' : ''}`}>
                  {status === 'DisApproved' ? 'DisApproved' : 'Approved'}
                </div>
              </>
          )}
        </Step>
      </ProgressBar>
  );
};

export default ProgressIndicator;
