import { API_URI, TOKEN_URI, USER_COOKIE } from "./constants";
import axios from "axios";
import SSRStorage from "./storage";
import { Toast } from "../components/shared/Toast";
import { toast } from "react-toastify";

const parseJSON = (response) => response.json();
export const isUnset = (o) => typeof o === "undefined" || o === null;
export function decodeValue(val) {
  if (typeof val === "string") {
    try {
      return JSON.parse(val);
    } catch (_) {
      console.log(_);
    }
  }

  return val;
}
export function encodeValue(val) {
  if (typeof val === "string") {
    return val;
  }

  return JSON.stringify(val);
}
const headers = (user) => {
  if (user) {
    const jwt = `Bearer ${user.token}`;
    return { ...defaultHeaders, Authorization: jwt };
  } else {
    return defaultHeaders;
  }
};

export const defaultHeaders = {
  Accept: "application/json",
  "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, PATCH",
  "Content-Type": "application/json",
};

const checkStatus = async (response) => {
  // console.log(response);
  if (!response.ok) {
    const message = await response.text();
    const err = JSON.parse(message);
    console.log(err);
    throw Object.freeze({ message: err || err.detail || err.message });
  }

  return response;
};
export const generateAdminToken = async (data) => {
  try {
    const rs = await fetch(TOKEN_URI, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams(data),
    })
      .then((rs) => rs.json())
      .then((res) => {
        if (res.access_token) {
          const token = res.access_token;
          new SSRStorage().setItem(USER_COOKIE, { token });
          return token;
        }
      });
    return rs;
  } catch (err) {
    console.log(err);
  }
};
export const uploadPassport = async (e) => {
  try {
    const formData = new FormData();
    formData.append("file", e);
    formData.append("upload_preset", "vcnpreset");
    const res = (
      await axios.post(process.env.REACT_APP_CLOUDINARY_URL, formData)
    ).data;

    return res.secure_url;
  } catch (err) {
    console.log(err);
  }
};

export const uploadID = async (e) => {
  try {
    const formData = new FormData();
    formData.append("file", e);
    formData.append("upload_preset", "vcnpreset");
    const res = (
      await axios.post(process.env.REACT_APP_CLOUDINARY_URL, formData)
    ).data;
    return res.secure_url;
  } catch (err) {
    console.log(err);
  }
};
export const uploadCertification = async (e) => {
  try {
    const formData = new FormData();
    formData.append("file", e);
    formData.append("upload_preset", "vcnpreset");
    const res = (
      await axios.post(process.env.REACT_APP_CLOUDINARY_URL, formData)
    ).data;
    return res.secure_url;
  } catch (err) {
    console.log(err);
  }
};

// Uploads Reciept
export const uploadReceipt = async (e) => {
  try {
    const formData = new FormData();
    formData.append("file", e);
    formData.append("upload_preset", "vcnpreset");
    const res = (
      await axios.post(process.env.REACT_APP_CLOUDINARY_URL, formData)
    ).data;

    return res.secure_url;
  } catch (err) {
    console.log(err);
  }
};

// request function for api calls
export const request = async (
  url,
  method,
  authed = false,
  data,
  responseType = "json"
) => {
  const user = await new SSRStorage().getItem(USER_COOKIE);
  if (responseType === "file") {
    const headers = {
      "Content-Type": "blob",
      Authorization: `Bearer ${user.token}`,
    };
    const config = {
      method: method,
      url: `${API_URI}/${url}`,
      responseType: "arraybuffer",
      headers,
    };
    const response = await axios(config);
    return response;
  }
  const response = await fetch(`${API_URI}/${url}`, {
    method: method,
    headers: authed ? headers(user) : { ...defaultHeaders },
    body: JSON.stringify(data),
  });
  const result = await checkStatus(response);
  return parseJSON(result);
};

// get date in format 12/12/2020
const date = new Date();
export const currentDate = new Intl.DateTimeFormat("en-US", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
}).format(date); // Short format

export const getDateISOFormat = (date, toDate = false) => {
  const newDate = new Date(date);
  const END_OF_DAY_HOUR = 23;
  const END_OF_DAY_MINUTE = 59;
  if (toDate) {
    newDate.setHours(END_OF_DAY_HOUR, END_OF_DAY_MINUTE, 59, 999);
  } else {
    newDate.setHours(0, 0, 0, 0);
  }
  return newDate.toISOString();
};

export const notifyToast = (type, text) => {
  if (type === "success") {
    toast.success(<Toast tbgt={text} color="success" />, {
      icon: false,
      hideProgressBar: false,
    });
  } else {
    toast.error(<Toast tbgt={text} color="danger" />, {
      icon: false,
      hideProgressBar: true,
    });
  }
};
export const checkForLoggedUser = async () => {
  const user = await new SSRStorage().getItem(USER_COOKIE);
  if (!user) {
    window.location.href = "/";
  }
};
// Scroll to top
export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export const getPaymentRefrence = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const reference = queryParams.get("reference");
  return reference;
};
export const practitionerName = (user, pid = false) => {
  return user ? `${user.fullName} ${user.vcnId}` : "--";
};
