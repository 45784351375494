import React, { useState, useCallback, useEffect } from 'react';
import {Card, Col, Row, Spinner} from 'reactstrap';
import {Container} from "react-bootstrap";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {ArrowLeft, Search} from "react-feather";
import ReactPaginate from "react-paginate";
import { scrollToTop, request } from "../services/utilities";
import EmptyImg from "../assets/images/empty.png";
import BillingDashboardTable from "../util/BillingDashboardTable";
import {CustomButton} from "./shared/CustomButton";
import OnboardingModal from "./modals/OnboardingModal";
import LoadingModal from "./modals/LoadingModal";
import SuccessModal from "./modals/SuccessModal";
import ErrorModal from "./modals/ErrorModal";
import { API_URI, USER_COOKIE } from "../services/constants";
import SSRStorage from "../services/storage";
const storage = new SSRStorage();

const BillingsDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [billings, setBillings] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [showOnUpload, setShowOnUpload] = useState(false);
  const [file, setFile] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const [showS, setShowS] = useState(false);
  const [showE, setShowE] = useState(false);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [firstItemOnPage, setFirstItemOnPage] = useState(0);
  const [lastItemOnPage, setLastItemOnPage] = useState(0);
  const [currentCount, setCurrentCount] = useState(0);
  const [error, setError] = useState("");
  const [recordError, setRecordError] = useState([]);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [isShowtransactionModal, setShowTransactionModal] = useState(false);
  const bgTxt = "Success!!";
  const smTxt = "Uploaded Ofiline transactions have been recorded successfully";
  const [vcn, setVcn] = useState("");
  const navigate = useNavigate();

  const location = useLocation().pathname;

  const fetchBillboardDashboard = useCallback(
    async (vcn) => {
      setLoading(true);
      try {
        const url = `BillingStructure/GetBillings?PaymentCompleted=${false}&${
          vcn ? `VcnId=${vcn}` : ""
        }`;
        const rs = await request(url, "GET", true);
        setTotalItemsCount(rs.payload?.totalItemCount);
        setLastItemOnPage(rs.payload?.lastItemOnPage);
        setFirstItemOnPage(rs.payload?.firstItemOnPage);
        setBillings(rs.payload?.items);
        setTotalPages(rs.payload?.pageCount);
        setLoading(false);
      } catch (err) {
        setError("Error fetching billboard data:");
      } finally {
        setLoading(false);
      }
    },
    [currentPage]
  );

  const handlePageChange = (selectedPage) => {
    scrollToTop();
    setCurrentCount(currentCount + 1);
    setCurrentPage(selectedPage.selected + 1);
  };

  const handleOfflineUpload = async () => {
    setShowOnUpload(false);
    setShowLoading(true);
    const user = await storage.getItem(USER_COOKIE);
    const url = `${API_URI}/Payment/AddOfflineTransactions`;
    const data = new FormData();
    data.append("file", file);
    try {
      const rs = await fetch(url, {
        method: "POST",
        body: data,
        //  Set headers manually for single file upload
        headers: {
          Authorization: `Bearer ${user.token}`,
          ContentType: `multipart/form-data"`,
          length: `${file.size}`,
          name: `${file.name}`,
          fileName: `${file.name}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setShowLoading(false);
          if (data.code === "OK") {
            setShowS(true);
            setFile(null);
            fetchBillboardDashboard();
          } else {
            setFile(null);
            setShowLoading(false);
            setRecordError([data.errors]);
            setShowE(true);
          }
        });
    } catch (err) {
      setFile(null);
      setShowLoading(false);
      setRecordError([
        "Failed to upload multiple Offline transaction, kindly try again ",
      ]);
      setShowE(true);
    }
  };

  useEffect(() => {
    fetchBillboardDashboard();
  }, [fetchBillboardDashboard]);

  return (
    <Container>
      <Row>
        <Card className="border-none border-white">
          <Row className="d-flex align-items-center">
            <Col>
              <h1 className="h1-color mb-0"> Practitioners Unpaid Bills </h1>
            </Col>
            <Col className="d-flex justify-content-end align-items-center">
              <Link
                to={"/admin/dashboard"}
                className="btn text-decoration-none"
                id="bggrey"
              >
                <ArrowLeft size={16} /> Back
              </Link>
            </Col>
          </Row>
          <Row>
            <div className="d-flex justify-content-end h-50 w-100">
              <div>{loading ? <Spinner color="danger" /> : ""}</div>
            </div>
          </Row>
          <Row className="mt-5 mb-2 gap-2 d-flex justify-content-xl-between">
            <Col className="d-flex mb-3" xl={6} sm={12} md={6}>
              <div className="d-flex gap-2 align-items-center form-control">
                <Search size={15} className="search-icon" />
                <input
                  type="number"
                  className="w-100 border-0 custom-search"
                  placeholder="Search Billing History by VCN Number"
                  onChange={(e) => {
                    setVcn(e.target.value);
                    if (e.target.value === "") fetchBillboardDashboard();
                  }}
                  value={vcn}
                />
              </div>
              <div className="fs-6 ms-2">
                <button
                  style={{ paddingLeft: "30px" }}
                  id="bgred"
                  placeholder="Search with VCN NUmber"
                  className="p-2 border-0 rounded text-light d-flex align-items-center gap-1"
                  onClick={() => fetchBillboardDashboard(vcn)}
                >
                  <Search size={15} color="white" className="search-icon" />
                  Search
                </button>
              </div>
            </Col>


            <Col xl={5} sm={12} md={12} className="d-flex justify-content-center align-items-center gap-2 justify-content-xl-end">
              <Col>
                <div className="mb-3 float-end">
                  <CustomButton
                      id="bgred"
                      iconType
                      icon="add"
                      color="red"
                      overideStyle="px-2"
                      action={() => navigate(`/admin/create-billing`)}
                      name="Create Bill"
                  />
                </div>
              </Col>

              <div className="d-flex mb-3">
                <CustomButton
                  id="bgred"
                  iconType
                  icon="add"
                  color="red"
                  overideStyle="px-2"
                  action={() => setShowOnUpload(true)}
                  name="Record Bulk Offline Transactions"
                />
              </div>
            </Col>
          </Row>

          <Row className="table-responsive table-container">
            <div className="">
              {billings.length >= 1 ? (
                <BillingDashboardTable
                  data={billings}
                  passSelectedTransaction={(data) => {
                    setSelectedTransaction(data);
                    setShowTransactionModal(!isShowtransactionModal);
                  }}
                />
              ) : (
                <div>
                  <div>
                    <BillingDashboardTable data={billings} />
                    <div className="text-center pt-5">
                      <img src={EmptyImg} className="img-fluid" alt="empty" />
                      <p className="p-color pt-2">No Record to show</p>
                    </div>
                  </div>
                </div>
              )}
              {billings.length > 1 ? (
                <div className="d-flex mt-5 justify-content-center">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel="next >"
                    onPageChange={handlePageChange}
                    pageRangeDisplayed={5}
                    pageCount={totalPages}
                    previousLabel="< previous"
                    // forcePage={currentPage}
                    containerClassName={"pagination"}
                    previousLinkClassName={"pagination__link"}
                    nextLinkClassName={"pagination__link"}
                    disabledClassName={"pagination__link--disabled"}
                    activeClassName={"pagination__link--active"}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </Row>
        </Card>
      </Row>
      <OnboardingModal
        show={showOnUpload}
        setShow={setShowOnUpload}
        setFile={setFile}
        file={file}
        btnName="Upload"
        title="Bulk Offline Transactions Upload"
        description="Upload multiple offline transactions from Excel (.xlsx) file"
        subDescription="Select excel (.xlsx) file containing offline transactions"
        handleOnboarding={handleOfflineUpload}
      />
      <LoadingModal show={showLoading} setShow={setShowLoading} />
      <SuccessModal
        show={showS}
        setShow={setShowS}
        bgTxt={bgTxt}
        smTxt={smTxt}
      />
      <ErrorModal
        show={showE}
        setShow={setShowE}
        bgTxt={"Failed"}
        smTxt2={"Check .xlsx file, and make sure data is properly arranged"}
        smTxt={"Could not upload offline transactions"}
        note={
          "Fix errors in the referenced Bill ID's row in the error message below, remove preceding rows before re-uploading."
        }
        serverError={recordError}
      />
    </Container>
  );
};

export default BillingsDashboard;
