import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Input,
  Label,
  FormGroup,
  FormFeedback,
  Spinner,
} from "reactstrap";
import SignUpImg from "../assets/images/signup.png";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Eye, EyeOff, X } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import FooterLogo from "../assets/images/footer.svg";
import { request } from "../services/utilities";

const ResetPasssword = () => {
  const [eye, setEye] = useState(false);
  const [uploadIdCard, setUploadIdCard] = useState(null);
  const [passport, setPassport] = useState(null);
  const [utilitybill, setUtilitybill] = useState(null);
  const [certification, setCertification] = useState(null);
  const [forignCertification, setForeignCertification] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState([]);
  const navigate = useNavigate();

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstname: "",
      lastname: "",
      othername: "",
      email: "",
      phone: "",
      sex: "",
      dob: "",
      passport: "",
      address: "",
      state: "",
      lga: "",
      utilitybill: "",
      idCard: "",
      highestQualification: "",
      certification: "",
      forignCertification: "",
      uploadId: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("Please Enter Your First Name"),
      lastname: Yup.string().required("Please Enter Your Last Name"),
      othername: Yup.string().required("Please Enter Your Other Name"),
      email: Yup.string().required("Please Enter Your Email"),
      phone: Yup.string().required("Please Enter Your Phone Number"),
      sex: Yup.string().required("Please Enter Your Sex"),
      dob: Yup.string().required("Please Enter Your Date of Birth"),
      // passport: Yup.string().required("Please Uplaod Your Passport"),
      address: Yup.string().required("Please Enter Your Address"),
      state: Yup.string().required("Please Enter Your State"),
      lga: Yup.string().required("Please Enter Your LGA"),
      // utilitybill: Yup.string().required("Please Upload Your Utility Bill"),
      // idCard: Yup.string().required("Please Select Your ID "),
      highestQualification: Yup.string().required(
        "Please Select Your Highest Qualification"
      ),
      // forignCertification: Yup.string().required("Please Upload Your Foriegn Certification"),
      // uploadId: Yup.string().required("Please Upload Your ID"),
      // certification: Yup.string().required("Please Upload Your Certification"),
      confirmPassword: Yup.string().required("Please Confirm Your Password"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: async (e) => {
      setLoading(true);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      const termscondition = document.getElementById("terms&condition");
      try {
        const data = [
          {
            firstname: e.firstname,
            lastname: e.lastname,
            middleName: e.othername,
            email: e.email,
            phoneNumber: e.phone,
            sex: e.sex,
            dateOfBirth: e.dob,
            photo: passport,
            address: e.address,
            state: e.state,
            lgaName: e.lga,
            recentUtilityBill: utilitybill,
            validIdType: e.idCard,
            validId: uploadIdCard,
            qualification: e.highestQualification,
            highestInstitutionalCertificate: certification,
            highestForeignInstitutionalCertificate: forignCertification,
            password: e.password,
            confirmPassword: e.confirmPassword,
            acceptTermsAndCondition: termscondition.checked,
            vcnId: "VCN123456",
          },
        ];
        const url = `profile/createAccount`;
        const rs = await request(url, "POST", false, data);
        setLoading(false);
        if (rs.hasErrors === true) {
          if (rs.errors) {
            setError(rs.errors);
          }
          if (rs.description) {
            setError([rs.description]);
          }
          if (rs.payload) {
            setError(rs.payload);
          }

          window.scrollTo({ top: 0, behavior: "smooth" });
        }
        if (rs.code === "OK") {
          navigate("/login");
        }
        const timer = setTimeout(() => setError([]), 3000);
        return () => clearTimeout(timer);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    },
  });

  return (
    <div style={{ overflowX: "hidden" }}>
      <Row>
        <img src={SignUpImg} alt="signup" />
      </Row>
      <Container>
        <div className="pt-4">
          <div className="d-flex justify-content-between">
            <h4 className="fw-bolder">Create an Account</h4>

            {loading ? <Spinner color="danger" /> : ""}

            {error.map((e) => {
              return (
                <div
                  key={e}
                  className="alert text-capitalize alert-danger alert-dismissible border-2 p-2 fade show"
                  role="alert"
                  style={{ background: "#fff", fontSize: "12px" }}
                >
                  {e}
                  <X
                    size={20}
                    className="mx-2"
                    color="red"
                    style={{
                      cursor: "pointer",
                      background: "rgb(253, 246, 246)",
                    }}
                  />
                </div>
              );
            })}
          </div>
          <Form
            className="pt-4"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div
              className="personal-information pb-4"
              style={{ borderBottom: "1px solid #eee" }}
            >
              <p className="fw-800 fs-6">Personal Information</p>
              <Row>
                <Col xl={4} sm={12} md={6}>
                  <FormGroup className="mb-3">
                    <Label htmlFor="val1">First Name</Label>
                    <Input
                      name="firstname"
                      placeholder="John"
                      type="text"
                      className="form-control"
                      id="val1"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.firstname || ""}
                      invalid={
                        validation.touched.firstname &&
                        validation.errors.firstname
                          ? true
                          : false
                      }
                    />
                    {validation.touched.firstname &&
                    validation.errors.firstname ? (
                      <FormFeedback type="invalid">
                        {validation.errors.firstname}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col xl={4} sm={12} md={6}>
                  <FormGroup className="mb-3">
                    <Label htmlFor="val2">Last Name</Label>
                    <Input
                      name="lastname"
                      placeholder="Paul"
                      type="text"
                      className="form-control"
                      id="val2"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.lastname || ""}
                      invalid={
                        validation.touched.lastname &&
                        validation.errors.lastname
                          ? true
                          : false
                      }
                    />
                    {validation.touched.lastname &&
                    validation.errors.lastname ? (
                      <FormFeedback type="invalid">
                        {validation.errors.lastname}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col xl={4} sm={12} md={6}>
                  <FormGroup className="mb-3">
                    <Label htmlFor="val3">Other Name</Label>
                    <Input
                      name="othername"
                      placeholder="Doe"
                      type="text"
                      className="form-control"
                      id="val3"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.othername || ""}
                      invalid={
                        validation.touched.othername &&
                        validation.errors.othername
                          ? true
                          : false
                      }
                    />
                    {validation.touched.othername &&
                    validation.errors.othername ? (
                      <FormFeedback type="invalid">
                        {validation.errors.othername}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xl={4} sm={12} md={6}>
                  <FormGroup className="mb-3">
                    <Label htmlFor="val4">Email</Label>
                    <Input
                      name="email"
                      placeholder="john@gmail.com"
                      type="text"
                      className="form-control"
                      id="val4"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.email || ""}
                      invalid={
                        validation.touched.email && validation.errors.email
                          ? true
                          : false
                      }
                    />
                    {validation.touched.email && validation.errors.email ? (
                      <FormFeedback type="invalid">
                        {validation.errors.email}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col xl={4} sm={12} md={6}>
                  <FormGroup className="mb-3">
                    <Label htmlFor="val5">Phone Number</Label>
                    <Input
                      name="phone"
                      placeholder="08068055438"
                      type="number"
                      className="form-control"
                      id="val5"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.phone || ""}
                      invalid={
                        validation.touched.phone && validation.errors.phone
                          ? true
                          : false
                      }
                    />
                    {validation.touched.phone && validation.errors.phone ? (
                      <FormFeedback type="invalid">
                        {validation.errors.phone}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="mb-3">
                    <Label htmlFor="val6">Sex</Label>
                    <Input
                      name="sex"
                      type="select"
                      className="form-control"
                      id="val6"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.sex || ""}
                      invalid={
                        validation.touched.sex && validation.errors.sex
                          ? true
                          : false
                      }
                    >
                      <option>Select Sex</option>
                      <option>Male</option>
                      <option>Female</option>
                    </Input>
                    {validation.touched.sex && validation.errors.sex ? (
                      <FormFeedback type="invalid">
                        {validation.errors.sex}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xl={4} sm={12} md={6}>
                  <FormGroup className="mb-3">
                    <Label htmlFor="val7">Date of Birth (dd/mm/yyy) </Label>
                    <Input
                      name="dob"
                      type="date"
                      className="form-control"
                      id="val7"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.dob || ""}
                      invalid={
                        validation.touched.dob && validation.errors.dob
                          ? true
                          : false
                      }
                    />
                    {validation.touched.dob && validation.errors.dob ? (
                      <FormFeedback type="invalid">
                        {validation.errors.dob}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col xl={4} sm={12} md={6}>
                  <FormGroup className="mb-3">
                    <Label htmlFor="val8">Upload Passport Photograph </Label>
                    <Input
                      name="passport"
                      type="file"
                      className="form-control"
                      id="val8"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        validation.handleChange(e);
                        let reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = function () {
                          setPassport(reader.result);
                        };
                        reader.onerror = function (error) {
                          console.log("Error: ", error);
                        };
                      }}
                      onBlur={validation.handleBlur}
                      value={validation.values.passport || ""}
                      invalid={
                        validation.touched.passport &&
                        validation.errors.passport
                          ? true
                          : false
                      }
                    />
                    {validation.touched.passport &&
                    validation.errors.passport ? (
                      <FormFeedback type="invalid">
                        {validation.errors.passport}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <div className="address pb-3">
              <p className="fs-6 pt-3 fw-800 pb-2">Address</p>
              <Row>
                <Col xl={4} sm={12} md={6}>
                  <FormGroup className="mb-3">
                    <Label htmlFor="val9">Permanent Residence </Label>
                    <Input
                      name="address"
                      type="text"
                      className="form-control"
                      placeholder="12 Police Station Street"
                      id="val9"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.address || ""}
                      invalid={
                        validation.touched.address && validation.errors.address
                          ? true
                          : false
                      }
                    />
                    {validation.touched.address && validation.errors.address ? (
                      <FormFeedback type="invalid">
                        {validation.errors.address}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col xl={4} sm={12} md={6}>
                  <FormGroup className="mb-3">
                    <Label htmlFor="val10">State </Label>
                    <Input
                      name="state"
                      type="select"
                      className="form-control"
                      id="val10"
                      placeholder="Select State"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.state || ""}
                      invalid={
                        validation.touched.state && validation.errors.state
                          ? true
                          : false
                      }
                    >
                      <option disabled defaultValue="">
                        Select State
                      </option>
                      <option>Lagos</option>
                      <option>Kwara</option>
                    </Input>
                    {validation.touched.state && validation.errors.state ? (
                      <FormFeedback type="invalid">
                        {validation.errors.state}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col xl={4} sm={12} md={6}>
                  <FormGroup className="mb-3">
                    <Label htmlFor="val11">LGA </Label>
                    <Input
                      name="lga"
                      type="select"
                      className="form-control"
                      id="val11"
                      placeholder="Select LGA"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.lga || ""}
                      invalid={
                        validation.touched.lga && validation.errors.lga
                          ? true
                          : false
                      }
                    >
                      <option disabled defaultValue="">
                        Select LGA
                      </option>
                      <option>Lagos</option>
                      <option>Kwara</option>
                    </Input>
                    {validation.touched.lga && validation.errors.lga ? (
                      <FormFeedback type="invalid">
                        {validation.errors.lga}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xl={4} sm={12} md={6}>
                  <FormGroup className="mb-3">
                    <Label htmlFor="val12">Uplaod Utility Bill </Label>
                    <Input
                      name="utilitybill"
                      type="file"
                      className="form-control"
                      id="val12"
                      placeholder="Uplaod Utitlity Bill"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        validation.handleChange(e);
                        let reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = function () {
                          setUtilitybill(reader.result);
                        };
                        reader.onerror = function (error) {
                          console.log("Error: ", error);
                        };
                      }}
                      onBlur={validation.handleBlur}
                      value={validation.values.utilitybill || ""}
                      invalid={
                        validation.touched.utilitybill &&
                        validation.errors.utilitybill
                          ? true
                          : false
                      }
                    />
                    {validation.touched.utilitybill &&
                    validation.errors.utilitybill ? (
                      <FormFeedback type="invalid">
                        {validation.errors.utilitybill}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
              <div
                className="Identification pb-3"
                style={{ borderBottom: "1px solid #eee" }}
              >
                <p className="fs-6 pt-3 fw-800 pb-2">Identification</p>
                <Row>
                  <Col xl={4} sm={12} md={6}>
                    <FormGroup className="mb-3">
                      <Label htmlFor="val13">Select ID </Label>
                      <Input
                        name="idCard"
                        type="select"
                        className="form-control"
                        id="val13"
                        placeholder="Uplaod Utitlity Bill"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.idCard || ""}
                        invalid={
                          validation.touched.idCard && validation.errors.idCard
                            ? true
                            : false
                        }
                      >
                        <option disabled defaultValue="">
                          Select ID
                        </option>
                        <option>National ID</option>
                        <option>Drivers Licence</option>
                      </Input>
                      {validation.touched.idCard && validation.errors.idCard ? (
                        <FormFeedback type="invalid">
                          {validation.errors.idCard}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col xl={4} sm={12} md={6}>
                    <FormGroup className="mb-3">
                      <Label htmlFor="val14">Uplaod ID </Label>
                      <Input
                        name="uploadId"
                        type="file"
                        className="form-control"
                        id="val14"
                        placeholder="Uplaod  ID"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          validation.handleChange(e);
                          let reader = new FileReader();
                          reader.readAsDataURL(file);
                          reader.onload = function () {
                            setUploadIdCard(reader.result);
                          };
                          reader.onerror = function (error) {
                            console.log("Error: ", error);
                          };
                        }}
                        onBlur={validation.handleBlur}
                        value={validation.values.uploadId || ""}
                        invalid={
                          validation.touched.uploadId &&
                          validation.errors.uploadId
                            ? true
                            : false
                        }
                      />
                      {validation.touched.uploadId &&
                      validation.errors.uploadId ? (
                        <FormFeedback type="invalid">
                          {validation.errors.uploadId}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </div>
            <div
              className="education pb-2"
              style={{ borderBottom: "1px solid #eee" }}
            >
              <p className="fw-bold pt-3 fs-6 pb-2">
                Education & Qualification
              </p>
              <Row>
                <Col xl={4} sm={12} md={6}>
                  <FormGroup className="mb-3">
                    <Label htmlFor="val15">Highest Qualification </Label>
                    <Input
                      name="highestQualification"
                      type="select"
                      className="form-control"
                      id="val15"
                      placeholder="Select Highest Qualification"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.highestQualification || ""}
                      invalid={
                        validation.touched.highestQualification &&
                        validation.errors.highestQualification
                          ? true
                          : false
                      }
                    >
                      <option disabled defaultValue="">
                        Select Highest Qualification
                      </option>
                      <option>Bachelor of Science (BSC)</option>
                      <option>Master of Science (MSC)</option>
                    </Input>
                    {validation.touched.highestQualification &&
                    validation.errors.highestQualification ? (
                      <FormFeedback type="invalid">
                        {validation.errors.highestQualification}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col xl={4} sm={12} md={6}>
                  <FormGroup className="mb-4">
                    <Label htmlFor="val16">Upload Certification </Label>
                    <Input
                      name="certification"
                      type="file"
                      className="form-control"
                      id="val16"
                      placeholder="Uplaod Certification"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        validation.handleChange(e);
                        let reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = function () {
                          setCertification(reader.result);
                        };
                        reader.onerror = function (error) {
                          console.log("Error: ", error);
                        };
                      }}
                      onBlur={validation.handleBlur}
                      value={validation.values.certification || ""}
                      invalid={
                        validation.touched.certification &&
                        validation.errors.certification
                          ? true
                          : false
                      }
                    />
                    {validation.touched.certification &&
                    validation.errors.certification ? (
                      <FormFeedback type="invalid">
                        {validation.errors.certification}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col xl={4} sm={12} md={6}>
                  <FormGroup className="mb-3">
                    <Label htmlFor="val17">Upload Foreign Certification </Label>
                    <Input
                      name="forignCertification"
                      type="file"
                      className="form-control"
                      id="val17"
                      placeholder="Uplaod Foreign Certification"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        validation.handleChange(e);
                        let reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = function () {
                          setForeignCertification(reader.result);
                        };
                        reader.onerror = function (error) {
                          console.log("Error: ", error);
                        };
                      }}
                      onBlur={validation.handleBlur}
                      value={validation.values.forignCertification || ""}
                      invalid={
                        validation.touched.forignCertification &&
                        validation.errors.forignCertification
                          ? true
                          : false
                      }
                    />
                    {validation.touched.forignCertification &&
                    validation.errors.forignCertification ? (
                      <FormFeedback type="invalid">
                        {validation.errors.forignCertification}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <div className="authentication">
              <p className="fs-6 pt-4 fw-bold">Authentication</p>
              <Row>
                <Col xl={4} sm={12} md={6}>
                  <div className="mb-3">
                    <Label htmlFor="val18" className="fs-6">
                      Password
                    </Label>
                    <div className="position-relative auth-pass-inputgroup mb-3">
                      <FormGroup className="mb-3">
                        <Input
                          name="password"
                          placeholder="Enter your Password"
                          type={eye === true ? "text" : "password"}
                          className="form-control"
                          id="val18"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                      <button
                        className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                        type="button"
                        id="password-addon"
                      >
                        {eye === true ? (
                          <Eye size={14} onClick={() => setEye(false)} />
                        ) : (
                          <EyeOff onClick={() => setEye(true)} size={14} />
                        )}
                      </button>
                    </div>
                  </div>
                </Col>
                <Col xl={4} sm={12} md={6}>
                  <div className="mb-3">
                    <Label htmlFor="val19" className="fs-6">
                      {" "}
                      Confirm Password
                    </Label>
                    <div className="position-relative auth-pass-inputgroup mb-3">
                      <FormGroup className="mb-3">
                        <Input
                          name="confirmPassword"
                          placeholder="Enter  Password"
                          type={eye === true ? "text" : "password"}
                          className="form-control"
                          id="val19"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.confirmPassword || ""}
                          invalid={
                            validation.touched.confirmPassword &&
                            validation.errors.confirmPassword
                              ? true
                              : false
                          }
                        />
                        {validation.touched.confirmPassword &&
                        validation.errors.confirmPassword ? (
                          <FormFeedback type="invalid">
                            {validation.errors.confirmPassword}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                      <button
                        className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                        type="button"
                        id="password-addon"
                      >
                        {eye === true ? (
                          <Eye size={14} onClick={() => setEye(false)} />
                        ) : (
                          <EyeOff onClick={() => setEye(true)} size={14} />
                        )}
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="form-check pt-4 mb-3 d-flex justify-content-between  checkBox">
              <Label className="form-check-label" htmlFor="terms&condition">
                <Input
                  type="checkbox"
                  className="form-check-input checkbox"
                  id="terms&condition"
                  required
                />
                I agree with the
                <Link
                  to="/signup"
                  className=" textRed text-decoration-none"
                >
                  {" "}
                  Terms and Agreement
                </Link>
              </Label>

              <div className="mt-4">
                <button id="bgred" className="btn text-light" type="submit">
                  Create Account
                </button>
              </div>
            </div>
          </Form>
        </div>

        <div className="text-center pt-5 pb-5">
          <p className="mb-0">
            <img
              src={FooterLogo}
              width="20"
              className="img-fluid"
              alt="footer"
            />{" "}
            Powered by Milsat Technologies
          </p>
        </div>
      </Container>
    </div>
  );
};

export default ResetPasssword;
