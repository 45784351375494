import {
  Card,
  CardBody,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import { Container } from "react-bootstrap";
import FooterLogo from "../assets/images/footer.svg";
import { ArrowLeft } from "react-feather";
import { useCallback, useEffect, useState } from "react";
import EmptyImg from "../assets/images/empty.png";
import { Link, useLocation } from "react-router-dom";
import { request } from "../services/utilities";
import ReactPaginate from "react-paginate";
import { useAuthContext } from "../context/UserProvider";
import { DESIGNATION } from "../services/constants";
import LoadingModal from "./modals/LoadingModal";
import CertificatesTable from "../util/CertificatesTable";

const Certificates = () => {
  const [practitioners, setLicensedPracticioners] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentCount, setCurrentCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [firstItemOnPage, setFirstItemOnPage] = useState(0);
  const [lastItemOnPage, setLastItemOnPage] = useState(0);

  const [loading, setLoading] = useState(false);
  const [showL, setShowL] = useState(false);


  const fetchLicensedPracticioners = useCallback(async () => {
    setLoading(true);
    const url = `BillingStructure/GetBillings?PaymentCompleted=${true}&ProductCategoryType=PractiseLicense`;
    try {
      const rs = await request(url, "GET", true);
      setLoading(false);
      if (rs.code === "OK") {
        setTotalItemsCount(rs.payload?.totalItemCount)
        setLastItemOnPage(rs.payload?.lastItemOnPage)
        setFirstItemOnPage(rs.payload?.firstItemOnPage)
        setLicensedPracticioners(rs.payload.items)
        setTotalPages(rs.payload?.pageCount)
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }, []);

  const handlePageChange = (selectedPage) => {
    setCurrentCount(currentCount + 1);
    setCurrentPage(selectedPage.selected + 1);
  };

  useEffect(() => {
    fetchLicensedPracticioners();
  }, [fetchLicensedPracticioners]);

  return (
      <Container>
        <Row>
          <Card className="border-none border-white">
            <Row className="d-flex align-items-center mb-3  ">
              <Col>
                <h1 className="h1-color mb-0">APL Certificates Section</h1>
              </Col>
              <Col className="d-flex justify-content-end align-items-center">
                <Link
                    to="/admin/dashboard"
                    className="btn text-decoration-none"
                    id="bggrey"
                >
                  <ArrowLeft size={16} /> Back
                </Link>
              </Col>
            </Row>
            <CardBody className="p-0">
              <Row className="float-end">
                <Col className="float-end">
                  {loading ? (
                      <div>
                        {" "}
                        <Spinner color="danger" />{" "}
                      </div>
                  ) : (
                      ""
                  )}
                </Col>
              </Row>

              <div className="">
                {practitioners.length >= 1 ? (
                    <CertificatesTable
                        data={practitioners}
                    />
                ) : (
                    <div>
                      <div>
                        <CertificatesTable
                            data={practitioners.length >= 1 ? practitioners : []}
                        />
                        <div className="text-center pt-5">
                          <img src={EmptyImg} className="img-fluid" alt="empty" />
                          <p className="p-color pt-2">No Record to show</p>
                          <p className="p-color pt-2">
                            Click the Add Practitioner button on the top <br />
                            right to add new practitioner profile
                          </p>
                          _
                        </div>
                      </div>
                    </div>
                )}
                {practitioners.length >= 1 ? (
                    <div className="d-flex mt-5  justify-content-center">
                      <ReactPaginate
                          breakLabel="..."
                          nextLabel="next >"
                          onPageChange={handlePageChange}
                          pageRangeDisplayed={5}
                          pageCount={totalPages}
                          previousLabel="< previous"
                          // forcePage={currentPage}
                          containerClassName={"pagination"}
                          previousLinkClassName={"pagination__link"}
                          nextLinkClassName={"pagination__link"}
                          disabledClassName={"pagination__link--disabled"}
                          activeClassName={"pagination__link--active"}
                      />
                    </div>
                ) : (
                    ""
                )}
              </div>

              <div className="text-end " style={{ paddingTop: "5rem" }}>
                <p className="mb-5">
                  <img
                      src={FooterLogo}
                      width="20"
                      className="img-fluid"
                      alt="footer"
                  />{" "}
                  Powered by Milsat Technologies
                </p>
              </div>
            </CardBody>
          </Card>
        </Row>

        <LoadingModal show={showL} setShow={setShowL} />
      </Container>
  );
};

export default Certificates;
