import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import PlaceholderImage from '../../assets/images/default_profile.jpg';
import LicenseGreen from "../../assets/images/licenseGreen.svg";
import NoLicenseIcon from "../../assets/images/noLicenseIcon.svg";

const VerificationModal = ({ isOpen, toggle, user, licenseStatusCount, licenseStatus }) => {
  return (
      <Modal isOpen={isOpen} toggle={toggle} centered className="verification-modal">
        <ModalHeader
            className="modal-title pb-2 fw-bold"
            toggle={() => {
              toggle(!isOpen);
            }}
        ></ModalHeader>
        <ModalBody className="d-flex justify-content-center align-items-center flex-column">
          <div className="image-container">
            <img
                src={user.passportPhotograph || PlaceholderImage}
                alt="Profile"
                className="bi-0-square"
                style={{ width: '12em', height: '12em', borderRadius: '2em' }}
            />
          </div>
          <div className="content-container">
            <h5 className="fw-semibold text-center">{user.fullName}</h5>
            <h6 className="text-center">VCN Number: {user.vcnId}</h6>
            <div className="license-status text-center">
              {licenseStatusCount === 0 ? (
                  <div className="text-capitalize border-0 " role="alert"
                       style={{
                         background: "#40DD7F1A",
                         color: "#1AB759",
                         fontSize: '14px',
                         padding: '0.5em',
                         borderRadius: '7px'
                  }}>
                    {licenseStatus}
                    <img src={LicenseGreen} alt="licence" className="img-fluid" style={{ width: '1.3em', height: '1.3em', marginLeft: '0.5em' }} />
                  </div>
              ) : (
                    <div className="border-0" role="alert"
                         style={{
                           background: '#FDF5F5',
                           color: '#CE2126',
                           fontSize: '14px',
                           padding: '0.5em',
                           borderRadius: '7px'
                    }}>
                      {licenseStatus}
                      <img
                          style={{width: '1.3em', height: '1.3em', marginLeft: '0.5em'}}
                          src={NoLicenseIcon}
                          alt="licence"
                          className="img-fluid"
                      />
                    </div>
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
  );
};

export default VerificationModal;
