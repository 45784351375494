import Navbar from "../components/Navbar";
import PremisesComponent from "../components/Premises";
import Footer from "../components/shared/Footer";

const Premises = () => {
  return (
      <>
        <div className="d-flex p-0 flex-column justify-content-between container-fluid" style={{height: '100vh'}}>
          <Navbar/>
          <div style={{paddingTop: "6rem"}}>
            <PremisesComponent/>
          </div>
          <Footer/>
        </div>
      </>
  );
};

export default Premises;
