import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { FormGroup, Form, Input, Label, FormFeedback } from "reactstrap";
import WarnImg from "../../assets/images/warnIcon.svg";

const ConfirmActionsModal = ({
  show,
  setShow,
  text,
  smText,
  action,
  showReason,
}) => {
  const [reason, setReason] = React.useState("");

  const createMarkup = (htmlString) => {
    // Directly returning object for dangerouslySetInnerHTML
    return { __html: htmlString };
  };

  {/* This Validation handles the required reason for rejection alone*/}

  const validation = useFormik({
    enableReinitialize: false,
    initialValues: {
      reason: "",
    },
    validationSchema: Yup.object({
      reason: Yup.string().required("Reason is required"),
    }),
    onSubmit: (values) => {
      action(values.reason);
    },
  });
  return (
    <>
      <Modal isOpen={show} centered>
        <ModalHeader
          style={{ borderBottom: "none" }}
          className="modal-title pb-0 fw-bold"
          toggle={() => {
            setShow(!show);
          }}
        ></ModalHeader>
        <ModalBody className="">
          <div className="confirm-modal-container">
            <div className="text-center">
              <img
                  src={WarnImg}
                  className="img-flusid"
                  height="80"
                  width="80"
                  alt={"warning"}
              />
            </div>
            {text && (
                <h5 className="mb-2 mt-2 text-center" dangerouslySetInnerHTML={createMarkup(text)}></h5>
            )}
            {smText && (
              <p className="mb-2 mt-2 text-center">{smText}</p>
            )}
          {/* This shows up when showReason is required */}
          {showReason ? (
              <Form
                  className="pt-4"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
              >
                <FormGroup>
                  <Label htmlFor="val8">Reason for rejection</Label>
                  <Input
                      className="form-control"
                      placeholder="Enter Reason for Rejection"
                      id="val12"
                      name="reason"
                      rows="3"
                      type="textarea"
                      onBlur={validation.handleBlur}
                      value={validation.values.reason || ""}
                      onChange={(e) =>
                          validation.setFieldValue("reason", e.target.value)
                      }
                      invalid={
                        validation.touched.reason && validation.errors.reason
                            ? true
                            : false
                      }
                  />
                  {validation.touched.reason && validation.errors.reason ? (
                      <FormFeedback type="invalid">
                        {validation.errors.reason}
                      </FormFeedback>
                  ) : null}
                  <div className="hstack mt-4 gap-4 justify-content-center">
                    <div
                        className="w-25 px-3 py-2 top-button"
                        onClick={() => setShow(!show)}
                    >
                      Cancel
                    </div>
                    <button
                        id="bgred"
                        className="w-25 login-btn px-3 py-2 text-light"
                        type="submit"
                    >
                      Yes
                    </button>
                  </div>
                </FormGroup>
              </Form>
          ) : (
              <div className="hstack mt-4 gap-4 justify-content-center">
                <button
                    className="w-25 px-3 py-2 top-button"
                    onClick={() => setShow(!show)}
                >
                  Cancel
                </button>
                <button
                    id="bgred"
                    className="w-25 login-btn px-3 py-2 text-light"
                    onClick={() => action()}
                >
                  Yes
                </button>
              </div>
          )}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ConfirmActionsModal;
