import React from 'react';

const CardComponent = ({ title, body }) => {
  return (
      <div className={`card-component col-6 col-md-3 mb-3`} style={{
        borderRadius: '10px',
        padding: '0.3em',
        fontWeight: 500,
        flex: '0 0 auto',
        width: '24%',
      }}>
        <h6 className="fs-13 px-2 py-1" style={{ width: '100%' }}>
          {title}
        </h6>
        <h6 className="fs-13 px-2 py-1" style={{ width: '100%' }}>
          {body}
        </h6>
      </div>
  );
};

export default CardComponent;
