import Navbar from "../components/Navbar";
import PractitionerComponent from "../components/Practitioner";

const Practitioner = () => {
  return (
    <>
      <Navbar />
      <div style={{paddingTop: "6rem"}}>
        <PractitionerComponent />
      </div>
    </>
  );
};

export default Practitioner;
