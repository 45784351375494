import {useCallback, useContext, useEffect, useState} from "react";
import {Container, Nav, Navbar} from "react-bootstrap";
import Logo from '../assets/images/vcn_logo.svg'
import UserImage from "../assets/images/user.png";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Store} from "../context/store";
import SSRStorage from "../services/storage";
import {DESIGNATION, USER_COOKIE} from "../services/constants";
import {checkForLoggedUser, request} from "../services/utilities";

const storage = new SSRStorage();

const Navbarmenu = () => {
  const store = useContext(Store);
  const [user, setUser] = store.user;
  const [, setAllState] = store.state;
  const navigate = useNavigate();
  const location = useLocation().pathname;
  const [userType, setUserType] = store.userType;
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  const handlelogout = () => {
    storage.removeItem(USER_COOKIE);
    navigate("/login");
  };

  const fetchLoggedUser = useCallback(async () => {
    const user = await storage.getItem(USER_COOKIE);
    await setUserType(user.user?.userType);
    try {
      const url = `Profile/GetUserProfileById?id=${user?.user?.sub}`;
      const rs = await request(url, "GET", true);
      setUser(rs.payload);
    } catch (err) {
      console.log(err);
    }
  }, [setUser]);

  const fetchState = useCallback(async () => {
    try {
      const url = "Locations/GetAllState";
      const rs = await request(url, "GET", false);
      setAllState(rs.payload);
    } catch (err) {
      console.log(err);
    }
  }, [setAllState]);
  useEffect(() => {
    checkForLoggedUser();
    fetchLoggedUser();
    fetchState();
  }, [fetchLoggedUser, fetchState]);

  return (
      <div
          className="position-fixed bg-white w-100"
          style={{zIndex: "99", boxShadow: "0 1px 2px rgb(222,222,222)"}}
      >
        <Container>
          <Navbar expand="lg" className="pt-3">
            <Container fluid className="align-items-center">
              <Navbar.Brand>
                <Link
                    to="/">
                  <img src={Logo} className="img-fluid" width="100%" style={{width: '8em'}} alt="logo"/>
                </Link>
              </Navbar.Brand>

              <Navbar.Toggle aria-controls="basic-navbar-nav"/>
              <Navbar.Collapse id="basic-navbar-nav" className="text-center">
                <Nav className="me-auto fs-6 fw-600"></Nav>
                <Nav className="me-auto fs-6 active">
                  <Nav.Link className="mx-2">
                    <div className="">
                      <Link
                          to={`${
                              userType === DESIGNATION.ADMIN ||
                              userType === DESIGNATION.SUPPORTOFFICER
                                  ? "/admin/dashboard"
                                  : "/dashboard"
                          }`}
                          className="text-decoration-none fs-6"
                          style={{
                            color:
                                location === "/dashboard"
                                    ? "#CE2126"
                                    : location === "admin/dashboard"
                                        ? "#CE2126"
                                        : "dark",
                          }}
                      >
                        Home
                      </Link>
                    </div>
                  </Nav.Link>
                  {(userType === DESIGNATION.ADMIN ||
                      userType === DESIGNATION.SUPPORTOFFICER) && (
                      <Nav.Link className="mx-2">
                        <div className="">
                          <Link
                              to="/admin/practitioner"
                              style={{
                                color: location === "/practitioner" ? "#CE2126" : "dark",
                              }}
                              className="text-decoration-none fs-6"
                          >
                            Practitioners
                          </Link>
                        </div>
                      </Nav.Link>
                  )}

                  {userType === DESIGNATION.SUPPORTOFFICER ? null : (
                      <Nav.Link className="mx-2">
                        <div className="">
                          <Link
                              to={`${
                                  userType === DESIGNATION.ADMIN
                                      ? "/admin/premises"
                                      : "/premises"
                              }`}
                              style={{
                                color:
                                    location === "/premises"
                                        ? "#CE2126"
                                        : location === "admin/premises"
                                            ? "#CE2126"
                                            : "dark",
                              }}
                              className="text-decoration-none fs-6"
                          >
                            Premise
                          </Link>
                        </div>
                      </Nav.Link>
                  )}
                  {userType === DESIGNATION.SUPPORTOFFICER ? null : (
                      <Nav.Link className="mx-2">
                        <div className="">
                          <Link
                              to={`${
                                  userType === DESIGNATION.ADMIN
                                      ? "/admin/payments"
                                      : "/payments?tab=All-Payments"
                              }`}
                              style={{
                                color:
                                    location === "/payments?tab=All-Payments"
                                        ? "#CE2126"
                                        : location === "admin/payments"
                                            ? "#CE2126"
                                            : "dark",
                              }}
                              className="text-decoration-none fs-6"
                          >
                            Payments
                          </Link>
                        </div>
                      </Nav.Link>
                  )}
                </Nav>
                <Nav>
                  <Link
                      to={
                        user?.designations !== DESIGNATION.ADMIN ||
                        user?.designations !== DESIGNATION.SUPPORTOFFICER
                            ? `/profile/${user?.id}`
                            : "#"
                      }
                      className="text-dark"
                  >
                    <img
                        src={user?.imageUrl ? user?.imageUrl : UserImage}
                        width="40"
                        height="40"
                        style={{borderRadius: "50%", border: "1px solid #fff"}}
                        alt="user"
                    />
                  </Link>
                  <Nav.Link>
                    <Link
                        to="/"
                        onClick={handlelogout}
                        className="fs-6 mx-2 text-decoration-none text-danger"
                    >
                      Logout
                    </Link>
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </Container>
      </div>
  );
};

export default Navbarmenu;
