import {request} from "./utilities";

// Generate Barcode for Users
export const generateBarcodeForUsers = async (userIds) => {
  try {
    // Endpoint for generating barcodes
    const endpoint = "Profile/GenerateBarcodeForUsers";

    // Check if userIds is an array, not empty or string
    if (typeof userIds === 'undefined' || userIds === null || (Array.isArray(userIds) && userIds.length === 0)) {
      console.error('User Ids must have value');
      return;
    }

    // Check if userIds is an array or string
    const payload = Array.isArray(userIds) ? userIds : [userIds];
    // POST request to the endpoint with user IDs
    return await request(
        endpoint,
        "POST",
        true, // assuming authentication is needed
        payload
    );
  } catch (error) {
    console.error("Error generating barcodes:", error);
    throw error;
  }
};
