import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useState } from "react";
import CaretIcon from "../assets/icons/CaretIcon";
import CheckIcon from "../assets/icons/CheckIcon";

// Select Component
export const UpdateProfileSelectDropdown = ({
  handleFilter,
  type,
  defaultOption = "",
  selectedValue,
  grouped = false,
  options = [],
  overideStyle,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const getSelectedLabel = (selectedValue, options) => {
    const selectedOption = options?.find(
      (option) => option?.name === selectedValue
    );
    return selectedOption ? selectedOption.name : defaultOption;
  };

  return (
    <div>
      {type && <h6>{type}:</h6>}
      <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
        <DropdownToggle
          style={{ width: "188px" }}
          className={`x-2 ${
            overideStyle &&
            getSelectedLabel(selectedValue, options) === defaultOption
              ? `${overideStyle} border-1`
              : selectedValue !== ""
              ? "bgred border-0"
              : ""
          }  d-flex align-items-center justify-content-between py-2 px-3 rounded`}
        >
          <span className="fs-6 text-center">
            {getSelectedLabel(selectedValue, options)}
          </span>
          <CaretIcon />
        </DropdownToggle>
        <DropdownMenu
          className="mt-1 position-absolute overflow-auto"
          style={{ height: "auto" }}
        >
          {options.map((option, index) => (
            <DropdownItem
              key={index}
              className="border-0 d-flex gap-4 hover-effect align-items-center py-2 justify-content-between px-4"
              onClick={() => handleFilter(option.name, option.id, option.price, option)}
            >
              <span> {option.name} </span>
              {option.name === "" ? null : (
                <CheckIcon
                  className={`${
                    option.name === selectedValue
                      ? "textred"
                      : "checkIcon border rounded-circle"
                  }`}
                />
              )}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};
