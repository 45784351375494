import React, {useEffect, useState, useCallback, useContext} from 'react'
import { Col, Row, Container } from 'reactstrap'
import { useParams } from 'react-router'
import {useNavigate } from "react-router-dom";
import LicenceImgGreen from "../assets/images/license_green.svg"
import { X } from "react-feather";
import {
    request,
  } from "../services/utilities";
import defaultImage from '../assets/images/default_profile.jpg'
import {Store} from "../context/store";


const MiniProfile = () => {
    const {id} = useParams()
    const store = useContext(Store);
    const [user] = store.user;
    const [error, setError] = useState([]);
    const [loading, setLoading] = useState(false);
    const [practitioner, SetPractititioner] = useState([]);
    const [licenseStatus, setLicensingStatus] = useState('');
    const [licenseStatusCount, setLicensingStatusCount] = useState(0);
    const navigate = useNavigate();

    const verifyVcn = useCallback(async (vcnId) => {
        if (vcnId === "") {
          setError(["VCN Number can not be empty"]);
          navigate("/login");
          return;
        }
        setLoading(true);
    
        try {
          const url = `Profile/GetUserProfileByVcnId?vcnId=${vcnId}`;
          const rs = await request(url, "GET", false);
          setLoading(false);
          if (rs.code === "OK") {
            SetPractititioner(rs.payload);   
          }
        } catch (err) {
          setLoading(false);
          setError(["Failed to get vcn number kindly try again later"]);
        }
      }, [ navigate, setError, SetPractititioner, setLoading ])

    const fetchLicenseStatus = useCallback(async () => {
      setLoading(true);
      const url = `BillingStructure/GetBillings?VcnId=${user?.vcnId}&PaymentCompleted=${false}&ProductCategoryType=PractiseLicense`;
      try {
        const rs = await request(url, "GET", true);
        setLoading(false);
        if (rs.code === "OK") {
          // Determine licensing status
          setLicensingStatusCount(rs.payload.items.length)
          const currentYear = new Date().getFullYear();
          const licensingStatus = licenseStatusCount === 0 ?
              `Licensed to practice for ${currentYear}` :
              `Not Licensed to Practice For Year ${currentYear}`;
          setLicensingStatus(licensingStatus); // Assuming you have a state to store this
        }
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    }, [user]);

      useEffect(() => {
          verifyVcn(id);
          fetchLicenseStatus()
      }, [id, verifyVcn]);

  return (
    <div className="auth-logo d-flex align-items-center justify-content-center ">
    <Container className="d-flex bglightred mx-auto border rounded bglightred p-2">
        <Row className="d-flex gap-4 flex-column w-100 py-5">
            <Col className="d-flex justify-content-center ">
                <img
                    src={practitioner?.imageUrl ?? defaultImage}
                    width="90"
                    height="90"
                    style={{
                    borderRadius: "50%",
                    border: "1px solid #fff",
                    }}
                    alt="user"
                />
            </Col>
            <Col className="d-flex justify-content-center align-items-center gap-2 ">
                <h6 className="text-capitalize">VCN NO: </h6>
             <h4 className="text-capitalize">{practitioner.vcnId}</h4>
            </Col>
            <Col>
              {licenseStatusCount === 0 ? (
                  <div
                      className={`alert w-100 text-end text-capitalize alert-dismissible border-0 fade show`}
                      role="alert"
                      style={{ fontSize: "16px", padding: "12px" }}
                  >
                    <img
                        src={LicenceImgGreen}
                        alt="licence"
                        className="img-fluid"
                    />{" "}
                    {licenseStatus}
                  </div>
              ) : (
                  <div
                      className={`alert w-100 text-end text-capitalize  alert-dismissible border-0 fade show`}
                      role="alert"
                      style={{ fontSize: "16px", padding: "12px" }}
                  >
                    <X color="red" size={20} />
                    {licenseStatus}
                  </div>
              )}
            </Col>
            <Col className="d-flex flex-column gap-3">
                <div className="d-flex align-items-center gap-3">
                    <h4 className="text-capitalize">Full Name:</h4>
                    <h4 className="text-capitalize">{practitioner.fullName || 'N/A'}</h4>
                </div>
                <div className="d-flex align-items-center gap-3">
                    <h4 className="text-capitalize">Gender:</h4>
                    <h4 className="text-capitalize">{practitioner.sex || 'N/A'}</h4>
                </div>
                <div className="d-flex align-items-center gap-3">
                    <h4 className="text-capitalize ">Phone Number:</h4>
                    <h4 className="text-capitalize">{practitioner.phoneNumber || 'N/A'}</h4>
                </div>
                <div className="d-flex align-items-center gap-3">
                    <h4 className="text-c4pitalize">Email:</h4>
                    <h4 className="text-capitalize">{practitioner.email || 'N/A'}</h4>
                </div>
            </Col>
        </Row>
    </Container>
    </div>
  )
}

export default MiniProfile
