import DownloadIcon from "../../assets/icons/DownloadIcon";
import { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const Download = ({ currentPage, handleDownloadRecords, isDropdownPostionEnd }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const downloadType = ["Current page records", "All records"];
  return (
    <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
      <DropdownToggle className="text-black gx-2 bglightred py-2 px-4 rounded border-0">
        <span className="me-2 fs-6 ">Download records</span>
        <DownloadIcon className="p-color" />
      </DropdownToggle>
      <DropdownMenu end={isDropdownPostionEnd} className={`mt-1 ${isDropdownPostionEnd ? "ms-3" : ""} position-absolute`}>
        {downloadType.map((type, index) => (
          <DropdownItem
            key={index}
            className="border-0 d-flex gap-4 hover-effect align-items-start py-2 justify-content-between px-4"
            onClick={()=>handleDownloadRecords(type)}
          >
            <span> {type} </span>
            <DownloadIcon className="p-color" />
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default Download;