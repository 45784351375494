import React from 'react';
import { Link } from 'react-router-dom';

const TabComponent = ({ tabs, activeTab, setActiveTab, activeTabNumber }) => {
  return (
      <div className="tab-component">
        <div className="tab-header">
          {tabs.map((tab) => (
              <Link
                  key={tab.label}
                  to={`/payments?tab=${tab.headerTab}`}
                  className={`tab-button ${activeTab === tab.headerTab ? 'active' : ''}`}
                  onClick={() => setActiveTab(tab.headerTab)}
              >
                {tab.label}
                {activeTab === tab.headerTab  && <span className="tab-number-circle">{tab.activeTabNumber}</span>}
              </Link>
          ))}
        </div>
        <div className="tab-content">
          {tabs.map((tab) => (
              <div
                  key={tab.headerTab}
                  className={`tab-panel ${activeTab === tab.headerTab ? 'active' : ''}`}
              >
                {tab.content}
              </div>
          ))}
        </div>
      </div>
  );
};

export default TabComponent;
