import FilterIcon from "../../assets/icons/filterIcon";
import { Plus } from "react-feather";
import DownloadIcon from '../../assets/icons/DownloadIcon';
import UploadIcon from '../../assets/icons/UploadIcon';

export const CustomButton = ({
  name = "filter",
  icon = "filter",
  iconType = false,
  color = "white",
  action,
  overideStyle,
}) => {
  const bgColor = {
    white: "bgwhite border-danger text-danger",
    red: "bgred text-white",
    lightRed: "bglightred",
  };

  return (
    <button
      className={`d-flex ${bgColor[color]} ${
        overideStyle ?  `${overideStyle}` : "px-3"
      } btn align-items-center gap-2 justify-content-between w-auto`}
      onClick={action}
    >
      {iconType && icon === "filter" && <FilterIcon />}

      {iconType && icon === "barcode" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M5 11h4c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v4c0 1.1.9 2 2 2zm0-6h4v4H5V5zm0 16h4c1.1 0 2-.9 2-2v-4c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v4c0 1.1.9 2 2 2zm0-6h4v4H5v-4zm8-10v4c0 1.1.9 2 2 2h4c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-4c-1.1 0-2 .9-2 2zm6 4h-4V5h4v4zm2 11.5v-1c0-.28-.22-.5-.5-.5h-1c-.28 0-.5.22-.5.5v1c0 .28.22.5.5.5h1c.28 0 .5-.22.5-.5zm-8-7v1c0 .28.22.5.5.5h1c.28 0 .5-.22.5-.5v-1c0-.28-.22-.5-.5-.5h-1c-.28 0-.5.22-.5.5zm3.5 1.5h-1c-.28 0-.5.22-.5.5v1c0 .28.22.5.5.5h1c.28 0 .5-.22.5-.5v-1c0-.28-.22-.5-.5-.5zM13 17.5v1c0 .28.22.5.5.5h1c.28 0 .5-.22.5-.5v-1c0-.28-.22-.5-.5-.5h-1c-.28 0-.5.22-.5.5zm2.5 3.5h1c.28 0 .5-.22.5-.5v-1c0-.28-.22-.5-.5-.5h-1c-.28 0-.5.22-.5.5v1c0 .28.22.5.5.5zm2-2h1c.28 0 .5-.22.5-.5v-1c0-.28-.22-.5-.5-.5h-1c-.28 0-.5.22-.5.5v1c0 .28.22.5.5.5zm1-6h-1c-.28 0-.5.22-.5.5v1c0 .28.22.5.5.5h1c.28 0 .5-.22.5-.5v-1c0-.28-.22-.5-.5-.5zm1 4h1c.28 0 .5-.22.5-.5v-1c0-.28-.22-.5-.5-.5h-1c-.28 0-.5.22-.5.5v1c0 .28.22.5.5.5z"
          />
        </svg>
      )}

      {iconType && icon === "add" && (
        <Plus size={20}/>
      )}

      {iconType && icon === "download" && (
          <DownloadIcon />
      )}

      {iconType && icon === "upload" && (
          <UploadIcon />
      )}

      <span>{name}</span>
    </button>
  );
};
