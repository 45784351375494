import React from 'react';
import DefaultImage from "../../assets/images/default-premise-picture.svg";

const PremiseProfileCard = ({ premiseName, profileImageUrl }) => {
  const cardStyles = {
    borderRadius: '17px',
    gap: '11px',
    background: '#FAFAFA',
    border: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '20px',
  };

  const imageStyles = {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    objectFit: 'cover',
    marginRight: '20px',
  };

  const buttonStyles = {
    margin: '5px',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  };

  const primaryButtonStyles = {
    ...buttonStyles,
    backgroundColor: '#007bff',
    color: 'white',
  };

  const secondaryButtonStyles = {
    ...buttonStyles,
    backgroundColor: '#6c757d',
    color: 'white',
  };

  return (
      <div style={cardStyles}>
        <img
            src={profileImageUrl ? profileImageUrl : DefaultImage}
            style={{
              borderRadius: "50%",
              border: "0.3em solid #FDD3D3FF",
              width: "5em",
              height: "5em",
              objectFit: "cover"
            }}
            alt="user"
        />
        <div>
          <span className='fw-semibold fs-6'>{premiseName}</span>
          <div>
            {/*<button style={primaryButtonStyles}>Button 1</button>*/}
            {/*<button style={secondaryButtonStyles}>Button 2</button>*/}
          </div>
        </div>
      </div>
  );
};

export default PremiseProfileCard;
