const FingerPrintIcon = (props) => (
    <svg
      width={22}
      height={22}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.91822 2.38565C7.40742 1.50524 9.1447 1 11 1C16.5228 1 21 5.47715 21 11C21 11.0331 20.9998 11.0662 20.9995 11.0993M2.38114 5.92585C1.50352 7.41335 1 9.14787 1 11C1 15.6596 4.18693 19.5748 8.5 20.685M19.7076 15.9206C18.3872 18.2522 16.1574 20.001 13.5 20.685M13.0893 5.37378C12.4387 5.13207 11.7348 5 11 5C7.68629 5 5 7.68629 5 11C5 11.7387 5.13351 12.4463 5.37772 13.0999M16.6251 8.90767C16.8675 9.55914 17 10.2641 17 11C17 14.3137 14.3137 17 11 17C10.2701 17 9.57072 16.8697 8.92373 16.631M11 9V13"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  
  export default FingerPrintIcon;
  