import React, { useCallback, useContext, useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Row, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import UserImage from "../assets/images/user.png";
import { Store } from "../context/store";
import { request } from "../services/utilities";
import LicenceImgWhite from "../assets/images/license_white.svg";
import AlertTriangle from "../assets/images/alert-triangle.svg";
import ArrowLeft from "../assets/images/arrow-left.svg";
import ProfileIcon from "../assets/images/profileIcon.svg";
import PendingBilling from "../assets/images/billing.svg";
import PremisesIcon from "../assets/images/premises.svg";
import AddOns from "../assets/images/addons.svg";
// const storage = new SSRStorage();

const Dashboard = () => {
  const store = useContext(Store);
  const [user] = store.user;
  const [premises, setPremises] = useState([]);
  const [billings, setBillings] = useState([]);
  const [licenseStatus, setLicensingStatus] = useState('');
  const [licenseStatusCount, setLicensingStatusCount] = useState(null);
  // const [payments,setPayments] = useState([]);
  const [loading, setLoading] = useState(false);
  const ispay = false;

  // const fetchPayments = useCallback(async () => {
  //     const user = await storage.getItem(USER_COOKIE);
  //     setLoading(true);
  //     try{
  //         const url = `payment/getpayments?pageSize=3&pageNumber=${1}&${user.user?.userType!== DESIGNATION.ADMIN?`userId=${user.user?.sub}`:``}`;
  //         const rs = await request(url,'GET',true);
  //          setLoading(false);
  //          setPayments(rs.payload.items);
  //     }catch(err){
  //         setLoading(false);
  //         console.log(err);
  //     }
  // },[]);

  const fetchPremises = useCallback(async () => {
    setLoading(true);
    const url = `premises/getpremises?ownerId=${user?.id}`;
    try {
      const rs = await request(url, "GET", true);
      setLoading(false);
      if (rs.code === "OK") {
        setPremises(rs.payload.items);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }, [user?.id]);

  const fetchBillings = useCallback(async () => {
    setLoading(true);
    const url = `BillingStructure/GetBillings?VcnId=${user?.vcnId}&PaymentCompleted=false`;
    try {
      const rs = await request(url, "GET", true);
      setLoading(false);
      if (rs.code === "OK") {
        setBillings(rs.payload.items);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }, [user]);

  const fetchLicenseStatus = useCallback(async () => {
    setLoading(true);
    const url = `BillingStructure/GetBillings?VcnId=${user?.vcnId}&PaymentCompleted=${false}&ProductCategoryType=PractiseLicense`;
    try {
      const rs = await request(url, "GET", true);
      setLoading(false);
      if (rs.code === "OK") {
        // Update the license status count in both state and localStorage
        const count = rs.payload.items.length;
        setLicensingStatusCount(count);

        // Determine licensing status based on the updated count
        const currentYear = new Date().getFullYear();
        const licensingStatus = count === 0 ?
            `Licensed to practice for ${currentYear}` :
            `You Have ${count} Pending Bills`;
        setLicensingStatus(licensingStatus);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }, [user]);

  useEffect(() => {
    fetchPremises();
    fetchLicenseStatus();
    fetchBillings();
  }, [fetchPremises, fetchLicenseStatus, fetchBillings]);


  return (
      <Container>
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="float-end">
              {loading ? <Spinner color="danger" /> : ""}
            </div>
          </div>
          <Row className='mx-2'>
            <Col xs={12}>
              <Card
                  className="text-white"
                  style={{borderRadius: "10px", position: "relative", overflow: "hidden", background: "#F3C9CA", border: "none"}}
              >
                <div className="rounded-circle" style={{
                  width: "40em",
                  height: "40em",
                  position: "absolute",
                  top: "-200px",
                  left: "-220px", background: "#FDF5F5",
                  zIndex: 0
                }}></div>
                <div className="rounded-circle" style={{
                  width: "25em",
                  height: "25em",
                  position: "absolute",
                  top: "86px",
                  right: "-80px",
                  background: "#FDF5F5",
                  zIndex: 0
                }}></div>
                <div className="rounded-circle" style={{
                  width: "25em",
                  height: "25em",
                  position: "absolute",
                  top: "-116%",
                  right: "18%",
                  background: "#FDF5F5",
                  zIndex: 0
                }}></div>

                <CardBody className="position-relative">
                  <Row className="align-items-center text-black">
                    <Col>
                      <div
                          className=" d-flex gap-5 align-items-center justify-items-center"
                          style={{padding: "1em 2em"}}>
                        <div
                            className="d-flex justify-content-center align-items-center bg-red rounded-circle"
                        >
                          <img
                              src={user?.imageUrl ? user?.imageUrl : UserImage}
                              style={{
                                borderRadius: "50%",
                                border: "0.5em solid #CE2126",
                                width: "9em",
                                height: "9em",
                                objectFit: "cover"
                              }}
                              alt="user"
                          />
                        </div>
                        <div className="row gap-1">
                          <div className="col-12">
                            {licenseStatusCount === 0 ? (
                                <div
                                    className={`alert text-capitalize alert-dismissible border-0 fade show text-white`}
                                    role="alert"
                                    style={{ width: "fit-content", fontSize: "0.8em", padding: 0, margin: 0, borderRadius: "8px", background: "#1AB759"}}
                                >
                                  <div
                                      className='p-1 d-flex align-items-center gap-1'>
                                    <img
                                        src={LicenceImgWhite}
                                        alt="licence"
                                        className="img-fluid"
                                    />{" "}
                                    {licenseStatus}
                                  </div>
                                </div>
                            ) : (
                                <div
                                    className={`alert text-capitalize text-white alert-dismissible border-0 fade show`}
                                    role="alert"
                                    style={{
                                      width: "fit-content",
                                      fontSize: "0.8em",
                                      padding: 0,
                                      margin: 0,
                                      borderRadius: "8px",
                                      background: "#E93C3C"
                                    }}
                                >
                                  <div
                                      className='p-1 d-flex align-items-center gap-1'>
                                    <img
                                        src={AlertTriangle}
                                        alt="licence"
                                        className="img-fluid"
                                    />{" "}
                                    <span style={{ paddingRight: '40px' }}>{licenseStatus}</span>
                                    <Link
                                        to="/payments?tab=Pending-Bills"
                                        className="btn text-white d-flex align-items-center justify-content-center gap-1"
                                        style={{background: '#FF6262', width: "fit-content", fontSize: "0.8em", padding: "0.25rem"}}
                                    >
                                      Pay Now
                                      <img
                                          src={ArrowLeft}
                                          alt="arrowLeft"
                                          className="img-fluid"
                                      />
                                    </Link>
                                  </div>
                                </div>
                            )}
                          </div>
                          <div className="d-flex flex-column">
                            <p className="fs-3 mb-0 fw-bold lh-base text-capitalize">
                              {user?.firstName}{" "}
                              {user?.middleName === "unavailable" ||
                              user?.middleName === null
                                  ? ""
                                  : user?.middleName}{" "}
                              {user?.lastName}
                            </p>
                            <div className="fs-5 mb-0 fw-semibold">
                              VCN Number: {user?.vcnId}{" "}
                            </div>
                          </div>

                          <div className="col-12">
                            <Link
                                to={`/profile/${user?.id}`}
                                className="btn text-white d-flex align-items-center fs-6 justify-content-center gap-1"
                                style={{background: "#CE2126", width: "fit-content", padding: "0.375rem"}}
                            >
                              View Profile
                              <img
                                  className="text-center"
                                  src={ProfileIcon}
                                  style={{width: "1.2em", height: "auto"}}
                                  alt="Icon"
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="pt-4">
            <Col>
              <div
                  className="d-grid flex-column justify-items-center container my-3"
                  style={{ width: "fit-content" }}
              >
                <p className="fs-4 fw-semibold">Quick Actions</p>
                <div className="row align-items-center" style={{ gap: '3em', display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
                  <div className="col-md-4 d-flex flex-column w-100  align-items-center text-center">
                    <Link to="/payments?tab=Pending-Bills" className="action-link">
                      <img src={PendingBilling} alt="Action 1" width='100' className="img-fluid action-image"/>
                      <span className="text-black mt-2 fs-6 px-3 py-2">Pending Bills</span>
                    </Link>
                  </div>
                  <div className="col-md-4 d-flex flex-column w-100  align-items-center text-center">
                    <Link to={"/premises"} className="action-link">
                      <img src={PremisesIcon} alt="Action 2" width='80' className="img-fluid action-image"/>
                      <span className="text-black mt-2 fs-6 px-3 py-2">Premises</span>
                    </Link>
                  </div>
                  <div className="col-md-4 d-flex flex-column w-100 align-items-center text-center">
                    <Link to="/payments?tab=Add-Ons" className="action-link">
                      <img src={AddOns} alt="Action 3" width='80' className="img-fluid action-image"/>
                      <span className="text-black mt-2 fs-6 px-3 py-2">Add-Ons</span>
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          {/*<Row className="pt-4 justify-content-center">*/}
          {/*  <div*/}
          {/*      className='d-grid flex-column justify-items-center mb-4'*/}
          {/*      style={{width: "fit-content"}}*/}
          {/*  >*/}
          {/*    <Card*/}
          {/*        className="text-white"*/}
          {/*        style={{borderRadius: "20px", position: "relative", overflow: "hidden", background: '#CE2126', border: "none"}}*/}
          {/*    >*/}
          {/*      <div className="rounded-circle" style={{*/}
          {/*        width: "20em",*/}
          {/*        height: "20em",*/}
          {/*        position: "absolute",*/}
          {/*        top: "58px",*/}
          {/*        left: "-44px",*/}
          {/*        background: '#AA0005',*/}
          {/*        zIndex: 0*/}
          {/*      }}></div>*/}
          {/*      <div className="rounded-circle" style={{*/}
          {/*        width: "20em",*/}
          {/*        height: "20em",*/}
          {/*        position: "absolute",*/}
          {/*        top: "-110px",*/}
          {/*        right: "-45px",*/}
          {/*        background: '#AA0005',*/}
          {/*        zIndex: 0*/}
          {/*      }}></div>*/}

          {/*      <CardBody className="position-relative">*/}
          {/*        <Row className="align-items-center text-black">*/}
          {/*          <div*/}
          {/*              className="d-flex flex-column align-items-center text-white"*/}
          {/*              style={{gap: "0.5em", padding: "0.5em", margin: "0 15em", width: "fit-content"}}*/}
          {/*          >*/}
          {/*            <div className="row">*/}
          {/*              <div className="col">*/}
          {/*                <p className="text-center mb-0" style={{fontSize: "small"}}>Payment Made Easy</p>*/}
          {/*              </div>*/}
          {/*            </div>*/}

          {/*            <div className="row my-2">*/}
          {/*              <div className="col">*/}
          {/*                <h2 className="text-center h5 mb-0">Renew your licenses in one click</h2>*/}
          {/*              </div>*/}
          {/*            </div>*/}

          {/*            <div className="row">*/}
          {/*              <div className="col">*/}
          {/*                <Link to="#" className="btn btn-light" style={{borderRadius: "11px", color: "#CE2126"}}>*/}
          {/*                  Learn More*/}
          {/*                </Link>*/}
          {/*              </div>*/}
          {/*            </div>*/}
          {/*          </div>*/}
          {/*        </Row>*/}
          {/*      </CardBody>*/}
          {/*    </Card>*/}
          {/*  </div>*/}
          {/*</Row>*/}
        </div>
      </Container>
  );
};

export default Dashboard;
