import React, { useCallback, useContext, useState } from "react";
import {
  Container,
  Card,
  CardBody,
  Spinner,
  Row,
  Col,
  Form,
  Input,
  Label,
  Button,
  FormGroup,
  FormFeedback,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import FooterLogo from "../assets/images/footer.svg";
import { Eye, EyeOff, ArrowLeft } from "react-feather";
import { LabelTag } from "./FormInput"
import SuccessModal from "./modals/SuccessModal";
import PlaceholderImage from "../assets/images/placeholder.png";
import {
  request,
  scrollToTop,
  uploadCertification,
  uploadID,
  uploadPassport,
} from "../services/utilities";
import { Store } from "../context/store";
import Flatpickr from "react-flatpickr";
import { designationForNonVeterinarian, DesignationType } from "../util/data";
import { useLocation } from "react-router-dom";


const AddUser = ({ designation = DesignationType.Veterinarian }) => {
  const store = useContext(Store);
  const [allState] = store.state;
  const [eye, setEye] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState([]);
  const [allLga, setAllLga] = useState([]);
  const bgTxt = "Account Created Successfully";
  const smTxt = "";
  const navigate = useNavigate();
  const location = useLocation();

  const isVppRoute = location.pathname.includes("add-vpp");

  const navigateTo = isVppRoute ? "/admin/vet-para-professional" : "/admin/practitioner";

  const defaultValidationSchema = {
    firstname: Yup.string().required("Please Enter Your First Name"),
    lastname: Yup.string().required("Please Enter Your Last Name"),
    email: Yup.string().required("Please Enter Your Email"),
    phone: Yup.string()
      .required("Please Enter Your Phone Number")
      .max(11, "Maximum of 11 number!"),
    sex: Yup.string().required("Please Enter Your Sex"),
    dob: Yup.string().required("Please Enter Your Date of Birth"),
    address: Yup.string().required("Please Enter Your Address"),
    state: Yup.string().required("Please Enter Your State"),
    lga: Yup.string().required("Please Enter Your LGA"),
    password: Yup.string().required("Please Enter Your  Password"),
    confirmPassword: Yup.string().required("Please Confirm Your Password"),
    // certification: Yup.string().required("Please Upload your Certificate"),
    status: Yup.string().required("Please Select Your Approval Status"),
    passport: Yup.string().required("Please Upload your Passport"),
  };

  // Updates the validation schema based on the account designation
  const validationSchema =
    designation === DesignationType.NonVeterinarian
      ? Yup.object({
          ...defaultValidationSchema,
          designation: Yup.string().required("Please Select a User Type"),
          vcn: Yup.string().required(
            "Please Enter a Username for Non-Veterinarian"
          )
        })
      : Yup.object({
          ...defaultValidationSchema,
          vcn: Yup.number().required("Please Enter Your VCN Number for Veterinarian"),
          idCard: Yup.string().required("Please Select Your ID"),
          uploadId: Yup.string().required("Please Upload your ID"),
          highestQualification: Yup.string().required(
            "Please Select Your Highest Qualification"
          ),
          title: Yup.string().required("Please Select Your Practitioner Title"),
        });

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize:false,

    initialValues: {
      firstname: "",
      lastname: "",
      othername: "",
      email: "",
      phone: "",
      sex: "",
      dob: "",
      address: "",
      state: "",
      lga: "",
      stateCode: "",
      lgaCode: "",
      previewPassport: "",
      highestQualification: "",
      title: "",
      password: "",
      confirmPassword: "",
      passport: "",
      idCard: "",
      uploadId: "",
      certification: "",
      status: "",
      vcn: "",
      designation: "",
    },


    validationSchema: validationSchema,
    onSubmit: async (e) => {
      scrollToTop();
      setLoading(true);
      if (e.password !== e.confirmPassword) {
        setLoading(false);
        return setError(["Password does not match"]);
      }
      const pasP = await uploadPassport(e.passport);
      const upIdc = await uploadID(e.uploadId);
      // const upcert = await  uploadCertification(e.certification);
      const upcert = "";
      await submitForm(e, pasP, upIdc, upcert);
    },
  });

  const submitForm = async (e, pasP, upIdc, upcert) => {
    const termscondition = document.getElementById("terms&condition");
    try {
      const data =[
        {
          firstname: e.firstname,
          lastname: e.lastname,
          middleName: e.othername,
          email: e.email,
          phoneNumber: e.phone.toString(),
          sex: e.sex,
          dateOfBirth: e.dob,
          photo: pasP ? pasP : "",
          permanentResidenceAddress: e.address,
          address: e.address,
          practitionerTitle: e.title,
          state: e.state,
          lgaName: e.lga,
          lgaCode: e.lgaCode,
          stateCode: e.stateCode,
          recentUtilityBill: "",
          validIdType: e.idCard,
          validId: upIdc ? upIdc : "",
          qualification: e.highestQualification,
          highestInstitutionalCertificate: upcert ? upcert : "",
          password: e.password,
          confirmPassword: e.confirmPassword,
          accessStatus: e.status,
          acceptTermsAndCondition: termscondition.checked,
          // irelevant data
          countryCode: "+234",
          fingerPrint: "",
          highestForeignInstitutionalCertificate: "",
          licensedYear: "",
          nameOfForeignInstitution: "",
          nameOfInstitution: "",
          nationality: "",
          postalAddress: "",
          qrGeneration: "",
          registrationNumber: "",
          residenceLocalGovernmentArea: e.lga,
          school: "",
          designation: isVppRoute ? "VeterinarianParaProfessional" : e.designation,
          vcnId: isVppRoute ? `VPP${e.vcn}` : e.vcn,
        }
      ]
      
      const url = `profile/createAccount`;
      const rs = await request(url, "POST", true, data);
      setLoading(false);
      if (rs.hasErrors === true) {
        if (rs.errors) {
          setError(rs.errors);
        }
        if (rs.description) {
          setError([rs.description]);
        }
        if (rs.payload) {
          setError(rs.payload);
        }

        window.scrollTo({ top: 0, behavior: "smooth" });
      }
      if (rs.code === "OK") {
        setShow(true);
        const timer = setTimeout(() => navigate(navigateTo), 2000);
      }
      const timer = setTimeout(() => setError([]), 3000);
      return () => clearTimeout(timer);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  // preview passport
  const previewPassport = async (file) => {
    if (!file) {
      return validation.setFieldValue("previewPassport", null);
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      validation.setFieldValue("previewPassport", reader.result);
    };
  };

  const fetchLga = useCallback(async (e) => {
    try {
      const url = `Locations/GetLGA?state=${e}`;
      const rs = await request(url, "GET", false);
      setAllLga(rs.payload);
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <>
      <Container>
        <Row>
          <Card className="border-none border-white">
            <Row>
            <Col xl={6} md={6} sm={12}>
                <h2 className="h1-color mb-0">
                  Create an Account for {designation}
                </h2>
              </Col>
            <Col className="d-flex justify-content-end align-items-center">
              <Link
                to={navigateTo}
                className="btn text-decoration-none"
                id="bggrey"
              >
                <ArrowLeft size={16} /> Back
              </Link>
            </Col>
            </Row>
            <Row>
              <Col>
                {error.map((e) => {
                  return (
                    <div
                      key={e}
                      className="alert w-100 text-center text-capitalize alert-danger alert-dismissible border-2 p-1 fade show"
                      role="alert"
                      style={{ background: "#fff", fontSize: "14px" }}
                    >
                      {e}
                    </div>
                  );
                })}
              </Col>
              <Col xl={4} md={6} sm={12}>
                <div className="float-end">
                  {loading ? <Spinner color="danger" /> : ""}
                </div>
              </Col>
            </Row>
            <CardBody className="p-0">
              <div className="bg-white">
                <Form
                  className="pt-4"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <div
                    className="personal-information pb-3"
                    style={{ borderBottom: "1px solid #eee" }}
                  >
                    <p className="fw-800 fs-6 pb-2">Personal Information</p>
                    <div className="bg-white mb-3 user-passport mb-4 d-flex align-items-end">
                      <div
                        style={{
                          width: "",
                          boxShadow: "0 1px 2px rgba(16,24,60,.05)",
                          padding: "10px",
                        }}
                      >
                        <img
                          src={
                            validation.values.previewPassport
                              ? validation.values.previewPassport
                              : PlaceholderImage
                          }
                          loading="lazy"
                          width="150"
                          height="150"
                          className="rounded-circle"
                          alt="user passport"
                        />
                      </div>
                      <div className="mx-3">
                        <FormGroup className="">
                          <Label htmlFor="val8">
                            Upload Passport Photograph{" "}
                          </Label>
                          <Input
                            name="passport"
                            type="file"
                            className="form-control"
                            id="val8"
                            onChange={(e) => {
                              previewPassport(e.currentTarget.files[0]);
                              validation.setFieldValue(
                                "passport",
                                e.currentTarget.files[0]
                              );
                            }}
                            onBlur={validation.handleBlur}
                            // value={validation.values.passport || ""}
                            invalid={
                              validation.touched.passport &&
                              validation.errors.passport
                                ? true
                                : false
                            }
                          />
                          {validation.touched.passport &&
                          validation.errors.passport ? (
                            <FormFeedback type="invalid">
                              {validation.errors.passport}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </div>
                    </div>
                    <Row>
                      <Col xl={4} sm={12} md={6}>
                        <FormGroup className="mb-3">
                        <LabelTag htmlFor="val1" label="First Name" required={validationSchema.fields["firstname"]} />
                          <Input
                            name="firstname"
                            placeholder="John"
                            type="text"
                            className="form-control"
                            id="val1"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.firstname || ""}
                            invalid={
                              validation.touched.firstname &&
                              validation.errors.firstname
                                ? true
                                : false
                            }
                          />
                          {validation.touched.firstname &&
                          validation.errors.firstname ? (
                            <FormFeedback type="invalid">
                              {validation.errors.firstname}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col xl={4} sm={12} md={6}>
                        <FormGroup className="mb-3">
                        <LabelTag htmlFor="val2" label="Last Name" required={validationSchema.fields["lastname"]} />
                          <Input
                            name="lastname"
                            placeholder="Paul"
                            type="text"
                            className="form-control"
                            id="val2"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.lastname || ""}
                            invalid={
                              validation.touched.lastname &&
                              validation.errors.lastname
                                ? true
                                : false
                            }
                          />
                          {validation.touched.lastname &&
                          validation.errors.lastname ? (
                            <FormFeedback type="invalid">
                              {validation.errors.lastname}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col xl={4} sm={12} md={6}>
                        <FormGroup className="mb-3">
                        <LabelTag htmlFor="val3" label="Other Name" required={validationSchema.fields["othername"]} />
                          <Input
                            name="othername"
                            placeholder="Doe"
                            type="text"
                            className="form-control"
                            id="val3"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.othername || ""}
                            invalid={
                              validation.touched.othername &&
                              validation.errors.othername
                                ? true
                                : false
                            }
                          />
                          {validation.touched.othername &&
                          validation.errors.othername ? (
                            <FormFeedback type="invalid">
                              {validation.errors.othername}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      {/* Shows when designation account be created is Non-Veterinarian */}
                      {
                        designation === DesignationType.NonVeterinarian &&(
                          <Col xl={4} sm={12} md={6}>
                        <FormGroup className="mb-3">
                        <LabelTag htmlFor="valvcn" label="Username" required={validationSchema.fields["vcn"]} />
                          <Input
                            name="vcn"
                            type="text"
                            className="form-control"
                            placeholder="Username"
                            id="valvcn"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.vcn || ""}
                            invalid={
                              validation.touched.vcn && validation.errors.vcn
                                ? true
                                : false
                            }
                          />
                          {validation.touched.vcn && validation.errors.vcn ? (
                            <FormFeedback type="invalid">
                              {validation.errors.vcn}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                        )
                      }
                      
                      <Col xl={4} sm={12} md={6}>
                        <FormGroup className="mb-3">
                        <LabelTag htmlFor="val4" label="Email" required={validationSchema.fields["email"]} />
                          <Input
                            name="email"
                            placeholder="john@gmail.com"
                            type="text"
                            className="form-control"
                            id="val4"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col xl={4} sm={12} md={6}>
                        <FormGroup className="mb-3">
                        <LabelTag htmlFor="val5" label="Phone Number" required={validationSchema.fields["phone"]} />
                          <Input
                            name="phone"
                            placeholder="Phone Number"
                            type="number"
                            className="form-control"
                            maxLength="11"
                            id="val5"
                            onChange={(e) => {
                              validation.setFieldValue("phone", e.target.value);
                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values.phone || ""}
                            invalid={
                              validation.touched.phone &&
                              validation.errors.phone
                                ? true
                                : false
                            }
                          />
                          {validation.touched.phone &&
                          validation.errors.phone ? (
                            <FormFeedback type="invalid">
                              {validation.errors.phone}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col xl={4} sm={12} md={6}>
                        <FormGroup className="mb-3">
                        <LabelTag htmlFor="val6" label="Sex" required={validationSchema.fields["sex"]} />
                          <Input
                            name="sex"
                            type="select"
                            className="form-control"
                            id="val6"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.sex || ""}
                            invalid={
                              validation.touched.sex && validation.errors.sex
                                ? true
                                : false
                            }
                          >
                            <option>Select Sex</option>
                            <option>Male</option>
                            <option>Female</option>
                            <option>Prefer Not To Say</option>
                          </Input>
                          {validation.touched.sex && validation.errors.sex ? (
                            <FormFeedback type="invalid">
                              {validation.errors.sex}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col xl={4} sm={12} md={6}>
                        <FormGroup className="mb-3">
                        <LabelTag htmlFor="val7" label="Date of Birth (dd/mm/yyy)" required={validationSchema.fields["dob"]} />
                          <Flatpickr
                            name="dob"
                            id="val7"
                            className={`form-control ${validation.touched.dob && validation.errors.dob ? 'is-invalid' : ''}`}
                            placeholder="dd/mm/yyyy"
                            value={validation.values.dob || ""}
                            onChange={(selectedDates, dateStr) => {
                              if (!selectedDates.length) return;
                              const ISODate = selectedDates[0].toISOString(); // iso date str
                              const utct = ISODate.split("T")[1];
                              const newDate = `${dateStr}T${utct}`;
                              validation.setFieldValue("dob", newDate);
                              validation.setFieldTouched("dob", true);
                              validation.handleChange(newDate);
                            }}
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              dateFormat: "Y-m-d",
                              maxDate: new Date(),
                            }}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.dob && validation.errors.dob
                                ? true
                                : false
                            }
                          />
                          {validation.touched.dob && validation.errors.dob ? (
                            <FormFeedback type="invalid">
                              {validation.errors.dob}
                            </FormFeedback>
                          ): null}
                        </FormGroup>
                      </Col>
                    {
                      designation === DesignationType.NonVeterinarian &&(
                        <Col xl={4} sm={12} md={6}>
                        <FormGroup className="mb-3">
                        <LabelTag htmlFor="val10" label="User Type" required={validationSchema.fields["designation"]} />
                          <Input
                            name="designation"
                            type="select"
                            className="form-control"
                            id="valDesignation"
                            placeholder="Designation"
                            onChange={(e) => {
                              validation.setFieldValue(
                                "designation",
                                e.target.value
                              );
                              validation.handleChange(e);
                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values.designation || ""}
                            invalid={
                              validation.touched.designation && validation.errors.designation ? true : false
                            }
                          >
                            <option>Select User Type</option>
                            {designationForNonVeterinarian?.map(
                              (designation, index) => {
                                return (
                                  <option
                                    id={designation.label}
                                    value={designation.value}
                                    key={index}
                                  >
                                    {designation.label}
                                  </option>
                                );
                              }
                            )}
                          </Input>
                          {validation.touched.designation &&
                          validation.errors.designation ? (
                            <FormFeedback type="invalid">
                              {validation.errors.designation}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      )
                    }
                    {/* Shows when designation account be created is Veterinarian */}
                    {
                      designation === DesignationType.Veterinarian &&(
                        <Col xl={4} sm={12} md={6}>
                        <FormGroup className="mb-3">
                        <LabelTag htmlFor="valvcn" label="VCN Number" required={validationSchema.fields["vcn"]} />
                          <Input
                            name="vcn"
                            type="number"
                            className="form-control"
                            placeholder="VCN Number"
                            id="valvcn"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.vcn || ""}
                            invalid={
                              validation.touched.vcn && validation.errors.vcn
                                ? true
                                : false
                            }
                          />
                          {validation.touched.vcn && validation.errors.vcn ? (
                            <FormFeedback type="invalid">
                              {validation.errors.vcn}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      )
                    }
                    </Row>
                  </div>
                  <div
                    className="address pb-3"
                    style={{ borderBottom: "1px solid #eee" }}
                  >
                    <p className="fw-800 fs-6 pb-2 pt-3">Address</p>
                    <Row>
                      <Col xl={4} sm={12} md={6}>
                        <FormGroup className="mb-3">
                        <LabelTag htmlFor="val9" label="Permanent Residence" required={validationSchema.fields["address"]} />
                          <Input
                            name="address"
                            type="text"
                            className="form-control"
                            placeholder="12 Police Station Street"
                            id="val9"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.address || ""}
                            invalid={
                              validation.touched.address &&
                              validation.errors.address
                                ? true
                                : false
                            }
                          />
                          {validation.touched.address &&
                          validation.errors.address ? (
                            <FormFeedback type="invalid">
                              {validation.errors.address}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col xl={4} sm={12} md={6}>
                        <FormGroup className="mb-3">
                        <LabelTag htmlFor="val91" label="Practice State" required={validationSchema.fields["state"]} />
                          <Input
                            name="state"
                            type="select"
                            className="form-control"
                            id="val91"
                            onChange={(e) => {
                              const stateId = document.querySelector("#val91");
                              const state_code =
                                stateId.options[
                                  stateId.selectedIndex
                                ].getAttribute("statecode");
                              validation.setFieldValue("stateCode", state_code);
                              validation.handleChange(e);
                              fetchLga(e.target.value);
                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values.state || ""}
                            invalid={
                              validation.touched.state &&
                              validation.errors.state
                                ? true
                                : false
                            }
                          >
                            <option>Select State</option>
                            {allState?.map((e) => {
                              return (
                                <option
                                  id={e.state__name}
                                  statecode={e.state__id}
                                  key={e.state__id}
                                >
                                  {e.state__name}
                                </option>
                              );
                            })}
                          </Input>
                          {validation.touched.state &&
                          validation.errors.state ? (
                            <FormFeedback type="invalid">
                              {validation.errors.state}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col xl={4} sm={12} md={6}>
                        <FormGroup className="mb-3">
                        <LabelTag htmlFor="val10" label="Practice LGA" required={validationSchema.fields["lga"]} />
                          <Input
                            name="lga"
                            type="select"
                            className="form-control"
                            id="val10"
                            onChange={(e) => {
                              const lgaId = document.querySelector("#val10");
                              const lga_code =
                                lgaId.options[lgaId.selectedIndex].getAttribute(
                                  "lgacode"
                                );
                              validation.setFieldValue("lgaCode", lga_code);
                              validation.handleChange(e);
                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values.lga || ""}
                            invalid={
                              validation.touched.lga && validation.errors.lga
                                ? true
                                : false
                            }
                          >
                            <option>Select LGA</option>
                            {allLga?.map((e) => {
                              return (
                                <option
                                  lgacode={e["LGA CODE"]}
                                  key={e["LGA CODE"]}
                                >
                                  {e["LGA NAME"]}
                                </option>
                              );
                            })}
                          </Input>
                          {validation.touched.lga && validation.errors.lga ? (
                            <FormFeedback type="invalid">
                              {validation.errors.lga}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div
                    className="identification pb-3"
                    style={{ borderBottom: "1px solid #eee" }}
                  >
                    <p className="fw-800 pb-2 pt-3 fs-6"> Identification</p>
                    <Row>
                      <Col xl={4} sm={12} md={6}>
                        <FormGroup className="mb-3">
                        <LabelTag htmlFor="val13" label="Select ID" required={validationSchema.fields["idCard"]} />
                          <Input
                            name="idCard"
                            type="select"
                            className="form-control"
                            id="val13"
                            placeholder="Uplaod Utitlity Bill"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.idCard || ""}
                            invalid={
                              validation.touched.idCard &&
                              validation.errors.idCard
                                ? true
                                : false
                            }
                          >
                            <option>Select ID</option>
                            <option>National ID</option>
                            <option>Drivers Licence</option>
                            <option>International Passport</option>
                            <option>Others</option>
                          </Input>
                          {validation.touched.idCard &&
                          validation.errors.idCard ? (
                            <FormFeedback type="invalid">
                              {validation.errors.idCard}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col xl={4} sm={12} md={6}>
                        <FormGroup className="mb-3">
                        <LabelTag htmlFor="val14" label="Uplaod ID " required={validationSchema.fields["uploadId"]} />
                          <Input
                            name="uploadId"
                            type="file"
                            className="form-control"
                            id="val14"
                            placeholder="Uplaod  ID"
                            onChange={(e) => {
                              validation.setFieldValue(
                                "uploadId",
                                e.currentTarget.files[0]
                              );
                            }}
                            onBlur={validation.handleBlur}
                            // value={validation.values.uploadId || ""}
                            invalid={
                              validation.touched.uploadId &&
                              validation.errors.uploadId
                                ? true
                                : false
                            }
                          />
                          {validation.touched.uploadId &&
                          validation.errors.uploadId ? (
                            <FormFeedback type="invalid">
                              {validation.errors.uploadId}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div
                    className="education pb-3"
                    style={{ borderBottom: "1px solid #eee" }}
                  >
                    <p className="fw-800 pt-4 pb-2 fs-6">
                      Education & Qualification
                    </p>
                    <Row>
                      <Col xl={4} sm={12} md={6}>
                        <FormGroup className="mb-3">
                        <LabelTag htmlFor="val15" label="Highest Qualification" required={validationSchema.fields["highestQualification"]} />
                          <Input
                            name="highestQualification"
                            type="select"
                            className="form-control"
                            id="val15"
                            placeholder="Select Highest Qualification"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.highestQualification || ""}
                            invalid={
                              validation.touched.highestQualification &&
                              validation.errors.highestQualification
                                ? true
                                : false
                            }
                          >
                            <option>Select Highest Qualification</option>
                            <option>
                              Doctor of Veterinary Medicine (D.V.M)
                            </option>
                            <option>Doctor of Philosophy (PhD)</option>
                            <option>Masters</option>
                            <option>Others</option>
                          </Input>
                          {validation.touched.highestQualification &&
                          validation.errors.highestQualification ? (
                            <FormFeedback type="invalid">
                              {validation.errors.highestQualification}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col xl={4} sm={12} md={6}>
                        <FormGroup className="mb-3">
                        <LabelTag htmlFor="val15" label="Practitioner Title" required={validationSchema.fields["title"]} />
                          <Input
                            name="title"
                            type="select"
                            className="form-control"
                            id="val16"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.title || ""}
                            invalid={
                              validation.touched.title &&
                              validation.errors.title
                                ? true
                                : false
                            }
                          >
                            <option>Select Practitioner Title</option>
                            <option>Prof.</option>
                            <option>Dr.</option>
                          </Input>
                          {validation.touched.title &&
                          validation.errors.title ? (
                            <FormFeedback type="invalid">
                              {validation.errors.title}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col xl={4} sm={12} md={6} className="d-none">
                        <FormGroup className="mb-4">
                        <LabelTag htmlFor="val17" label="Upload Certification" required={validationSchema.fields["certification"]} />
                          <Input
                            name="certification"
                            type="file"
                            className="form-control"
                            id="val17"
                            placeholder="Uplaod Certification"
                            onChange={(e) => {
                              validation.setFieldValue(
                                "certification",
                                e.currentTarget.files[0]
                              );
                            }}
                            onBlur={validation.handleBlur}
                            // value={validation.values.certification || ""}
                            invalid={
                              validation.touched.certification &&
                              validation.errors.certification
                                ? true
                                : false
                            }
                          />
                          {validation.touched.certification &&
                          validation.errors.certification ? (
                            <FormFeedback type="invalid">
                              {validation.errors.certification}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div className="authentication pb-3">
                    <p className="fs-6 pt-4 fw-800 pb-2">Authentication</p>
                    <Row>
                      <Col xl={4} sm={12} md={6}>
                        <div className="mb-3">
                        <LabelTag htmlFor="val18" label="Password" required={validationSchema.fields["password"]} />
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <FormGroup className="mb-3">
                              <Input
                                name="password"
                                placeholder="Enter your  Password"
                                type={eye === true ? "text" : "password"}
                                className="form-control"
                                id="val18"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.password || ""}
                                invalid={
                                  validation.touched.password &&
                                  validation.errors.password
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.password &&
                              validation.errors.password ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.password}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                            <button
                              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                              type="button"
                              id="password-addon1"
                            >
                              {eye === true ? (
                                <Eye size={14} onClick={() => setEye(false)} />
                              ) : (
                                <EyeOff
                                  onClick={() => setEye(true)}
                                  size={14}
                                />
                              )}
                            </button>
                          </div>
                        </div>
                      </Col>
                      <Col xl={4} sm={12} md={6}>
                        <div className="mb-3">
                        <LabelTag htmlFor="val19" label="Confirm Password" required={validationSchema.fields["confirmPassword"]} />
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <FormGroup className="mb-3">
                              <Input
                                name="confirmPassword"
                                placeholder="Enter  Password"
                                type={eye === true ? "text" : "password"}
                                className="form-control"
                                id="val19"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.confirmPassword || ""}
                                invalid={
                                  validation.touched.confirmPassword &&
                                  validation.errors.confirmPassword
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.confirmPassword &&
                              validation.errors.confirmPassword ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.confirmPassword}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                            <button
                              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                              type="button"
                              id="password-addon"
                            >
                              {eye === true ? (
                                <Eye size={14} onClick={() => setEye(false)} />
                              ) : (
                                <EyeOff
                                  onClick={() => setEye(true)}
                                  size={14}
                                />
                              )}
                            </button>
                          </div>
                        </div>
                      </Col>
                      <Col xl={4} sm={12} md={6}>
                        <FormGroup className="mb-3">
                          <LabelTag htmlFor="val1A" label="Approval Status" required={validationSchema.fields["status"]} />
                          <Input
                            name="status"
                            type="select"
                            className="form-control"
                            id="val1A"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.status || ""}
                            invalid={
                              validation.touched.status &&
                              validation.errors.status
                                ? true
                                : false
                            }
                          >
                            <option>Select Approval Status</option>
                            <option>Approve</option>
                            <option value="DisApprove">Disapprove</option>
                          </Input>
                          {validation.touched.status &&
                          validation.errors.status ? (
                            <FormFeedback type="invalid">
                              {validation.errors.status}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <div className="form-check text-center mb-3 checkBox pt-4">
                    <Label
                      className="form-check-label"
                      htmlFor="terms&condition"
                    >
                      <Input
                        type="checkbox"
                        className="form-check-input checkBox"
                        id="terms&condition"
                        required
                      />
                      I declare that all information I have provided are correct
                      and agree with
                      <Link to="#" className="textRed text-decoration-none">
                        {" "}
                        Terms and Condition
                      </Link>
                    </Label>
                  </div>
                  <div className="mt-4 text-center">
                    <button
                      id="bgred"
                      className="modalw btn text-light"
                      type="submit"
                    >
                      Register
                    </button>
                  </div>
                </Form>
              </div>
              <div className="text-center pt-5">
                <p className="mb-0">
                  <img
                    src={FooterLogo}
                    loading="lazy"
                    width="20"
                    className="img-fluid"
                    alt="footer"
                  />{" "}
                  Powered by Milsat Technologies
                </p>
              </div>
            </CardBody>
          </Card>
        </Row>
      </Container>
      <SuccessModal show={show} setShow={setShow} bgTxt={bgTxt} smTxt={smTxt} />
    </>
  );
};

export default AddUser;

