import { Routes, Route } from "react-router-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "flatpickr/dist/themes/material_green.css";
import ResetPasssword from "./pages/ResetPassword";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Dashboard from "./pages/Dashboard";
import RegisterPremises from "./pages/RegisterPremises";
import Premises from "./pages/Premises";
import Payment from "./pages/Payment";
import Profile from "./pages/Profile";
import VetParaProfessional from "./pages/VetParaProfessional";
import AdminDashboard from "./pages/AdminDashboard";
import Practitioner from "./pages/Practitioner";
import AddUser from "./pages/AddUser";
import AdminPremises from "./pages/AdminPremises";
import StoreContext from "./context/store";
import AuthProvider from './context/UserProvider';
import ForgotPassword from './pages/ForgotPassword';
import UpdateProfile from './pages/UpdateProfile';
import ProfileRequestUpdate from './pages/ProfileRequestUpdate';
import AdminProfile from './pages/AdminProfile';
import MiniProfile from './pages/MiniProfile';
import Billings from './pages/Billings';
import BillingsDashboard from './pages/BillingsDashboard';
import RecordOfflineTransaction from './pages/RecordOfflineTransaction';
import CreateBilling from "./pages/CreateBilling";
import Certificates from './pages/Certificates';
import OptionSection from "./pages/OptionSection";
import VerifyProfile from "./pages/VerifyProfile";
import PremiseProfile from "./pages/PremiseProfile";
import AdminPayments from "./pages/AdminPayments";


function App() {
  return (
      <StoreContext>
        <AuthProvider>
          <Routes>
            <Route path="/select-option" element={<OptionSection />} />
            <Route path="/" element={<OptionSection />} />
            <Route path="/login" element={<Login />} />
            <Route path="/reset-password" element={<ResetPasssword />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/mini-profile/:id" element={<MiniProfile />} />

            <Route path="/signup" element={<Signup />} />
            <Route path="/veterinarian" element={<UpdateProfile />} />
            <Route path="/verify-profile" element={<VerifyProfile />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/admin/register-premises" element={<RegisterPremises />} />
            <Route path="/register-premises" element={<RegisterPremises />} />
            <Route path="/register-premises" element={<RegisterPremises />} />
            <Route path="/premises/:ownerId/:id" element={<RegisterPremises />} />
            <Route path="/premise/:id" element={<PremiseProfile />} />
            <Route path="/premises" element={<Premises />} />
            <Route path="/payments" element={<Payment />} />
            <Route path="/billings-section" element={<Billings />} />
            <Route path="/profile/:id" element={<Profile />} />
            {/* admin */}
            <Route path="/admin/dashboard" element={<AdminDashboard />} />
            <Route path="/admin/practitioner" element={<Practitioner />} />
            <Route path="/admin/add-user" element={<AddUser />} />
            <Route path="/admin/add-vpp" element={<AddUser />} />
            <Route path="/admin/user-details/:id" element={<AdminProfile />} />
            <Route path="/admin/vet-para-professional" element={<VetParaProfessional />} />
            <Route path="/admin/premises" element={<AdminPremises />} />
            <Route path="/admin/payments" element={<AdminPayments />} />
            <Route path="/admin/billings" element={<BillingsDashboard />} />
            <Route path="/admin/certifications" element={<Certificates />} />
            <Route path="/admin/paybill" element={<RecordOfflineTransaction />} />
            <Route path="/admin/create-billing" element={<CreateBilling />} />
            <Route path="/admin/profile-update-request" element={<ProfileRequestUpdate />} />

          </Routes>
        </AuthProvider>
      </StoreContext>
  );
}

export default App;
