import Navbar from "../components/Navbar";
import PaymentSection from "../components/PaymentSection";

const Payment = () => {
  return (
      <>
        <Navbar/>
        <div style={{paddingTop: "7rem"}}>
          <PaymentSection/>
        </div>
      </>
  );
};

export default Payment;
