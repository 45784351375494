import React, { useState } from "react";
import {
  Form,
  Label,
  Spinner,
} from "reactstrap";
import SuccessImg from "../assets/images/SuccessCheck.svg";
import AlertIcon from "../assets/icons/AlertIcon";
import CancelIcon from "../assets/icons/CancelIcon";
import * as Yup from "yup";
import { useFormik } from "formik";
import { request, scrollToTop } from "../services/utilities";
import {useNavigate } from "react-router-dom";
import AuthLayout from "./layout/AuthLayout";
import CustomInput from "./shared/CustomInput";
import {Link} from "react-router-dom";
const ResetPasssword = () => {
  const [error, setError] = useState([]);
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const query = new URLSearchParams(window.location.search);
  const code = query.get("code");

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      confirmPassword: "",
      password: "",
      vcnId: "",
    },
    validationSchema: Yup.object({
      vcnId: Yup.number().required("Please Enter Your VCN Number"),
      password: Yup.string().required("Please Enter New Password"),
      confirmPassword: Yup.string().required(
          "Please Enter New Confirm Password"
      ),
    }),
    onSubmit: async (e) => {
      setError([]);
      setLoading(true);
      const data = {
        vcnId: e.vcnId,
        password: e.password,
        code,
        confirmPassword: e.confirmPassword,
      };
      try {
        const url = `profile/ResetPassword`;
        const rs = await request(url, "POST", false, data);
        setLoading(false);
        if (rs.hasErrors === true) {
          if (rs.errors) {
            setError(rs.errors);
          }
          if (rs.description) {
            setError([rs.description]);
          }
          if (rs.payload) {
            setError(rs.payload);
          }
          scrollToTop();
        }
        if (rs.code === "OK") {
          setSuccess("Password updated successfully");
          setTimeout(() => (window.location.href = "/"), 3000);
        }
      } catch (err) {
        setLoading(false);
        setError(["Failed to reset password kindly try again later"]);
      }
    },
  });

  return (
      <AuthLayout>
        <div className="login-error-container">
          {error.length > 0 && (
              <div
                  className="d-flex p-4 border rounded h-75 w-100 text-capitalize border-1"
                  role="alert"
              >
                <div className="gap-3 d-flex w-100 ">
                  <AlertIcon />
                  <div className="d-flex flex-column justify-content-between">
                    {error.map((e) => {
                      return (
                          <h3 key={e} className="fs-5 fw-semibold">
                            {e}
                          </h3>
                      );
                    })}
                    <p className="pt-2">
                      Contact the admin for help or try reset password again
                    </p>
                  </div>
                </div>
                <CancelIcon
                    onClick={() => setError([])}
                    style={{ cursor: "pointer" }}
                />
              </div>
          )}
        </div>
        {success ? (
            <SuccessReset />
        ) : (
            <main className="layout-wrapper h-75 m-auto ">
              <div className="d-flex justify-content-center">
                <h1 className="fs-1 fw-bold">Set new password</h1>
              </div>
              <Form
                  className="pt-4"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
              >
                <div className="mb-3">
                  <Label htmlFor="vcnId">Enter your VCN Number</Label>
                  <CustomInput
                      name="vcnId"
                      placeholder="VCN Number"
                      type="number"
                      id="vcnId"
                      value={validation.values.vcnId || ""}
                      errors={validation.errors.vcnId}
                      touched={validation.touched.vcnId}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.vcnId && validation.errors.vcnId
                            ? true
                            : false
                      }
                  />
                </div>
                <div>
                  <Label htmlFor="password">New Password</Label>
                  <CustomInput
                      name="password"
                      placeholder="Password"
                      type="password"
                      id="password"
                      value={validation.values.password || ""}
                      errors={validation.errors.password}
                      touched={validation.touched.password}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.password && validation.errors.password
                            ? true
                            : false
                      }
                  />
                </div>
                <div>
                  <Label htmlFor="confirmPassword">Confirm New Password</Label>
                  <CustomInput
                      name="confirmPassword"
                      placeholder="Password"
                      type="password"
                      id="confirmPassword"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.confirmPassword || ""}
                      errors={validation.errors.confirmPassword}
                      touched={validation.touched.confirmPassword}
                      invalid={
                        validation.touched.confirmPassword &&
                        validation.errors.confirmPassword
                            ? true
                            : false
                      }
                  />
                </div>
                <div className="w-100" style={{ padding: "0.5em 0 2em 0" }}>
                  <button
                      className="w-100 login-btn py-2 text-light fw-normal"
                      type="submit"
                  >
                    {loading ? (
                        <Spinner size={"xs"} color="white" />
                    ) : (
                        " Reset Password"
                    )}
                  </button>
                </div>
              </Form>
              <Link
                  to="/login"
                  className="text-center mt-5 fs-6 text-grey text-decoration-none"
              >
                <p className="mb-0">
                  Return to <span className="textred">Login</span>
                </p>
              </Link>
            </main>
        )}
      </AuthLayout>
  );
};

export default ResetPasssword;

const SuccessReset = () => {
  return (
      <main className="layout-wrapper h-50 d-flex flex-column gap-5 m-auto text-center">
        <div>
          <img
              src={SuccessImg}
              className="img-fluid"
              style={{ maxWidth: "40%", height: "auto" }}
              alt="successImg"
          />
        </div>

        <h2>Password reset</h2>
        <div className="w-100">
          <button
              className="w-100 login-btn py-2 text-light fw-normal"
              onClick={() => (window.location.href = "/")}
          >
            Login
          </button>
        </div>
      </main>
  );
};
