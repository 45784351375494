import Navbar from "../components/Navbar";
import BillingComponent from  "../components/Billings";

const Billings = () => {
	return (
		<>
			<Navbar />
			<div style={{paddingTop: "6rem"}}>
				<BillingComponent/>
			</div>
		</>
	);
};

export default Billings;
