import { Card, CardBody, CardHeader, Col, Row, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import LogoImage from "../assets/images/logo.jpeg";
import Frame1 from "../assets/images/frame1.png";
import Frame2 from "../assets/images/frame2.png";
import Frame3 from "../assets/images/frame3.png";
import Frame5 from "../assets/images/frame5.png";
import Frame6 from "../assets/images/frame6.png";
import { ArrowRight } from "react-feather";
import FooterLogo from "../assets/images/footer.svg";
import { useCallback, useEffect, useState } from "react";
import { useAuthContext } from "../context/UserProvider";
import { DESIGNATION } from "../services/constants";
import { request } from "../services/utilities";

const AdminDashboard = () => {
  const { userType } = useAuthContext();
  const [totalPrac, setTotalPrac] = useState("");
  const [totalPrem, setTotalPrem] = useState("");

  const [loading, setLoading] = useState(false);

  const fetchPractitioners = useCallback(async () => {
    setLoading(true);
    try {
      const url = `profile/getusers?PageSize=1`;
      const rs = await request(url, "GET", true);
      setLoading(false);
      setTotalPrac(rs.payload.totalItemCount);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }, []);
  const fetchPremise = useCallback(async () => {
    setLoading(true);
    try {
      const url = `premises/getpremisescount`;
      const rs = await request(url, "GET", true);
      setLoading(false);
      setTotalPrem(rs.payload.totalPremises);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }, []);

  useEffect(() => {
    fetchPractitioners();
    fetchPremise();
  }, []);

  return (
    <Container>
      <Row>
        <Col xs={12}>
          <Card
            className="text-white"
            style={{ borderRadius: "15px", background: "#CE2126" }}
          >
            <CardBody className="p-2">
              <Row className="align-items-end">
                <Col sm={12}>
                  <div className="d-flex align-items-center">
                    <div>
                      <img
                        src={LogoImage}
                        width="70"
                        height="70"
                        style={{ borderRadius: "50%" }}
                        alt="user"
                      />
                    </div>
                    <div className="p-3">
                      <h1 className="fs-3 mb-0 fw-600 lh-base text-uppercase">
                        Veterinary Council Of Nigeria
                      </h1>
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <h1 className="h1-color  pb-3 pt-4">
            {userType === DESIGNATION.SUPPORTOFFICER
              ? "Support Officer Dashboard"
              : "Admin Dashboard"}
          </h1>
        </Col>
        <Col>
          <div className="float-end">
            {loading ? <Spinner color="danger" /> : ""}
          </div>
        </Col>
      </Row>

      <Row className="pt-4">
        <Col xl={userType === DESIGNATION.SUPPORTOFFICER ? 12 : 6} md={6} sm={12} className="mb-2">
          <Card
            style={{
              background: "#FDF6F6",
              border: "none",
              borderRadius: "15px",
            }}
          >
            <CardBody>
              <div className="text-center">
                <h1 className="card-title fs-1 fw-600">
                  {" "}
                  {new Intl.NumberFormat("en-US").format(totalPrac)}{" "}
                </h1>
                <p className="card-text fs-5 p-color">
                  Registered Practitioners{" "}
                </p>
              </div>
            </CardBody>
          </Card>
        </Col>
        {userType === DESIGNATION.ADMIN && (
        <Col xl={6} md={6} sm={12} className="mb-2">
          <Card
            style={{
              background: "#FDF6F6",
              border: "none",
              borderRadius: "15px",
            }}
          >
            
              <CardBody>
                <div className="text-center">
                  <h1 className="card-title fs-1 fw-600">
                    {new Intl.NumberFormat("en-US").format(totalPrem)}{" "}
                  </h1>
                  <p className="card-text fs-5 p-color">Registered Premises </p>
                </div>
              </CardBody>
            
          </Card>
        </Col>
        )}
      </Row>

      <Row className="pt-5 row-gap-2">
        {(userType === DESIGNATION.ADMIN) |
          (userType === DESIGNATION.SUPPORTOFFICER) && (
          <Col xl={4} md={6} sm={12} className="mb-2">
            <Card
              style={{
                borderRadius: "15px",
                border: "1px solid red",
                borderBottom: "none",
                background: "#FDF6F6",
              }}
            >
              <CardBody>
                <div>
                  <img src={Frame1} alt="alt" className="img-fluid" />
                </div>
                <div className="pt-4 card-text fs-4">Manage</div>
                <div className="card-text fs-3">Practitioners</div>
                <div className="card-text fs-6 p-color pb-4">
                  Register, view and manage veterinary Practitioners
                </div>
              </CardBody>
              <Link className="text-decoration-none" to="/admin/practitioner">
                <div
                  className="p-4 text-light"
                  style={{
                    background: "#CE2126",
                    cursor: "pointer",
                    borderBottomLeftRadius: "15px",
                    borderBottomRightRadius: "15px",
                  }}
                >
                  Manage all Practitioners{" "}
                  <ArrowRight className="float-end" size={18} />
                </div>
              </Link>
            </Card>
          </Col>
        )}
        {userType === DESIGNATION.ADMIN && (
          <Col xl={4} md={6} sm={12} className="mb-2">
            <Card
              style={{
                borderRadius: "15px",
                border: "1px solid red",
                borderBottom: "none",
                background: "#FDF6F6",
              }}
            >
              <CardBody>
                <div>
                  <img src={Frame2} alt="alt" className="img-fluid" />
                </div>
                <div className="pt-4 card-text fs-4">Manage</div>
                <div className="card-text fs-3">Premise</div>
                <div className="card-text fs-6 p-color pb-4">
                  Register, view and manage veterinary premises
                </div>
              </CardBody>
              <Link to="/admin/premises" className="text-decoration-none">
                <div
                  className="p-4 text-light"
                  style={{
                    background: "#CE2126",
                    cursor: "pointer",
                    borderBottomLeftRadius: "15px",
                    borderBottomRightRadius: "15px",
                  }}
                >
                  Manage all Premises{" "}
                  <ArrowRight className="float-end" size={18} />
                </div>
              </Link>
            </Card>
          </Col>
        )}

        {userType === DESIGNATION.ADMIN && (
          <Col xl={4} md={6} sm={12} className="mb-2">
            <Card
              style={{
                borderRadius: "15px",
                border: "1px solid red",
                borderBottom: "none",
                background: "#FDF6F6",
              }}
            >
              <CardBody>
                <div>
                  <img src={Frame3} alt="alt" className="img-fluid" />
                </div>
                <div className="pt-4 card-text fs-4">Manage</div>
                <div className="card-text fs-3">Payments</div>
                <div className="card-text fs-6 p-color pb-4 text-capitalize">
                  View and manage licence payments
                </div>
              </CardBody>
              <Link to="/admin/payments" className="text-decoration-none">
                <div
                  className="p-4 text-light"
                  style={{
                    background: "#CE2126",
                    cursor: "pointer",
                    borderBottomLeftRadius: "15px",
                    borderBottomRightRadius: "15px",
                  }}
                >
                  Manage all Payments{" "}
                  <ArrowRight className="float-end" size={18} />
                </div>
              </Link>
            </Card>
          </Col>
        )}

        {userType === DESIGNATION.ADMIN && (
            <Col xl={4} md={6} sm={12} className="mb-2">
              <Card
                  style={{
                    borderRadius: "15px",
                    border: "1px solid red",
                    borderBottom: "none",
                    background: "#FDF6F6",
                  }}
              >
                <CardBody>
                  <div>
                    <img src={Frame5} alt="alt" className="img-fluid" />
                  </div>
                  <div className="pt-4 card-text fs-4">Manage</div>
                  <div className="card-text fs-3">Vet Para Professionals</div>
                  <div className="card-text fs-6 p-color pb-4 text-capitalize">
                    View and manage vet para professionals
                  </div>
                </CardBody>
                <Link to="/admin/vet-para-professional" className="text-decoration-none">
                  <div
                      className="p-4 text-light"
                      style={{
                        background: "#CE2126",
                        cursor: "pointer",
                        borderBottomLeftRadius: "15px",
                        borderBottomRightRadius: "15px",
                      }}
                  >
                    Manage all Vet Para Professionals {" "}
                    <ArrowRight className="float-end" size={18} />
                  </div>
                </Link>
              </Card>
            </Col>
        )}

        {userType === DESIGNATION.ADMIN && (
            <Col xl={4} md={6} sm={12} className="mb-2">
              <Card
                  style={{
                    borderRadius: "15px",
                    border: "1px solid red",
                    borderBottom: "none",
                    background: "#FDF6F6",
                  }}
              >
                <CardBody>
                  <div>
                    <img src={Frame6} alt="alt" className="img-fluid" />
                  </div>
                  <div className="pt-4 card-text fs-4">Manage</div>
                  <div className="card-text fs-3">Billings</div>
                  <div className="card-text fs-6 p-color pb-4 text-capitalize">
                  Manage practitioners billings and make offline transactions
                  </div>
                </CardBody>
                <Link to="/admin/billings" className="text-decoration-none">
                  <div
                      className="p-4 text-light"
                      style={{
                        background: "#CE2126",
                        cursor: "pointer",
                        borderBottomLeftRadius: "15px",
                        borderBottomRightRadius: "15px",
                      }}
                  >
                    Manage all Billings{" "}
                    <ArrowRight className="float-end" size={18} />
                  </div>
                </Link>
              </Card>
            </Col>
        )}
      </Row>

      <div className="text-end pb-5" style={{ paddingTop: "10rem" }}>
        <p className="mb-0">
          <img src={FooterLogo} width="20" className="img-fluid" alt="footer" />{" "}
          Powered by Milsat Technologies
        </p>
      </div>
    </Container>
  );
};

export default AdminDashboard;
