import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Input,
  Label,
  FormGroup,
  FormFeedback,
  Spinner,
  Button,
} from "reactstrap";
import PlaceholderImage from "../assets/images/placeholder.png";

import * as Yup from "yup";
import { useFormik } from "formik";
import { ArrowLeft, X } from "react-feather";
import { Link } from "react-router-dom";
import {
  request,
  scrollToTop,
  uploadID,
  uploadPassport,
} from "../services/utilities";
import { Store } from "../context/store";
import SuccessModal from "./modals/SuccessModal";
import TermsAndPolicy from "./TermsAndPolicy";
import { termsAndCondtion } from "../util/data";
import { PAYMENT_CALLBACK_URL } from "../services/constants";
import PaymentAmountModal from "./modals/PaymentAmountModal";
import Flatpickr from "react-flatpickr";
import { UpdateProfileSelectDropdown } from "./UpdateProfileFormInput";
import ConfirmationModal from "./modals/ConfirmationModal";
import { VeterinarianTabType } from "../util/data";
import AuthLayout from "./layout/AuthLayout";
import LicenseGreen from "../assets/images/licenseGreen.svg";
import NoLicenseIcon from "../assets/images/licenseErrorIcon.svg";

const UpdateProfile = ({ tab = VeterinarianTabType.Update }) => {
  const store = useContext(Store);
  const [allState, setAllState] = store.state;
  const [vcn, setVcn] = useState("");
  const [disabled, setDisabled] = useState(false);
  let [passport, setPassport] = useState(null);
  const [certification, setCertification] = useState(null);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState([]);
  const [errP, setErrP] = useState("");

  // user state
  const [firstname, setFristname] = useState("");
  const [lastname, setLastname] = useState("");
  const [othername, setOthername] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [dob, setDob] = useState("");
  const [picker, setPicker] = useState("");
  const [sex, setSex] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [lga, setLga] = useState("");
  const [idCard, setIdCard] = useState("");
  const [highestQualification, setHighestQualification] = useState("");
  const [title, setTitle] = useState("");
  const [uploadId, setUploadId] = useState(null);
  const [userId, setUserId] = useState("");
  const [isFirstTimeUpdateDone, setIsFirstTimeUpdateDone] = useState(false);
  const [allLga, setAllLga] = useState([]);
  const [loading, setLoading] = useState(false);
  const [bgTxt, setBgTxt] = useState("Update Profile Completed Successfully'");
  const [smTxt, setSmTxt] = useState(
    "Your profile update has been received and is currently reviewed by the council"
  );
  const [products, setProducts] = useState([]);
  const [show, setShow] = useState(false);
  const [showP, setShowP] = useState(false);
  const [showT, setShowT] = useState(false);
  const [amountOwed, setAmountOwed] = useState("");
  const [amount, setAmount] = useState("");
  const [accessStatus, setAccessStatus] = useState("");
  const [productName, setProductName] = useState("Accessories");
  const [productId, setProductId] = useState(null);
  const [licenseStatus, setLicensingStatus] = useState('');
  const [licenseStatusCount, setLicensingStatusCount] = useState(0);
  const [paymentServiceProvider, setPaymentServiceProvider] = useState(null);
  const [tempVcn, setTempVcn] = useState("");
  const [confirmationModalShow, setConfirmationModalShow] = useState(false);
  const query = new URLSearchParams(window.location.search);
  const transacId = query.get("transactionId");

  const vcnNo = query.get("vcn");
  const reference = query.get("reference");
  const DEBT = "Outstanding Debt";

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstname,
      lastname,
      othername,
      email,
      phone: phone !== null ? phone.toString() : "",
      sex,
      dob,
      picker,
      passport,
      previewPassport: "",
      address,
      state,
      lga,
      stateCode: "",
      lgaCode: "",
      idCard,
      highestQualification,
      title,
      certification,
      uploadId,
      terms: false,
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email").email(),
      phone: Yup.string()
        .required("Please Enter Your Phone Number")
        .max(11, "Maximum of 11 number!"),
      sex: Yup.string().required("Please Enter Your Sex"),
      dob: Yup.string().required("Please Enter Your Date of Birth"),
      passport: Yup.string().required("Please Upload Your Passport"),
      address: Yup.string().required("Please Enter Your Address"),
      state: Yup.string().required("Please Enter Your State"),
      lga: Yup.string().required("Please Enter Your LGA"),
      idCard: Yup.string().required("Please Select Your ID "),
      title: Yup.string().required("Please Select Your Practitioner Title"),
      highestQualification: Yup.string().required(
        "Please Select Your Highest Qualification"
      ),
      uploadId: Yup.string().required("Please Upload Your ID"),
      // certification: Yup.string().required("Please Upload Your Certification"),
      terms: Yup.boolean()
        .required("The terms and conditions must be accepted.")
        .oneOf([true], "The terms and conditions must be accepted."),
    }),
    onSubmit: async (e) => {
      scrollToTop();
      setLoading(true);
      const pasP = await uploadPassport(e.passport);
      const upIdc = await uploadID(e.uploadId);
      // const upcert = await  uploadCertification(e.certification);
      const upcert = "";
      await submitForm(e, pasP, upIdc, upcert);
    },
  });
  const submitForm = async (e, pasP, upIdc, upcert) => {
    try {
      const data = {
        userId,
        firstname: e.firstname,
        lastname: e.lastname,
        middleName: e.othername,
        email: e.email,
        phoneNumber: e.phone.toString(),
        sex: e.sex,
        dateOfBirth: e.picker,
        photo: pasP ? pasP : "",
        permanentResidenceAddress: e.address,
        lgaCode: e.lgaCode,
        state: e.state,
        stateCode: e.stateCode,
        residenceLocalGovernmentArea: e.lga,
        recentUtilityBill: "",
        validIdType: e.idCard,
        validId: upIdc ? upIdc : "",
        qualification: e.highestQualification,
        practitionerTitle: e.title,
        highestInstitutionalCertificate: upcert ? upcert : "",
        acceptTermsAndCondition: e.terms,
        // inrelevant data
        countryCode: "+234",
        fingerPrint: "",
        highestForeignInstitutionalCertificate: "",
        licensedYear: "",
        nameOfForeignInstitution: "",
        nameOfInstitution: "",
        nationality: "",
        postalAddress: "",
        qrGeneration: "",
        registrationNumber: "",
        designations: "Veterinarian",
      };
      const url = `Profile/FirstTimeUpdateAccount`;
      const rs = await request(url, "POST", false, data);
      setLoading(false);
      if (rs.hasErrors === true) {
        if (rs.errors) {
          setError(rs.errors);
        }
        if (rs.description) {
          setError([rs.description]);
        }
        if (rs.payload) {
          setError(rs.payload);
        }
        scrollToTop();
      }
      if (rs.code === "OK") {
        setIsFirstTimeUpdateDone(true);
        setShow(true);
        setDisabled(true);
      }
      const timer = setTimeout(() => {
        setError([]);
        setSuccess("");
      }, 3000);
      return () => clearTimeout(timer);
    } catch (err) {
      setLoading(false);
    }
  };
  const verifyVcn = async (e) => {
    if (tempVcn === "" && e === undefined) {
      setError(["VCN Number can not be empty"]);
      msgTimer();
      return;
    }
    setLoading(true);
    try {
      const url = `Profile/GetUserProfileByVcnId?vcnId=${e ? e : tempVcn}`;
      const rs = await request(url, "GET", false);
      if (rs.hasErrors === true) {
        if (rs.errors) {
          setError(rs.errors);
        }
        if (rs.description) {
          setError([rs.description]);
        }
        if (rs.payload) {
          setError(rs.payload);
        }
        scrollToTop();
      }
      if (rs.code === "OK") {
        setVcn(tempVcn);
        fetchLicenseStatus()
        await fetchProducts(rs.payload.amountOwed);
        if (rs.payload.isFirstTimeUpdateDone === true) {
          setDisabled(true);
          updateUserInput(rs.payload);
        } else {
          setDisabled(false);
          updateUserInput(rs.payload);
        }
      }
      msgTimer();
    } catch (err) {
      setLoading(false);
      setError(["Failed to verify vcn number kindly try again later"]);
    }
  };
  const updateUserInput = async (e) => {
    setAmountOwed(e.amountOwed);
    setAccessStatus(e.accessStatus);
    fetchLga(e.state);
    setUserId(e.id);
    setFristname(e.firstName);
    setLastname(e.lastName);
    setOthername(
      e.middleName === "unavailable" || e.middleName === null
        ? ""
        : e.middleName
    );
    setEmail(e.email);
    setPhone(e.phoneNumber);
    setState(e.state);
    setSex(e.sex);
    setDob(e.dateOfBirth.split("T")[0]);
    setPicker(e.dateOfBirth.split("T")[0]);
    setPassport(e.passportPhotograph);
    setAddress(e.permanentResidenceAddress);
    setLga(e.residenceLocalGovernmentArea);
    setIdCard(e.validIdType);
    setHighestQualification(e.qualification);
    setTitle(e.practitionerTitle);
    setCertification(e.uploadHighestInstitutionalCertificate);
    setUploadId(e.uploadValidId);
    setIsFirstTimeUpdateDone(e.isFirstTimeUpdateDone);
    setVcn(e.vcnId);
    setLoading(false);
  };
  // preview passport
  const previewPassport = async (file) => {
    if (!file) {
      return validation.setFieldValue("previewPassport", null);
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      validation.setFieldValue("previewPassport", reader.result);
    };
  };
  const msgTimer = () => {
    const timer = setTimeout(() => {
      setError([]);
      setSuccess("");
    }, 5000);
    return () => clearTimeout(timer);
  };

  const makePaymentWithRemita = async () => {
    setLoading(true);
    const data = {
      userId,
      currency: "NGN",
      callBackUrl: "",
      amount,
      productId,
    };
    const url = "payment/InitiateRemittaPayment";
    try {
      const rs = await request(url, "POST", false, data);
      setLoading(false);
      if (rs.code === "OK") {
        const data = {
          key: rs.payload.key,
          transactionId: rs.payload.transactionId,
          firstName: firstname,
          lastName: lastname,
          amount: rs.payload.amount,
          email,
          narration: "Testing",
        };
        payWithRemita(data);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  const makePaymentWithPaystack = async (amt) => {
    setLoading(true);
    setShowP(false);
    const NEW_PAYSTACK_CALLBACK_URL = PAYMENT_CALLBACK_URL.split(
      "/verify-payment"
    ).join(`/veterinarian?tab=update-profile?vcn=${vcn}`);
    const data = {
      userId,
      currency: "NGN",
      productId: productId,
      callBackUrl: `${NEW_PAYSTACK_CALLBACK_URL}`,
      amount: amt ? amt : amount,
    };
    const url = "payment/InitiatePaystackPayment";
    try {
      const rs = await request(url, "POST", false, data);
      setLoading(false);
      if (rs.code === "OK") {
        window.open(rs.payload, "_blank");
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const payWithRemita = (e) => {
    let data = { ...e };
    window.makePayment(data, vcn);
    setShowP(false);
  };

  const verifyPaymentOnRemita = useCallback(async (e) => {
    setLoading(true);
    try {
      const url = `payment/verifyTransaction?reference=${e}&paymentServiceProvider=${"remitta"}`;
      const rs = await request(url, "GET", true);
      setLoading(false);
      if (rs.code === "OK") {
        setVcn(vcnNo);
        await verifyVcn(vcnNo);
        setBgTxt("Payment Completed Successfully");
        setSmTxt("Payment Successfully Received");
        setShow(true);
      }
    } catch (err) {
      setLoading(false);
    }
  }, []);

  const verifyPaymentOnPaystack = useCallback(async (e) => {
    setLoading(true);
    try {
      const url = `payment/verifyTransaction?reference=${e}&paymentServiceProvider=${"paystack"}`;
      const rs = await request(url, "GET", true);
      if (rs.code === "OK") {
        setBgTxt("Payment Completed Successfully");
        setSmTxt("Payment Successfully Received");
        setShow(true);
        setVcn(vcnNo);
        const timer = setTimeout(() => {
          verifyVcn(vcnNo);
        }, 3000);
        return () => clearTimeout(timer);
      }
    } catch (err) {
      setLoading(false);
    }
  }, []);

  const handleShowConfirmationModal = () => {
    setConfirmationModalShow(true);
  };

  const initiatePayment = () => {
    if (paymentServiceProvider === "Paystack") {
      makePaymentWithPaystack();
    } else if (paymentServiceProvider === "Remitta") {
      makePaymentWithRemita();
    } else {
      console.error("Invalid payment service provider");
    }
  };

  const fetchState = useCallback(async () => {
    try {
      const url = "Locations/GetAllState";
      const rs = await request(url, "GET", false);
      setAllState(rs.payload);
    } catch (err) {}
  }, [setAllState]);
  const fetchLga = useCallback(async (e) => {
    try {
      const url = `Locations/GetLGA?state=${e}`;
      const rs = await request(url, "GET", false);
      setAllLga(rs.payload);
    } catch (err) {}
  }, []);

  const fetchProducts = useCallback(
    async (debt, productCategoryType = "Accessories", isActive = true) => {
      try {
        const url = `product/getProducts?ProductCategoryType=${productCategoryType}&IsActive=${isActive}`;
        const rs = await request(url, "GET", false);
        if (rs.code === "OK") {
          const newProducts = [...rs.payload.items];
          // if (debt > 0) {
          //   newProducts.push({
          //     id: 1,
          //     name: "DEBT",
          //     description: "",
          //     price: null,
          //     paymentServiceProvider: "Remitta"
          //   });
          // }
          setProducts(newProducts);
        }
      } catch (err) {
        console.error(err); // Error logging
      }
    },
    []
  );

  const currentYear = new Date().getFullYear();

  const fetchLicenseStatus = useCallback(async () => {
    setLoading(true);
    const url = `BillingStructure/GetBillings?VcnId=${tempVcn}&PaymentCompleted=${false}&ProductCategoryType=PractiseLicense`;
    try {
      const rs = await request(url, "GET", true);
      setLoading(false);
      if (rs.code === "OK") {
        // Determine licensing status
        setLicensingStatusCount(rs.payload.items.length)
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }, [tempVcn]);

  const handleFilter = (e, id, price, product) => {
    setProductName(e);
    setProductId(id);
    setAmount(price);
    setPaymentServiceProvider(product.paymentServiceProvider);
  };

  useEffect(() => {
    if (reference) {
      verifyPaymentOnPaystack(reference);
    } else if (transacId) {
      verifyPaymentOnRemita(transacId);
    }

    fetchState();
  }, [
    fetchState,
    reference,
    transacId,
    verifyPaymentOnPaystack,
    verifyPaymentOnRemita,
  ]);

  useEffect(() => {
    if (tab === VeterinarianTabType.Verify) {
      setDisabled(true);
    }
  }, [tab]);

  return (
      <AuthLayout>
        <Container>
          <div className="pt-4">
            <Row>
              <div className="d-flex align-items-center justify-content-between">
                <Link
                    to={'/'}
                    className="mb-3 btn text-decoration-none"
                    style={{background: "#EFEFEF"}}
                >
                  <ArrowLeft size={16}/> Back
                </Link>

                <p className="fs-2 fw-bold mb-0 m-0">Update Profile</p>

                <div />
              </div>

              <Col>
                <div className="float-end">
                  {loading ? <Spinner color="danger" /> : ""}
                </div>
              </Col>
            </Row>
            <Row className="w-100">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  {vcn === "" ? (
                      ""
                  ) : licenseStatusCount === 0 ? (
                      <div className="border-0 " role="alert"
                           style={{
                             background: "#40DD7F1A",
                             color: "#1AB759",
                             padding: '0.5em',
                             borderRadius: '7px'
                           }}>
                        <img
                            src={LicenseGreen}
                            alt="licence"
                            className="img-fluid"
                            style={{width: '1.3em', height: '1.3em', marginRight: '0.5em'}}
                        />
                        {`Licenced to Practice`}
                      </div>
                  ) : (
                      <div className="border-0" role="alert"
                           style={{
                             background: '#FDF5F5',
                             color: '#CE2126',
                             padding: '0.5em',
                             borderRadius: '7px'
                           }}>
                        <img
                            style={{width: '1.3em', height: '1.3em', marginRight: '0.5em'}}
                            src={NoLicenseIcon}
                            alt="licence"
                            className="img-fluid"
                        />
                        {`Not Licensed to Practice For ${currentYear}`}
                      </div>
                  )}
                </div>

                <Col className="text-center">
                  {error?.map((e) => {
                    return (
                        <div
                            key={e}
                            className="alert w-100 text-capitalize alert-danger alert-dismissible border-2 p-1 fade show"
                            role="alert"
                            style={{ background: "#fff", fontSize: "14px" }}
                        >
                          {e}
                        </div>
                    );
                  })}
                  {success ? (
                      <div
                          className="alert w-100 text-capitalize text-center alert-success alert-dismissible border-2 p-1 fade show"
                          role="alert"
                          style={{ background: "#fff", fontSize: "14px" }}
                      >
                        {success}
                      </div>
                  ) : (
                      ""
                  )}
                </Col>

                {tab === VeterinarianTabType.Update && amountOwed !== "" ? (
                    <Col>
                      <Row
                          className={`mt-4 alert text-center text-capitalize ${
                              amountOwed <= 0 ? "alert-success" : ""
                          } alert-dismissible border-0 fade show`}
                          role="alert"
                          style={{ fontSize: "16px", padding: "12px" }}
                      >
                        <Col xl={6}>
                          {amountOwed < 0 ?
                              `You have an extra ₦${new Intl.NumberFormat("en-US").format(Math.abs(amountOwed))} in your account` : ''
                          }
                        </Col>

                        <Col>
                          <div className=" d-flex justify-content-end">
                            <Button
                                className="border-0 login-btn"
                                disabled={!productId}
                                onClick={() => {
                                  if (productName === DEBT) {
                                    setShowP(true);
                                  } else {
                                    initiatePayment();
                                  }
                                }}
                            >
                              Pay Now
                            </Button>

                            <div className="mx-2">
                              <UpdateProfileSelectDropdown
                                  defaultOption={"Accessories"}
                                  selectedValue={productName}
                                  options={products}
                                  handleFilter={(e, id, price, product) => {
                                    handleFilter(e, id, price, product);
                                  }}
                                  overideStyle="border-danger bglightred text-black"
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                ) : (
                    ""
                )}
              </div>

            </Row>
            <div
                className="vcn pb-4 pt-4"
                style={{ borderBottom: "1px solid #B0B0B0" }}
            >
              <Row className="align-items-center">
                <Col xl={4} sm={12} md={6}>
                  <FormGroup className="mb-0">
                    <Label htmlFor="val0">VCN Number</Label>
                    <Input
                        name="vcnId"
                        placeholder="VCN Number"
                        type="text"
                        className="form-control"
                        value={tempVcn}
                        id="val0"
                        onChange={(e) => setTempVcn(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col xl={2} sm={12} md={6}>
                  <FormGroup style={{ marginTop: "30px" }} className="mb-3">
                    <button
                        onClick={() => verifyVcn()}
                        className="btn text-light p-2 login-btn"
                        type="submit"
                    >
                      Verify VCN
                    </button>
                  </FormGroup>
                </Col>
              </Row>
            </div>

          <Form
            className="pt-4"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div
              className="personal-information pb-4"
              style={{ borderBottom: "1px solid #B0B0B0" }}
            >
              <p className="fw-semibold fs-5">Personal Information</p>
              <div
                  style={{borderBottom: '1px solid #B0B0B0'}}
                  className="bg-white mb-3 user-passport mb-4 d-flex align-items-end pb-4">
                <div
                  style={{
                    width: "",
                    boxShadow: "0 1px 2px rgba(16,24,60,.05)",
                    padding: "7px",
                    backgroundColor: "#FAE9E9",
                  }}
                  className="d-flex justify-content-center align-items-center rounded-circle"
                >
                  <img
                    style={{
                      width: '7em',
                      height: '7em',
                    }}
                    src={
                      passport
                        ? passport
                        : validation.values.previewPassport
                        ? validation.values.previewPassport
                        : PlaceholderImage
                    }
                    className="rounded-circle"
                    alt="user passport"
                  />
                </div>
                <div className="mx-3">
                  <FormGroup className="">
                    <Label htmlFor="val8">Upload Passport Photograph </Label>
                    <Input
                      name="passport"
                      type="file"
                      className="form-control"
                      disabled={disabled}
                      id="val8"
                      onChange={(e) => {
                        previewPassport(e.currentTarget.files[0]);
                        validation.setFieldValue(
                          "passport",
                          e.currentTarget.files[0]
                        );
                      }}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.passport &&
                        validation.errors.passport
                          ? true
                          : false
                      }
                    />
                    {validation.touched.passport &&
                    validation.errors.passport ? (
                      <FormFeedback type="invalid">
                        {validation.errors.passport}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </div>
              </div>
              <Row>
                <Row>
                  <Col xl={4} sm={12} md={6}>
                    <FormGroup className="mb-3">
                      <Label htmlFor="val15">Practitioner Title </Label>
                      <Input
                          name="title"
                          type="select"
                          className="form-control"
                          disabled={disabled}
                          id="val15"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.title || ""}
                          invalid={
                            validation.touched.title && validation.errors.title
                                ? true
                                : false
                          }
                      >
                        <option>Select Practitioner Title</option>
                        <option>Prof.</option>
                        <option>Dr.</option>
                      </Input>
                      {validation.touched.title && validation.errors.title ? (
                          <FormFeedback type="invalid">
                            {validation.errors.title}
                          </FormFeedback>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>

                <Col xl={4} sm={12} md={6}>
                  <FormGroup className="mb-3">
                    <Label htmlFor="val1">First Name</Label>
                    <Input
                      name="firstname"
                      placeholder="First Name"
                      type="text"
                      className="form-control"
                      id="val1"
                      disabled
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.firstname || ""}
                      invalid={
                        validation.touched.firstname &&
                        validation.errors.firstname
                          ? true
                          : false
                      }
                    />
                    {validation.touched.firstname &&
                    validation.errors.firstname ? (
                      <FormFeedback type="invalid">
                        {validation.errors.firstname}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col xl={4} sm={12} md={6}>
                  <FormGroup className="mb-3">
                    <Label htmlFor="val2">Last Name</Label>
                    <Input
                      name="lastname"
                      placeholder="Last Name"
                      type="text"
                      className="form-control"
                      id="val2"
                      disabled
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.lastname || ""}
                      invalid={
                        validation.touched.lastname &&
                        validation.errors.lastname
                          ? true
                          : false
                      }
                    />
                    {validation.touched.lastname &&
                    validation.errors.lastname ? (
                      <FormFeedback type="invalid">
                        {validation.errors.lastname}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col xl={4} sm={12} md={6}>
                  <FormGroup className="mb-3">
                    <Label htmlFor="val3">Other Name</Label>
                    <Input
                      name="othername"
                      placeholder="Other Name"
                      type="text"
                      className="form-control"
                      disabled
                      id="val3"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.othername || ""}
                      invalid={
                        validation.touched.othername &&
                        validation.errors.othername
                          ? true
                          : false
                      }
                    />
                    {validation.touched.othername &&
                    validation.errors.othername ? (
                      <FormFeedback type="invalid">
                        {validation.errors.othername}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xl={4} sm={12} md={6}>
                  <FormGroup className="mb-3">
                    <Label htmlFor="val4">Email</Label>
                    <Input
                      name="email"
                      placeholder="Email"
                      disabled={disabled}
                      type="text"
                      className="form-control"
                      id="val4"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.email || ""}
                      invalid={
                        validation.touched.email && validation.errors.email
                          ? true
                          : false
                      }
                    />
                    {validation.touched.email && validation.errors.email ? (
                      <FormFeedback type="invalid">
                        {validation.errors.email}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col xl={4} sm={12} md={6}>
                  <FormGroup className="mb-3">
                    <Label htmlFor="val5">Phone Number</Label>
                    <Input
                      name="phone"
                      placeholder="Phone Number"
                      type="number"
                      className="form-control"
                      maxLength="11"
                      disabled={disabled}
                      id="val5"
                      onChange={(e) => {
                        validation.setFieldValue("phone", e.target.value);
                      }}
                      onBlur={validation.handleBlur}
                      value={validation.values.phone || ""}
                      invalid={
                        validation.touched.phone && validation.errors.phone
                          ? true
                          : false
                      }
                    />
                    {validation.touched.phone && validation.errors.phone ? (
                      <FormFeedback type="invalid">
                        {validation.errors.phone}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="mb-3">
                    <Label htmlFor="val6">Sex</Label>
                    <Input
                      name="sex"
                      type="select"
                      className="form-control"
                      disabled={disabled}
                      id="val6"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.sex || ""}
                      invalid={
                        validation.touched.sex && validation.errors.sex
                          ? true
                          : false
                      }
                    >
                      <option>Select Sex</option>
                      <option>Male</option>
                      <option>Female</option>
                      <option>Prefer Not To Say</option>
                    </Input>
                    {validation.touched.sex && validation.errors.sex ? (
                      <FormFeedback type="invalid">
                        {validation.errors.sex}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xl={4} sm={12} md={6}>
                  <FormGroup className="mb-3">
                    <Label htmlFor="val7">Date of Birth (dd/mm/yyy) </Label>
                    {disabled === true ? (
                      <Input
                        className="form-control"
                        disabled={disabled}
                        type="date"
                        value={dob}
                      />
                    ) : (
                      <Flatpickr
                        name="dob"
                        id="val7"
                        className="form-control"
                        placeholder="dd/mm/yyyy"
                        style={{
                          backgroundColor:
                            disabled === true ? "#e9ecef!important" : "",
                        }}
                        value={validation.values.dob || ""}
                        onChange={(selectedDates, dateStr) => {
                          if (!selectedDates.length) return;
                          const ISODate = selectedDates[0].toISOString(); // iso date str
                          const utct = ISODate.split("T")[1];
                          const newDate = `${dateStr}T${utct}`;
                          validation.setFieldValue("dob", ISODate);
                          validation.setFieldValue("picker", newDate);
                        }}
                        options={{
                          altInput: true,
                          altFormat: "F j, Y",
                          dateFormat: "Y-m-d",
                          maxDate: new Date(),
                          disabled,
                        }}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.dob && validation.errors.dob
                            ? true
                            : false
                        }
                      />
                    )}

                      {validation.touched.dob && validation.errors.dob ? (
                          <FormFeedback type="invalid">
                            {validation.errors.dob}
                          </FormFeedback>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <div className="address pb-3"
                   style={{ borderBottom: "1px solid #B0B0B0" }}
              >
                <p className="pt-3 fw-semibold fs-5 pb-2">Address</p>
                <Row>
                  <Col xl={4} sm={12} md={6}>
                    <FormGroup className="mb-3">
                      <Label htmlFor="val9">Permanent Residence Address</Label>
                      <Input
                          name="address"
                          type="text"
                          className="form-control"
                          placeholder="Permanent Residence"
                          disabled={disabled}
                          id="val9"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.address || ""}
                          invalid={
                            validation.touched.address && validation.errors.address
                                ? true
                                : false
                          }
                      />
                      {validation.touched.address && validation.errors.address ? (
                          <FormFeedback type="invalid">
                            {validation.errors.address}
                          </FormFeedback>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col xl={4} sm={12} md={6}>
                    <FormGroup className="mb-3">
                      <Label htmlFor="val10">Practise State </Label>
                      <Input
                          name="state"
                          type="select"
                          className="form-control"
                          disabled={disabled}
                          id="val10"
                          placeholder="Select State"
                          onChange={(e) => {
                            const stateId = document.querySelector("#val10");
                            const state_code =
                                stateId.options[stateId.selectedIndex].getAttribute(
                                    "statecode"
                                );
                            validation.setFieldValue("stateCode", state_code);
                            validation.handleChange(e);
                            fetchLga(e.target.value);
                          }}
                          onBlur={validation.handleBlur}
                          value={validation.values.state || ""}
                          invalid={
                            validation.touched.state && validation.errors.state
                                ? true
                                : false
                          }
                      >
                        <option>Select State</option>
                        {allState?.map((e) => {
                          return (
                              <option
                                  id={e.state__name}
                                  statecode={e.state__id}
                                  key={e.state__id}
                              >
                                {e.state__name}
                              </option>
                          );
                        })}
                      </Input>
                      {validation.touched.state && validation.errors.state ? (
                          <FormFeedback type="invalid">
                            {validation.errors.state}
                          </FormFeedback>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col xl={4} sm={12} md={6}>
                    <FormGroup className="mb-3">
                      <Label htmlFor="val11">Practise LGA </Label>
                      <Input
                          name="lga"
                          type="select"
                          className="form-control"
                          disabled={disabled}
                          id="val11"
                          placeholder="Select LGA"
                          onChange={(e) => {
                            const lgaId = document.querySelector("#val11");
                            const lga_code =
                                lgaId.options[lgaId.selectedIndex].getAttribute(
                                    "lgacode"
                                );
                            validation.setFieldValue("lgaCode", lga_code);
                            validation.handleChange(e);
                          }}
                          onBlur={validation.handleBlur}
                          value={validation.values.lga}
                          invalid={
                            validation.touched.lga && validation.errors.lga
                                ? true
                                : false
                          }
                      >
                        <option>{"Select LGA"}</option>
                        {allLga?.map((e) => {
                          return (
                              <option lgacode={e["LGA CODE"]} key={e["LGA CODE"]}>
                                {e["LGA NAME"]}
                              </option>
                          );
                        })}
                      </Input>
                      {validation.touched.lga && validation.errors.lga ? (
                          <FormFeedback type="invalid">
                            {validation.errors.lga}
                          </FormFeedback>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
              </div>

                <div
                    className="Identification pb-3"
                    style={{ borderBottom: "1px solid #B0B0B0" }}
                >
                  <p className="fw-semibold fs-5 pt-3  pb-2">Identification</p>
                  <Row>
                    <Col xl={4} sm={12} md={6}>
                      <FormGroup className="mb-3">
                        <Label htmlFor="val13">Select ID </Label>
                        <Input
                            name="idCard"
                            type="select"
                            className="form-control"
                            id="val13"
                            placeholder="Upload Id Card"
                            disabled={disabled}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.idCard || ""}
                            invalid={
                              validation.touched.idCard && validation.errors.idCard
                                  ? true
                                  : false
                            }
                        >
                          <option>Select ID</option>
                          <option>National ID</option>
                          <option>Drivers Licence</option>
                          <option>International Passport</option>
                          <option>Others</option>
                        </Input>
                        {validation.touched.idCard && validation.errors.idCard ? (
                            <FormFeedback type="invalid">
                              {validation.errors.idCard}
                            </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                </div>

              <div
                  className="education pb-2"
                  style={{ borderBottom: "1px solid #B0B0B0" }}
              >
                <p className="fw-semibold fs-5 pt-3 pb-2">
                  Education & Qualification
                </p>
                <Row>
                  <Col xl={4} sm={12} md={6}>
                    <FormGroup className="mb-3">
                      <Label htmlFor="val15">Highest Qualification </Label>
                      <Input
                          name="highestQualification"
                          type="select"
                          className="form-control"
                          disabled={disabled}
                          id="val15"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.highestQualification || ""}
                          invalid={
                            validation.touched.highestQualification &&
                            validation.errors.highestQualification
                                ? true
                                : false
                          }
                      >
                        <option>Select Highest Qualification</option>
                        <option>Doctor of Veterinary Medicine (D.V.M)</option>
                        <option>Doctor of Philosophy (PhD)</option>
                        <option>Masters</option>
                        <option>Others</option>
                      </Input>
                      {validation.touched.highestQualification &&
                      validation.errors.highestQualification ? (
                          <FormFeedback type="invalid">
                            {validation.errors.highestQualification}
                          </FormFeedback>
                      ) : null}
                    </FormGroup>
                  </Col>

                  <Col xl={4} sm={12} md={6}>
                    <FormGroup className="mb-3">
                      <Label htmlFor="val14">Upload ID </Label>
                      <Input
                          name="uploadId"
                          type="file"
                          className="form-control"
                          id="val14"
                          disabled={disabled}
                          placeholder="Upload  ID"
                          onChange={(e) => {
                            validation.setFieldValue(
                                "uploadId",
                                e.currentTarget.files[0]
                            );
                          }}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.uploadId &&
                            validation.errors.uploadId
                                ? true
                                : false
                          }
                      />
                      {validation.touched.uploadId &&
                      validation.errors.uploadId ? (
                          <FormFeedback type="invalid">
                            {validation.errors.uploadId}
                          </FormFeedback>
                      ) : null}
                    </FormGroup>
                  </Col>

                  <Col xl={4} sm={12} md={6} className="d-none">
                    <FormGroup className="mb-4">
                      <Label htmlFor="val16">Upload Certification </Label>
                      <Input
                          name="certification"
                          type="file"
                          className="form-control"
                          id="val16"
                          placeholder="Upload Certification"
                          disabled={disabled}
                          onChange={(e) => {
                            validation.setFieldValue(
                                "certification",
                                e.currentTarget.files[0]
                            );
                          }}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.certification &&
                            validation.errors.certification
                                ? true
                                : false
                          }
                      />
                      {validation.touched.certification &&
                      validation.errors.certification ? (
                          <FormFeedback type="invalid">
                            {validation.errors.certification}
                          </FormFeedback>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <Row>
                {tab === VeterinarianTabType.Update && (
                    <Col sm={12}>
                      <div className="checkBox mt-4">
                        <Label
                            className="form-check-label"
                            htmlFor="terms&condition"
                        >
                          <Input
                              type="checkbox"
                              className="form-check-input checkBox"
                              name="terms"
                              id="terms&condition"
                              checked={
                                disabled === true ? true : validation.values.terms
                              }
                              onChange={(e) => {
                                validation.handleChange(e);
                              }}
                              disabled={disabled}
                              onBlur={validation.handleBlur}
                              value={validation.values.terms || ""}
                              invalid={
                                validation.touched.terms && validation.errors.terms
                                    ? true
                                    : false
                              }
                          />
                          {validation.touched.terms && validation.errors.terms ? (
                              <FormFeedback type="invalid">
                                {validation.errors.terms}
                              </FormFeedback>
                          ) : null}
                          I agree with the
                          <Link
                              to="#"
                              onClick={(e) => {
                                e.preventDefault()
                                setShowT(!showT)}
                              }
                              className="textRed text-decoration-none"
                          >
                            {" "}
                            Terms and Condition
                          </Link>
                        </Label>
                      </div>
                    </Col>
                )}
                {tab === VeterinarianTabType.Update && (
                    <Col sm={12}>
                      <div className="mt-4 float-end">
                        <button
                            disabled={
                                !validation.isValid ||
                                !validation.dirty ||
                                disabled === true
                            }
                            className="btn text-light login-btn"
                            type="submit"
                        >
                          Update Profile
                        </button>
                      </div>
                    </Col>
                )}
              </Row>
            </Form>
          </div>
        </Container>


        <SuccessModal show={show} setShow={setShow} bgTxt={bgTxt} smTxt={smTxt} />
        <TermsAndPolicy
            show={showT}
            setShow={setShowT}
            termsData={termsAndCondtion}
        />
        <PaymentAmountModal
            show={showP}
            setShow={setShowP}
            amount={amount}
            makePayment={initiatePayment}
            setAmount={setAmount}
            amountOwed={amountOwed}
            errP={errP}
            setErrP={setErrP}
        />
        <ConfirmationModal
            show={confirmationModalShow}
            setShow={setConfirmationModalShow}
            onConfirmation={() => {
              if (productName === DEBT) {
                setShowP(true);
              } else {
                makePaymentWithPaystack();
              }
            }}
        />
      </AuthLayout>
  );
};

export default UpdateProfile;
