import Navbar from "../components/Navbar";
import DashboardComponent from "../components/Dashboard";
import { useAuthContext } from "../context/UserProvider";
import React, { useEffect } from "react";
import Footer from "../components/shared/Footer";


const  Dashboard = () => {
  const {getUser} = useAuthContext();

  useEffect(() => {
    getUser();
  },[]);

  return (
      <>
        <div className="d-flex p-0 flex-column justify-content-between container-fluid" style={{ height: '100vh'}}>
          <Navbar />
          <div style={{paddingTop: "6rem"}}>
            <DashboardComponent />
          </div>
          <Footer />
        </div>
      </>
  );
};

export default Dashboard;
