import React from 'react';
import { Card, CardBody } from 'reactstrap';

const InformationCard = ({ info }) => {
  return (
      <Card className="information-card">
        <CardBody>
          {info.map((item, index) => {
            const [label, value] = Object.entries(item)[0];

            // Modify label and value based on specific conditions
            let modifiedLabel = label;
            let modifiedValue = value;
            if (label === 'cacRegNumber') {
              modifiedLabel = 'CAC Registration No';
              modifiedValue = `RC: ${value}`;
            } else if (label === 'premiseType') {
              modifiedLabel = 'Premise Type';
            } else if (label === 'clinicalServicesType') {
              modifiedLabel = 'Service Offered';
            } else if (label === 'phoneNumber') {
              modifiedLabel = 'Phone number';
            } else if (label === 'emailAddress') {
              modifiedLabel = 'Facility Email Address';
            } else if (label === 'address') {
              modifiedLabel = 'Address';
            } else if (label === 'lgaName') {
              modifiedLabel = 'LGA';
            } else if (label === 'stateName') {
              modifiedLabel = 'State';
            }

            return (
                <div key={index} className="information-row">
                  <span className="label">{modifiedLabel}</span>
                  <span className="value">{modifiedValue}</span>
                </div>
            );
          })}
        </CardBody>
      </Card>
  );
};

export default InformationCard;
