const DownloadIcon = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        d="M11.292 15.706C11.3849 15.7991 11.4952 15.873 11.6167 15.9234C11.7382 15.9738 11.8685 15.9998 12 15.9998C12.1315 15.9998 12.2618 15.9738 12.3833 15.9234C12.5048 15.873 12.6151 15.7991 12.708 15.706L16.708 11.706C16.8902 11.5174 16.991 11.2648 16.9887 11.0026C16.9864 10.7404 16.8812 10.4896 16.6958 10.3042C16.5104 10.1188 16.2596 10.0136 15.9974 10.0113C15.7352 10.009 15.4826 10.1098 15.294 10.292L13 12.586V4C13 3.73478 12.8946 3.48043 12.7071 3.29289C12.5196 3.10536 12.2652 3 12 3C11.7348 3 11.4804 3.10536 11.2929 3.29289C11.1054 3.48043 11 3.73478 11 4V12.586L8.707 10.293C8.61475 10.1975 8.50441 10.1213 8.3824 10.0689C8.2604 10.0165 8.12918 9.9889 7.9964 9.98775C7.86362 9.9866 7.73194 10.0119 7.60905 10.0622C7.48615 10.1125 7.3745 10.1867 7.2806 10.2806C7.18671 10.3745 7.11246 10.4861 7.06218 10.609C7.0119 10.7319 6.9866 10.8636 6.98775 10.9964C6.9889 11.1292 7.01649 11.2604 7.0689 11.3824C7.12131 11.5044 7.19749 11.6148 7.293 11.707L11.292 15.706Z"
        fill="currentColor"
      />
      <path
        d="M19 14C18.7348 14 18.4804 14.1054 18.2929 14.2929C18.1054 14.4804 18 14.7348 18 15V18C18 18.2652 17.8946 18.5196 17.7071 18.7071C17.5196 18.8946 17.2652 19 17 19H7C6.73478 19 6.48043 18.8946 6.29289 18.7071C6.10536 18.5196 6 18.2652 6 18V15C6 14.7348 5.89464 14.4804 5.70711 14.2929C5.51957 14.1054 5.26522 14 5 14C4.73478 14 4.48043 14.1054 4.29289 14.2929C4.10536 14.4804 4 14.7348 4 15V18C4 18.7956 4.31607 19.5587 4.87868 20.1213C5.44129 20.6839 6.20435 21 7 21H17C17.7956 21 18.5587 20.6839 19.1213 20.1213C19.6839 19.5587 20 18.7956 20 18V15C20 14.7348 19.8946 14.4804 19.7071 14.2929C19.5196 14.1054 19.2652 14 19 14Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default DownloadIcon;
