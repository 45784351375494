import Navbar from "../components/Navbar";
import VetParaProfessionalComponent from "../components/VetParaProfessional";

const VetParaProfessional = () => {
  return (
      <>
        <Navbar />
        <div style={{ paddingTop: "7rem" }}>
          <VetParaProfessionalComponent />
        </div>
      </>
  );
};

export default VetParaProfessional;