const FilterIcon = (props) => (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.133 5.6L9.989 12.5L10 17L14 20L14.011 12.5L19.867 5.6C19.9786 5.45116 20.0465 5.27413 20.0631 5.08881C20.0796 4.90349 20.0441 4.71724 19.9605 4.55099C19.877 4.38475 19.7487 4.24511 19.5902 4.14777C19.4316 4.05043 19.2491 3.99926 19.063 4H4.937C4.75095 3.99926 4.56838 4.05043 4.40982 4.14777C4.25126 4.24511 4.123 4.38475 4.03946 4.55099C3.95592 4.71724 3.92042 4.90349 3.93695 5.08881C3.95347 5.27413 4.02137 5.45116 4.133 5.6Z"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  
  export default FilterIcon ;
  