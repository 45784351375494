import React, { useState, useEffect, useCallback } from 'react';
import { Modal, ModalHeader, ModalBody, Input, Spinner, Button, FormGroup, Label, FormFeedback } from 'reactstrap';
import ReactPaginate from 'react-paginate';
import {ChevronDown, X} from "react-feather";
import WarningIcon from "../../assets/images/warningIcon.svg";
import CloseIcon from "../../assets/images/closeIcon.svg";

const PractitionerSelectionModal = ({
  request,
  invalid,
  disabled,
  onBlur,
  className = "form-control rounded-lg",
  errors,
  touched,
  onSelectionChange,
}) => {
  const [practitioners, setPractitioners] = useState([]);
  const [selectedPractitioners, setSelectedPractitioners] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  const fetchPractitioners = useCallback(async () => {
    setLoading(true);
    try {
      const url = `profile/getusers?PageSize=15&${!searchTerm ? `PageNumber=${currentPage}` : ""}&${searchTerm ? `vcnId=${searchTerm}` : ""}`;
      const rs = await request(url, "GET", true); // Assuming request is a function for API calls
      const filteredPractitioners = rs.payload?.items.filter(practitioner => practitioner.designation !== 'VeterinarianParaProfessional');
      setPractitioners(filteredPractitioners || []);
      setTotalPages(rs.payload?.pageCount);
    } catch (err) {
      console.error("Failed to fetch practitioners:", err);
    } finally {
      setLoading(false);
    }
  }, [currentPage, searchTerm, request]);

  const toggleModal = () => setModalOpen(!modalOpen);

  useEffect(() => {
    fetchPractitioners();
  }, [fetchPractitioners]);

  useEffect(() => {
    // This will send the selected practitioners back to the parent component whenever the selection changes.
    if (onSelectionChange) {
      onSelectionChange(selectedPractitioners);
    }
  }, [selectedPractitioners, onSelectionChange]);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  const toggleSelection = (practitioner) => {
    const isSelected = selectedPractitioners.find(p => p.id === practitioner.id);
    if (isSelected) {
      setSelectedPractitioners(selectedPractitioners.filter(p => p.id !== practitioner.id));
    } else {
      setSelectedPractitioners([...selectedPractitioners, practitioner]);
    }
  };


  const removePractitioner = (practitionerId) => {
    setSelectedPractitioners(selectedPractitioners.filter(p => p.id !== practitionerId));
  };

  const inputStyle = {
    backgroundColor: "#F5F5F5",
    border: "1px solid #EBEBEB",
  };

  return (
      <>
        <div
            className={`pills-container d-flex 
            ${selectedPractitioners.length > 0 || errors ? 'selected' : ''} ${disabled ? 'disabled' : ''} 
            ${className} ${invalid ? "is-invalid" : ""} `}
            style={inputStyle}
            onBlur={onBlur}
            onClick={() => setModalOpen(!modalOpen)} // Corrected to toggle modal visibility
        >
          {selectedPractitioners.length > 0 ? (
              selectedPractitioners.map((practitioner, index) => (
                  <div key={index} className="pills-button d-flex align-items-center justify-content-between">
                    {practitioner.fullName} {/* Display the practitioner's name */}
                    <img
                        src={CloseIcon}
                        className="img-fluid mx-1"
                        style={{width: '1.25em', height: '1.25em', cursor: "pointer"}}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent modal from toggling
                          removePractitioner(practitioner.id)
                        }}
                        alt={"close"}
                    />
                  </div>
              ))
          ) : (
              <div className="d-flex align-items-center justify-content-between w-100">
                <span className="text">Select Practitioners</span>
                {errors ? <img src={WarningIcon} width="16" height="16" /> : <ChevronDown className="dropdown-icon" />}
              </div>
          )}
          {touched && errors ? (
              <FormFeedback type="invalid">
                {errors}
              </FormFeedback>
          ) : null}
        </div>

        <Modal isOpen={modalOpen} toggle={toggleModal} size="lg">
          <ModalHeader toggle={toggleModal}>Select Practitioners</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="searchTerm">Search Practitioners</Label>
              <div className='d-flex w-100 gap-3'>
                <Input
                    id="searchTerm"
                    name="searchTerm"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Enter VCN Number"
                    type="text"
                />
                <button
                    type="button"
                    className="login-btn p-2 text-white"
                    onClick={() => setCurrentPage(1)}
                >
                  Search
                </button>
              </div>
            </FormGroup>
            {loading ? <Spinner/> : (
                <>
                  {practitioners.map(practitioner => (
                      <div key={practitioner.id} className="d-flex justify-content-between align-items-center p-0 m-4">
                        <div className='text-black'>{practitioner.fullName}, {practitioner.vcnId}</div>
                        <Input
                            type="checkbox"
                            className="form-check-input checkBox"
                            checked={selectedPractitioners.includes(practitioner)}
                            onChange={() => toggleSelection(practitioner)}
                            style={{borderRadius: '0.25em', width: '1em', height: '1em'}}
                        />
                      </div>
                  ))}
                  <ReactPaginate
                      previousLabel={'<'}
                      nextLabel={'>'}
                      breakLabel={'...'}
                      pageCount={totalPages}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={'pagination justify-content-center'}
                      activeClassName={'active'}
                  />
                </>
            )}
            <div className='d-flex justify-content-center p-0 w-100'>
              <button
                  className="w-50 login-btn text-light p-2 fs-6"
                  style={{borderRadius: '1em'}}
                  onClick={toggleModal}
              >
                Done
              </button>
            </div>
          </ModalBody>
        </Modal>
      </>
  );
};

export default PractitionerSelectionModal;
