const CaretIcon = (props) => (
  <svg
    width={12}
    height={12}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.71682 4.1175C9.64115 3.93472 9.51297 3.77849 9.3485 3.66857C9.18402 3.55866 8.99064 3.49999 8.79282 3.5H3.20682C3.00907 3.50004 2.81577 3.55871 2.65136 3.6686C2.48695 3.77848 2.35881 3.93465 2.28314 4.11735C2.20747 4.30005 2.18767 4.50108 2.22623 4.69503C2.2648 4.88898 2.36001 5.06715 2.49982 5.207L5.29282 8C5.48035 8.18747 5.73465 8.29279 5.99982 8.29279C6.26498 8.29279 6.51929 8.18747 6.70682 8L9.49982 5.207C9.63965 5.0672 9.7349 4.88908 9.77352 4.69515C9.81215 4.50123 9.79241 4.30021 9.71682 4.1175Z"
      fill="currentColor"
    />
  </svg>
);

export default CaretIcon;