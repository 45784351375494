import React from 'react';
import { Modal, ModalBody } from 'reactstrap';

const ImageViewModal = ({ isOpen, toggle, imageUrl }) => {
  return (
      <Modal isOpen={isOpen} toggle={toggle} centered size="lg">
        <ModalBody className="d-flex justify-content-center align-items-center" style={{ backgroundColor: 'white' }}>
          <img
              src={imageUrl}
              alt="Enlarged View"
              style={{ height: '100%', width: '50%' }}
              onClick={toggle} // Close modal when image is clicked
          />
        </ModalBody>
      </Modal>
  );
};

export default ImageViewModal;
