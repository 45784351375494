import React from "react";
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";

const ConfirmationModal = ({ show, setShow, onConfirmation }) => {
    return (
        <>
            <Modal isOpen={show} className="modalw">
                <ModalHeader
                    style={{ borderBottom: "none" }}
                    className="modal-title pb-0 fw-bold"
                    toggle={() => {
                        setShow(!show);
                    }}
                >
                    Payment Confirmation
                </ModalHeader>
                <ModalBody>
                    <p>
                        Ensure that your payment shows up in the app before you close it. It's
                        best to use your card to make this payment.
                    </p>
                    <div className="d-flex justify-content-end">
                        <Button
                            id="bgred"
                            className="btn text-light"
                            onClick={() => {
                                setShow(!show);
                                onConfirmation(); // Call the provided function on confirmation
                            }}
                        >
                            OK
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};

export default ConfirmationModal;
