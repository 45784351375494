import { Card, CardBody, Col, Row, Spinner } from 'reactstrap';
import { Container } from 'react-bootstrap';
import EmptyImg from '../assets/images/empty.png';
import { useLocation } from 'react-router-dom';
import React, {useCallback, useEffect, useState} from 'react';
import { request, scrollToTop, } from '../services/utilities';
import ReactPaginate from 'react-paginate';
import AddOnsTable from "../util/AddOnsTable";

const AddOns = ({onAddonsCountChange}) => {
  const [ totalPages, setTotalPages ] = useState( 0 );
  const [ currentPage, setCurrentPage ] = useState( 0 );
  const [ totalItemsCount, setTotalItemsCount ] = useState( 0 );
  const [ firstItemOnPage, setFirstItemOnPage ] = useState( 0 );
  const [ lastItemOnPage, setLastItemOnPage ] = useState( 0 );

  const [ addOnsItems, setAddOnsItems] = useState([]);
  const [ loading, setLoading ] = useState( false );
  const [ currentCount, setCurrentCount ] = useState( 0 );
  const [ error, setError ] = useState( '' );

  const clearError = () => {
    const timer = setTimeout( () => setError( [] ), 3000 );
    return () => clearTimeout( timer );
  };

  const fetchAddOns = useCallback(
      async (debt, productCategoryType = "Accessories", isActive = true) => {
        setLoading(true);
        const url = `Product/GetProducts?ProductCategoryType=${productCategoryType}&IsActive=${isActive}`;
    try {
      const rs = await request(url, "GET", true);
      setLoading(false);
      if (rs.code === "OK") {
        setAddOnsItems(rs.payload.items);
        setTotalItemsCount( rs.payload.totalItemCount ); //rs.payload.totalItemCount;
        setTotalPages( rs.payload.pageCount ); // rs.payload.pageCount
        setLastItemOnPage( rs.payload.lastItemOnPage );
        setFirstItemOnPage( rs.payload.firstItemOnPage );
      }
    } catch (err) {
      setLoading(false);
      setError( 'Failed to get addOns kindly try again later' );
      clearError();
      console.log(err);
    }
  }, []);


  const handlePageChange = ( selectedPage ) => {
    scrollToTop();
    setCurrentCount( currentCount + 1 );
    setCurrentPage( selectedPage.selected + 1 );
  };

  useEffect(() => {
      fetchAddOns();
  }, [fetchAddOns]);

  useEffect(() => {
    if (onAddonsCountChange) {
      onAddonsCountChange(addOnsItems.length);
    }
  }, [addOnsItems, onAddonsCountChange]);

  return (
      <Container>
        <Row>
          <Card className='border-none border-white table-container'>
            <CardBody className='p-0'>
              {error.length >= 1 && (
                  <div
                      className='float-end alert text-capitalize alert-danger alert-dismissible border-2 p-1 fade show'
                      role='alert'
                      style={{background: '#fff', fontSize: '14px'}}
                  >
                    {error}
                  </div>
              )}
              <div className='float-end'>
                {loading ? <Spinner color='danger'/> : ''}
              </div>


              <Row className='table-responsive'>
                <div className=''>
                  { addOnsItems.length >= 1 ? (
                      <AddOnsTable
                          data={ addOnsItems }
                          pending={ false }
                      />
                  ) : (
                      <div>
                        <div>
                          <AddOnsTable
                              data={ addOnsItems.length >= 1 ? addOnsItems : [] }
                              pending={ false }
                          />
                          <div className='text-center pt-5'>
                            <img loading="lazy" src={ EmptyImg } className='img-fluid' alt='empty' />
                            <p className='p-color pt-2'>No Record to show</p>
                          </div>
                        </div>
                      </div>
                  ) }
                  { addOnsItems.length > 1 ? (
                      <div className='d-flex mt-5  justify-content-center'>
                        <ReactPaginate
                            breakLabel='...'
                            nextLabel='next >'
                            onPageChange={ handlePageChange }
                            pageRangeDisplayed={ 5 }
                            pageCount={ totalPages }
                            previousLabel='< previous'
                            // forcePage={currentPage}
                            containerClassName={ 'pagination' }
                            previousLinkClassName={ 'pagination__link' }
                            nextLinkClassName={ 'pagination__link' }
                            disabledClassName={ 'pagination__link--disabled' }
                            activeClassName={ 'pagination__link--active' }
                        />
                      </div>
                  ) : (
                      ''
                  ) }
                </div>
              </Row>
            </CardBody>
          </Card>
        </Row>
      </Container>
  );
};

export default AddOns;
