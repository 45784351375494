import React, { useState } from "react";
import {
  Form,
  Label,
  Spinner,
} from "reactstrap";
import AlertIcon from "../assets/icons/AlertIcon";
import CancelIcon from "../assets/icons/CancelIcon";
import * as Yup from "yup";
import { useFormik } from "formik";
import Mail from "../assets/images/mail.svg";
import { request } from "../services/utilities";
import AuthLayout from "./layout/AuthLayout";
import CustomInput from "./shared/CustomInput";
import { Link } from "react-router-dom";

const ResetPasssword = () => {
  const [error, setError] = useState([]);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      vcnId: "",
    },
    validationSchema: Yup.object({
      vcnId: Yup.number().required("Please Enter Your VCN Number"),
    }),
    onSubmit: async (e) => {
      setError([]);
      setLoading(true);
      const data = { vcnId: e.vcnId };
      try {
        const url = `profile/ForgotPassword`;
        const rs = await request(url, "POST", false, data);
        setLoading(false);
        if (rs.hasErrors === true) {
          if (rs.errors) {
            setError(rs.errors);
          }
          if (rs.description) {
            setError([rs.description]);
          }
          if (rs.payload) {
            setError(rs.payload);
          }

          window.scrollTo({ top: 0, behavior: "smooth" });
        }
        if (rs.code === "OK") {
          setSuccess(true);
        }
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    },
  });

  return (
      <AuthLayout>
        <div className="login-error-container">
          {error.length > 0 && (
              <div
                  className="login-error-alert  w-100 text-capitalize border-1"
                  role="alert"
              >
                <div className="gap-3 d-flex w-100 ">
                  <AlertIcon />
                  <div className="d-flex flex-column gap-2 justify-content-between" >
                    {error.map((e) => {
                      return (
                          <h3
                              key={e}
                              className="fs-4 fw-semibold"
                          >
                            {e}
                          </h3>
                      );
                    })}
                    <p className="" >Contact the admin for help</p>
                  </div>
                </div>
                <CancelIcon
                    onClick={() => setError([])}
                    style={{ cursor: "pointer" }}
                />
              </div>
          )}
        </div>
        {success ? (
            <SuccessResetPassword vcnId={validation.values.vcnId} />) : (
            <main className="layout-wrapper h-50 m-auto">
              <div className="d-flex justify-content-center">
                <h1 className="fs-1 fw-bold">Forgot Password ?</h1>
              </div>
              <Form
                  className="pt-4"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
              >
                <div className="mb-3">
                  <Label htmlFor="validationCustom01">VCN Number</Label>
                  <CustomInput
                      name="vcnId"
                      placeholder="Enter your VCN Number"
                      type="text"
                      id="validationCustom01"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.vcnId || ""}
                      errors={validation.errors.vcnId}
                      touched={validation.touched.vcnId}
                      invalid={
                        validation.touched.vcnId && validation.errors.vcnId
                            ? true
                            : false
                      }
                  />
                </div>
                <div className="w-100" style={{ padding: "0.5em 0 2em 0", }}>
                  <button
                      className="w-100 login-btn py-2 text-light fw-normal"
                      type="submit"
                  >
                    {loading ? <Spinner size={"xs"} color="white" /> : " Reset Password"}
                  </button>
                </div>
                <Link to="/login" className="text-center mt-5 fs-6 text-grey text-decoration-none">
                  <p className="mb-0">Return to <span className="textred">Login</span></p>
                </Link>
              </Form>
            </main>
        )}
      </AuthLayout>
  );
};

export default ResetPasssword;


const SuccessResetPassword = ({
    vcnId
  }) => {
  return (
      <main className="layout-wrapper h-75 m-auto text-center">
        <img
            src={Mail}
            className="img-fluid"
            style={{ maxWidth: "70%", height: "auto" }}
            alt="logo"
        />
        <h2 className="fw-semibold fs-1 pt-5 pb-4 text-main-black">Check your mail box</h2>
        <div>
          <p className="text-neutral-400">A reset link for <span className="textred">VCN ID: {vcnId}</span> account has been sent to your mail</p>
        </div>
        <div className="d-flex justify-content-center  mt-5 gap-5 mx-4">
          <h4 className="fw-semibold fs-5 text-neutral-300">Didn't receive the mail?</h4>
          <a href="/login" className="text-center fs-6 text-grey text-decoration-none">
            <p className="mb-0">Return to <span className="textred">Login</span></p>
          </a>
        </div>
      </main>
  )
}
