import { createContext, useState, useContext, useEffect } from "react";
import SSRStorage from "../services/storage";
import { USER_COOKIE } from "../services/constants";
const storage = new SSRStorage();

const AuthContext = createContext();

export const useAuthContext = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  // store all user's information
  const [userInfo, setUserInfo] = useState(null);

  const getUser = async () => {
    try {
      const loggedInUser = await storage.getItem(USER_COOKIE);
      const newUserInfo = {
        firstName: loggedInUser?.user?.firstname,
        lastName: loggedInUser?.user?.lastname,
        token: loggedInUser?.token,
        userType: loggedInUser?.user?.userType,
        userId: loggedInUser?.user?.sub,
      };
      setUserInfo(newUserInfo);
    } catch (error) {
      console.error("Error fetching user information:", error);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        getUser,
        userInfo,
        userType: userInfo?.userType,
        token: userInfo?.token,
        userId: userInfo?.userId,
        userFirstName: userInfo?.firstName,
        userLastName: userInfo?.lastName,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
