import React from "react";
import { Modal, ModalBody, ModalHeader, Col, Input, Button } from "reactstrap";
import ErrorImg from "../../assets/images/error.png";

const ErrorModal = ({ show, setShow, bgTxt, smTxt, smTxt2, serverError, note }) => {
  return (
    <>
      <Modal isOpen={show} centered className="modalw">
        <ModalHeader
          style={{ borderBottom: "none" }}
          className="modal-title pb-0 fw-bold"
          toggle={() => {
            setShow(!show);
          }}
        ></ModalHeader>
        <ModalBody className="">
          <form action="#">
            <div className="row g-3">
              <Col xxl={12}>
                <div className="text-center">
                  <img src={ErrorImg} className="img-fluid" alt={"error"} />
                </div>
              </Col>
              <Col xxl={12}>
                <div className="mb-2 text-center">
                  <strong>
                    <h5>{bgTxt}!!!</h5>
                  </strong>
                  <div className="p-color">{smTxt}</div>
                  <div className="p-color text-light-emphasis">{smTxt2}</div>
                </div>
                {
                    note && <div className="p-color text-light-emphasis my-3"><strong className="text-danger">Note: </strong>{note}</div>
                  }
                {serverError.length >= 1 && (
                  <div
                    style={{ fontSize: "" }}
                    className="p-color text-light-emphasis"
                  >
                    <strong> Errors:</strong>
                    {serverError.map((e) => {
                      return (
                        <li
                          key={e}
                          className="text-danger"
                          style={{ fontSize: "14px" }}
                        >
                          {e}
                        </li>
                      );
                    })}
                  </div>
                )}
                <div></div>
              </Col>

              <Col lg={12}>
                <div className=" gap-2 pb-3 text-center">
                  <button
                    id="bgred"
                    type="button"
                    className="btn w-75 text-light"
                    onClick={() => setShow(!show)}
                  >
                    Ok
                  </button>
                </div>
              </Col>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ErrorModal;
