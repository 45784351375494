import { Fragment } from "react";
import { Check } from "react-feather";

export const Toast = ({ tsmt = "", tbgt = "Success" }) => (
  <Fragment>
    <div className="toastify-header">
      <div className="title-wrapper">
        <Check color="success" size={12} />
        <h6 className="toast-title text-success">{tbgt}!</h6>
      </div>
    </div>
    <div className="toastify-body">
      <span role="img" aria-label="toast-text">
        {tsmt}
      </span>
    </div>
  </Fragment>
);
