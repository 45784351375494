import Navbar from "../components/Navbar";
import AdminPaymentsComponent from "../components/AdminPayments";

const Payment = () => {
  return (
      <>
        <Navbar />
        <div style={{ paddingTop: "7rem" }}>
          <AdminPaymentsComponent />
        </div>
      </>
  );
};

export default Payment;