import { Modal, ModalBody, ModalHeader, Col, Input } from "reactstrap";

const FileUploadActionModal = ({
     show,
     setShow,
     setFile,
     file,
     handleAction,
     title,
     message,
     subMessage,
     buttonText,
     children
   }) => {
  return (
      <>
        <Modal isOpen={show} centered className="w-100">
          <ModalHeader
              style={{ borderBottom: "none" }}
              className="modal-title pb-0 fw-bold"
              toggle={() => setShow(!show)}
          >
            {title}
          </ModalHeader>
          <ModalBody>
            <p className="fs-6 text-light-emphasis">{message}</p>
            <p className="fs-6 text-light-emphasis">{subMessage}</p>
            <form>
              <div className="row g-3">
                <Col xxl={12}>
                  <div>
                    <Input
                        type="file"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        className="form-control"
                        onChange={(e) => setFile(e.target.files[0])}
                        id="amount"
                    />
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="hstack gap-2 pb-3 justify-content-end">
                    {children}

                    <button
                        id="bgred"
                        type="button"
                        disabled={!file}
                        className="btn text-light"
                        onClick={handleAction}
                    >
                      {buttonText}
                    </button>
                  </div>
                </Col>
              </div>
            </form>
          </ModalBody>
        </Modal>
      </>
  );
};

export default FileUploadActionModal;
