import { Card, CardBody, Col, Input, Row, Spinner } from "reactstrap";
import { Container } from "react-bootstrap";
import FooterLogo from "../assets/images/footer.svg";
import PremiseTable from "../util/PremiseTable";
import { Plus, Search } from "react-feather";
import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ArrowLeft } from "react-feather";
import EmptyImg from "../assets/images/empty.png";
import { Link } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import { PremiseGroupOptions, approvalStatusType } from "../util/data";
import { currentDate, notifyToast, request,getDateISOFormat, scrollToTop } from "../services/utilities";
import { useAuthContext } from "../context/UserProvider"
import { Select } from "../components/FormInput";
import {CustomButton} from "../components/shared/CustomButton"
import ReactPaginate from "react-paginate";
import { DESIGNATION } from '../services/constants';
import { Store } from "../context/store";
import Download from "./shared/Download";

const AdminPremises = () => {
  const store = useContext(Store);
  const [user] = store.user;
  const { userType } = useAuthContext();
  const [premises, setPremises] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [firstItemOnPage, setFirstItemOnPage] = useState(0);
  const [lastItemOnPage, setLastItemOnPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [activateFilter, setActivateFilter] = useState(false);
  const [clearFilter, setClearFilter] = useState(false);
  const [params, setParams] = useState({
    approvalStatus: "",
    fromDate: "",
    toDate: "",
  });
  const [currentCount, setCurrentCount] = useState(0);
  const [vcn, setVcn] = useState("");

  const location = useLocation().pathname;

  const fetchPremise = useCallback(
    async (vcn) => {
      setLoading(true);
      try {
        const url = `premises/getpremises?pageSize=20&pageNumber=${currentPage}&${params.approvalStatus && `ApprovalStatus=${params.approvalStatus}`}&&${
          params.toDate && `ToDate=${params.toDate}`
        }&${params.fromDate && `FromDate=${params.fromDate}`}&${
          vcn ? `managingDirectorVCNID=${vcn}` : ""
        }`;
        const rs = await request(url, "GET", true);
        setLoading(false);
        setTotalItemsCount(rs.payload.totalItemCount); //rs.payload.totalItemCount;
        setLastItemOnPage(rs.payload.lastItemOnPage);
        setFirstItemOnPage(rs.payload.firstItemOnPage);
        setPremises(rs.payload.items);
        setTotalPages(rs.payload.pageCount); // rs.payload.pageCount
      } catch (err) {
        setLoading(false);
      }
    },
    [currentPage, params]
  );

  const handleDownloadRecords = useCallback(async (type, pageSize = 20) => {
    setLoading(true);
    if (userType === !DESIGNATION.ADMIN) return;
    else {
      try {
        const url = `Premises/GetPremiseRecordExcelSheet?pageSize=${pageSize}&pageNumber=${currentPage}&${params.approvalStatus && `ApprovalStatus=${params.approvalStatus}`}&${
          params.toDate && `ToDate=${params.toDate}`
        }&${params.fromDate && `FromDate=${params.fromDate}`}&${
          vcn && `&vcnID=${vcn}`
        }`;
        const response = await request(url, "GET", true, null, "file");
        let outputFilename = "";
        if (type === "all") {
          outputFilename = `All_${
            params.approvalStatus && `_${approvalStatusType[params.approvalStatus]}`
          }${
            params.fromDate &&
            `_for_(${new Date(params.fromDate).toDateString()}`
          }${
            params.toDate
            && ` to ${new Date(params.toDate).toDateString()})`
          }_Premises_(${currentDate}).xlsx`;
        } else {
          outputFilename = `Page_${currentPage}_${
            params.approvalStatus && `_${approvalStatusType[params.approvalStatus]}`
          }${
            params.fromDate &&
            `_for_(${new Date(params.fromDate).toDateString()}`
          }${
            params.toDate
            && ` to ${new Date(params.toDate).toDateString()})`
          }_Premises_(${currentDate}).xlsx`;
        }
        const blobUrl = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = blobUrl;
        link.setAttribute("download", outputFilename);
        document.body.appendChild(link);
        link.click();
        setLoading(false);
        notifyToast("success", "Downloaded successfully");
      } catch (err) {
        setLoading(false);
        notifyToast("error", "Failed to download premises");
      }
    }
  }, [currentPage, params, vcn, userType]);

  // Set the total number of records to be downloaded
  const handlePageDownload = (type) => {
    let recordSize = 0;
    if (type === "All records") {
      recordSize = totalItemsCount;
      handleDownloadRecords("all", recordSize);
    }
    if (type === "Current page records") {
      recordSize = 20;
      handleDownloadRecords("one", recordSize);
    }
  };

  const handlePageChange = (selectedPage) => {
    setCurrentCount(currentCount + 1);
    setCurrentPage(selectedPage.selected + 1);
  };
  const handleClearFilter = () => {
    setCurrentPage(1);
    setClearFilter(false);
    setParams({
      approvalStatus: "",
      fromDate: "",
      toDate: "",
    });
  };

  
  const handleFilter = (e, group) => {
    if (group === "Approval Status") {
      setParams({
        approvalStatus: e,
        fromDate: params.fromDate,
        toDate: params.toDate,
      });
    }else{
      setParams({
        approvalStatus: "",
        fromDate: "",
        toDate: "",
      });
    }
  }

  const handleActiveFilter = () => {
    if (activateFilter) {
      handleClearFilter()
    }
    setActivateFilter(!activateFilter);
  };

  useEffect(() => {
    fetchPremise();
  }, [fetchPremise]);

  return (
    <Container>
      <Row>
        <Card className="border-none border-white">
          <Row className="d-flex align-items-center">
            <Col>
              <h1 className="h1-color mb-0">My Premise</h1>
            </Col>
            <Col className="d-flex justify-content-end align-items-center">
              <Link
                to={
                  location === "/admin/premises"
                    ? "/admin/dashboard"
                    : "/dashboard"
                }
                className="btn text-decoration-none"
                id="bggrey"
              >
                <ArrowLeft size={16} />
                Back
              </Link>
            </Col>
          </Row>
          <p className="text-muted pt-3 fs-6 text-capitalize">
            {" "}
            This is a Directory of all registered premises
          </p>
          <div className="d-flex justify-content-end w-100">
            <div>{loading ? <Spinner color="danger" /> : ""}</div>
          </div>
          <CardBody className="p-0">
          <Col>
                {/* firstItemOnPage lastItemOnPage */}
                <p className="p-color fs-5 mx-2">
                  {" "}
                  {premises.length >= 1
                    ? `${firstItemOnPage}-${lastItemOnPage} / ${totalItemsCount}`
                    : "0"}{" "}
                  premises
                </p>
              </Col>
            <Row className="mt-5 mb-2">
              <Col xl={location === "/admin/payments" ? 5 : 5} sm={12} md={5}>
                <div className="d-flex flex-xl-row flex-sm-column flex-md-row gap-4 mb-3">
                  <CustomButton
                    name="Filter"
                    iconType
                    icon="filter"
                    color={activateFilter ? "red" : "white"}
                    action={handleActiveFilter}
                  />
                </div>
              </Col>
              {userType === DESIGNATION.ADMIN && (
                <Col className="mb-3 float-xl-end" sm={12} md={12} xl={3}>
                  <div className="d-flex justify-content-xl-end">
                    <div className="search-box fs-6">
                    <input
                      type="number"
                      className="form-control  search-input"
                      placeholder="Search Premises by VCN Number"
                      onChange={(e) => {
                        setVcn(e.target.value);
                        if (e.target.value === "") fetchPremise();
                      }}
                    />
                      <Search size={15} className="search-icon" />
                    </div>
                  </div>
                </Col>
              )}
              {userType === DESIGNATION.ADMIN && (
                <Col xl={4} sm={12} md={12}>
                  <div className="d-flex justify-content-xl-end mb-3">
                    <div
                      className="search-box fs-6"
                      onClick={() => fetchPremise(vcn)}
                    >
                      <button
                        style={{ paddingLeft: "30px" }}
                        className="btn text-light"
                        id="bgred"
                        placeholder="Search with VCN NUmber"
                      >
                        Search
                      </button>
                      <Search size={15} color="white" className="search-icon" />
                    </div>
                  </div>
                </Col>
              )}
            </Row>
            <Row className="mt-2">
              {activateFilter && (
                <Row className="d-flex gap-2">
                  {PremiseGroupOptions.map((premiseGroup, index) => {
                    return (
                      <Col xl={2} sm={12} md={4} key={index}>
                        <Select
                          defaultOption={premiseGroup.group}
                          selectedValue={params[`${premiseGroup.filterName}`]}
                          options={premiseGroup.options}
                          handleFilter={(e) => {
                            setClearFilter(true);
                            handleFilter(e, premiseGroup.group);
                          }}
                          overideStyle="border-danger bglightred text-black"
                        />
                      </Col>
                    );
                  })}
                  <Col xl={3} sm={12} md={4}>
                    <Flatpickr
                      id="val7"
                      className={`form-control border-danger rounded-pill ${params.fromDate ? "bgred text-white": " bglightred text-black"}`}
                      placeholder="Select Date Range"
                      value={
                        params.fromDate && params.toDate
                          ? [params.fromDate, params.toDate]
                          : params.fromDate && !params.toDate
                          ? [params.fromDate, ""]
                          : ["", ""]
                      }
                      options={{
                        mode: "range",
                        maxDate: new Date(),
                        dateFormat: "Y-m-d",
                        defaultDate: [""],
                      }}
                      onChange={(date) => {
                        setClearFilter(true);
                        if (!date) return;
                        if (date.length === 1) {
                          const fromDate = getDateISOFormat(date[0]);
                          return setParams({
                            approvalStatus: params.approvalStatus,   
                            fromDate: fromDate,
                            toDate: "",
                          });
                        } else {
                          const startDate = getDateISOFormat(date[0]);
                          const toDate = true;
                          const endDate = getDateISOFormat(date[1], toDate);
                          return setParams({
                            approvalStatus: params.approvalStatus,
                            fromDate: startDate,
                            toDate: endDate,
                          });
                        }
                      }}
                    />
                  </Col>
                  <Col
                    xl={3}
                    sm={12}
                    md={8}
                    className="d-flex gap-3 mt-2 mt-md-0"
                  >
                    {clearFilter && (
                      <CustomButton
                        name="Clear Filter"
                        color="lightRed"
                        action={handleClearFilter}
                        overideStyle="rounded-pill"
                      />
                    )}
                    <CustomButton
                      name="Close Filter"
                      color="lightRed"
                      action={handleActiveFilter}
                      overideStyle="rounded-pill"
                    />
                  </Col>
                </Row>
              )}
            </Row>
            <Row className="mt-3">
              <Col>
                {/* Show Export button for only Admin */}
                {userType === DESIGNATION.ADMIN && (
                  <div className="mb-2">
                    <div className="d-flex align-items-center justify-content-end relative">
                      <Download
                        currentPage={currentPage}
                        isDropdownPostionEnd={true}
                        handleDownloadRecords={handlePageDownload}
                      />
                    </div>
                  </div>
                )}
              </Col>
            </Row>
            <div className="mt-4">
              {premises.length >= 1 ? (
                <PremiseTable data={premises} />
              ) : (
                <div>
                  <div>
                    <PremiseTable data={premises.length >= 1 ? premises : []} />
                    <div className="text-center pt-5">
                      <img src={EmptyImg} className="img-fluid" alt="empty" />
                      <p className="p-color pt-2">No Record to show</p>
                      <p className="p-color pt-2">
                        Click the Add Practitioner button on the top <br />
                        right to add new practitioner profile
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {premises.length > 1 ? (
                <div className="d-flex mt-5  justify-content-center">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel="next >"
                    onPageChange={handlePageChange}
                    pageRangeDisplayed={5}
                    pageCount={totalPages}
                    previousLabel="< previous"
                    // forcePage={currentPage}
                    containerClassName={"pagination"}
                    previousLinkClassName={"pagination__link"}
                    nextLinkClassName={"pagination__link"}
                    disabledClassName={"pagination__link--disabled"}
                    activeClassName={"pagination__link--active"}
                  />
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="text-end " style={{ paddingTop: "5rem" }}>
              <p className="mb-5">
                <img
                  src={FooterLogo}
                  width="20"
                  className="img-fluid"
                  alt="footer"
                />{" "}
                Powered by Milsat Technologies
              </p>
            </div>
          </CardBody>
        </Card>
      </Row>
    </Container>
  );
};

export default AdminPremises;
