import { Modal, ModalBody, ModalHeader, Col, Input } from "reactstrap";

const OnboardingModal = ({
  show,
  setShow,
  setFile,
  title="Bulk Onboarding/Uploading",
  description="OnboardingUpload multiple entries from csv file",
  subDescription="Select file containing practitioners/transaction",
  btnName = "Onboard/Upload",
  file,
  handleOnboarding,
  children
}) => {
  return (
    <>
      <Modal isOpen={show} centered className="w-100">
        <ModalHeader
          style={{ borderBottom: "none" }}
          className="modal-title pb-0 fw-bold"
          toggle={() => {
            setShow(!show);
          }}
        >
          {title}
        </ModalHeader>
        <ModalBody className="">
          <p className="fs-6 text-light-emphasis">
            {description}
          </p>
          <form action="#">
            <div className="row g-3">
              <Col xxl={12}>
                <div>
                  <label
                    htmlFor="amount"
                    className="form-label fs-6 text-light-emphasis"
                  >
                    {subDescription}
                  </label>
                  <Input
                    type="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    className="form-control"
                    onChange={(e) => setFile(e.target.files[0])}
                    id="amount"
                    placeholder="Enter Amount"
                  />
                </div>
              </Col>
              <Col lg={12}>
                <div className="hstack gap-2 pb-3 justify-content-end">
                  {children}

                  <button
                    id="bgred"
                    type="button"
                    disabled={file ? false : true}
                    className="btn text-light"
                    onClick={handleOnboarding}
                  >
                    {btnName}
                  </button>
                </div>
              </Col>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default OnboardingModal;
