import { useEffect, useState } from "react";
import SortImg from "../assets/images/arrowsort.png";
import UserImage from "../assets/images/user.png";
import { Badge } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Table } from "reactstrap";

function PremiseTable({ data }) {
  const [sortKey, setSortKey] = useState("last_name");
  const [sortOrder, setSortOrder] = useState("ascn");
  const [premiseData, setPremiseData] = useState([]);
  const navigate = useNavigate();
  function sortData({ tableData, sortKey, reverse }) {
    if (!sortKey) return tableData;

    const sortedData = data.sort((a, b) => {
      return a[sortKey] > b[sortKey] ? 1 : -1;
    });

    if (reverse) {
      return sortedData.reverse();
    }

    return sortedData;
  }

  function SortButton({ onClick }) {
    return (
      <span
        id="sort"
        style={{ cursor: "pointer" }}
        className="mx-2"
        onClick={onClick}
      >
        <img src={SortImg} className="img-fluid float-end" alt="sort" />
      </span>
    );
  }
  const headers = [
    { key: "managingDirectorVCNID", label: "VCN Number" },
    { key: "facilityName", label: "Facility Name" },
    { key: "phoneNumber", label: "Phone Number" },
    { key: "emailAddress", label: "Email" },
    { key: "dateCreated", label: "Date Created" },
  ];

    // updates the table based on updated data 
    useEffect(() => {
      if(data !== premiseData){
        setPremiseData(sortData({ tableData: data, sortKey, reverse: sortOrder === "desc" }));
      }
    }, [data, premiseData, sortKey, sortOrder]);
  

  function changeSort(key) {
    setSortOrder(sortOrder === "ascn" ? "desc" : "ascn");
    setSortKey(key);
    setPremiseData(sortData({ tableData: data, sortKey, reverse: sortOrder === "desc" }));
  }

  return (
    <div className="fixTableHead">
      <Table
        responsive
        id="table-border-collapse"
        className="table table-borderless table-hover align-middle table-nowrap headerFixed"
      >
        <thead className="admin-table-red headerFixed">
          <tr>
            {headers.map((row) => {
              return (
                <th
                  scope="col"
                  className="text-nowrap headerFixed"
                  key={row.key}
                  style={{ padding: "12px" }}
                >
                  {row.label}{" "}
                  <SortButton
                    columnKey={row.key}
                    onClick={() => changeSort(row.key)}
                    {...{
                      sortOrder,
                      sortKey,
                    }}
                  />
                </th>
              );
            })}
          </tr>
        </thead>

        <tbody>
          {premiseData.map((e) => {
            return (
              <tr
                key={e.id}
                onClick={() => navigate(`/premises/${e.ownerId}/${e.id}`)}
                style={{ cursor: "pointer" }}
              >
                {/* <td className='text-nowrap' style={{padding:"12px"}}>
            <img src={e.imageUrl ? e.imageUrl : UserImage} className="mx-2" width='35' height='35' style={{borderRadius:"50%",border:'1px solid #fff'}} alt='user'/>
            {e.fullName}
            </td> */}
                <td className="text-nowrap">
                  {e.managingDirectorVCNID || "--"}
                </td>
                <td className="text-nowrap">{e.facilityName || "--"}</td>
                <td className="text-nowrap">{e.phoneNumber || "--"}</td>
                <td className="text-nowrap">{e.emailAddress || "--"}</td>
                <td className="text-nowrap">
                  {new Date(e.dateCreated).toDateString() || "--"}
                </td>

                {/* <td className='text-nowrap'> ₦{new Intl.NumberFormat('en-US').format(e.amountOwed)}</td> */}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

export default PremiseTable;
