import React, {useCallback, useContext, useEffect, useState} from 'react';
import TabComponent from "./misc/TabComponent";
import Billings from "./Billings";
import { Row, Container } from 'reactstrap';
import PaymentHeader from "./PaymentHeader";
import { request } from '../services/utilities';
import {useAuthContext} from "../context/UserProvider";
import {Store} from "../context/store";
import Payment from "./Payment";
import AddOns from "./AddOns";
import {useLocation} from "react-router-dom";

const PaymentSection = () => {
  const store = useContext(Store);
  const [user] = store.user;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tabQueryParam = queryParams.get('tab');
  const [activeTab, setActiveTab] = useState(tabQueryParam);
  const [paymentCount, setPaymentCount] = useState(null);
  const [addOnsCount, setAddOnsCount] = useState(null);
  const handlePaymentCount = (count) => {
    setPaymentCount(count);
  };
  const handleAddOnsCount = (count) => {
    setAddOnsCount(count);
  };
  const { userInfo } = useAuthContext();

  const [billings, setBillings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [ billingItems, setBillingItems] = useState([]);
  const [premiseLicenseCount, setPremiseLicenseCount] = useState(0);
  const [practiseLicenseCount, setPractiseLicenseCount] = useState(0);
  const [accessoriesCount, setAccessoriesCount] = useState(0);
  const [totalAmountOwedSum, setTotalAmountOwedSum] = useState(0);
  const tabs = [
    { label: 'Pending Bills', content: <Billings />, headerTab: 'Pending-Bills', activeTabNumber: billings.length  },
    { label: 'Add-Ons', content: <AddOns onAddonsCountChange={handleAddOnsCount} />, headerTab: 'Add-Ons', activeTabNumber: addOnsCount },
    { label: 'All Payments', content: <Payment onPaymentCountChange={handlePaymentCount} />, headerTab: 'All-Payments', activeTabNumber: paymentCount },
  ];

  const clearError = () => {
    const timer = setTimeout( () => setError( [] ), 3000 );
    return () => clearTimeout( timer );
  };

  const fetchBillings = useCallback(async () => {
    setLoading(true);
    const url = `BillingStructure/GetBillings?VcnId=${user?.vcnId}&PaymentCompleted=false`;
    try {
      const rs = await request(url, "GET", true);
      setLoading(false);
      if (rs.code === "OK") {
        setBillings(rs.payload.items);
      }
    } catch (err) {
      setLoading(false);
      setError( 'Failed to get billings kindly try again later' );
      clearError();
      console.log(err);
    }
  }, [user]);

  const fetchProductsAndAddBilling = useCallback(async (billingsArr) => {
    setLoading(true);
    const productDetailsCache = new Map();
    let updatedItemsPromises = billingsArr.map(async (item) => {
      try {
        if (!productDetailsCache.has(item.productId)) {
          const url = `Product/GetProducts?Id=${item.productId}`;
          const response = await request(url, "GET", true);
          if (response.code === "OK") {
            const productData = response.payload.items[0];
            productDetailsCache.set(item.productId, productData);
          } else {
            throw new Error('Product fetch failed');
          }
        }

        const productData = productDetailsCache.get(item.productId);

        return {
          ...item,
          name: productData?.name,
          paymentServiceProvider: productData?.paymentServiceProvider,
          productCategoryType: productData?.productCategoryType,
        };
      } catch (error) {
        console.error("Error fetching product data:", error);
        return item;
      }
    });

    try {
      const updatedItems = await Promise.all(updatedItemsPromises);
      setBillingItems(updatedItems);
    } catch (err) {
      setError('Failed to process billing information');
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [billings]);

  const updateCountsAndSum = (items) => {
    let premiseCount = 0;
    let practiseCount = 0;
    let accessoriesCount = 0;
    let totalSum = 0;

    items.forEach(item => {
      if (item.productCategoryType === 'PremiseLicense') {
        premiseCount++;
      } else if (item.productCategoryType === 'PractiseLicense') {
        practiseCount++;
      } else if (item.productCategoryType === 'Accessories') {
        accessoriesCount++;
      }
      totalSum += item.totalAmountOwed;
    });

    setPremiseLicenseCount(premiseCount);
    setPractiseLicenseCount(practiseCount);
    setAccessoriesCount(accessoriesCount);
    setTotalAmountOwedSum(totalSum);
  };


  const cardData =  [
    { title: 'Pending Payments', body: billings.length },
    { title: 'Pending Practice License Payments', body: practiseLicenseCount },
    { title: 'Pending Premise License Payments', body: premiseLicenseCount },
    { title: 'Total Pending Payments', body: `₦${new Intl.NumberFormat('en-US').format(totalAmountOwedSum)}` },
  ]

  useEffect(() => {
    const matchingTab = tabs.find(tab => tab.headerTab === tabQueryParam);
    if (matchingTab) {
      setActiveTab(matchingTab.headerTab);
    }
  }, [tabQueryParam, tabs]);

  const activeTabObject = tabs.find(tab => tab.label === activeTab) || tabs[0];

  useEffect(() => {
    if (userInfo) {
      fetchBillings();
    }
  }, [fetchBillings])

  useEffect(() => {
    if (billings.length > 0) {
      fetchProductsAndAddBilling(billings);
    }
  }, [billings]);

  useEffect(() => {
    if (billingItems.length > 0) {
      updateCountsAndSum(billingItems);
    }
  }, [billingItems]);
  return (
      <Container>
        {activeTab === 'Pending-Bills' && (
            <PaymentHeader
                title="Payments"
                backLink="/dashboard"
                cardData={cardData}
                // buttonFunction={() => console.log('Pay all Pending')}
                // buttonTitle={'Pay all Pending'}
            />
        )}
        {activeTab === 'Add-Ons' && (
            <PaymentHeader
                title="Payments"
                backLink="/dashboard"
            />
        )}
        {activeTab === 'All-Payments' && (
            <PaymentHeader
                title="Payments History"
                backLink="/dashboard"
            />
        )}
        <Row>
          <TabComponent
              tabs={tabs}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
          />
        </Row>
      </Container>
  );
};

export default PaymentSection;
