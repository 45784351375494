import {Modal, ModalBody, ModalHeader, Col, Input, FormGroup} from "reactstrap";
import React, { useEffect } from 'react';
import Footer from "../shared/Footer";
import FooterLogo from "../../assets/images/footer.svg";

const PaymentAmountModal = ({
  show,
  setShow,
  amountOwed,
  setErrP,
  errP,
  amount,
  setAmount,
  makePayment,
  payAll = false,
}) => {

  useEffect(() => {
    if (show) {
      setAmount(payAll ? amountOwed : ''); // Set amount automatically if payAll is true
      setErrP('');   // Also clear any existing error messages
    }
  }, [show, setAmount, setErrP]);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
      <FormGroup>
        <Modal isOpen={show} centered className="w-100">
          <ModalHeader
              style={{borderBottom: "none"}}
              className="modal-title pb-0 fw-bold"
              toggle={() => {
                setShow(!show);
              }}
          >
          </ModalHeader>
          <ModalBody style={{padding: '2em 5em'}}
          >
            <div className="mb-3 fw-semibold" style={{textAlign: 'center', fontSize: '1.5rem'}}>
              Amount Owed - <span style={{color: '#CE2126'}}>₦{new Intl.NumberFormat("en-US").format(amountOwed)}</span>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="row g-3">
                <Col xxl={12}>
                  <div>
                    {errP ? (
                        <div
                            className="alert w-100 text-capitalize alert-danger alert-dismissible border-2 p-1 fade show"
                            role="alert"
                            style={{fontSize: "12px"}}
                        >
                          {errP}
                        </div>
                    ) : (
                        ""
                    )}
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <label htmlFor="amount" className="form-label pt-3">
                        <span style={{color: '#CE2126'}}>*</span> Amount to Pay
                      </label>
                    </div>
                    <Input
                        type="number"
                        value={amount}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (!payAll) {
                            if (value > amountOwed) {
                              setErrP("Sorry! You can't enter more money than you owe.");
                            } else {
                              setAmount(value);
                              setErrP("");
                            }
                          }
                        }}
                        className="form-control"
                        id="amount"
                        placeholder="Enter Amount"
                    />
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="d-grid gap-2">
                    {payAll ? (
                        <button
                            id="pay"
                            type="button"
                            onClick={() => makePayment()}
                            disabled={!amount || errP !== ""}
                            className="login-btn p-2 text-white"
                        >
                          Pay
                        </button>
                    ) : (
                        <>
                          <button
                              id="paySome"
                              type="button"
                              onClick={() => makePayment()}
                              disabled={!(amount >= 1000 && errP === "")}
                              className="login-btn p-2 text-white"
                          >
                            Pay
                          </button>
                          <span className='d-flex align-items-center justify-content-center'>or</span>
                          <button
                              id="payAll"
                              type="button"
                              onClick={() => setAmount(amountOwed)}
                              className="top-button p-2"
                          >
                            Pay All
                          </button>
                        </>
                    )}
                  </div>
                </Col>
              </div>
            </form>
          </ModalBody>
          <div className="text-center py-2" style={{background: '#FAFAFA', borderRadius: 'calc(0.5rem - 1px)'}}>
            <p className="mb-0">
              <img
                  src={FooterLogo}
                  width="20"
                  className="img-fluid"
                  alt="footer"
              />{" "}
              Powered by Milsat Technologies
            </p>
          </div>
        </Modal>
      </FormGroup>
  );
};

export default PaymentAmountModal;
