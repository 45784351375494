import React from 'react';
import { Card, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'react-feather';
import CardComponent from './misc/CardComponent';

const PaymentHeader = ({ title, backLink, cardData, buttonTitle, buttonFunction, children }) => {
  return (
      <Card className="border-none border-white">
        <Row className="justify-content-between align-items-center">
          <Col xs={12} md={4} className="d-flex justify-content-start">
            <Link to={backLink} className="btn text-decoration-none" id="bggrey">
              <ArrowLeft size={16} /> Back
            </Link>
          </Col>

          <Col xs={12} md={4} className="d-flex justify-content-center">
            <p className="fs-2 fw-semibold mb-0 h1-color m-0">{title}</p>
          </Col>

          <Col xs={12} md={4} className="d-flex justify-content-end">
            {buttonTitle ?
                <button
                    className="btn login-btn p-3 text-white d-flex align-items-center"
                    onClick={buttonFunction}
                >
                  {buttonTitle}
                </button> : ''
            }

          </Col>
        </Row>

        {cardData && (
            <Row className="pt-4 pb-3 justify-content-between">
              {cardData.map((card) => (
                  <CardComponent key={card.title} title={card.title} body={card.body} />
          ))}
        </Row>
        )}
      </Card>
  );
};

export default PaymentHeader;
