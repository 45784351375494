import React, {useCallback, useEffect, useState} from "react";
import {Col, Modal, ModalBody, ModalHeader} from "reactstrap";
import { request } from "../../services/utilities";
import SuccessImg from "../../assets/images/successIcon.svg";
import { PAYMENT_CALLBACK_URL } from "../../services/constants";
import ErrorModal from "./ErrorModal";
import SuccessModal from "./SuccessModal";

const BillSuccessModal = ({ show, setShow, bgTxts, smTxts, action, product, user, price}) => {
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [bgTxt, setBgTxt] = useState('');
  const [smTxt, setSmTxt] = useState('');
  const [showP, setShowP] = useState(false);
  const [showE, setShowE] = useState(false);
  const [showS, setShowS] = useState(false);
  const query = new URLSearchParams(window.location.search);

  const transacId = query.get("transactionId");
  const reference = query.get("reference");

  const handleOkClick = () => {
    if (action && typeof action === 'function') {
      setShow(!show);
      action();
    } else {
      setShow(!show);
    }
  };

  const makePaymentWithRemita = async () => {
    setLoading(true);
    const data = {
      userId: user.id,
      currency: "NGN",
      callBackUrl: "",
      amount: product.amount,
      productId: product.id,
    };
    const url = "payment/InitiateRemittaPayment";
    try {
      const rs = await request(url, "POST", false, data);
      setLoading(false);
      if (rs.code === "OK") {
        const data = {
          key: rs.payload.key,
          transactionId: rs.payload.transactionId,
          firstName: user.firstname,
          lastName: user.lastname,
          amount: rs.payload.amount,
          email: user.email,
          narration: "Testing",
        };
        payWithRemita(data);
      }else {
        setShowE(true);
        setError(rs.errors);
      }
    } catch (err) {
      setShowE(true);
      setError([err]);
      setLoading(false);
      console.log(err);
    }
  };
  const makePaymentWithPaystack = async (amt) => {
    setLoading(true);
    setShowP(false);
    let NEW_PAYSTACK_CALLBACK_URL = PAYMENT_CALLBACK_URL.split("/veterinarian?tab=update-profile").join("");
    NEW_PAYSTACK_CALLBACK_URL = NEW_PAYSTACK_CALLBACK_URL.split("/verify-payment").join("");
    NEW_PAYSTACK_CALLBACK_URL += `/billings-section?vcn=${user.vcnId}`;
    const data = {
      userId: user.id,
      currency: "NGN",
      productId: product.id,
      callBackUrl: `${NEW_PAYSTACK_CALLBACK_URL}`,
      amount: product.amount,
    };
    const url = "payment/InitiatePaystackPayment";
    try {
      const rs = await request(url, "POST", false, data);
      setLoading(false);
      if (rs.code === "OK") {
        window.open(rs.payload, "_blank");
      }else {
        setShowE(true);
        setError(rs.errors);
      }
    } catch (err) {
      setError(err)
      setShowE(true);
      setLoading(false);
    }
  };

  const payWithRemita = (e) => {
    let data = { ...e };
    window.makePayment(data, user.vcnId);
    setShowP(false);
  };

  const verifyPaymentOnRemita = useCallback(async (e) => {
    setLoading(true);
    try {
      const url = `payment/verifyTransaction?reference=${e}&paymentServiceProvider=${"remitta"}`;
      const rs = await request(url, "GET", true);
      setLoading(false);
      if (rs.code === "OK") {
        setBgTxt("Payment Completed Successfully");
        setSmTxt("Payment Successfully Received");
        setShowS(true);
      }
    } catch (err) {
      setLoading(false);
    }
  }, []);

  const verifyPaymentOnPaystack = useCallback(async (e) => {
    setLoading(true);
    try {
      const url = `payment/verifyTransaction?reference=${e}&paymentServiceProvider=${"paystack"}`;
      const rs = await request(url, "GET", true);
      if (rs.code === "OK") {
        setBgTxt("Payment Completed Successfully");
        setSmTxt("Payment Successfully Received");
        setShowS(true);
      }
    } catch (err) {
      setLoading(false);
    }
  }, []);


  const initiatePayment = () => {
    if (product.paymentServiceProvider === "Paystack") {
      makePaymentWithPaystack();
    } else if (product.paymentServiceProvider === "Remitta") {
      makePaymentWithRemita();
    } else {
      console.error("Invalid payment service provider");
    }
  };

  useEffect(() => {
    if (reference) {
      verifyPaymentOnPaystack(reference);
    } else if(transacId) {
      verifyPaymentOnRemita(transacId);
    }
  }, [verifyPaymentOnPaystack, verifyPaymentOnRemita]);
  return (
      <>
        <Modal isOpen={show} centered className="w-100">
          <ModalHeader
              style={{ borderBottom: 'none' }}
              className="modal-title pb-0 fw-bold"
              toggle={() => {
                setShow(!show);
              }}
          >
          </ModalHeader>
          <ModalBody style={{padding: '1em'}}>
            <form action="#">
              <div className="row g-3">
                <Col xxl={12}>
                  <div className="text-center">
                    <h4>Premise License</h4> {/* Header added */}
                    <img src={SuccessImg} className="img-fluid" width='70' height='70' alt={"success"}/>
                    <div className="mb-3 fw-semibold" style={{textAlign: 'center', fontSize: '1.5rem'}}>
                      <span style={{
                        color: '#CE2126',
                        fontSize: '1.85em'
                      }}>₦{new Intl.NumberFormat("en-US").format(price)}</span>
                    </div>
                    <h5>{bgTxts}</h5>
                  </div>
                </Col>

                <Col lg={12}>
                <>
                    {/*<div className=" gap-2 text-center">*/}
                    {/*  <button*/}
                    {/*      id="paySome"*/}
                    {/*      type="button"*/}
                    {/*      onClick={() => initiatePayment()}*/}
                    {/*      className="login-btn w-75 p-2 text-white"*/}
                    {/*  >*/}
                    {/*    Pay Now*/}
                    {/*  </button>*/}
                    {/*</div>*/}
                    {/*<span className='d-flex align-items-center justify-content-center'>or</span>*/}
                    <div className=" gap-2 pb-3 text-center">
                      <button
                          id="payAll"
                          type="button"
                          onClick={handleOkClick}
                          className="w-75 top-button p-2"
                      >
                        Pay Later
                      </button>
                    </div>
                  </>
                </Col>
              </div>
            </form>
          </ModalBody>
        </Modal>

        <ErrorModal
            show={showE}
            setShow={setShowE}
            bgTxt={"Failed"}
            serverError={error}
        />

        <SuccessModal
            show={showS} setShow={setShowS} bgTxt={bgTxts} smTxt={smTxts}
            action={() => window.location.href = `/#/payments?tab=All-Payments`}/>
      </>
  );
};

export default BillSuccessModal;
