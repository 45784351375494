import React, {useEffect, useState, useCallback } from "react";
import ReusableTable from "../components/misc/TableComponent";
import {request, scrollToTop} from "../services/utilities";
import {PAYMENT_CALLBACK_URL} from "../services/constants";
import {useAuthContext} from "../context/UserProvider";
import PaymentAmountModal from "../components/modals/PaymentAmountModal";
import ErrorModal from "../components/modals/ErrorModal";
import SuccessModal from "../components/modals/SuccessModal";


function AddOns({ data, pending }) {
  const { userId } = useAuthContext();
  const [vcn, setVcn] = useState("");
  const [error, setError] = useState([]);
  const [success, setSuccess] = useState("");
  const [disabled, setDisabled] = useState(false);

  const [sortKey, setSortKey] = useState("last_name");
  const [sortOrder, setSortOrder] = useState("ascn");
  const [addOnsData, setAddOnsData] = useState([]);
  const [amount, setAmount] = useState("");
  const [showP, setShowP] = useState(false);
  const [errP, setErrP] = useState(false);
  const [showE, setShowE] = useState(false);
  const [text, setText] = useState('');
  const [amountOwed, setAmountOwed] = useState("");
  const [singleBilling, setSingleBilling] = useState("");
  const [bgTxt, setBgTxt] = useState("Payment Completed Successfully'");
  const [smTxt, setSmTxt] = useState(
      "Your payment has been received and is currently reviewed by the council"
  );
  const [billingData, setBillingData] = useState([]);

  const [show, setShow] = useState(false);
  const [ loading, setLoading ] = useState( false );

  const [ email, setEmail ] = useState( '' );
  const [firstname, setFristname] = useState("");
  const [lastname, setLastname] = useState("");

  const query = new URLSearchParams(window.location.search);
  const transacId = query.get("transactionId");

  const vcnNo = query.get("vcn");
  const reference = query.get("reference");

  const verifyVcn = async (e) => {
    if (vcn === "" && e === undefined) {
      setError(["VCN Number can not be empty"]);
      msgTimer();
      return;
    }
    setLoading(true);
    try {
      const url = `Profile/GetUserProfileByVcnId?vcnId=${e ? e : vcn}`;
      const rs = await request(url, "GET", false);
      if (rs.hasErrors === true) {
        if (rs.errors) {
          setError(rs.errors);
        }
        if (rs.description) {
          setError([rs.description]);
        }
        if (rs.payload) {
          setError(rs.payload);
        }
        scrollToTop();
      }
      if (rs.code === "OK") {
        if (rs.payload.isFirstTimeUpdateDone === true) {
          setDisabled(true);
          await updateUserInput( rs.payload );
        } else {
          setDisabled(false);
          await updateUserInput( rs.payload );
        }
      }
      msgTimer();
    } catch (err) {
      setLoading(false);
      setError(["Failed to verify vcn number kindly try again later"]);
    }
  };

  const updateUserInput = async (e) => {
    setEmail(e.email)
    setFristname(e.firstName);
    setLastname(e.lastName);
  }

  const msgTimer = () => {
    const timer = setTimeout( () => {
      setError( [] );
      setSuccess( "" );
    }, 5000 );
    return () => clearTimeout( timer );
  }

  const  makePaymentWithRemita = async (singleBilling) => {
    setLoading(true);
    const data = {
      userId,
      currency: "NGN",
      callBackUrl:"",
      amount,
      productId: singleBilling.id,
      billId: null
    };
    const url = 'payment/InitiateRemittaPayment'
    try{
      const rs = await request(url,'POST',false,data);
      setLoading(false)
      if(rs.code === 'OK'){
        const data = {
          key:rs.payload.key,
          transactionId:rs.payload.transactionId,
          firstName:firstname,
          lastName:lastname,
          amount:rs.payload.amount,
          email,
          narration:"Testing"
        }
        payWithRemita(data);
      }else {
        setShowE(true);
        setError(rs.errors);
      }
    }catch(err){
      setText(err)
      setError([err]);
      setShowE(true)
      setLoading(false);
      console.log(err);
    }
  };

  const makePaymentWithPaystack = async (singleBilling) => {
    setLoading(true);
    setShowP(false);
    let NEW_PAYSTACK_CALLBACK_URL = PAYMENT_CALLBACK_URL.split("/veterinarian?tab=update-profile").join("");
    NEW_PAYSTACK_CALLBACK_URL = NEW_PAYSTACK_CALLBACK_URL.split("/verify-payment").join("");
    NEW_PAYSTACK_CALLBACK_URL += `/billings-section?vcn=${vcn}`;
    const data = {
      userId,
      currency: "NGN",
      productId: singleBilling.id,
      billId: null,
      callBackUrl: `${NEW_PAYSTACK_CALLBACK_URL}`,
      amount: amount,
    };
    const url = "payment/InitiatePaystackPayment";
    try {
      const rs = await request(url, "POST", false, data);
      setLoading(false);
      if (rs.code === "OK") {
        window.open(rs.payload, "_blank");
      }else {
        setShowE(true);
        setError(rs.errors);
      }
    } catch (err) {
      setText(err)
      setShowE(true);
      setLoading(false);
    }
  };

  const payWithRemita = (e) => {
    let data = {...e}
    let route = true
    window.makePayment(data,vcn,route);
    setShowP(false);
  }

  const verifyPaymentOnRemita = useCallback(async (e) => {
    setLoading(true);
    try {
      const url = `payment/verifyTransaction?reference=${e}&paymentServiceProvider=${"remitta"}`;
      const rs = await request(url, "GET", true);
      setLoading(false);
      if (rs.code === "OK") {
        setVcn(vcnNo);
        await verifyVcn( vcnNo );
        setBgTxt("Payment Completed Successfully");
        setSmTxt("Payment Successfully Received");
        setShow(true);
      }
    } catch (err) {
      setLoading(false);
    }
  }, []);

  const verifyPaymentOnPaystack = useCallback(async (e) => {
    setLoading(true);
    try {
      const url = `payment/verifyTransaction?reference=${e}&paymentServiceProvider=${"paystack"}`;
      const rs = await request(url, "GET", true);
      if (rs.code === "OK") {
        setBgTxt("Payment Completed Successfully");
        setSmTxt("Payment Successfully Received");
        setShow(true);
        setVcn(vcnNo);
        const timer = setTimeout(() => {
          verifyVcn(vcnNo);
        }, 3000);
        return () => clearTimeout(timer);
      }
    } catch (err) {
      // setText(err)
      setLoading(false);
    }
  }, []);


  const initiatePayment = (amountOwed, singleBilling) => {
    if (singleBilling.paymentServiceProvider === 'Paystack') {
      makePaymentWithPaystack(singleBilling);
    } else if (singleBilling.paymentServiceProvider === 'Remitta') {
      makePaymentWithRemita(singleBilling);
    } else {
      console.error('Invalid payment service provider');
    }
  };

  function sortData({ tableData, sortKey, reverse }) {
    if (!sortKey) return tableData;

    const sortedData = data.sort((a, b) => {
      return a[sortKey] > b[sortKey] ? 1 : -1;
    });

    if (reverse) {
      return sortedData.reverse();
    }

    return sortedData;
  }

  const headers = [
    { key: "name", label: "Product Name" },
    { key: "description", label: "Description" },
    { key: "productCategoryType", label: "Product Category" },
    { key: "price", label: "Amount" },
    { key: "action", label: "Action", renderer: (row) => (
          <button
              id="bgred"
              className="login-btn text-light"
              style={{padding: '0.4em'}}
              type="button"
              onClick={(e) => {
                e.stopPropagation(); // Prevent row click event
                setShowP(true);
                setAmountOwed(row.price);
                setSingleBilling(row);
              }}
          >
            Pay Now
          </button>
      )}
  ];
  const pendingHeader = [
    { key: "paymentCompleted", label: "Payment Complete" },
  ];

  // updates the table based on updated data
  useEffect(() => {
    if (data !== addOnsData) {
      setBillingData(
          sortData({ tableData: data, sortKey, reverse: sortOrder === "desc" })
      );
    }
  }, [data, billingData, sortKey, sortOrder, verifyPaymentOnPaystack, verifyPaymentOnRemita]);

  useEffect(() => {
    if (reference) {
      verifyPaymentOnPaystack(reference);
    } else if(transacId) {
      verifyPaymentOnRemita(transacId);
    }
  }, [verifyPaymentOnPaystack, verifyPaymentOnRemita]);


  function changeSort(key) {
    setSortOrder(sortOrder === "ascn" ? "desc" : "ascn");
    setSortKey(key);
    setBillingData(
        sortData({ tableData: data, sortKey, reverse: sortOrder === "desc" })
    );
  }

  return (
      <div>
        <ReusableTable
            data={data}
            headers={headers}
            onSortChange={changeSort}
        />

        <PaymentAmountModal
            show={showP}
            setShow={setShowP}
            amount={amount}
            setAmount={setAmount}
            payAll={true}
            makePayment={() => initiatePayment(amountOwed, singleBilling)}
            amountOwed={amountOwed}
            errP={errP}
            setErrP={setErrP}
        />

        <ErrorModal
            show={showE}
            setShow={setShowE}
            bgTxt={"Failed"}
            smTxt={text}
            serverError={error}
        />

        <SuccessModal show={show} setShow={setShow} bgTxt={bgTxt} smTxt={smTxt} action={() => window.location.href = `/#/payments?tab=All-Payments`}/>
      </div>
  );
}

export default AddOns;
