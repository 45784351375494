import React from "react";
import { Modal, ModalBody, ModalHeader, Col, Input, Button } from "reactstrap";
import SuccessImg from "../../assets/images/success.png";

const SuccessModal = ({ show, setShow, bgTxt, smTxt, action }) => {
  const handleOkClick = () => {
    if (action && typeof action === 'function') {
      setShow(!show);
      action();
    } else {
      setShow(!show);
    }
  };

  return (
    <>
      <Modal isOpen={show} centered className="modalw">
        <ModalHeader
          style={{ borderBottom: "none" }}
          className="modal-title pb-0 fw-bold"
          toggle={() => {
            setShow(!show);
          }}
        ></ModalHeader>
        <ModalBody className="">
          <form action="#">
            <div className="row g-3">
              <Col xxl={12}>
                <div className="text-center">
                  <img src={SuccessImg} className="img-fluid" alt={"sucess"} />
                </div>
              </Col>
              <Col xxl={12}>
                <div className="mb-2 text-center">
                  <h5>{bgTxt}</h5>
                  <p className="p-color">{smTxt}</p>
                </div>
              </Col>

              <Col lg={12}>
                <div className=" gap-2 pb-3 text-center">
                  <button
                    id="bgred"
                    type="button"
                    className="btn w-75 text-light"
                    onClick={handleOkClick}
                  >
                    Ok
                  </button>
                </div>
              </Col>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default SuccessModal;
