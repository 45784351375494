const AlertIcon = (props) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_80_61)">
      <g>
        <g>
          <path
            d="M16 2.66667C13.3629 2.66667 10.785 3.44865 8.59239 4.91374C6.39974 6.37882 4.69077 8.46121 3.6816 10.8976C2.67243 13.3339 2.40839 16.0148 2.92286 18.6012C3.43733 21.1876 4.70721 23.5634 6.57191 25.4281C8.43661 27.2928 10.8124 28.5627 13.3988 29.0771C15.9852 29.5916 18.6661 29.3276 21.1024 28.3184C23.5388 27.3092 25.6212 25.6003 27.0863 23.4076C28.5513 21.2149 29.3333 18.6371 29.3333 16C29.3333 14.249 28.9885 12.5152 28.3184 10.8976C27.6483 9.27988 26.6662 7.81002 25.4281 6.57191C24.19 5.33379 22.7201 4.35167 21.1024 3.68161C19.4848 3.01154 17.751 2.66667 16 2.66667V2.66667ZM16 26.6667C13.8903 26.6667 11.828 26.0411 10.0739 24.869C8.31979 23.6969 6.95262 22.031 6.14528 20.082C5.33795 18.1329 5.12671 15.9882 5.53829 13.919C5.94986 11.8499 6.96576 9.94929 8.45752 8.45753C9.94928 6.96577 11.8499 5.94987 13.919 5.53829C15.9882 5.12671 18.1329 5.33795 20.082 6.14528C22.031 6.95262 23.6969 8.31979 24.869 10.0739C26.0411 11.828 26.6667 13.8903 26.6667 16C26.6667 18.829 25.5429 21.5421 23.5425 23.5425C21.5421 25.5429 18.829 26.6667 16 26.6667V26.6667Z"
            fill="#F79009"
          />
          <path
            d="M16 22.6667C16.7364 22.6667 17.3333 22.0697 17.3333 21.3333C17.3333 20.597 16.7364 20 16 20C15.2636 20 14.6667 20.597 14.6667 21.3333C14.6667 22.0697 15.2636 22.6667 16 22.6667Z"
            fill="#F79009"
          />
          <path
            d="M16 9.33333C15.6464 9.33333 15.3072 9.47381 15.0572 9.72386C14.8071 9.97391 14.6667 10.313 14.6667 10.6667V17.3333C14.6667 17.687 14.8071 18.0261 15.0572 18.2761C15.3072 18.5262 15.6464 18.6667 16 18.6667C16.3536 18.6667 16.6928 18.5262 16.9428 18.2761C17.1928 18.0261 17.3333 17.687 17.3333 17.3333V10.6667C17.3333 10.313 17.1928 9.97391 16.9428 9.72386C16.6928 9.47381 16.3536 9.33333 16 9.33333Z"
            fill="#F79009"
          />
        </g>
      </g>
    </g>
    <defs>
      <clipPath>
        <rect width={32} height={32} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default AlertIcon;
