import React, {useState, useEffect} from 'react';
import {Modal, ModalHeader, ModalBody, FormFeedback, FormGroup} from 'reactstrap';
import {ChevronDown} from "react-feather";
import WarningIcon from "../../assets/images/warningIcon.svg"

const MultiSelectComboBox = ({
     header,
     invalid,
     disabled,
     onBlur,
     className = "form-control rounded-lg",
     selectedOptionsString,
     onSelectionChange,
     options,
     errors,
     touched
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if (selectedOptionsString) {
      const selected = selectedOptionsString.split(', ').map(value => options.find(option => option === value)).filter(Boolean);
      setSelectedOptions(selected);
    }
  }, [selectedOptionsString, options]);


  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => setModalOpen(!modalOpen);

  const handleOptionClick = (option) => {
    let newSelectedOptions;
    if (selectedOptions.includes(option)) {
      newSelectedOptions = selectedOptions.filter((opt) => opt !== option);
    } else {
      newSelectedOptions = [...selectedOptions, option];
    }
    setSelectedOptions(newSelectedOptions);
    if (onSelectionChange) {
      onSelectionChange(newSelectedOptions.map(opt => opt).join(', '));
    }
  };

  const inputStyle = {
    backgroundColor: "#F5F5F5",
    border: "1px solid #EBEBEB",
  };

  return (
      <>
        <div
            className={`pills-container d-flex 
              ${selectedOptions.length > 0 || errors ? 'selected' : ''} ${disabled ? 'disabled' : ''} 
              ${className} ${invalid ? "is-invalid" : ""} `
            }
            style={inputStyle}
            onBlur={onBlur}
            onClick={toggleModal}
        >
          {selectedOptions.length > 0 ? (
              selectedOptions.map((option, index) => (
                  <button key={index} className="pills-button" onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}>
                    {option}
                  </button>
              ))
          ) : (
              <div className="d-flex align-items-center justify-content-between w-100">
                <span className="text">Select options</span>
                {errors ? <img src={WarningIcon} width="16" height="16" /> : <ChevronDown className="dropdown-icon"/>}
              </div>
          )}
          {touched && errors ? (
              <FormFeedback type="invalid">
                {errors}
              </FormFeedback>
          ) : null}
        </div>


        <Modal className='modal-container' isOpen={modalOpen} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>{header}</ModalHeader>
          <ModalBody>
            <div>
              {options.map((option) => (
                  <div key={option} className="d-flex justify-content-between align-items-center p-0 m-4">
                    {option}
                    <input
                        type="checkbox"
                        className="form-check-input checkBox"
                        checked={selectedOptions.includes(option)}
                        onChange={() => handleOptionClick(option)}
                        style={{borderRadius: '0.25em', width: '1em', height: '1em'}}
                    />
                  </div>
              ))}
            </div>

            <div className='d-flex justify-content-center p-0 w-100'>
              <button
                  className="w-100 login-btn text-light fw-normal mx-4 my-2 p-2"
                  style={{borderRadius: '1em'}}
                  onClick={toggleModal}
              >
                Done
              </button>
            </div>
          </ModalBody>
        </Modal>
      </>
  );
};

export default MultiSelectComboBox;
