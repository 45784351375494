import React, { useState } from "react";
import { generateBarcodeForUsers } from "../../services/generateBarcodeForUsers";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { DESIGNATION } from "../../services/constants";
import { useAuthContext } from "../../context/UserProvider";
import JSZip from "jszip";
import { saveAs } from "file-saver";

const ConfirmQRCodeModal = ({
  show,
  setShow,
  userData,
  fileName,
  modalTitle,
}) => {
  const { userType: loggedInUserType } = useAuthContext();
  const [loading, setLoading] = useState(true);
  const [showBarcodes, setShowBarcodes] = useState(false);
  const [qrCodes, setQrCodes] = useState([]);

  const generateBarcode = async (userIds) => {
    setLoading(true);
    try {
      const responses = await generateBarcodeForUsers(userIds);
      setQrCodes(userIds.map((id) => responses?.payload?.[id]));
    } catch (error) {
      console.error("Error in generating barcode:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadQRCode = async (codes, id) => {
    if (Array.isArray(id)) {
      const zip = new JSZip();
      await Promise.all(
        qrCodes.map((qrCode, index) => {
          zip.file(
            `${userData.name[index]}_${userData.vcnID[index]}.png`,
            qrCode,
            { base64: true }
          );
        })
      );

      const content = await zip.generateAsync({ type: "blob" });
      saveAs(content, `${fileName}.zip`);
      // close modal
      setShow(!show);
      setShowBarcodes(!showBarcodes)
    } else {
     const link = document.createElement( 'a' );
      link.href = `data:image/png;base64,${ qrCodes[ 0 ] }`;
      link.download = `${ userData.name }.png`;
      link.click();
      // close modal
      setShow(!show);
      setShowBarcodes(!showBarcodes)
    }
  };

  return (
    <Modal isOpen={show} centered className="modalw">
      <div>
        <ModalHeader
          style={{ borderBottom: "none" }}
          className="modal-title pb-2 text-center fw-bold"
        >
          {showBarcodes ? (
            <h5 text-center pb-3 fw-bold>
              QR Code generated for User ID:{" "}
              {Array.isArray(userData.name) ? `${modalTitle}` : userData.name}
            </h5>
          ) : (
            <h5 text-center pb-3 fw-bold>
              Confirm to Generate User Barcode for User ID: 
              {Array.isArray(userData.name) ? `${modalTitle}` : userData.name}
            </h5>
          )}
        </ModalHeader>

        <ModalBody className="px-4 ">
          {showBarcodes && (
            <div className="row g-3">
              <Col lg={12}>
                <div className="hstack gap-2 pb-3 justify-content-center">
                  {loading ? (
                    <Spinner color="danger" />
                  ) : Array.isArray(qrCodes) && qrCodes.length > 1 ? null : (
                    <img
                      src={`data:image/png;base64,${qrCodes}`}
                      alt="QR Code"
                      className="img-fluid"
                    />
                  )}
                </div>
              </Col>
            </div>
          )}
          <div className="hstack gap-2 pb-3 gap-5 justify-content-between">
            {showBarcodes ? (
              <Button
                id="bgred"
                className="btn text-light w-50"
                onClick={() => {setShow(!show); setShowBarcodes(!showBarcodes); }}
              >
                Close
              </Button>
            ) : (
              <Button
                id="bgred"
                className="btn text-light w-50"
                onClick={() =>setShow(!show)}
              >
                No
              </Button>
            )}

            {showBarcodes ? (
              (loggedInUserType === DESIGNATION.ADMIN ||
                loggedInUserType === DESIGNATION.SUPPORTOFFICER) && (
                <Button
                  id="bgred"
                  className="btn text-light w-50"
                  onClick={() => handleDownloadQRCode(qrCodes, userData.id)}
                >
                  Download QR Code
                </Button>
              )
            ) : (
              <Button
                id="bgred"
                className="btn text-light w-50"
                onClick={() => {
                  if ( userData.id ) {
                    setShowBarcodes(true);
                    generateBarcode( Array.isArray( userData.id ) ? userData.id : [ userData.id ] );
                  }
                 
                }}
              >
                Yes
              </Button>
            )}
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
};

export default ConfirmQRCodeModal;
