import React, {useCallback, useEffect, useState} from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner,
} from "reactstrap";
import { request } from "../services/utilities";
import SuccessModal from "./modals/SuccessModal";
import ConfrimActionsModal from "./modals/ConfirmActionsModal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ArrowLeft } from "react-feather";
import {LabelTag} from "./FormInput";
import CustomInput from "./shared/CustomInput";
import ErrorModal from "./modals/ErrorModal";
import PractitionerSelectionModal from "./modals/PractitionerSelectionModal";

const CreateBilling = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState([]);
  const [entry, setEntry] = useState({});
  const [bgTxt, setBgTxt] = useState("Record Uploaded Successfully");
  const [smTxt, setSmTxt] = useState(
      "You have successfully uploaded your record"
  );
  const navigate = useNavigate();

  const [text, setText] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showE, setShowE] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedPractitioners, setSelectedPractitioners] = useState([]);
  const [billings, setBillings] = useState([]);
  const [billingType, setBillingType] = useState('single'); // 'single' or 'multiple'

  const fetchProducts = useCallback(
      async (debt, isActive = true) => {
        setLoading(true);
        try {
          const url = `Product/GetProducts?ProductCategoryType=PractiseLicense&IsActive=${isActive}`;
          const rs = await request(url, "GET", false);
          if (rs.code === "OK") {
            const newProducts = [...rs.payload.items];

            setProducts(newProducts);
          }
          setLoading(false);
        } catch (err) {
          console.error(err); // Error logging
          setLoading(false);
        }
      },
      []
  );

  const fetchBillboardDashboard = useCallback(async (vcn) => {
    setLoading(true);
    try {
      const url = `BillingStructure/GetBillings?PaymentCompleted=${false}`;
      const rs = await request(url, "GET", true);
      setBillings(rs.payload?.items);
      setLoading(false);
    } catch (err) {
      setError('Error fetching billboard data:');
    } finally {
      setLoading(false);
    }
  });

  // Form formik schema
  const formikSchema = Yup.object({
    price: Yup.number()
        .typeError("price must be a number")
        .required("price is required")
        .min(1000, "Amount should not be below 1000"),
    description: Yup.string().required("Please fill in the description"),
    productId: Yup.string()
        .required("Please Select Product Category "),
    userId: Yup.array()
        .of(Yup.string().required("A user is required"))
        .min(1, "Please select at least one user"),  });

  // Formik for form handling
  const formik = useFormik({
    initialValues: {
      price: "",
      description: "",
      productId: "",
      userId: []
    },
    formikSchema: formikSchema,
    onSubmit: async (e) => {
      setEntry(e);
      let modalMessage = "";

      if (billingType === 'all') {
        // Message for billing all users
        modalMessage = `You are about to add <b>${e.description}</b> of <b>₦${e.price}</b> as a bill for all Vet Practitioners in the system.<br/><br/>Click yes to continue.`;
      } else {
        // Fetching the selected billing objects from the state
        if (billingType === 'multiple') {
          const userNames = selectedPractitioners.map(practitioner => practitioner.fullName).join(", ");
          modalMessage = `You are about to add <b>${e.description}</b> of <b>₦${e.price}</b> as a bill to the following practitioners: <b>${userNames}</b>.`;
        }
      }

      // Ensure the modal or the method displaying the text can interpret HTML for the bold tags and line breaks to work
      setText(modalMessage);
      setShowConfirmModal(true)
    }
  });

  const submitForm = async (e) => {
    let data;
    switch (billingType) {
      case 'multiple':
        data = {
          createBillForAllUsers: false,
          billingParameters: selectedPractitioners.map(practitioner => {
            return {
              userId: practitioner.id,
              description: formik.values.description,
              totalAmountOwed: formik.values.price,
              productId: formik.values.productId,
            };
          }),
        };
        break;
      case 'all':
        data = {
          createBillForAllUsers: true,
          description: formik.values.description,
          totalAmountOwed: formik.values.price,
          productId: formik.values.productId,
        };
        break;
      default:
        // Handle no selection or any other case
        console.error("No valid billing type selected");
        return;
    }

    try {
      const url = `BillingStructure/CreateBillings`;
      const rs = await request(url, "POST", true, data);
      setLoading(true);
      if (rs.hasErrors === true) {
        if (rs.errors) {
          setError(rs.errors);
          console.log(rs.errors);
        }
        if (rs.description) {
          setError([rs.description]);
        }
        if (rs.payload) {
          setError(rs.payload);
        }
        setShowE(true)
      }
      if (rs.code === "OK") {
        setBgTxt("Bill Created Successfully");
        setSmTxt(`You have successfully Created a bill`);
        setShowSuccessModal(true);
        formik.resetForm(); // Reset the form to initial values
      }
      setLoading(false);
      const timer = setTimeout(() => setError([]), 6000);
      return () => clearTimeout(timer);
    } catch (err) {
      setLoading(false);
      setShowE(true)
      setError(err);
      console.log(err);
    }
    setLoading(false);
  };

  const handleBillingTypeChange = (type) => {
    setBillingType(prevType => prevType === type ? '' : type); // Toggle functionality
  };

  const handleSelectionChange = (selected) => {
    console.log(selected)
    setSelectedPractitioners(selected);
  };

  const handlSubmitForm = () => {
    setShowConfirmModal(false);
    setLoading(true);

    submitForm(entry)
  }

  useEffect(() => {
    fetchProducts();
    fetchBillboardDashboard()
  }, []);

  return (
      <Container>
        <Row className="d-flex align-items-center mb-3">
          <Col xl={6} sm={8} md={8}>
            <h2 className="h1-color mb-3">Create Billing</h2>
          </Col>
          <Col xl={6} sm={4} md={4}>
            <Link
                to={"/admin/billings"}
                className="mb-3 btn text-decoration-none float-end"
                style={{ background: "#EFEFEF" }}
            >
              <ArrowLeft size={16} /> Back
            </Link>
          </Col>
        </Row>
        <Row className="align-items-center mb-3 h-50">
          <Col xl={6} sm={12} md={4}>
            {error?.map((e, index) => {
              return (
                  <div
                      key={index}
                      className="alert text-center w-100 text-capitalize alert-danger alert-dismissible border-2 p-1 fade show"
                      role="alert"
                      style={{ background: "#fff", fontSize: "14px" }}
                  >
                    {e}
                  </div>
              );
            })}
          </Col>
          <Col xl={6} sm={12} md={6}>
            <div className="float-end">
              {loading ? <Spinner color="danger" /> : ""}
            </div>
          </Col>
        </Row>
        <Row>
          <p>Please provide the necessary following information:</p>
        </Row>
        <Card>
          <CardBody>
            <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.validateForm().then(errors => {
                    // Check if 'price', 'description', and 'productId' fields are empty
                    const areCriticalFieldsEmpty = !formik.values.price || !formik.values.description || !formik.values.productId;

                    if (Object.keys(errors).length === 0 && !areCriticalFieldsEmpty) {
                      // No errors and critical fields are not empty, proceed to show the confirmation modal
                      setShowConfirmModal(true);
                    } else {
                      // If any critical fields are empty, manually set errors for them
                      if (areCriticalFieldsEmpty) {
                        const newErrors = {...errors};
                        if (!formik.values.price) {
                          newErrors.price = "Price is required";
                        }
                        if (!formik.values.description) {
                          newErrors.description = "Description is required";
                        }
                        if (!formik.values.productId) {
                          newErrors.productId = "Selecting a product is required";
                        }
                        formik.setErrors(newErrors);
                      }

                      // Ensure all fields that have errors are marked as touched to display errors
                      formik.setTouched({
                        price: true,
                        description: true,
                        productId: true, // Ensure this field is also touched to show  the error
                        // Include other fields as necessary
                      }, false); // Prevent Formik from re-running validations which might overwrite the manual errors
                    }
                  });
                  formik.handleSubmit();
                  return false;
                }}
            >
              <Row>
                <Col md={6}>
                  <FormGroup className="mb-3">
                    <LabelTag htmlFor="val13" label="Select item from catalogue" required={formikSchema.fields["productId"]} />
                    <Input
                        name="productId"
                        type="select"
                        className="form-control"
                        id="val13"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.productId || ""}
                        style={{
                          backgroundColor: formik.values.productId ? '#FDF5F5' : 'transparent'
                        }}
                        invalid={
                          !!(formik.touched.productId &&
                              formik.errors.productId)
                        }
                    >
                      <option value="">Select Product</option>
                      {products.map((product, index) => (
                          <option key={index} value={product.id}>{product.name}</option>
                      ))}
                    </Input>
                    {formik.touched.productId && formik.errors.productId && (
                        <FormFeedback type="invalid">
                          {formik.errors.productId}
                        </FormFeedback>
                    )}
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label for="description">Description</Label>
                    <Input
                        type="text"
                        name="description"
                        id="description"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.description}
                        placeholder='Description'
                        invalid={
                            formik.touched.description && !!formik.errors.description
                        }
                    />
                    {formik.touched.description && formik.errors.description && (
                        <FormFeedback type="invalid">
                          {formik.errors.description}
                        </FormFeedback>
                    )}
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label for="price">Price</Label>
                    <Input
                        type="number"
                        name="price"
                        id="price"
                        onChange={formik.handleChange}
                        placeholder="Price"
                        onBlur={formik.handleBlur}
                        value={formik.values.price}
                        invalid={formik.touched.price && !!formik.errors.price}
                    />
                    {formik.touched.price && formik.errors.price && (
                        <FormFeedback type="invalid">
                          {formik.errors.price}
                        </FormFeedback>
                    )}
                  </FormGroup>
                </Col>

                {/* Toggle for creating bill for all users */}
                <Col md={6}>
                  <FormGroup>
                    <Label>Select Billing Type</Label>
                    <div className="d-flex gap-5">
                      <CustomInput
                          type="radio"
                          id="multipleUserBilling"
                          name="billingType"
                          label="Bill multiple users"
                          checked={billingType === 'multiple'}
                          onChange={() => handleBillingTypeChange('multiple')}
                      />
                      <CustomInput
                          type="radio"
                          id="multipleUserBilling"
                          name="billingType"
                          label="Bill all users"
                          checked={billingType === ' all'}
                          onChange={() => handleBillingTypeChange('all')}
                      />
                    </div>
                  </FormGroup>
                </Col>

                {billingType === 'multiple' && (
                    <Col md={6}>
                      <FormGroup className="mb-3">
                        <LabelTag htmlFor="val14" label="Select Users" required={formikSchema.fields["userId"]} />
                        <PractitionerSelectionModal
                            request={request}
                            errors={formik.errors.userId}
                            touched={formik.touched.userId }
                            onSelectionChange={handleSelectionChange}
                        />
                      </FormGroup>
                    </Col>
                )}

                <Col md={12}>
                  <button
                      id="bgred"
                      className=" btn text-light"
                      type="submit"
                  >
                    Submit
                  </button>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
        <SuccessModal
            show={showSuccessModal}
            setShow={setShowSuccessModal}
            bgTxt={bgTxt}
            smTxt={smTxt}
            action={() => navigate("/admin/billings")}
        />
        <ConfrimActionsModal
            show={showConfirmModal}
            setShow={setShowConfirmModal}
            text={text}
            smText={""}
            action={handlSubmitForm}
        />

        <ErrorModal
            show={showE}
            setShow={setShowE}
            bgTxt={"Failed"}
            smTxt={error}
            serverError={error}
        />
      </Container>
  );
};

export default CreateBilling;
