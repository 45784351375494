import Navbar from "../components/Navbar";
import BillingsDashboardComponent from  "../components/BillingsDashboard";

const BillingsDashboard = () => {
  return (
      <>
        <Navbar />
        <div style={{paddingTop: "6rem"}}>
          <BillingsDashboardComponent/>
        </div>
      </>
  );
};

export default BillingsDashboard;
