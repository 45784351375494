import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  Label,
  Spinner,
  FormGroup,
  FormFeedback,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link, useParams } from "react-router-dom";
import UserImage from "../assets/images/user.png";
import { ArrowLeft, Eye, EyeOff } from "react-feather";
import RequestProfileModel from "./modals/RequestProfileModel";
import { Store } from "../context/store";
import moment from "moment";
import { request, scrollToTop } from "../services/utilities";
import SuccessModal from "./modals/SuccessModal";
import { Toast } from "./shared/Toast";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import fileDownload from "js-file-download";
import axios from "axios";
import { DESIGNATION } from "../services/constants";
import LicenseGreen from "../assets/images/license_green.svg";

const Profile = () => {
  const store = useContext(Store);
  const [userType] = store.userType;
  const [userDetials, setUserDetails] = useState(null);
  const [userInfo, setUserInfo] = useState({
    firstName: null,
    lastName: null,
    dateOfBirth: null,
    middleName: null,
    phoneNumber: null,
    address: null,
    imageUrl: null,
    state: null,
    lga: null,
    stateCode: null,
    lgaCode: null,
  });
  const [eye, setEye] = useState(false);
  const [show, setShow] = useState(false);
  const [error, setError] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bgTxt, setBgTxt] = useState("Update Password Completed Successfully");
  const [smTxt, setSmTxt] = useState(
    "Your Password has been updated successfully"
  );
  const userid = useParams();
  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstname: "",
      lastname: "",
      othername: "",
      email: "",
      phone: "",
      sex: "",
      dob: "",
      address: "",
      state: "",
      lga: "",
      highestQualification: "",
      oldpassword: "",
      newpassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      newpassword: Yup.string().required("Please Enter Your New Password"),
      confirmPassword: Yup.string().required("Please Confirm Your Password"),
      oldpassword: Yup.string().required("Please Enter Your Old Password"),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const clearError = () => {
    const timer = setTimeout(() => setError([]), 3000);
    return () => clearTimeout(timer);
  };
  async function handleSubmit(e) {
    setLoading(true);

    if (e.newpassword !== e.confirmPassword) {
      scrollToTop();
      setLoading(false);
      return setError(["Password does not match"]);
    }
    const data = {
      userId: userDetials?.id,
      oldPassword: e.oldpassword,
      newPassword: e.newpassword,
      confirmNewPassword: e.confirmPassword,
    };
    try {
      const url = `Profile/changePassword`;
      const rs = await request(url, "POST", true, data);
      setLoading(false);
      scrollToTop();

      if (rs.hasErrors === true) {
        if (rs.errors) {
          setError(rs.errors);
        }
        if (rs.description) {
          setError([rs.description]);
        }
        if (rs.payload) {
          setError(rs.payload);
        }
      }
      if (rs.code === "OK") {
        setShowSuccessModal(true);
      }
      clearError();
    } catch (err) {
      setLoading(false);
      setError(["Unable to change password kindly try again later"]);
      clearError();
    }
  }

  const requestProfileUpdate = async () => {
    setLoading(true);
    const url = `Profile/RequestAccountUpdate`;
    const userId = userid.id;
    const data = { ...userInfo, userId };
    try {
      const rs = await request(url, "POST", true, data);
      setUserInfo({
        firstName: null,
        lastName: null,
        dateOfBirth: null,
        middleName: null,
        phoneNumber: null,
        address: null,
        imageUrl: null,
        state: null,
        lga: null,
        stateCode: null,
        lgaCode: null
      });
      if (rs.code === "OK") {
        setBgTxt("Request Sent Successfully");
        setSmTxt("profile update request successfully sent");
        setShowSuccessModal(true);
      }
      setShow(false);
      setLoading(false);
    } catch (err) {
      setError(["Unable to request profile update kindly try again later"]);
      clearError();
      setLoading(false);
    }
  };
  const downLoadFile = ({ file, name }) => {
    const fileArr = file.split(".");
    const lastindex = fileArr.length - 1;
    const ext = fileArr[lastindex];

    axios
      .get(file, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, `${name}.${ext}`);
        notifyToast();
      });
  };
  const notifyToast = () =>
    toast.success(<Toast tbgt="Downloaded Successfully" />, {
      icon: false,
      hideProgressBar: false,
    });

  const fetchLoggedUser = useCallback(async () => {
    setLoading(true);
    try {
      const url = `Profile/GetUserProfileById?id=${userid.id}`;
      const rs = await request(url, "GET", true);
      const data = rs.payload;
      setUserDetails(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError([
        "Unable to retrieve the profile at the moment. Please try again later",
      ]);
      clearError();
    }
  }, [userid.id]);
  useEffect(() => {
    fetchLoggedUser();
  }, [fetchLoggedUser]);
  return (
    <>
      <Container>
        <Row>
          <Col xs={12}>
            <div className="float-end modalw mt-4">
              {error.map((e) => {
                return (
                    <div
                        key={e}
                        className="alert text-center w-100 text-capitalize alert-danger alert-dismissible border-2 p-1 fade show"
                        role="alert"
                        style={{background: "#fff", fontSize: "14px"}}
                    >
                      {e}
                    </div>
                );
              })}
            </div>
            <div className="pt-2">
              <Link
                  to={
                    userType === DESIGNATION.ADMIN
                        ? "/admin/practitioner"
                        : "/dashboard"
                  }
                  className="mb-3 btn text-decoration-none"
                  style={{background: "#EFEFEF"}}
              >
                <ArrowLeft size={16}/> Back
              </Link>
            </div>
          </Col>
          <Col xs={12}>
            <div
                className="personal-information pb-4"
            >
              <p className="fw-semibold fs-5">Personal Information</p>
              {userDetials?.amountOwed > 0 && (
                  <div style={{
                    backgroundColor: "#ECFDF3",
                    color: '#027A48',
                    border: "1px solid #fff",
                  }} className="alert alert-info">
                    <img
                        src={LicenseGreen}
                        alt="licence"
                        className="img-fluid status-success"
                    />{" "}
                    You have an extra {`₦${Math.abs(userDetials?.amountOwed)}`} in your account.
                  </div>
              )}
              <div
                  style={{borderBottom: '1px solid #B0B0B0'}}
                  className="bg-white mb-3 user-passport mb-4 d-flex align-items-center pb-4">
                <div
                    style={{
                      width: "",
                      boxShadow: "0 1px 2px rgba(16,24,60,.05)",
                      padding: "7px",
                      backgroundColor: "#FAE9E9",
                    }}
                    className="d-flex justify-content-center align-items-center rounded-circle"
                >
                  <div>
                    <img
                        src={
                          userDetials?.imageUrl !== "passport" &&
                          userDetials?.imageUrl !== null
                              ? userDetials?.imageUrl
                              : UserImage
                        }
                        width="100"
                        height="100"
                        style={{
                          borderRadius: "50%",
                          border: "1px solid #fff",
                        }}
                        alt="user"
                        className="rounded-circle"
                    />
                  </div>
                </div>
                <div className="p-3">
                  <p className="fs-4 mb-0 fw-semibold lh-base text-capitalize">
                    {userDetials?.firstName}{" "}
                    {userDetials?.middleName === "unavailable" ||
                    userDetials?.middleName === null
                        ? ""
                        : userDetials?.middleName}{" "}
                    {userDetials?.lastName}
                  </p>
                  <div className="fs-6 fw-400">
                    VCN Number: {userDetials?.vcnId}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Card className="border-none border-white">
            <CardBody className="p-0">
              <div className="bg-white">
                <div
                    className="personal-information pb-3"
                    style={{borderBottom: "1px solid #B0B0B0"}}
                >
                  <Row>
                    <Col xl={4} sm={12} md={6}>
                      <FormGroup className="mb-3">
                        <Label htmlFor="valT">Practitioner Title</Label>
                        <Input
                            name="title"
                            placeholder="Practitioner Title"
                            type="text"
                            className="form-control"
                            disabled
                            id="valT"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={userDetials?.practitionerTitle || ""}
                            invalid={
                              validation.touched.title && validation.errors.title
                                  ? true
                                  : false
                            }
                        />
                        {validation.touched.title && validation.errors.title ? (
                            <FormFeedback type="invalid">
                              {validation.errors.title}
                            </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col xl={4} sm={12} md={6}>
                      <FormGroup className="mb-3 ">
                        <Label htmlFor="val1">First Name</Label>
                        <Input
                            name="firstname"
                            placeholder=""
                            type="text"
                            className="form-control"
                            id="val1"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={userDetials?.firstName}
                            disabled
                            invalid={
                              validation.touched.firstname &&
                              validation.errors.firstname
                                  ? true
                                  : false
                            }
                        />
                        {validation.touched.firstname &&
                        validation.errors.firstname ? (
                            <FormFeedback type="invalid">
                              {validation.errors.firstname}
                            </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>

                    <Col xl={4} sm={12} md={6}>
                      <FormGroup className="mb-3">
                        <Label htmlFor="val3">Other Name</Label>
                        <Input
                            name="othername"
                            placeholder=""
                            type="text"
                            className="form-control"
                            id="val3"
                            disabled
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={
                              userDetials?.middleName === "unavailable" ||
                              userDetials?.middleName === null
                                  ? ""
                                  : userDetials?.middleName || ""
                            }
                            invalid={
                              validation.touched.othername &&
                              validation.errors.othername
                                  ? true
                                  : false
                            }
                        />
                        {validation.touched.othername &&
                        validation.errors.othername ? (
                            <FormFeedback type="invalid">
                              {validation.errors.othername}
                            </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col xl={4} sm={12} md={6}>
                      <FormGroup className="mb-3">
                        <Label htmlFor="val2">Last Name</Label>
                        <Input
                            name="lastname"
                            placeholder=""
                            disabled
                            type="text"
                            className="form-control"
                            id="val2"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={userDetials?.lastName || ""}
                            invalid={
                              validation.touched.lastname &&
                              validation.errors.lastname
                                  ? true
                                  : false
                            }
                        />
                        {validation.touched.lastname &&
                        validation.errors.lastname ? (
                            <FormFeedback type="invalid">
                              {validation.errors.lastname}
                            </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={4} sm={12} md={6}>
                      <FormGroup className="mb-3">
                        <Label htmlFor="val4">Email</Label>
                        <Input
                            name="email"
                            placeholder=""
                            type="text"
                            className="form-control"
                            disabled
                            id="val4"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={userDetials?.email || ""}
                            invalid={
                              validation.touched.email && validation.errors.email
                                  ? true
                                  : false
                            }
                        />
                        {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col xl={4} sm={12} md={6}>
                      <FormGroup className="mb-3">
                        <Label htmlFor="val5">Phone Number</Label>
                        <Input
                            name="phone"
                            placeholder=""
                            type="number"
                            className="form-control"
                            disabled
                            id="val5"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={userDetials?.phoneNumber || ""}
                            invalid={
                              validation.touched.phone && validation.errors.phone
                                  ? true
                                  : false
                            }
                        />
                        {validation.touched.phone && validation.errors.phone ? (
                            <FormFeedback type="invalid">
                              {validation.errors.phone}
                            </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col xl={4} sm={12} md={6}>
                      <FormGroup className="mb-3">
                        <Label htmlFor="val6">Sex</Label>
                        <Input
                            name="sex"
                            type="text"
                            className="form-control"
                            id="val6"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={userDetials?.sex || ""}
                            disabled
                            invalid={
                              validation.touched.sex && validation.errors.sex
                                  ? true
                                  : false
                            }
                        >
                          {/* <option>Select Sex</option> */}
                          {/* <option>Male</option>
                          <option>Female</option> */}
                        </Input>
                        {validation.touched.sex && validation.errors.sex ? (
                            <FormFeedback type="invalid">
                              {validation.errors.sex}
                            </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={4} sm={12} md={6}>
                      <FormGroup className="mb-3">
                        <Label htmlFor="val7">Date of Birth (dd/mm/yyy) </Label>
                        <Input
                            name="dob"
                            type="date"
                            className="form-control"
                            id="val7"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={
                                moment(userDetials?.dateOfBirth).format(
                                    "YYYY-MM-DD"
                                ) || ""
                            }
                            disabled
                            invalid={
                              validation.touched.dob && validation.errors.dob
                                  ? true
                                  : false
                            }
                        />
                        {validation.touched.dob && validation.errors.dob ? (
                            <FormFeedback type="invalid">
                              {validation.errors.dob}
                            </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div
                    className="address pt-4 pb-3"
                    style={{borderBottom: "1px solid #B0B0B0"}}
                >
                  <p className="fw-semibold fs-5">Address</p>
                  <Row>
                    <Col xl={4} sm={12} md={6}>
                      <FormGroup className="mb-3">
                        <Label htmlFor="val9a">Permanent Residence </Label>
                        <Input
                          name="address"
                          type="text"
                          className="form-control"
                          placeholder=""
                          id="val9a"
                          onChange={validation.handleChange}
                          disabled
                          onBlur={validation.handleBlur}
                          value={userDetials?.permanentResidenceAddress || ""}
                          invalid={
                            validation.touched.address &&
                            validation.errors.address
                              ? true
                              : false
                          }
                        />
                        {validation.touched.address &&
                        validation.errors.address ? (
                          <FormFeedback type="invalid">
                            {validation.errors.address}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col xl={4} sm={12} md={6}>
                      <FormGroup className="mb-3">
                        <Label htmlFor="val9">State</Label>
                        <Input
                          name="state"
                          type="text"
                          className="form-control"
                          id="val9"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={userDetials?.state || ""}
                          disabled
                          invalid={
                            validation.touched.state && validation.errors.state
                              ? true
                              : false
                          }
                        >
                          <option>Select State</option>
                          <option>Lagos</option>
                          <option>Kaduna</option>
                        </Input>
                        {validation.touched.state && validation.errors.state ? (
                          <FormFeedback type="invalid">
                            {validation.errors.state}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col xl={4} sm={12} md={6}>
                      <FormGroup className="mb-3">
                        <Label htmlFor="val10">LGA</Label>
                        <Input
                          name="lga"
                          type="text"
                          className="form-control"
                          id="val10"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={
                            userDetials?.residenceLocalGovernmentArea || ""
                          }
                          disabled
                          invalid={
                            validation.touched.lga && validation.errors.lga
                              ? true
                              : false
                          }
                        >
                          <option>...</option>
                          <option>Lagos</option>
                          <option>Lagos</option>
                        </Input>
                        {validation.touched.lga && validation.errors.lga ? (
                          <FormFeedback type="invalid">
                            {validation.errors.lga}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>

                    <Col xl={4} sm={12} md={6}>
                      <Label htmlFor="val7" style={{visibility: "hidden"}}>
                        {" "}
                      </Label>
                      <div className="d-flex mt-2">
                        <button
                            type="button"
                            disabled={userDetials?.imageUrl ? false : true}
                            id="bgred"
                            onClick={() =>
                                downLoadFile({
                                  file: userDetials?.imageUrl,
                                  name: `${userDetials?.fullName}-${userDetials?.vcnId}-passport`,
                                })
                            }
                            style={{
                              borderTopRightRadius: "0px",
                              borderBottomRightRadius: "0px",
                            }}
                            className="btn text-white w-75"
                        >
                          Download Passport
                        </button>
                        <Input
                            style={{
                              cursor: "pointer",
                              borderTopLeftRadius: "0px",
                              borderBottomLeftRadius: "0px",
                            }}
                            value={userDetials?.imageUrl?.substring(64)}
                            disabled
                            className="form-control"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
                <div
                  className="education pb-3 mt-4"
                  style={{ borderBottom: "1px solid #B0B0B0" }}
                >
                  <p className="fw-semibold fs-5">Education & Qualification</p>
                  <Row>
                    <Col xl={4} sm={12} md={6}>
                      <FormGroup className="mb-3">
                        <Label htmlFor="val15">Highest Qualification </Label>
                        <Input
                          name="highestQualification"
                          type="text"
                          className="form-control"
                          id="val15"
                          placeholder="Select Highest Qualification"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={userDetials?.qualification || ""}
                          disabled
                          invalid={
                            validation.touched.highestQualification &&
                            validation.errors.highestQualification
                              ? true
                              : false
                          }
                        >
                          <option>Select Highest Qualification</option>
                          <option>Bachelor of Science (BSC)</option>
                          <option>Master of Science (MSC)</option>
                        </Input>
                        {validation.touched.highestQualification &&
                        validation.errors.highestQualification ? (
                          <FormFeedback type="invalid">
                            {validation.errors.highestQualification}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                    {userType !== DESIGNATION.ADMIN ? (
                      ""
                    ) : (
                      <Col xl={4} sm={12} md={6}>
                        <Label htmlFor="val7" style={{ visibility: "hidden" }}>
                          {" "}
                        </Label>
                        <div className="d-flex">
                          <button
                            type="button"
                            disabled={userDetials?.uploadValidId ? false : true}
                            id="bgred"
                            onClick={() =>
                              downLoadFile({
                                file: userDetials?.uploadValidId,
                                name: `${userDetials?.fullName}-${userDetials?.vcnId}-ID`,
                              })
                            }
                            style={{
                              borderTopRightRadius: "0px",
                              borderBottomRightRadius: "0px",
                            }}
                            className="btn text-white w-75"
                          >
                            Download ID
                          </button>
                          <Input
                            style={{
                              cursor: "pointer",
                              borderTopLeftRadius: "0px",
                              borderBottomLeftRadius: "0px",
                            }}
                            value={userDetials?.uploadValidId?.substring(64)}
                            disabled
                            className="form-control"
                          />
                        </div>
                      </Col>
                    )}
                    {userType !== DESIGNATION.ADMIN ? (
                      ""
                    ) : (
                      <Col xl={4} sm={12} md={6} className="d-none">
                        <Label htmlFor="val7" style={{ visibility: "hidden" }}>
                          {" "}
                        </Label>
                        <div className="d-flex mt-2">
                          <button
                            type="button"
                            disabled={
                              userDetials?.uploadHighestInstitutionalCertificate
                                ? false
                                : true
                            }
                            id="bgred"
                            onClick={() =>
                              downLoadFile({
                                file: userDetials?.uploadHighestInstitutionalCertificate,
                                name: `${userDetials?.fullName}-${userDetials?.vcnId}-Certificate`,
                              })
                            }
                            style={{
                              borderTopRightRadius: "0px",
                              borderBottomRightRadius: "0px",
                            }}
                            className="btn text-white w-100"
                          >
                            Download Certificate
                          </button>
                          <Input
                            style={{
                              cursor: "pointer",
                              borderTopLeftRadius: "0px",
                              borderBottomLeftRadius: "0px",
                            }}
                            value={userDetials?.uploadHighestInstitutionalCertificate?.substring(
                              64
                            )}
                            disabled
                            className="form-control"
                          />
                        </div>
                      </Col>
                    )}
                    {userType === DESIGNATION.ADMIN ? (
                      ""
                    ) : (
                      <Col>
                        <div
                          className="float-end"
                          style={{ marginTop: "2.5rem" }}
                        >
                          Do you want to update your profile?
                          <Link
                            to="#"
                            onClick={() => setShow(!show)}
                            className="textRed text-decoration-none"
                          >
                            {" "}
                            Request Profile Update
                          </Link>
                        </div>
                      </Col>
                    )}
                  </Row>
                </div>
                <Form
                  className="pt-4"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <div className="authentication pt-2">
                    <p className="fw-semibold fs-5">Change Password</p>
                    <Row>
                      <Col xl={4} sm={12} md={6}>
                        <div className="mb-3">
                          <Label htmlFor="val18" className="fs-6">
                            Old Password
                          </Label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <FormGroup className="mb-3">
                              <Input
                                name="oldpassword"
                                placeholder="Enter your Old Password"
                                type={eye === true ? "text" : "password"}
                                className="form-control"
                                id="val18"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.oldpassword || ""}
                                invalid={
                                  validation.touched.oldpassword &&
                                  validation.errors.oldpassword
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.oldpassword &&
                              validation.errors.oldpassword ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.oldpassword}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                            <button
                              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                              type="button"
                              id="password-addon1"
                            >
                              {eye === true ? (
                                <Eye size={14} onClick={() => setEye(false)} />
                              ) : (
                                <EyeOff
                                  onClick={() => setEye(true)}
                                  size={14}
                                />
                              )}
                            </button>
                          </div>
                        </div>
                      </Col>
                      <Col xl={4} sm={12} md={6}>
                        <div className="mb-3">
                          <Label htmlFor="val18a" className="fs-6">
                            New Password
                          </Label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <FormGroup className="mb-3">
                              <Input
                                name="newpassword"
                                placeholder="Enter your New Password"
                                type={eye === true ? "text" : "password"}
                                className="form-control"
                                id="val18a"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.newpassword || ""}
                                invalid={
                                  validation.touched.newpassword &&
                                  validation.errors.newpassword
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.newpassword &&
                              validation.errors.newpassword ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.newpassword}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                            <button
                              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                              type="button"
                              id="password-addon2"
                            >
                              {eye === true ? (
                                <Eye size={14} onClick={() => setEye(false)} />
                              ) : (
                                <EyeOff
                                  onClick={() => setEye(true)}
                                  size={14}
                                />
                              )}
                            </button>
                          </div>
                        </div>
                      </Col>
                      <Col xl={4} sm={12} md={6}>
                        <div className="mb-3">
                          <Label htmlFor="val19" className="fs-6">
                            {" "}
                            Confirm Password
                          </Label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <FormGroup className="mb-3">
                              <Input
                                name="confirmPassword"
                                placeholder="Confirm  Password"
                                type={eye === true ? "text" : "password"}
                                className="form-control"
                                id="val19"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.confirmPassword || ""}
                                invalid={
                                  validation.touched.confirmPassword &&
                                  validation.errors.confirmPassword
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.confirmPassword &&
                              validation.errors.confirmPassword ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.confirmPassword}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                            <button
                              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                              type="button"
                              id="password-addon"
                            >
                              {eye === true ? (
                                <Eye size={14} onClick={() => setEye(false)} />
                              ) : (
                                <EyeOff
                                  onClick={() => setEye(true)}
                                  size={14}
                                />
                              )}
                            </button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>{loading ? <Spinner color="danger" /> : ""}</div>
                    <div>
                      <div className="mt-4 text-end">
                        <button
                          className="btn text-light"
                          id="bgred"
                          type="submit"
                        >
                          Change Password
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </CardBody>
          </Card>
        </Row>
      </Container>
      <RequestProfileModel
        show={show}
        setShow={setShow}
        user={userInfo}
        setUser={setUserInfo}
        requestProfileUpdate={requestProfileUpdate}
        loading={loading}
        setLoading={setLoading}
      />
      <SuccessModal
        show={showSuccessModal}
        setShow={setShowSuccessModal}
        bgTxt={bgTxt}
        smTxt={smTxt}
      />
    </>
  );
};

export default Profile;
