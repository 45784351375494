import Navbar from "../components/Navbar";
import AdminPremisesComponent from "../components/AdminPremises";

const AdminPremises = () => {
  return (
    <>
      <Navbar />
      <div style={{paddingTop: "6rem"}}>
        <AdminPremisesComponent/>
      </div>
    </>
  );
};

export default AdminPremises;
