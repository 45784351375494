import { useEffect, useState } from "react";
import UpdateProfileComponent from "../components/UpdateProfile";
import {useLocation, useNavigate } from "react-router-dom";
import { VeterinarianTabType } from "../util/data";

const UpdateProfile = () => {
  const [tab, setTab] = useState(VeterinarianTabType.Update);
  const location = useLocation();
  const navigate = useNavigate();

    useEffect(() => {
      const searchParams = new URLSearchParams(location.search);
      const tabType = searchParams.get('tab');
  
      if (!tabType || !Object.values(VeterinarianTabType).includes(tabType)) {
        navigate('/login');
      } else {
        setTab(tabType);
      }
      
    }, [location.search, navigate]);
  return (
    <div style={{ backgroundColor: "" }} className="h-100">
      <UpdateProfileComponent tab={tab} />
    </div>
  );
};

export default UpdateProfile;
