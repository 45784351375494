import React, { useEffect, useState } from "react";
import SortImg from "../assets/images/arrowsort.png";
import {Table} from "reactstrap";
import {useNavigate} from "react-router-dom";

function BillingsDashboardTable({ data, passSelectedTransaction }) {
  const [sortKey, setSortKey] = useState("vcnId");
  const [sortOrder, setSortOrder] = useState("ascn");
  const [billingData, setBillingData] = useState([]);
  const navigate = useNavigate();

  function sortData({ tableData, sortKey, reverse }) {
    if (!sortKey) return tableData;

    const sortedData = tableData.sort((a, b) => {
      return a[sortKey] > b[sortKey] ? 1 : -1;
    });

    if (reverse) {
      return sortedData.reverse();
    }

    return sortedData;
  }

  function SortButton({ onClick }) {
    return (
        <span id="sort" style={{ cursor: "pointer" }} onClick={onClick}>
        <img src={SortImg} className="img-fluid float-end" alt="sort" />
      </span>
    );
  }

  const headers = [
    { key: "fullName", label: "Full Name" },
    {key: 'vcnId', label: 'VCN Number'},
    { key: "description", label: "Description" },
    { key: "amountOwed", label: "Amount Owed" },
    { key: "id", label: "Product ID" },
    { key: "productId", label: "Billing ID" },
    { key: "productCategory", label: "Product Category" },
    // { key: "dateCreated", label: "Date Created" },
    { key: "makeTransaction", label: "Pay" },
  ];

  useEffect(() => {
    setBillingData(
        sortData({ tableData: data, sortKey, reverse: sortOrder === "desc" })
    );
  }, [data, sortKey, sortOrder]);

  function changeSort(key) {
    setSortOrder(sortOrder === "ascn" ? "desc" : "ascn");
    setSortKey(key);
    setBillingData(
        sortData({ tableData: data, sortKey, reverse: sortOrder === "desc" })
    );
  }

  return (
      <div className="fixTableHead">
        <Table
            responsive
            id="table-border-collapse"
            className="table table-borderless table-hover align-middle table-nowrap headerFixed"
        >
          <thead className="admin-table-red headerFixed">
          <tr>
            {headers.map((row) => {
              return (
                  <th
                      scope="col"
                      className="text-nowrap headerFixed"
                      key={row.key}
                      style={{padding: '12px'}}
                  >
                    {row.label}{' '}
                    <SortButton
                        columnKey={row.key}
                        onClick={() => changeSort(row.key)}
                        {...{
                          sortOrder,
                          sortKey,
                        }}
                    />
                  </th>
              );
            })}
          </tr>
          </thead>

          <tbody>
          {billingData.map((e) => {

            return (
                <tr
                    key={e.id}
                    style={{cursor: 'pointer'}}
                >
                   <td
                    className="text-nowrap"
                    style={{padding: '12px'}}
                >
                  {e.user.fullName || '--'}
                   </td>
                  <td className="text-nowrap">{e.user.vcnId || '--'}</td>
                  <td className="text-nowrap">{e.description || '--'}</td>
                  <td className="text-nowrap">₦{new Intl.NumberFormat('en-US').format(e.totalAmountOwed)}{""}</td>
                  <td className="text-nowrap">
                    {e.productId || '--'}
                  </td>
                  <td className="text-nowrap">
                    {e.id || '--'}
                  </td>
                  <td className="text-nowrap">
                    {e.product.productCategoryType || '--'}
                  </td>
                  {/* <td className="text-nowrap"> {e.dateCreated ? new Date(e.dateCreated).toDateString() : '--'}</td> */}
                  <td>
                    <button
                        id="bgred"
                        className="btn text-light"
                        type="submit"
                        onClick={() => navigate(`/admin/paybill?billId=${e.id}&vcnId=${e.user.vcnId}&userId=${e.userId}`)}
                    >
                      Pay
                    </button>
                  </td>
                </tr>
            );
          })}
          </tbody>
        </Table>
      </div>
  );
}

export default BillingsDashboardTable;
