import React, {useEffect, useState, useCallback, useContext} from 'react';
import { Modal, ModalBody, ModalHeader, Col, Input, FormGroup, Button, Form, Label } from 'reactstrap';
import FooterLogo from '../../assets/images/footer.svg';
import { request } from "../../services/utilities";
import { Store } from "../../context/store";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import SuccessModal from "./SuccessModal";
import {useNavigate} from "react-router-dom";
import ErrorModal from "./ErrorModal";
import BillSuccessModal from "./BillSuccessModal";
import ConfirmActionsModal from "./ConfirmActionsModal";

const AddBillModal = ({ show, setShow, premiseId }) => {
  const store = useContext(Store);
  const [user] = store.user;
  const navigate = useNavigate();

  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedProducts, setSelectedProducts] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [products, setProducts] = useState([]);
  const [price, setPrice] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState([]);
  const [bgTxt, setBgTxt] = useState("Bill Uploaded Successfully");
  const [smTxt, setSmTxt] = useState(
      "You have successfully uploaded your bill"
  );
  const [showE, setShowE] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const text = `Do you want to add the bill of ₦${selectedProducts.price} to premise: ${selectedProducts.name}`

  const fetchProducts = useCallback(async () => {
    try {
      const url = `Product/GetProducts?ProductCategoryType=PremiseLicense&IsActive=true`;
      const rs = await request(url, "GET", false);
      if (rs.code === "OK") {
        setProducts(rs.payload.items);
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  const handleProductChange = (e) => {
    const selectedProductId = e.target.value;
    const selectedProduct = products.find(product => product.id.toString() === selectedProductId);

    setSelectedProducts(selectedProduct);
    setSelectedProduct(selectedProductId);
    setPrice(selectedProduct ? selectedProduct.price : '');
  };

  const validationSchema = Yup.object({
    selectedProduct: Yup.string().required('Product is required'),
    price: Yup.number().required('Price is required'),
  });

  const formik = useFormik({
    initialValues: {
      description: '',
      selectedProduct: '',
      price: '',
    },
    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = async () => {
    setShowConfirmModal(false)
    try {
      const data = {
        createBillForAllUsers: false,
        description: selectedProducts.description,
        totalAmountOwed: selectedProducts.price,
        productId: selectedProducts.id,
        billingParameters: [{
          userId: user.id,
          description: selectedProducts.description,
          totalAmountOwed: selectedProducts.price,
          productId: selectedProducts.id,
          premisesId: premiseId,
        }],
      }
      const url = `BillingStructure/CreateBillings`;
      const rs = await request(url, "POST", true, data);
      setLoading(true);
      if (rs.hasErrors === true) {
        if (rs.errors) {
          setError(rs.errors);
          console.log(rs.errors);
        }
        if (rs.description) {
          setError([rs.description]);
        }
        if (rs.payload) {
          setError(rs.payload);
        }
        setShowE(true)
      }
      if (rs.code === "OK") {
        setBgTxt("Bill Created Successfully");
        setSmTxt(`You have successfully Created a bill`);
        setShowSuccessModal(true);
        formik.resetForm();
      }
      setLoading(false);
      const timer = setTimeout(() => setError([]), 6000);
      return () => clearTimeout(timer);
    } catch (err) {
      setLoading(false);
      setError(err);
      setShowE(true)
      console.log(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (show) {
      setSelectedProduct('');
      setPrice('');
      fetchProducts();
    }
  }, [show, fetchProducts]);

  return (
      <FormGroup>
        <Modal isOpen={show} centered className="w-100">
          <ModalHeader
              style={{ borderBottom: 'none' }}
              className="modal-title pb-0 fw-bold"
              toggle={() => {
                setShow(!show);
              }}
          >
          </ModalHeader>
          <ModalBody style={{padding: '2em 5em'}}>
            <div className="mb-3 fw-semibold" style={{textAlign: 'center', fontSize: '1.5rem'}}>
              Bill Amount - <span style={{color: '#CE2126'}}>₦{new Intl.NumberFormat("en-US").format(price)}</span>
            </div>
            <Form onSubmit={handleSubmit}>
              <div className="row g-3">
                <Col xxl={12} className="m-0">
                  <Label htmlFor="productDropdown" className="form-label pt-3">
                    <span style={{color: '#CE2126'}}>*</span> Select Premise Type
                  </Label>
                  <Input
                      type="select"
                      value={selectedProduct}
                      onChange={handleProductChange}
                      className="custom-select"
                      id="productDropdown"
                      required
                  >
                    <option value="">Select Premise Type</option>
                    {products.map(product => (
                        <option key={product.id} value={product.id}>
                          {product.name}
                        </option>
                    ))}
                  </Input>
                </Col>
                <Col xxl={12} className="m-1">
                  <Label htmlFor="price" className="form-label pt-3">
                    <span style={{color: '#CE2126'}}>*</span> Price
                  </Label>
                  <Input
                      type="text"
                      value={price}
                      className="form-control"
                      id="price"
                      readOnly
                  />
                </Col>
                <Col lg={12}>
                  <div className="d-grid gap-2">
                    <Button
                        type="button"
                        className="login-btn p-2 text-white"
                        onClick={() => setShowConfirmModal(true)}
                    >
                      Add License Type
                    </Button>
                  </div>
                </Col>
              </div>
            </Form>
          </ModalBody>
          <div className="text-center py-2" style={{background: '#FAFAFA', borderRadius: 'calc(0.5rem - 1px)'}}>
            <p className="mb-0">
              <img
                  src={FooterLogo}
                  width="20"
                  className="img-fluid"
                  alt="footer"
              />{" "}
              Powered by Milsat Technologies
            </p>
          </div>
        </Modal>

        <ConfirmActionsModal
            show={showConfirmModal}
            setShow={setShowConfirmModal}
            text={text}
            smText={""}
            action={handleSubmit}
        />

        <BillSuccessModal
            show={showSuccessModal}
            setShow={setShowSuccessModal}
            bgTxts={'Premise Bill Successfully Added'}
            smTxts={smTxt}
            action={() => navigate(`/payments?tab=Pending-Bills`)}
            product={selectedProducts}
            user={user}
            price={price}
        />

        <ErrorModal
            show={showE}
            setShow={setShowE}
            bgTxt={"Failed"}
            smTxt={'Could Not Add Bill'}
            serverError={error}
        />
      </FormGroup>
  );
};

export default AddBillModal;
