import { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import AddUserComponent from "../components/AddUser";
import {useLocation, useNavigate } from "react-router-dom";
import { DesignationType } from "../util/data";

const AddUser = () => {
  const [designation, setDesignation] = useState(DesignationType.Veterinarian);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    // Get the 'designation' query parameter
    const designationType = searchParams.get('designation');

    if (!designationType) {
      // Redirect to a default route or any specific route when 'role' is not found
      navigate('/admin/practitioner');
    }

    setDesignation(designationType);

  }, [location.search, navigate]);

  return (
    <>
      <Navbar />
      <div style={{ paddingTop: "8rem"}}>
        <AddUserComponent designation={designation} />
      </div>
    </>
  );
};

export default AddUser;
