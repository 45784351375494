import Navbar from "../components/Navbar";
import CertificatesComponent from "../components/Certificates";

const Certificates = () => {
  return (
      <>
        <Navbar />
        <div style={{paddingTop: "6rem"}}>
          <CertificatesComponent/>
        </div>
      </>
  );
};

export default Certificates;