import {
  Modal,
  ModalBody,
  ModalHeader,
  Col,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import moment from "moment";
import PlaceholderImage from "../../assets/images/placeholder.png";

const ProcessRequestProfileModal = ({
  show,
  setShow,
  user,
  error,
  loading,
  handleComfirmActionText,
}) => {
  return (
    <>
      <Modal isOpen={show} centered className="" size="lg">
        <ModalHeader
          style={{ borderBottom: "none" }}
          className="modal-title pb-0 fw-bold"
          toggle={() => {
            setShow(!show);
          }}
        >
          Profile Update Request
        </ModalHeader>
        <div className="float-end  mx-5">
          {error.map((e) => {
            return (
              <div
                key={e}
                className="alert text-center w-100 mb-0 alert-danger alert-dismissible border-2 p-1 fade show"
                role="alert"
                style={{ background: "#fff", fontSize: "14px" }}
              >
                {e}
              </div>
            );
          })}
        </div>
        <ModalBody className="">
          <form action="#">
            <div className="row">
              <Col xxl={12}>
                <div>
                  <label htmlFor="amount" className="fs14 form-label">
                    VCN Number
                  </label>
                  <Input
                    type="number"
                    className="form-control fs14"
                    id="vcn"
                    value={user?.vcnId}
                    disabled
                  />
                </div>
              </Col>
            </div>
            <div>
              <p className="fw-bolder text-uppercase fs14 pt-3 border-bottom">
                {" "}
                Personal Information
              </p>
              {/* firsr name */}
              {user?.firstName?.request && (
                <Row className="mb-3">
                  <Col xl={6}>
                    <div>
                      <label htmlFor="amount" className="fs14 form-label">
                        Current First Name
                      </label>
                      <Input
                        type="text"
                        className="form-control fs14"
                        id="cfirstName"
                        value={user?.firstName?.current}
                        disabled
                      />
                    </div>
                  </Col>
                  <Col xl={6}>
                    <div>
                      <label htmlFor="amount" className="fs14 form-label">
                        Requested First Name Change
                      </label>
                      <Input
                        type="text"
                        className="form-control fs14"
                        id="rfirstName"
                        value={user?.firstName?.request}
                        disabled
                      />
                    </div>
                  </Col>
                </Row>
              )}
              {/* middle name */}
              {user?.middleName?.request && (
                <Row className="mb-3">
                  <Col xl={6}>
                    <div>
                      <label htmlFor="amount" className="fs14 form-label">
                        Current Middle Name
                      </label>
                      <Input
                        type="text"
                        className="form-control fs14"
                        id="cmiddleName"
                        value={user?.middleName?.current}
                        disabled
                      />
                    </div>
                  </Col>
                  <Col xl={6}>
                    <div>
                      <label htmlFor="amount" className="fs14 form-label">
                        Requested Middle Name Change
                      </label>
                      <Input
                        type="text"
                        className="form-control fs14"
                        id="rmiddleName"
                        value={user?.middleName?.request}
                        disabled
                      />
                    </div>
                  </Col>
                </Row>
              )}
              {/* last name */}
              {user?.lastName?.request && (
                <Row className="mb-3">
                  <Col xl={6}>
                    <div>
                      <label htmlFor="clastname" className="fs14 form-label">
                        Current Last Name
                      </label>
                      <Input
                        type="text"
                        className="form-control fs14"
                        id="clastname"
                        value={user?.lastName?.current}
                        disabled
                      />
                    </div>
                  </Col>
                  <Col xl={6}>
                    <div>
                      <label htmlFor="rlastname" className="fs14 form-label">
                        Requested Last Name Change
                      </label>
                      <Input
                        type="text"
                        className="form-control fs14"
                        id="rlastname"
                        value={user?.lastName?.request}
                        disabled
                      />
                    </div>
                  </Col>
                </Row>
              )}
              {/* address */}
              {user?.address?.request && (
                <Row className="mb-3">
                  <Col xl={6}>
                    <div>
                      <label htmlFor="caddress" className="fs14 form-label">
                        Current Address
                      </label>
                      <Input
                        type="text"
                        className="form-control fs14"
                        id="caddress"
                        value={user?.address?.current}
                        disabled
                      />
                    </div>
                  </Col>
                  <Col xl={6}>
                    <div>
                      <label htmlFor="raddress" className="fs14 form-label">
                        Requested Address Change
                      </label>
                      <Input
                        type="text"
                        className="form-control fs14"
                        id="raddress"
                        value={user?.address?.request}
                        disabled
                      />
                    </div>
                  </Col>
                </Row>
              )}
              {/* phone number */}
              {user?.phoneNumber?.request && (
                <Row className="mb-3">
                  <Col xl={6}>
                    <div>
                      <label htmlFor="cphone" className="fs14 form-label">
                        Current Phone Number
                      </label>
                      <Input
                        type="text"
                        className="form-control fs14"
                        id="cphone"
                        value={user?.phoneNumber?.current}
                        disabled
                      />
                    </div>
                  </Col>
                  <Col xl={6}>
                    <div>
                      <label htmlFor="rphone" className="fs14 form-label">
                        Requested Phone Number Change
                      </label>
                      <Input
                        type="text"
                        className="form-control fs14"
                        id="rphone"
                        value={user?.phoneNumber?.request}
                        disabled
                      />
                    </div>
                  </Col>
                </Row>
              )}
              {/* date of birth */}
              {user?.dateOfBirth?.request && (
                <Row className="mb-3">
                  <Col xl={6}>
                    <div>
                      <label htmlFor="cdate" className="fs14 form-label">
                        Current Date Of Birth
                      </label>
                      <Input
                        type="text"
                        className="form-control fs14"
                        id="cdate"
                        value={user?.dateOfBirth?.current.split("T")[0]}
                        disabled
                      />
                    </div>
                  </Col>
                  <Col xl={6}>
                    <div>
                      <label htmlFor="rdate" className="fs14 form-label">
                        Requested Date Of Birth Change
                      </label>
                      <Input
                        type="text"
                        className="form-control fs14"
                        id="rdate"
                        value={user?.dateOfBirth?.request.split("T")[0]}
                        disabled
                      />
                    </div>
                  </Col>
                </Row>
              )}
              {/* image ur; */}
              {user?.imageUrl?.request && (
                <Row className="mb-3">
                  <Col xl={6}>
                    <div className="bg-white mb-3 user-passport mb-4 d-flex align-items-end">
                      <div
                        style={{
                          width: "",
                          boxShadow: "0 1px 2px rgba(16,24,60,.05)",
                          padding: "10px",
                        }}
                      >
                        <img
                          src={
                            user?.imageUrl?.current
                              ? user?.imageUrl?.current
                              : PlaceholderImage
                          }
                          width="150"
                          height="150"
                          className="rounded-circle"
                          alt="user passport"
                        />
                      </div>
                    </div>
                    <div>
                      <label htmlFor="cimage" className="fs14 form-label">
                        Current Image
                      </label>
                      {/* <Input
                        type="text"
                        className="form-control fs14"
                        id="cimage"
                        value={user?.imageUrl?.current.substring(64)}
                        disabled
                      /> */}
                    </div>
                  </Col>
                  <Col xl={6}>
                    <div>
                      <div className="bg-white mb-3 user-passport mb-4 d-flex align-items-end">
                        <div
                          style={{
                            width: "",
                            boxShadow: "0 1px 2px rgba(16,24,60,.05)",
                            padding: "10px",
                          }}
                        >
                          <img
                            src={
                              user?.imageUrl?.request
                                ? user?.imageUrl?.request
                                : PlaceholderImage
                            }
                            width="150"
                            height="150"
                            className="rounded-circle"
                            alt="user passport"
                          />
                        </div>
                      </div>
                      <label htmlFor="rimage" className="fs14 form-label">
                        Requested Image Change
                      </label>
                      {/* <Input
                        type="text"
                        className="form-control fs14"
                        id="rimage"
                        value={user?.imageUrl?.request.substring(64)}
                        disabled
                      /> */}
                    </div>
                  </Col>
                </Row>
              )}
            </div>
            <Row className="align-items-center">
              <Col>
                {user?.status !== "Pending" && (
                  <div>
                    <p className="fs14 mt-3 fw-bolder fst-italic">
                      {user?.status} {moment(user?.dateUpdated).fromNow()}
                    </p>
                  </div>
                )}
              </Col>
              <Col>
                <div className="d-flex justify-content-between">
                  <div>{loading ? <Spinner color="danger" /> : ""}</div>
                  <div className="float-end mt-3">
                    {user?.status === "Pending" && (
                      <div>
                        <button
                          type="button"
                          disabled={user?.status === "Approved" && true}
                          onClick={() =>
                            handleComfirmActionText(
                              user.vcnId,
                              "Reject",
                              user.id
                            )
                          }
                          className="btn fs14 border-danger bg-white text-danger"
                        >
                          Reject Change
                        </button>
                        <button
                          id="bgred"
                          type="button"
                          disabled={user?.status === "Approved" && true}
                          onClick={() =>
                            handleComfirmActionText(
                              user.vcnId,
                              "Approve",
                              user.id
                            )
                          }
                          className="btn fs14 mx-1 text-light"
                        >
                          Approve Change
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ProcessRequestProfileModal;
