import ResetPassswordComponent from "../components/ResetPassword";

const ResetPasssword = () => {
  return (
    <div style={{ backgroundColor: "#eee", height: "100vh" }}>
      <ResetPassswordComponent />
    </div>
  );
};

export default ResetPasssword;
