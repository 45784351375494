import Navbar from "../components/Navbar";
import { ToastContainer } from "react-toastify";
import RecordOfflineTransactionComponent from "../components/RecordOfflineTransaction";

const RegisterPremises = () => {
  return (
      <>
        <Navbar />
        <div style={{paddingTop: "6rem"}}>
          <RecordOfflineTransactionComponent />
        </div>
        <ToastContainer />
      </>
  );
};

export default RegisterPremises;
