import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useContext,
} from "react";
import {
  Container,
  Card,
  Spinner,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  Label,
  FormGroup,
  FormFeedback, Tooltip,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import {Link, useNavigate, useParams} from "react-router-dom";
import FooterLogo from "../assets/images/footer.svg";
import {
  practitionerName,
  request,
  scrollToTop,
  uploadCertification,
} from "../services/utilities";
import SuccessModal from "./modals/SuccessModal";
import { Store } from "../context/store";
import AsyncSelect from "react-select/async";
import { termsAndCondtion } from "../util/data";
import TermsAndPolicy from "./TermsAndPolicy";
import { Toast } from "./shared/Toast";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import fileDownload from "js-file-download";
import axios from "axios";
import ConfrimActionsModal from "../components/modals/ConfirmActionsModal";
import { DESIGNATION } from "../services/constants";
import {ArrowLeft, Server} from "react-feather";
import GreenCheckIcon from "../assets/images/greenCheckIcon.svg"
import MultiSelectComboBox from "./combobox/MultiSelectComboBox";

const RegisterPremises = () => {
  const store = useContext(Store);
  const [allState] = store.state;
  const [userType] = store.userType;
  const [user] = store.user;
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState([]);
  const [allLga, setAllLga] = useState([]);
  const [premises, setPremises] = useState([]);
  const [loading, setLoading] = useState(false);
  const [validatePremiseManagers, setValidatePremiseManager] = useState(false);
  const [validatePremiseOwners, setValidatePremiseOwner] = useState(false);
  const [showT, setShowT] = useState(false);
  const [certificate, setCertificate] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [facility, setFacility] = useState("");
  const [csc, setCsc] = useState("");
  const [premise, setPremise] = useState("");
  const [service, setService] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [stateCode, setStateCode] = useState("");
  const [lgaCode, setLgaCode] = useState("");
  const [lga, setLga] = useState("");
  const [vcn, setVcn] = useState("");
  const [premiseOwner, setPremiseOwner] = useState("");
  const [userOwnerId, setUserOwnerId] = useState("");
  const [premiseManager, setPremiseManager] = useState("");
  const [premiseOwnerValidation, setPremiseOwnerValidation] = useState(null);
  const [premiseManagerValidation, setPremiseManagerValidation] = useState(null);
  const [whoAreYou, setWhoAreYou] = useState('');
  const [serviceError, setServiceError] = useState("");
  const [disabledReason, setDisabledReason] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [serviceTouched, setServiceTouched] = useState(false);
  const [managerStatusText, setManagerStatusText] = useState('');
  const [managerStatusType, setManagerStatusType] = useState('');
  const [ownerStatusText, setOwnerStatusText] = useState('');
  const [ownerStatusType, setOwnerStatusType] = useState('');

  const [approval, setApproval] = useState("");
  const [userId, setUserId] = useState("");
  const params = useParams();
  const ownerId = params?.ownerId;
  const id = params?.id;
  const [actionStatus, setActionStatus] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [text, setText] = useState("");
  const [bgTxt, setBgTxt] = useState("Registration Completed Successfully");
  const [smTxt, setSmTxt] = useState(
    "You have successfully resgistered your premise"
  );
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const navigate = useNavigate();

  const toggleTooltip = (val) => setTooltipOpen(val);

  const serviceOptions = [
    'Treatments of animals diseases',
    'Vaccination',
    'Surgeries',
    'Pets Shop',
    'Fumigation',
  ]

  const inputStyle = {
    backgroundColor: "#F5F5F5",
    border: "1px solid #EBEBEB",
  };

  const handleServicesChange = (newSelectedServices) => {
    setService(newSelectedServices);
    setServiceTouched(true);
    // Validate the service field
    const error = validateService(newSelectedServices);
    setServiceError(error);
  };

// Function to validate the service field
  const validateService = (selectedServices) => {
    if (!selectedServices) {
      return "Please Select Service Offered";
    }
    return "";
  };

  const getValidationSchema = () => {
    let schema = {
      facility: Yup.string().required("Please Enter Facility Name"),
      csc: Yup.string().required("Please Enter CSC Registration No"),
      premise: Yup.string().required("Please Select Premise Type"),
      email: Yup.string().required("Please Enter Facility Email Address"),
      address: Yup.string().required("Please Enter Your Company Address"),
      state: Yup.string().required("Please Enter Your State"),
      lga: Yup.string().required("Please Enter Your LGA"),
      phone: Yup.string()
          .required("Please Enter Your Phone Number")
          .max(11, "Maximum of 11 number!"),
      certificate: Yup.string().required("Please Upload CSC Certificate"),
    };

    if (whoAreYou !== 'premiseOwner' && whoAreYou !== '') {
      schema.premiseOwner = Yup.string().required("Please Select Premise Owner");
    }

    return Yup.object(schema);
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      facility,
      csc,
      premise,
      email,
      phone,
      address,
      state,
      lga,
      certificate,
      stateCode,
      lgaCode,
      premiseOwner,
      premiseManager,
      vcn,
      userId,
    },
    validationSchema: getValidationSchema(),
    onSubmit: async (e) => {
      setLoading(true);
      scrollToTop();
      const cert = await uploadCertification(e.certificate);
      const adddata = [
        {
          emailAddress: e.email,
          phoneNumber: e.phone.toString(),
          lgaCode: e.lgaCode,
          stateCode: e.stateCode,
          stateName: e.state,
          lgaName: e.lga,
          cacRegNumber: e.csc,
          premiseType: e.premise,
          clinicalServicesType: service,
          address: e.address,
          facilityName: e.facility,
          certificateImage: cert ? cert : "",
          managingDirectorVCNID: premiseManagerValidation?.vcnId ? premiseManagerValidation?.vcnId : null,
          cacRegName: e.csc,
          managerId: premiseManagerValidation?.id ? premiseManagerValidation?.id : null,
          premiseLocalNumber: "",
          manufacturersType: "",
          distributorType: "",
          facilitiesAvailable: "",
          afflictedVeterinaryClinic: "",
          managingDirectorFullName: premiseManagerValidation?.name ? premiseManagerValidation?.name : null,
          inspectorFullName: "",
          inspectionDate: "",
          inspectorId: "",
          licenceYear: "",
          recommendation: "",
          longtitude: "",
          latitude: "",
          accuracy: "",
          pharmacyType: "",
          companyLogo: "",
          premiseManager: premiseManagerValidation?.vcnId ? premiseManagerValidation?.vcnId : null,
          premiseOwner: whoAreYou === 'premiseOwner' ? user?.id : premiseOwnerValidation?.id,
          ownerId: whoAreYou === 'premiseOwner' ? user?.id : premiseOwnerValidation?.id,
        },
      ];
      const updatedata = [
        {
          id,
          logo: "",
          emailAddress: e.email,
          phoneNumber: e.phone.toString(),
          lgaCode: e.lgaCode,
          stateCode: e.stateCode,
          stateName: e.state,
          lgaName: e.lga,
          cacRegNumber: e.csc,
          premiseType: e.premise,
          clinicalServicesType: service,
          address: e.address,
          facilityName: e.facility,
          certificateImage: cert ? cert : "",
          managingDirectorVCNID: premiseManagerValidation?.vcnId ? premiseManagerValidation?.vcnId : null,
          cacRegName: e.csc,
          managerId: whoAreYou === 'premiseManager' ? user?.id : premiseManagerValidation?.id,
          premiseLocalNumber: "",
          manufacturersType: "",
          distributorType: "",
          facilitiesAvailable: "",
          afflictedVeterinaryClinic: "",
          managingDirectorFullName: premiseManagerValidation?.name ? premiseManagerValidation?.name : null,
          inspectorFullName: "",
          inspectionDate: "",
          inspectorId: "",
          licenceYear: "",
          recommendation: "",
          longtitude: "",
          latitude: "",
          accuracy: "",
          pharmacyType: "",
          companyLogo: "",
          premiseManager: whoAreYou === 'premiseManager' ? user?.vcnId : premiseManagerValidation?.vcnId,
          premiseOwner: userOwnerId,
          ownerId: userOwnerId,
        },
      ];
      const data = id ? updatedata : adddata;
      try {
        const url = `premises/${id ? "updatePremises" : "addPremises"}`;
        const rs = await request(url, "POST", true, data);
        console.log(rs);
        setLoading(false);
        if (rs.hasErrors === true) {
          if (rs.errors) {
            setError(rs.errors);
          }
          if (rs.description) {
            setError([rs.description]);
          }
          if (rs.payload) {
            setError(rs.payload);
          }
        }
        if (rs.code === "OK") {
          if (id) {
            setBgTxt("Update Completed Successfully");
            setSmTxt("You have successfully updated your premise");
          }
          setShowSuccessModal(true);
        }
        const timer = setTimeout(() => setError([]), 3000);
        return () => clearTimeout(timer);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    },
  });

  const getOptionValues = (option) => option.id;
  const getOptionLabels = (option) => practitionerName(option, true);

  const fetchPractitionerByVcnId = async (e) => {
    try {
      const url = `profile/getusers?vcnId=${e}`;
      const rs = await request(url, "GET", true);
      return rs.payload.items;
    } catch (err) {
      console.log(err);
    }
  };
  const handleToggleConfirmModal = (action) => {
    setText(
      `You Are About To ${
        action === "NotApproved" ? "Disapprove" : "Approve"
      } The Practitioner Premise  For VCNID ${vcn}.`
    );
    setActionStatus(action);
    setShowConfirmModal(true);
  };
  const fetchLga = useCallback(async (e) => {
    try {
      const url = `Locations/GetLGA?state=${e}`;
      const rs = await request(url, "GET", false);
      setAllLga(rs.payload);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const fetchUserPremise = useCallback(
    async (e) => {
      setLoading(true);
      try {
        const url = `premises/getpremises?id=${id}&ownerId=${ownerId}`;
        const rs = await request(url, "GET", true);
        setLoading(false);
        handleSetPremiseValues(rs.payload.items[0]);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    },
    [id]
  );

  const downLoadFile = ({ file, name }) => {
    const fileArr = file.split(".");
    const lastindex = fileArr.length - 1;
    const ext = fileArr[lastindex];

    axios
      .get(file, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, `${name}.${ext}`);
        notifyToast();
      });
  };
  const notifyToast = () =>
    toast.success(<Toast tbgt="Downloaded Successfully" />, {
      icon: false,
      hideProgressBar: false,
    });

  const handleSetPremiseValues = (e) => {
    setApproval(e.approvalStatus);
    fetchLga(e.stateName);
    setVcn(e.managingDirectorVCNID);
    setUserId(e.managerId);
    setFacility(e.facilityName);
    setCsc(e.cacRegNumber);
    setPremise(e.premiseType);
    setService(e.clinicalServicesType);
    setEmail(e.emailAddress);
    setPhone(e.phoneNumber);
    setCertificate(e.certificateImage);
    setAddress(e.address);
    setState(e.stateName);
    setStateCode(e.stateCode);
    setLga(e.lgaName);
    setLgaCode(e.lgaCode);
    setUserOwnerId(e.ownerId)
    setPremiseOwner(e.premiseOwner);
    setPremiseManager(e.premiseManager);
  };

  const handleApprovalStatus = useCallback(async () => {
    setLoading(true);
    setShowConfirmModal(false);
    const data = { approvalStatus: actionStatus, premiseId: id };
    const url = `premises/updatepremisestatus`;
    try {
      const rs = await request(url, "POST", true, data);
      setLoading(false);
      setBgTxt(
        `${
          actionStatus === "NotApproved" ? "Disapproved" : "Approved"
        }  Successfully`
      );
      setSmTxt(
        `You have successfully ${
          actionStatus === "NotApproved" ? "disapproved" : "approved"
        } premise`
      );
      setApproval(actionStatus);
      setShowSuccessModal(true);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }, []);

  const getStatus = (isValid, isLicensed) => {
    console.log(isValid, isLicensed, 'TOBE')
    if (isValid && isLicensed) {
      return { text: 'Practitioner is eligible', type: 'success' };
    } else if (!isValid && !isLicensed) {
      return { text: 'Practitioner is not eligible', type: 'error' };
    } else if (!isValid && isLicensed) {
      return { text: "Practitioner's profile not approved", type: 'error' };
    } else if (isValid && !isLicensed) {
      return { text: 'Practitioner license has expired', type: 'error' };
    }
    return { text: '', type: '' };
  };

  const validatePremiseOwner = useCallback(async (name) => {
    if (!name || name.trim().length === 0) {
      validation.setFieldTouched('premiseOwner');
      validation.setFieldError("premiseOwner", "Please enter a Premise Owner name for validation");
      return;
    }

    try {
      setLoading(true)
      setValidatePremiseOwner(true)
      const url = `Profile/GetUserProfileById?vcnId=${name}`;
      const rs = await request(url, "GET", true);
      if (rs.payload) {
        setPremiseOwnerValidation({
          isValid: rs.payload.accessStatus === 'Approve',
          name: rs.payload.fullName,
          id: rs.payload.id,
          vcnId: rs.payload.vcnId,
          status: rs.payload.accessStatus,
        });

        // Only run license validation if rs.payload is true
        const url2 = `BillingStructure/GetBillings?VcnId=${name}&PaymentCompleted=${false}&ProductCategoryType=PractiseLicense`;
        try {
          const response = await request(url2, "GET", true);
          if (response.code === "OK") {
            const isLicensed = response.payload.items.length === 0;
            setPremiseOwnerValidation((prev) => ({
              ...prev,
              isLicensed: isLicensed,
              licenseStatus: isLicensed ? "Licensed" : "Not Licensed",
            }));
          } else if (response.hasErrors === true) {
            validation.setFieldError("premiseOwner", rs.errors[0]);
          }
        } catch (err) {
          setError(["Failed to validate license owner"]);
          setLoading(false);
          console.log(err);
        }
      } else if (rs.hasErrors === true) {
        setPremiseOwnerValidation({
          isValid: false,
          name: null,
          status: "Not Approved",
        });
        validation.setFieldError("premiseOwner", rs.errors[0]);
      } else {
        setPremiseOwnerValidation({
          isValid: false,
          name: null,
          status: "Not Approved",
        });
      }
    } catch (err) {
      setLoading(false)
      setValidatePremiseOwner(false)
      console.log(err);
      setError(["Failed to validate premise owner"]);
      validation.setFieldError("premiseOwner", "Failed to validate premise owner");
    }
    setLoading(false)
    setValidatePremiseOwner(false)
  }, []);

  const validatePremiseManager = async (name) => {
    if (!name || name.trim().length === 0) {
      validation.setFieldTouched('premiseManager');
      validation.setFieldError("premiseManager", "Please enter a Premise Manager name for validation");
      return;
    }

    try {
      setLoading(true)
      setValidatePremiseManager(true)
      const url = `Profile/GetUserProfileByVcnId?vcnId=${name}`;
      const rs = await request(url, "GET", true);
      if (rs.payload) {
        setPremiseManagerValidation({
          isValid: rs.payload.accessStatus === 'Approve',
          id: rs.payload.id,
          vcnId: rs.payload.vcnId,
          name: rs.payload.fullName,
          status: rs.payload.accessStatus,
        });

        // Only run license validation if rs.payload is true
        const url2 = `BillingStructure/GetBillings?VcnId=${name}&PaymentCompleted=${false}&ProductCategoryType=PractiseLicense`;
        try {
          const response = await request(url2, "GET", true);
          if (response.code === "OK") {
            const isLicensed = response.payload.items.length === 0;
            setPremiseManagerValidation((prev) => ({
              ...prev,
              isLicensed: isLicensed,
              licenseStatus: isLicensed ? "Licensed" : "Not Licensed",
            }));
          } else if (response.hasErrors === true) {
            validation.setFieldError("premiseManager", rs.errors[0]);
          }
        } catch (err) {
          setError(err);
          setLoading(false);
          console.log(err);
        }
      } else if (rs.hasErrors === true) {
        setPremiseManagerValidation({
          isValid: false,
          name: null,
          status: "Not Approved",
        });
        validation.setFieldError("premiseManager", rs.errors[0]);
      } else {
        setPremiseManagerValidation({
          isValid: false,
          name: null,
          status: "Not Approved",
        });
      }
    } catch (err) {
      console.log(err);
      setLoading(false)
      setValidatePremiseManager(false)
      setError(["Failed to validate premise manager"]);
      validation.setFieldError("premiseManager", "Failed to validate premise manager");
    }
    setLoading(false)
    setValidatePremiseManager(false)
  };

  const getSubmitButtonState = () => {
    if (!id) {
      if (!whoAreYou) {
        return {disabled: true, reason: 'Please select an option in "Who are you?".'};
      }

      if (premiseOwnerValidation?.status === 'Not Approved') {
        return {disabled: true, reason: 'Premise Owner is not approved.'};
      } else if (premiseOwnerValidation?.licenseStatus === 'Not Licensed') {
        return {disabled: true, reason: 'Premise Owner is not licensed.'};
      } else if (whoAreYou !== 'premiseOwner' && !premiseOwnerValidation?.isValid) {
        return {disabled: true, reason: 'Premise Owner is not validated.'};
      }

      if (whoAreYou !== 'premiseOwner' && whoAreYou === 'premiseManager') {
        validation.values.premiseManager = ''
        setPremiseManagerValidation(null);
      }

      // Clear validation for premiseOwner if not showing
      if (whoAreYou !== 'premiseManager' && whoAreYou === 'premiseOwner') {
        validation.values.premiseOwner = ''
        setPremiseOwnerValidation(null);
      }
    }

    return { disabled: false, reason: '' };
  };

  const goBack = () => {
    if(userType === DESIGNATION.ADMIN) {
      navigate("/admin/premises")
    } else {
      navigate(-1)
    }
  }

  useEffect(() => {
    const { disabled, reason } = getSubmitButtonState();
    setDisabledReason(reason);
    setIsButtonDisabled(disabled);
  }, [whoAreYou, premiseOwnerValidation, premiseManagerValidation, validation.values.premiseManager]);


  useEffect(() => {
    if (id) {
      fetchUserPremise();
    }
  }, [fetchUserPremise]);

  useEffect(() => {
    const managerStatus = getStatus(premiseManagerValidation?.isValid, premiseManagerValidation?.isLicensed);
    setManagerStatusText(managerStatus.text);
    setManagerStatusType(managerStatus.type);

    const ownerStatus = getStatus(premiseOwnerValidation?.isValid, premiseOwnerValidation?.isLicensed); // You can reuse the same function as for manager status
    setOwnerStatusText(ownerStatus.text);
    setOwnerStatusType(ownerStatus.type);
  }, [premiseManagerValidation, premiseOwnerValidation]);

  return (
    <>
      <Container>
        <Row>
          <Card className="border-none border-white">
            <Row className="justify-content-between align-items-center">
              <Col xs={12} md={4} className="d-flex justify-content-start">

                <button
                    onClick={goBack}
                    className="btn text-decoration-none"
                    id="bggrey"
                >
                  <ArrowLeft size={16}/>
                  Back
                </button>
              </Col>

              <Col xs={12} md={4} className="d-flex justify-content-center">
                <span className="mb-0 fw-semibold fs-4">
                  {id ? "Edit your" : "Create New"}{" "}
                   Premise{" "}
                </span>
              </Col>

              <Col xs={12} md={4} className="d-flex justify-content-end">
                <div>
                  {error.map((e) => {
                    return (
                        <div
                            key={e}
                            className="alert w-100 text-center text-capitalize alert-danger alert-dismissible border-2 p-1 fade show"
                            role="alert"
                            style={{ background: "#fff", fontSize: "14px" }}
                        >
                          {e}
                        </div>
                    );
                  })}
                </div>
                <div>
                  {loading ? <Spinner color="danger" /> : ""}
                </div>
                {userType === DESIGNATION.ADMIN ? (
                    <button
                        id="bgred"
                        className="btn text-light"
                        onClick={() =>
                            handleToggleConfirmModal(
                                approval !== "Approved" ? "Approved" : "NotApproved"
                            )
                        }
                        type="button"
                    >
                      {approval !== "Approved" ? "Approve" : "Disapprove"}
                    </button>
                ) : (
                    ""
                )}
              </Col>
            </Row>
            <CardBody className="p-0">
              <div className="bg-white">
                <Form
                  className="pt-3"
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (!service) {
                      setServiceError("Please Select Service Offered");
                      validation.handleSubmit();
                    } else {
                      validation.handleSubmit();
                    }
                    return false;
                  }}
                >
                  <div
                    className="personal-information pb-3"
                    style={{ borderBottom: "1px solid #B0B0B0" }}
                  >
                    <p className="fw-bold fs-6">Basic Information</p>
                    <Row>
                      {userType === DESIGNATION.ADMIN && !id ? (
                        <Col xl={4} sm={12} md={6}>
                          <FormGroup className="mb-3">
                            <Label htmlFor="val1">VCN Number</Label>
                            <AsyncSelect
                              name="vcn"
                              placeholder="VCN Number"
                              isClearable={false}
                              className="react-select text-capitalize"
                              classNamePrefix="select"
                              getOptionValue={getOptionValues}
                              getOptionLabel={getOptionLabels}
                              loadOptions={fetchPractitionerByVcnId}
                              disabled={disabled}
                              onChange={(e) => {
                                validation.setFieldValue("userId", e.id);
                                validation.setFieldValue("vcn", e.vcnId);
                              }}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.vcn && validation.errors.vcn
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.vcn && validation.errors.vcn ? (
                              <FormFeedback type="invalid">
                                {validation.errors.vcn}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                      ) : (
                        ""
                      )}

                      <Col xl={4} sm={12} md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="val1">Facility Name</Label>
                          <Input
                            name="facility"
                            placeholder="Facility Name"
                            type="text"
                            className="form-control"
                            id="val1"
                            style={inputStyle}
                            disabled={disabled}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.facility || ""}
                            invalid={
                              validation.touched.facility &&
                              validation.errors.facility
                                ? true
                                : false
                            }
                          />
                          {validation.touched.facility &&
                          validation.errors.facility ? (
                            <FormFeedback type="invalid">
                              {validation.errors.facility}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col xl={4} sm={12} md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="val2">CSC Registration No</Label>
                          <Input
                            name="csc"
                            placeholder="RC: 00000"
                            type="text"
                            className="form-control"
                            id="val2"
                            style={inputStyle}
                            disabled={disabled}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.csc || ""}
                            invalid={
                              validation.touched.csc && validation.errors.csc
                                ? true
                                : false
                            }
                          />
                          {validation.touched.csc && validation.errors.csc ? (
                            <FormFeedback type="invalid">
                              {validation.errors.csc}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>

                      <Col xl={4} sm={12} md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="val3">Premise Type</Label>
                          <Input
                            name="premise"
                            type="select"
                            className="form-control"
                            id="val3"
                            disabled={disabled}
                            style={inputStyle}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.premise || ""}
                            invalid={
                              validation.touched.premise &&
                              validation.errors.premise
                                ? true
                                : false
                            }
                          >
                            <option>Select Premise Type</option>
                            <option>Clinic</option>
                            <option>OutPatient</option>
                            <option>Emergency Facility</option>
                            <option>Speciality Facility</option>
                            <option>Referrer</option>
                            <option>Center</option>
                          </Input>
                          {validation.touched.premise &&
                          validation.errors.premise ? (
                            <FormFeedback type="invalid">
                              {validation.errors.premise}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col xl={4} sm={12} md={6}>
                        <FormGroup className="mb-1">
                          <Label htmlFor="val4">Services Offered</Label>
                          <MultiSelectComboBox
                              selectedOptionsString={service}
                              onSelectionChange={handleServicesChange}
                              onBlur={() => setServiceTouched(true)}
                              options={serviceOptions}
                              errors={serviceError}
                              touched={serviceTouched}
                              header={'Select Services'}
                          />
                          {serviceError ? (
                              <FormFeedback className="d-block">
                                {serviceError}
                              </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col xl={4} sm={12} md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="val5">Facility Email Address</Label>
                          <Input
                            name="email"
                            placeholder="email"
                            type="email"
                            className="form-control"
                            id="val5"
                            style={inputStyle}
                            disabled={disabled}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col xl={4} sm={12} md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="val6">Phone Number</Label>
                          <Input
                            name="phone"
                            placeholder="Phone Number"
                            type="number"
                            className="form-control"
                            maxLength="11"
                            id="val5"
                            style={inputStyle}
                            disabled={disabled}
                            onChange={(e) => {
                              validation.setFieldValue("phone", e.target.value);
                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values.phone || ""}
                            invalid={
                              validation.touched.phone &&
                              validation.errors.phone
                                ? true
                                : false
                            }
                          />
                          {validation.touched.phone &&
                          validation.errors.phone ? (
                            <FormFeedback type="invalid">
                              {validation.errors.phone}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      {id && disabled === true ? (
                        <Col xl={4} sm={12} md={6}>
                          <Label
                            htmlFor="val7"
                            style={{ visibility: "hidden" }}
                          >
                            {" "}
                          </Label>
                          <div className="d-flex mt-2">
                            <button
                              type="button"
                              disabled={certificate ? false : true}
                              id="bgred"
                              onClick={() =>
                                downLoadFile({
                                  file: certificate,
                                  name: `${user?.fullName}-${user?.vcnId}-certificate`,
                                })
                              }
                              style={{
                                borderTopRightRadius: "0px",
                                borderBottomRightRadius: "0px",
                              }}
                              className="btn w-100 text-white"
                            >
                              Download Certificate
                            </button>
                            <Input
                              style={{
                                cursor: "pointer",
                                borderTopLeftRadius: "0px",
                                borderBottomLeftRadius: "0px",
                              }}
                              value={certificate?.substring(64)}
                              disabled
                              class="form-control"
                            />
                          </div>
                        </Col>
                      ) : (
                        <Col xl={4} sm={12} md={6}>
                          <FormGroup className="mb-4">
                            <Label htmlFor="val7">
                              Upload CSC Certificate{" "}
                            </Label>
                            <Input
                              name="certificate"
                              type="file"
                              className="form-control"
                              id="val7"
                              style={inputStyle}
                              disabled={disabled}
                              onChange={(e) => {
                                validation.setFieldValue(
                                  "certificate",
                                  e.currentTarget.files[0]
                                );
                              }}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.certificate &&
                                validation.errors.certificate
                                  ? true
                                  : false
                              }
                              accept="image/*"
                            />
                            {validation.touched.certificate &&
                            validation.errors.certificate ? (
                              <FormFeedback type="invalid">
                                {validation.errors.certificate}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                      )}
                    </Row>
                  </div>

                  <div className="address pt-3 pb-3"
                       style={{ borderBottom: "1px solid #B0B0B0" }}
                  >
                    <p className="fw-bold fs-6">Premise Management</p>
                    <Row>
                      {!id && (
                      <Col xl={4} sm={12} md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="whoAreYou">Who are you?</Label>
                          <Input
                              name="whoAreYou"
                              type="select"
                              className="form-control"
                              style={inputStyle}
                              id="whoAreYou"
                              onChange={(e) => setWhoAreYou(e.target.value)}
                              value={whoAreYou}
                          >
                            <option value="">Select an option</option>
                            <option value="premiseOwner">Premise Owner</option>
                            <option value="premiseManager">Premise Manager</option>
                            <option value="creatingPremise">Creating premise for the owner</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      )}

                      {whoAreYou !== 'premiseOwner' && whoAreYou !== '' && (
                          <Col xl={4} sm={12} md={6}>
                            <FormGroup className="mb-3">
                          <Label htmlFor="premiseOwner">Premise Owner</Label>
                          <Input
                              name="premiseOwner"
                              placeholder="Enter Vcn id or username"
                              type="text"
                              className={`form-control ${premiseOwnerValidation?.isValid ? 'is-valid' : premiseOwnerValidation !== null ? 'is-invalid' : ''}`}
                              style={{
                                backgroundColor: "#F5F5F5",
                                border: premiseOwnerValidation?.isValid ? "1px solid #2AC769" : "1px solid #EBEBEB",
                                backgroundImage: premiseOwnerValidation?.isValid && `url(${GreenCheckIcon})`,
                              }}
                              id="premiseOwner"
                              disabled={disabled}
                              onChange={validation.handleChange}
                              value={validation.values.premiseOwner || ''}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.premiseOwner &&
                                validation.errors.premiseOwner
                                    ? true
                                    : false
                              }
                          />
                          <div className='d-flex flex-row align-items-center gap-2'>
                            <button
                                type="button"
                                className="validate-btn mt-2"
                                onClick={() => validatePremiseOwner(validation.values.premiseOwner)}
                            >
                              Validate
                            </button>
                            {loading && validatePremiseOwners ? <Spinner color="danger" style={{ width: '8px',  height: '8px',  padding: '0.5em' }} /> : ""}
                            {premiseOwnerValidation && (
                                <div className='d-flex gap-2'>
                                  {premiseOwnerValidation.name && (
                                      <span
                                          className={`mt-2 ${premiseOwnerValidation.isValid ? 'success-pill' : 'error-pill'}`}>
                                          {premiseOwnerValidation.name}
                                      </span>
                                  )}
                                  <span
                                      className={`mt-2 text-uppercase ${ownerStatusType === 'success' ? 'success-pill-status' : 'error-pill-status'}`}>
                                      {ownerStatusText}
                                  </span>
                                </div>
                            )}
                          </div>
                              {validation.touched.premiseOwner &&
                              validation.errors.premiseOwner ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.premiseOwner}
                              </FormFeedback>
                          ) : null}
                        </FormGroup>
                          </Col>
                      )}

                      {whoAreYou !== 'premiseManager' && whoAreYou !== '' && (
                          <Col xl={4} sm={12} md={6}>
                            <FormGroup className="mb-3">
                          <Label htmlFor="premiseManager">Premise Manager</Label>
                          <Input
                              name="premiseManager"
                              placeholder="Enter Vcn id or username"
                              type="text"
                              className={`form-control ${premiseManagerValidation?.isValid ? 'is-valid' : premiseManagerValidation !== null ? 'is-invalid' : ''}`}
                              style={{
                                backgroundColor: "#F5F5F5",
                                border: premiseManagerValidation?.isValid ? "1px solid #2AC769" : "1px solid #EBEBEB",
                                backgroundImage: premiseManagerValidation?.isValid && `url(${GreenCheckIcon})`,
                              }}
                              id="premiseManager"
                              disabled={disabled}
                              onChange={validation.handleChange}
                              value={validation.values.premiseManager || ''}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.premiseManager &&
                                validation.errors.premiseManager
                                    ? true
                                    : false
                              }
                          />
                          <div className='d-flex flex-row align-items-center gap-2'>
                            <button
                                type="button"
                                className="validate-btn mt-2"
                                onClick={() => validatePremiseManager(validation.values.premiseManager)}
                            >
                              Validate
                            </button>
                            {loading && validatePremiseManagers ? <Spinner color="danger" style={{ width: '8px',  height: '8px', padding: '0.5em' }} /> : ""}
                            {premiseManagerValidation && (
                                <div className='d-flex gap-2'>
                                  {premiseManagerValidation.name && (
                                      <span
                                          className={`mt-2 ${premiseManagerValidation.isValid ? 'success-pill' : 'error-pill'}`}>
                                         {premiseManagerValidation.name}
                                     </span>
                                  )}

                                  <span
                                      className={`mt-2 text-uppercase ${managerStatusType === 'success' ? 'success-pill-status' : 'error-pill-status'}`}>
                                        {managerStatusText}
                                    </span>
                                </div>
                            )}
                          </div>
                              {validation.touched.premiseManager &&
                              validation.errors.premiseManager ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.premiseManager}
                              </FormFeedback>
                          ) : null}
                        </FormGroup>
                          </Col>
                      )}

                      {id && (
                          <Col xl={4} sm={12} md={6}>
                            <FormGroup className="mb-3">
                              <Label htmlFor="premiseManager">Premise Manager</Label>
                              <Input
                                  name="premiseManager"
                                  placeholder="Enter Vcn id or username"
                                  type="text"
                                  className={`form-control ${premiseManagerValidation?.isValid ? 'is-valid' : premiseManagerValidation !== null ? 'is-invalid' : ''}`}
                                  style={{
                                    backgroundColor: "#F5F5F5",
                                    border: premiseManagerValidation?.isValid ? "1px solid #2AC769" : "1px solid #EBEBEB",
                                    backgroundImage: premiseManagerValidation?.isValid && `url(${GreenCheckIcon})`,
                                  }}
                                  id="premiseManager"
                                  disabled={disabled}
                                  onChange={validation.handleChange}
                                  value={validation.values.premiseManager || ''}
                                  onBlur={validation.handleBlur}
                                  invalid={
                                    validation.touched.premiseManager && validation.errors.premiseManager ? true : false
                                  }
                              />
                              <div className='d-flex flex-row align-items-center gap-2'>
                                <button
                                    type="button"
                                    className="validate-btn mt-2"
                                    onClick={() => validatePremiseManager(validation.values.premiseManager)}
                                >
                                  Validate
                                </button>
                                {/* Render the validation status */}
                                {premiseManagerValidation && (
                                    <div className='d-flex gap-2'>
                                      {premiseManagerValidation.name && (
                                          <span
                                              className={`mt-2 ${premiseManagerValidation.isValid ? 'success-pill' : 'error-pill'}`}>
                                            {premiseManagerValidation.name}
                                          </span>
                                      )}
                                      <span
                                        className={`mt-2 text-uppercase ${managerStatusType === 'success' ? 'success-pill-status' : 'error-pill-status'}`}>
                                        {managerStatusText}
                                      </span>
                                    </div>
                                )}
                              </div>
                              {validation.touched.premiseManager && validation.errors.premiseManager && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.premiseManager}
                                  </FormFeedback>
                              )}
                            </FormGroup>
                          </Col>
                      )}
                    </Row>
                  </div>

                  <div className="address pt-3">
                    <p className="fw-bold fs-6">Address</p>
                    <Row>
                      <Col xl={4} sm={12} md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="val8">Company Address</Label>
                          <Input
                            name="address"
                            placeholder="12 Police Station Street"
                            type="text"
                            className="form-control"
                            id="val8"
                            style={inputStyle}
                            disabled={disabled}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.address || ""}
                            invalid={
                              validation.touched.address &&
                              validation.errors.address
                                ? true
                                : false
                            }
                          />
                          {validation.touched.address &&
                          validation.errors.address ? (
                            <FormFeedback type="invalid">
                              {validation.errors.address}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col xl={4} sm={12} md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="val9">State</Label>
                          <Input
                            name="state"
                            type="select"
                            className="form-control"
                            id="val9"
                            style={inputStyle}
                            disabled={disabled}
                            onChange={(e) => {
                              const stateId = document.querySelector("#val9");
                              const state_code =
                                stateId.options[
                                  stateId.selectedIndex
                                ].getAttribute("statecode");
                              validation.setFieldValue("stateCode", state_code);
                              validation.handleChange(e);
                              fetchLga(e.target.value);
                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values.state || ""}
                            invalid={
                              validation.touched.state &&
                              validation.errors.state
                                ? true
                                : false
                            }
                          >
                            <option>Select State</option>
                            {allState?.map((e) => {
                              return (
                                <option
                                  id={e.state__name}
                                  statecode={e.state__id}
                                  key={e.state__id}
                                >
                                  {e.state__name}
                                </option>
                              );
                            })}
                          </Input>
                          {validation.touched.state &&
                          validation.errors.state ? (
                            <FormFeedback type="invalid">
                              {validation.errors.state}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col xl={4} sm={12} md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="val10">LGA</Label>
                          <Input
                            name="lga"
                            type="select"
                            className="form-control"
                            id="val10"
                            style={inputStyle}
                            disabled={disabled}
                            onChange={(e) => {
                              const lgaId = document.querySelector("#val10");
                              const lga_code =
                                lgaId.options[lgaId.selectedIndex].getAttribute(
                                  "lgacode"
                                );
                              validation.setFieldValue("lgaCode", lga_code);
                              validation.handleChange(e);
                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values.lga || ""}
                            invalid={
                              validation.touched.lga && validation.errors.lga
                                ? true
                                : false
                            }
                          >
                            <option>Select LGA</option>
                            {allLga?.map((e) => {
                              return (
                                <option
                                  lgacode={e["LGA CODE"]}
                                  key={e["LGA CODE"]}
                                >
                                  {e["LGA NAME"]}
                                </option>
                              );
                            })}
                          </Input>
                          {validation.touched.lga && validation.errors.lga ? (
                            <FormFeedback type="invalid">
                              {validation.errors.lga}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <Row>
                    <Col xl={8}>
                      <div className="form-check mb-3 checkBox pt-4">
                        <Label
                          className="form-check-label"
                          htmlFor="validationFormCheck1"
                        >
                          <Input
                            type="checkbox"
                            className="form-check-input checkBox"
                            id="validationFormCheck1"
                            disabled={disabled}
                            required
                          />
                          <span className='text-black'>
                              I declare that all information I have provided are
                              correct and agree with
                          </span>

                          <Link
                            to="#"
                            onClick={() => setShowT(!showT)}
                            className="textRed text-decoration-none"
                          >
                            {" "}
                            Terms and Condition
                          </Link>
                        </Label>
                      </div>
                    </Col>
                    <Col xl={4}>
                      <div className="mt-4 float-end text-center">
                        <div
                            onMouseEnter={() => setTooltipOpen(isButtonDisabled)}
                            onMouseLeave={() => setTooltipOpen(false)}
                        >
                          <button
                            id="submit-button"
                            className=" btn btn-danger text-light"
                            type="submit"
                            disabled={isButtonDisabled || serviceError}
                          >
                            {id ? "Update" : "Register"}
                          </button>
                        </div>
                        <Tooltip
                            placement="bottom"
                            isOpen={tooltipOpen}
                            target="submit-button"
                        >
                          {disabledReason}
                        </Tooltip>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
              <div className="text-center pt-5">
                <p className="mb-0">
                  <img
                    src={FooterLogo}
                    width="20"
                    className="img-fluid"
                    alt="footer"
                  />{" "}
                  Powered by Milsat Technologies
                </p>
              </div>
            </CardBody>
          </Card>
        </Row>
      </Container>
      <SuccessModal
        show={showSuccessModal}
        setShow={setShowSuccessModal}
        bgTxt={bgTxt}
        smTxt={smTxt}
        action={() => navigate('/premises')}
      />
      <ConfrimActionsModal
        show={showConfirmModal}
        setShow={setShowConfirmModal}
        text={text}
        smText={""}
        action={handleApprovalStatus}
      />
      <TermsAndPolicy
        show={showT}
        setShow={setShowT}
        termsData={termsAndCondtion}
      />
    </>
  );
};

export default RegisterPremises;
