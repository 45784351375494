import LoginComponent from "../components/Login";
const Login = () => {
  return (
    <div style={{ backgroundColor: "#F7F7F7", height: "100vh" }}>
      <LoginComponent />
    </div>
  );
};

export default Login;
